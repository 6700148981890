import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';

import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  GridLayoutAdditionalHeader,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { HolidayStatement } from './HolidayStatement';
import { SearchBinding } from '../../../../models/common';
import { Date6Calculator } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { Range } from '../../../../utils/array';

/**
 * 화면
 * @window w_pb204w_01
 * @category 휴가현황
 */

export const HolidayStatementTemplate: React.FC<TemplateProps<HolidayStatement>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={400}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.yearmon}
            textAlign="center"
            format="####-##"
            onChange={
              (value) => {
                // eslint-disable-next-line radix,max-len
                if (scope.state.nyearmon < value || parseInt(value) < parseInt(scope.state.nyearmon) - 10) {
                  ConfirmWarning.show('경고', '올바른 기간을 설정해주세요.');
                  scope.setState({ yearmon: value });
                } else {
                  scope.setState({ yearmon: value });
                }
              }
            }
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                // eslint-disable-next-line max-len
                scope.setState({
                  yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString(),
                  nyearmon: new Date6Calculator(scope.state.nyearmon).add(-1).toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                // eslint-disable-next-line max-len
                scope.setState({
                  yearmon: new Date6Calculator(scope.state.yearmon).add().toString(),
                  nyearmon: new Date6Calculator(scope.state.nyearmon).add().toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
          <DateTextBox
            value={scope.state.nyearmon}
            textAlign="center"
            format="####-##"
            onChange={
              (value) => {
                // eslint-disable-next-line radix,max-len
                if (scope.state.yearmon > value || parseInt(scope.state.yearmon) < parseInt(value) - 10) {
                  ConfirmWarning.show('경고', '올바른 기간을 설정해주세요.');
                  scope.setState({ nyearmon: value });
                } else {
                  scope.setState({ nyearmon: value });
                }
              }
            }
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                // eslint-disable-next-line max-len
                scope.setState({
                  yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString(),
                  nyearmon: new Date6Calculator(scope.state.nyearmon).add(-1).toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                // eslint-disable-next-line max-len
                scope.setState({
                  yearmon: new Date6Calculator(scope.state.yearmon).add().toString(),
                  nyearmon: new Date6Calculator(scope.state.nyearmon).add().toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={200}>
          <span>재고유무</span>
          <ComboBox
            value={scope.state.mpclafi}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '상주' },
              { value: '0', remark: '직영' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => scope.setState({ mpclafi: option.value },
              () => {
                scope.onRetrieveEvent();
              })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>발주부서</span>
          <TextBox
            value={scope.state.divicd}
            textAlign="center"
            onChange={(value) => scope.setState({ divicd: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                scope.setState({
                  divicd: item.cd,
                  divinm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.divinm}
            weight={2}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>사용자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.pernm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout isVertical={true}>
        <GridLayoutAdditionalHeader
          ref={scope.additionalTitle}
          height={Global.LAYOUT_TITLE_HEIGHT_1}
          contentWidth={2246}
        >
          <FlexLayout
            size={261}
            justify="center"
            align="center"
          >
            항목
          </FlexLayout>

          <FlexLayout
            size={990}
            justify="center"
            align="center"
          >
            {`${scope.state.yearmon.substring(4, 6)}월`}
          </FlexLayout>

          <FlexLayout
            size={990}
            justify="center"
            align="center"
          >
            {`${scope.state.nyearmon.substring(4, 6)}월`}
          </FlexLayout>
        </GridLayoutAdditionalHeader>

        <GridLayout
          ref={scope.grid}
          headerHeight={Global.LAYOUT_SEARCH_HEIGHT_1}
          isScroll={true}
          header={[
            {
              id: 'seq',
              text: '순번',
              width: 40,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.seq}
                </FlexLayout>
              ),
            },
            {
              id: 'divinm',
              text: '부서',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '성명',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'daynum',
              text: '일수',
              width: 40,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.daynum}
                </FlexLayout>
              ),
            },
            // 앞달 1~31일
            ...Range.make(31).map((key) => (
              {
                id: `date${key + 1}`,
                text:
                  <FlexLayout
                    style={{
                      // @ts-ignore
                      color: scope.state.holidayStatementList[0]?.[`weekday${key + 1}`] === '토'
                        // @ts-ignore
                        ? 'var(--color-blue)' : scope.state.holidayStatementList[0]?.[`weekday${key + 1}`] === '일'
                          ? 'var(--color-red)' : 'var(--color-black)',
                    }}
                    isVertical={true}
                  >
                    <FlexLayout>
                      {key + 1}
                    </FlexLayout>
                    <FlexLayout style={{ marginTop: -20 }}>
                      {/* @ts-ignore */}
                      {scope.state.holidayStatementList[0]?.[`weekday${key + 1}`]}
                    </FlexLayout>
                  </FlexLayout>,
                width: 30,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <div style={{
                      // @ts-ignore
                      color: (scope.state.holidayStatementList[0]?.[`weekday${key + 1}`] === '토'
                        // @ts-ignore
                        || scope.state.holidayStatementList[0]?.[`weekday${key + 1}`] === '일')
                        // @ts-ignore
                        ? 'darkorange' : scope.state.holidayStatementList[x.seq - 1]?.[`date${key + 1}`] !== ''
                          ? 'red' : undefined,
                    }}>
                    {
                      // @ts-ignore
                      scope.state.holidayStatementList[0]?.[`weekday${key + 1}`] === '토'
                      // @ts-ignore
                      || scope.state.holidayStatementList[0]?.[`weekday${key + 1}`] === '일'
                        // @ts-ignore
                        ? '휴' : scope.state.holidayStatementList[x.seq - 1]?.[`date${key + 1}`]
                    }
                    </div>
                  </FlexLayout>
                ),
              }
            )),
            // 뒷달 1~31일
            ...Range.make(31).map((key) => (
              {
                id: `ndate${key + 1}`,
                text:
                  <FlexLayout
                    style={{
                      // @ts-ignore
                      color: scope.state.holidayStatementList[0]?.[`nweekday${key + 1}`] === '토'
                        // @ts-ignore
                        ? 'var(--color-blue)' : scope.state.holidayStatementList[0]?.[`nweekday${key + 1}`] === '일'
                          ? 'var(--color-red)' : 'var(--color-black)',
                    }}
                    isVertical={true}
                  >
                    <FlexLayout>
                      {key + 1}
                    </FlexLayout>
                    <FlexLayout style={{ marginTop: -20 }}>
                      {/* @ts-ignore */}
                      {scope.state.holidayStatementList[0]?.[`nweekday${key + 1}`]}
                    </FlexLayout>
                  </FlexLayout>,
                width: 30,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <div style={{
                      // eslint-disable-next-line no-nested-ternary
                      // @ts-ignore
                      color: (scope.state.holidayStatementList[0]?.[`nweekday${key + 1}`] === '토'
                        // @ts-ignore
                        || scope.state.holidayStatementList[0]?.[`nweekday${key + 1}`] === '일')
                        // @ts-ignore
                        ? 'darkorange' : scope.state.holidayStatementList[x.seq - 1]?.[`ndate${key + 1}`] !== ''
                          ? 'red' : undefined,
                    }}>
                      {
                        // @ts-ignore
                        scope.state.holidayStatementList[0]?.[`nweekday${key + 1}`] === '토'
                        // @ts-ignore
                        || scope.state.holidayStatementList[0]?.[`nweekday${key + 1}`] === '일'
                          // @ts-ignore
                          ? '휴' : scope.state.holidayStatementList[x.seq - 1]?.[`ndate${key + 1}`]
                      }
                    </div>
                  </FlexLayout>
                ),
              }
            )),
          ]}
          contentWidth={2246}
          data={scope.state.holidayStatementList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
          onHorizontalScroll={(x) => scope.additionalTitle.current?.scrollTo(x)}
        />
      </FlexLayout>
    </FlexLayout>
);
