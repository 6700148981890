import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_xlogin_01
 * @category 시스템접속현황
 */

export class SystemModel {
  /**
   * 사용일자
   *
   * **data type** datetime
   */
  readonly londt: string;

  /**
   * 사용일자
   *
   * **data type** datetime
   */
  readonly londtdate: string;

  /**
   * 사용일자
   *
   * **data type** datetime
   */
  readonly londttime: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 사용자
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 사용자
   *
   * **data type** varchar(10)
   */
  pernm: string;

  /**
   * 윈도우명
   *
   * **data type** varchar(50)
   */
  winid: string;

  /**
   * 사용내역
   *
   * **data type** varchar(5)
   */
  buton: string;

  /**
   * 사용한화면
   *
   * **data type** varchar(100)
   */
  winnm: string;

  /**
   * 종료시간
   *
   * **data type** varchar(5)
   */
  endtime: string;

  /**
   * 접속횟수
   *
   * **data type** float
   */
  percnt: string;

  /**
   * 접속ip
   *
   * **data type** varchar(20)
   */
  logip: string;

  /**
   * 접속플랫폼
   *
   * **data type** char(20)
   */
  logname: string;

  /**
   * 로그인기록
   *
   * **data type** text
   */
  logtext: string;

  /**
   * 사용순번
   *
   * **data type** varchar(3)
   */
  lognum: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.londt = data.londt || '';
    this.londtdate = data.londtdate || '';
    this.londttime = data.londttime || '';
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.winid = data.winid || '';
    this.buton = data.buton || '';
    this.winnm = data.winnm || '';
    this.endtime = data.endtime || '';
    this.percnt = data.percnt || '';
    this.logip = data.logip || '';
    this.logname = data.logname || '';
    this.logtext = data.logtext || '';
    this.lognum = data.lognum || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
