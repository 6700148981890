import { GLHF, KTNET_STATES } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Collect } from '../Collect';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

const gubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

/**
 * 화면 > 탭 > 매출내역 - 그리드 레이아웃
 * @window w_tb_e601w_sulchi_02
 * @category 설치수금현황
 */
export const CollectTabDaGridHeader: GLHF<any, Collect> = (scope) => ([
  {
    id: 'misdate',
    text: '매출일자',
    width: 100,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.misdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'misnum',
    text: '번호',
    width: 100,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        건수
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.misnum}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '매출분류',
    width: 100,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope.state.dataDa.length}
      </FlexLayout>
    ),
    render: (x) => {
      const fp = gubuns.filter((y) => y.value === x.gubun);
      const nm = fp.length > 0 ? fp[0].remark : '';

      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'cltnm',
    text: '거래처명',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {Date8.withDash(x.actnm) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'taxcls',
    text: '부가세여부',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.taxcls === '0' ? '별도' : '포함'}
      </FlexLayout>
    ),
  },
  {
    id: 'amt',
    text: '매출액',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.all(scope.state.dataDa, 'amt'))}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.amt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'addamt',
    text: '부가세',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.all(scope.state.dataDa, 'addamt'))}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.addamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '합계',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.all(scope.state.dataDa, 'misamt'))}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.misamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '입금액',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.all(scope.state.dataDa, 'rcvamt'))}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.rcvamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'jamt',
    text: '잔액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number((x.misamt || 0) - (x.rcvamt || 0)) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'accyn',
    text: '전자세금계산서',
    width: 120,
    render: (x) => {
      const fp = KTNET_STATES.filter((y) => y.value === x.recivstatus.trim());
      const nm = fp.length > 0 ? fp[0].remark : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{
            color,
          }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'remark',
    text: '적요',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
]);
