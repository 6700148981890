import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import {
  Gubuns,
  States,
  Status,
} from '../Status';
import StatusDetailModel from '../models/StatusDetailModel';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_da451w_01
 * @category 설치견적현황
 */
export const StatusDetailGridHeader: GLHF<StatusDetailModel, Status> = (scope) => ([
  {
    id: 'costdate',
    text: '견적일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.costdate)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="center" align="center">
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'costnum',
    text: '번호',
    width: 80,
  },
  {
    id: 'contflag',
    text: '계약구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Gubuns.filter((y) => y.key === x.contflag)[0]?.value}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '매출구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope.state.gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm}
      </FlexLayout>
    ),
  },
  {
    id: 'constnm',
    text: '공사명',
    width: 300,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.constnm}
      </FlexLayout>
    ),
  },
  {
    id: 'myn',
    text: '유무상',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.myn === '1' ? '무상' : '유상'}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품목명',
    width: 300,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 300,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.psize}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="left" align="center">
        {Format.number(scope.state.total2)}건
      </FlexLayout>
    ),
  },
  {
    id: 'jeoban',
    text: '제어반',
    width: 80,
  },
  {
    id: 'nperson',
    text: '인승',
    width: 80,
  },
  {
    id: 'number',
    text: '층수',
    width: 80,
  },
  {
    id: 'state',
    text: '상태',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{ color: x.state === '1' ? 'var(--color-blue)' : '' }}
      >
        {States.filter((y) => y.key === x.state)[0]?.value}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 80,
    trail: () => (
      <FlexLayout justify="center" align="center">
        {Format.number(scope.state.tabDetailqty_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.uamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="end" align="center">
        {Format.number(scope.state.tabDetailuamt_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="end" align="center">
        {Format.number(scope.state.tabDetailsamt_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.tamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="end" align="center">
        {Format.number(scope.state.tabDetailtamt_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'mamt',
    text: '공사금액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="end" align="center">
        {Format.number(scope.state.tabDetailmamt_tot)}
      </FlexLayout>
    ),
  },
]);
