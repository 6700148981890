import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_e301
 * @category 민원접수등록
 */

export class ReportModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** varchar(3)
   */
  readonly state: string;

  /**
   * 민원일자
   *
   * **data type** varchar(3)
   */
  readonly recedate: string;

  /**
   * 번호
   *
   * **data type** varchar(3)
   */
  readonly recenum: string;

  /**
   * 고객/민원신고자명
   *
   * **data type** varchar(3)
   */
  readonly actnm: string;

  /**
   * 완료일자
   *
   * **data type** varchar(3)
   */
  readonly compdate: string;

  /**
   * 민원접수일자
   *
   * **data type** varchar(3)
   */
  readonly recetime: string;

  /**
   * 접수자
   *
   * **data type** varchar(3)
   */
  readonly reperid: string;

  /**
   * 민원접수일자
   *
   * **data type** varchar(3)
   */
  readonly repernm: string;

  /**
   * 현장담당자
   *
   * **data type** varchar(5)
   */
  readonly actperid: string;

  /**
   * 민원접수일자
   *
   * **data type** varchar(100)
   */
  readonly actpernm: string;

  /**
   * 고객명
   *
   * **data type** varchar(5)
   */
  readonly actcd: string;

  /**
   * 고객연락처
   *
   * **data type** varchar(100)
   */
  readonly acttelnum: string;

  /**
   * 민원구분
   *
   * **data type** varchar(100)
   */
  readonly civilcd: string;

  /**
   * 민원구분
   *
   * **data type** varchar(100)
   */
  readonly civilnm: string;

  /**
   * 민원처리자
   *
   * **data type** varchar(100)
   */
  readonly perid: string;

  /**
   * 민원처리자
   *
   * **data type** varchar(100)
   */
  readonly pernm: string;

  /**
   * 민원처리자부서
   *
   * **data type** varchar(100)
   */
  readonly divicd: string;

  /**
   * 민원처리자부서
   *
   * **data type** varchar(100)
   */
  readonly divinm: string;

  /**
   * 처리자배정
   *
   * **data type** varchar(100)
   */
  readonly okflag: string;

  /**
   * 민원상세내용
   *
   * **data type** varchar(3)
   */
  readonly remark: string;

  /**
   * ? 모름
   *
   * **data type** varchar(3)
   */
  readonly civilflag: string;

  /**
   * ? 모름
   *
   * **data type** varchar(3)
   */
  readonly recetelnum: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.recedate = data.recedate || '';
    this.recenum = data.recenum || '';
    this.actnm = data.actnm || '';
    this.compdate = data.compdate || '';
    this.recetime = data.recetime || '';
    this.reperid = data.reperid || '';
    this.repernm = data.repernm || '';
    this.actperid = data.actperid || '';
    this.actpernm = data.actpernm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.acttelnum = data.acttelnum || '';
    this.civilcd = data.civilcd || '';
    this.civilnm = data.civilnm || '';
    this.civilflag = data.civilflag || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.okflag = data.okflag || '';
    this.remark = Fix.newline(data.remark) || '';
    this.recetelnum = data.recetelnum || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
