import { computed } from 'mobx';

/**
 * 가족 모델
 * @window w_p2110
 * @category 인사등록
 */
export class FamilyModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 직원번호
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 순번
   *
   * **data type** varchar(2)
   */
  readonly seq: string;

  /**
   * 주민등록번호
   *
   * **data type** varchar(13)
   */
  readonly faminum: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  readonly faminm: string;

  /**
   * 관계
   *
   * **data type** varchar(3)
   */
  readonly concd: string;

  /**
   * 기본공제
   *
   * **data type** varchar(1)
   */
  readonly sureyn: string;

  /**
   * 내국인
   *
   * **data type** varchar(1)
   */
  readonly resyn: string;

  /**
   * 장애
   *
   * **data type** varchar(1)
   */
  readonly disperyn: string;

  /**
   * 자녀양육
   *
   * **data type** varchar(1)
   */
  readonly childBryn: string;

  /**
   * 학력
   *
   * **data type** varchar(30)
   */
  readonly schnm: string;

  /**
   * 직업
   *
   * **data type** varchar(30)
   */
  readonly jobnm: string;

  /**
   * 직장명
   *
   * **data type** varchar(1)
   */
  readonly compnm: string;

  /**
   * 보험
   *
   * **data type** varchar(1)
   */
  readonly jd001_1: string;

  /**
   * 의료
   *
   * **data type** varchar(1)
   */
  readonly jd001_2: string;

  /**
   * 교육
   *
   * **data type** varchar(1)
   */
  readonly jd001_3: string;

  /**
   * 신용카드
   *
   * **data type** varchar(1)
   */
  readonly jd001_4: string;

  /**
   * 생일
   *
   * **data type** varchar(8)
   */
  readonly birthday: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.seq = data.seq || '';
    this.faminum = data.faminum || '';
    this.faminm = data.faminm || '';
    this.concd = data.concd || '';
    this.sureyn = data.sureyn || '';
    this.resyn = data.resyn || '';
    this.disperyn = data.disperyn || '';
    this.childBryn = data.childBryn || '';
    this.schnm = data.schnm || '';
    this.jobnm = data.jobnm || '';
    this.compnm = data.compnm || '';
    this.jd001_1 = data.jd001_1 || '';
    this.jd001_2 = data.jd001_2 || '';
    this.jd001_3 = data.jd001_3 || '';
    this.jd001_4 = data.jd001_4 || '';
    this.birthday = data.birthday || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
