import * as React from 'react';
import {
  Button, ComboBox, ComboBoxModel,
  FlexLayout, FormatTextBox, OptionBox, SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SelfInspection } from './SelfInspection';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';


/**
 * 화면
 * @window w_tb_e032_new
 * @category 자체점검항목등록
 */
export const SelfInspectionTemplate: React.FC<TemplateProps<SelfInspection>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout weight={0.2}>
          <span>종류</span>
          <ComboBox
            value={scope.state.searchEvlcd}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.evlcds?.map((x) => new ComboBoxModel(x.evlcd, x.evlnm))}
            onSelect={(option) => setData({ searchEvlcd: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'gevcd',
              text: '구분코드',
              width: 2,
            },
            {
              id: 'gevnm',
              text: '구분명',
              width: 7,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.gevnm}
                  onChange={(value) => rowUpdate({ gevnm: value })}
                />
              ),
            },
            {
              id: 'remark',
              text: '비고',
              width: 10,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.remark}
                  onChange={(value) => rowUpdate({ remark: value })}
                />
              ),
            },
            {
              id: 'SEL_CHK_ITEM_CD',
              text: '정보센터연동코드',
              width: 3,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.SEL_CHK_ITEM_CD}
                  onChange={(value) => rowUpdate({ SEL_CHK_ITEM_CD: value })}
                />
              ),
            },
          ]}
          data={scope.state.data}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          newOnlyOne={true}
          infinityHandler={scope.infinity}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
        />
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
        <FlexLayout justify="right" weight={3}>
          <Button
            style={{ height: '25px', width: '60px' }}
            onClick={() => scope.onNewEvent2()}
          >
            추가
          </Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.onDeleteEvent2()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <TableLayout
          ref={scope.tableDetail}
          header={[
            {
              id: 'evcd',
              text: 'No',
              width: 10,
            },
            {
              id: 'evnm',
              text: '검사항목.장치',
              width: 50,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.evnm}
                  onChange={(value) => rowUpdate({ evnm: value })}
                />
              ),
            },
            {
              id: 'evdtlnm',
              text: '세부사항',
              width: 50,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.evdtlnm}
                  onChange={(value) => rowUpdate({ evdtlnm: value })}
                />
              ),
            },
            {
              id: 'evmon',
              text: '점검주기',
              width: 10,
              render: (x, rowUpdate, ref) => (
                <FormatTextBox
                  ref={ref}
                  textAlign="center"
                  value={x.evmon ?? ''}
                  format="#개월"
                  onChange={(value) => rowUpdate({ evmon: value })}
                />
              ),
            },
            {
              id: 'state',
              text: '상태(기본값)',
              width: 50,
              render: (x, rowUpdate) => (
                <OptionBox
                  value={x.state}
                  data={[
                    { value: 'A', remark: '양호' },
                    { value: 'B', remark: '주의관찰' },
                    { value: 'C', remark: '긴급수리' },
                    { value: 'D', remark: '제외' },
                    { value: 'E', remark: '없음' },
                  ]}
                  onChange={(item) => rowUpdate({ state: item.value })}
                />
              ),
            },
            {
              id: 'SEL_CHK_ITEM_CD',
              text: '정보센터연동코드',
              width: 20,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.SEL_CHK_ITEM_CD}
                  onChange={(value) => rowUpdate({ SEL_CHK_ITEM_CD: value })}
                />
              ),
            },
          ]}
          data={scope.state.inspectiondata}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity2}
          onChange={(rows2, updatedRows2) => {
            scope.onUpdatedRows2(rows2, updatedRows2);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
        />
      </FlexLayout>

    </FlexLayout>
  );
};
