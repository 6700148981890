import { action } from 'mobx';
import * as React from 'react';
import { PageComponent } from '../../../../utils';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { ReturnRegistrationTemplate } from './ReturnRegistration.template';
import { ReturnRegistrationModel } from './models/ReturnRegistration.model';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { ConfirmWarning } from '../../../../utils/confirm';
import { ReturnDetailRegistrationDetailModel } from './models/ReturnDetailRegistrationDetail.model';
import { SupplyDetailModel } from '../Supply/models';
import Confirm from '../../../../utils/confirm/Confirm';
import { ReturnModalModel } from './models/ReturnModal.model';
import { ActionRepository } from '../../../../repositories';
import { Date8 } from '../../../../utils/time';

interface ReturnResistrationState {
  // 조회 조건
  stdate: string;
  enddate: string;
  searchQuery: string;

  // 왼쪽 그리드
  returnList: Array<ReturnRegistrationModel>;
  focusedList: ReturnRegistrationModel;

  // 오른쪽 리스트
  returnDate: string;
  rowFocusedData: ReturnRegistrationModel;

  // 하단 detail (반납내역)
  detailData: Array<ReturnDetailRegistrationDetailModel>;
  detailFocusedData: ReturnDetailRegistrationDetailModel

  // 팝업창
  addModal: boolean;
  popupData: Array<ReturnModalModel>;
  popupDelDate: string;

  // 팝업창 조회조건
  popupPcode: string;
  popupPname: string;
  popupPsize: string;

  detailFocusedIndex: number;
}

/**
 * 컨트롤러
 * @window w_tb_da038_01
 * @category 반납등록
 */
export class ReturnRegistration extends PageComponent<PageProps, ReturnResistrationState> implements PageToolEvents {
  updatedRows?: Array<SupplyDetailModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);

    this.props.onMount && this.props.onMount(this);

    const today = new Date();
    const year = today.getFullYear();
    let month: string | number = today.getMonth() + 1;

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate();

    if (date < 10) {
      date = `0${date}`;
    }

    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',

      // 왼쪽 그리드
      returnList: [],
      focusedList: [],

      // 오른쪽 리스트
      returnDate: `${year}${month}${date}`,
      rowFocusedData: [],

      // 하단 detail (반납내역)
      detailData: [],
      detailFocusedData: [],

      // 팝업창
      addModal: false,
      popupData: [],
      popupDelDate: '00000000',

      // 팝업창 조회조건
      popupPcode: '',
      popupPname: '',
      popupPsize: '',

      detailFocusedIndex: 0,
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          returnList: [...this.state.returnList, ...items],
        });
      },
      async () => {
        await this.SS({
          returnList: [],
        });

        await this.infinity?.retrieveAll();
      },
    );


    await this.setState({
      returnList: [],
      detailData: [],
      focusedList: new ReturnRegistrationModel(false),
      rowFocusedData: new ReturnRegistrationModel(false),
    });

    await this.infinity?.retrieveAll();

    if (this.state.returnList.length > 0) {
      this.grid.current?.setFocus(0);
    }

    // 삭제 시 detail update
    await this.table.current?.update(true);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    if (this.state.focusedList?.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const data = await api.new();

    // 왼쪽 List 신규Data 추가
    // 반납내역 List 초기화
    // 포커스 맨 위
    if (data) {
      await this.setState({
        returnList: [new ReturnRegistrationModel(data, true), ...this.state.returnList],
        detailData: [],
      });
    }
    await this.grid.current?.setFocus(0);
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    if (this.state.returnList.length < 1) {
      ConfirmWarning.show('경고', '삭제할 내역이 없습니다.');
      return;
    }

    const text = `반납일자: ${Date8.withDash(this.state.rowFocusedData?.bandate)}\n 반납번호: ${this.state.rowFocusedData?.bannum}\n 삭제하시겠습니까?`;

    await api.delete(text, {
      bandate: this.state.rowFocusedData?.bandate,
      bannum: this.state.rowFocusedData?.bannum,
      store: this.state.rowFocusedData?.store,
    }) && await this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (this.state.returnList?.length < 1) {
      ConfirmWarning.show('경고', '저장할 내역이 없습니다');
      return;
    }

    // 반납자 공백일 시
    if (this.state.rowFocusedData?.perid.trim() === '' || this.state.rowFocusedData?.pernm.trim() === '') {
      ConfirmWarning.show('경고', '반납자를 확인해주세요');
      return;
    }

    // 반납내역 없을 시
    if (this.state.detailData?.length < 1) {
      ConfirmWarning.show('경고', '불출할 품목이 없습니다');
      return;
    }

    await api.save({
      new: this.state.rowFocusedData?.new,
      custcd: this.state.rowFocusedData?.custcd,
      spjangcd: this.state.rowFocusedData?.spjangcd,
      bandate: this.state.rowFocusedData?.bandate,
      bannum: this.state.rowFocusedData?.bannum,
      bantype: this.state.rowFocusedData?.bantype,
      store: this.state.rowFocusedData?.store,
      storenm: this.state.rowFocusedData?.storenm,
      cltcd: this.state.rowFocusedData?.cltcd,
      cltnm: this.state.rowFocusedData?.cltnm,
      perid: this.state.rowFocusedData?.perid,
      pernm: this.state.rowFocusedData?.pernm,
      divicd: this.state.rowFocusedData?.divicd,
      taxcls: this.state.rowFocusedData?.taxcls,
      setcls: this.state.rowFocusedData?.setcls,
      domcls: this.state.rowFocusedData?.domcls,
      taxflag: this.state.rowFocusedData?.taxflag,
      taxdate: this.state.rowFocusedData?.taxdate,
      taxnum: this.state.rowFocusedData?.taxnum,
      selflag: this.state.rowFocusedData?.selflag,
      remark: this.state.rowFocusedData?.remark,
      serinum: this.state.rowFocusedData?.serinum,
      sgmdate: this.state.rowFocusedData?.sgmdate,
      indate: this.state.rowFocusedData?.indate,
      inperid: this.state.rowFocusedData.inperid,
      state: this.state.rowFocusedData?.state,
      deldate: this.state.rowFocusedData?.deldate,
      delnum: this.state.rowFocusedData?.delnum,
      actcd: this.state.rowFocusedData?.actcd,
      actnm: this.state.rowFocusedData?.actnm,
      compperid: this.state.rowFocusedData?.compperid,
      compdivicd: this.state.rowFocusedData?.compdivicd,
      compdate: this.state.rowFocusedData?.compdate,
      compflag: this.state.rowFocusedData?.compflag,
      receperid: this.state.rowFocusedData?.receperid,
      recepernm: this.state.rowFocusedData?.recepernm,
      pumnum: this.state.rowFocusedData?.pumnum,
      pumdate: this.state.rowFocusedData?.pumdate,
      bansayoo: this.state.rowFocusedData?.bansayoo,
    }, this.state.rowFocusedData?.isNew);
  }

  @action
  async onRowFocusEvent(item: ReturnRegistrationModel) {
    const { actionStore: api } = this.props;

    // 신규 일 때 setfocus(0)으로 인해
    // rowfocus 데이터말고 신규데이터로 값 넣어주기
    if (item?.isNew) {
      await this.SS({
        focusedList: new ReturnRegistrationModel(item, true),
        rowFocusedData: new ReturnRegistrationModel(item, true),
      });
      this.table.current?.update(true);
      return;
    }

    const data = await api.fxExec('dw_1_RowFocuschanged',
      {
        bandate: item.bandate,
        bannum: item.bannum,
      });

    // 신규 아닐 때 rowfocus 시
    await this.SS({
      focusedList: new ReturnRegistrationModel(item, false),
      rowFocusedData: new ReturnRegistrationModel(data, false),
      detailData: data?.items || [],
    });

    // rowfocus 시 반납내역 update 및 focus 0으로
    await this.table.current?.update(true);
    await this.table.current?.setFocus(0);
  }

  @action
  onDetailUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ detailData: rows });
  }

  @action
  async onDetailNewEvent() {
    const { modalStore } = this.props;

    if (this.state.detailData?.length > 0) {
      ConfirmWarning.show('경고', '반납등록은 수정이나 새로 추가 할 수 없습니다.');
      return;
    }

    // 반납내역 없으면 제품선택하라고 팝업창
    if (this.state.detailData?.length < 1) {
      const result = await Confirm.show('확인', '먼저 제품을 선택해주세요', ConfirmType.QUESTION);

      // 취소누르면 return;
      if (!result) return;

      // 반납내역 없으면 제품코드 팝업창 open
      // 제품코드 선택 후 불출내역 팝업창 oepn
      const data = await modalStore.openProductSelector();

      await this.SS({
        popupPcode: data?.phm_pcod,
        popupPname: data?.phm_pnam,
        popupPsize: data?.phm_size,
        addModal: true,
      });
      await this.returnPopupRetrieve();
    }
  }

  @action
  async onDetailDeleteEvent() {
    const { actionStore: api } = this.props;

    if (this.state.detailData?.length < 1) {
      ConfirmWarning.show('경고', '삭제 할 내역이 없습니다.');
      return;
    }

    const text = `품목코드: ${this.state.detailFocusedData?.pcode}\n품 명: ${this.state.detailFocusedData?.pname}\n삭제하시겠습니까?`;

    await api.fxDelete(
      'dw_3_delete',
      text,
      {
        new: this.state.detailFocusedData?.new,
        bandate: this.state.detailFocusedData?.bandate,
        bannum: this.state.detailFocusedData?.bannum,
        banseq: this.state.detailFocusedData?.banseq,
        store: this.state.detailFocusedData?.store,
        deldate: this.state.detailFocusedData?.deldate,
        delnum: this.state.detailFocusedData?.delnum,
        delseq: this.state.detailFocusedData?.delseq,
        qty: this.state.detailFocusedData?.qty,
        pcode: this.state.detailFocusedData?.pcode,
      },
    );

    await this.onRowFocusEvent(this.state.rowFocusedData);
  }

  @action
  async onDetailRowFocusEvent(item: ReturnDetailRegistrationDetailModel, index: number) {
    await this.SS({
      detailFocusedData: new ReturnDetailRegistrationDetailModel(item, item.isNew),
      detailFocusedIndex: index,
    });
  }

  @action
  async returnPopupRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'retrieve',
      {
        sub: 'w_popup_da034_01',
        pcode: this.state.popupPcode,
      },
    );

    // popup 조회시 items data setState
    await this.SS({
      popupData: data?.items,
    });

    await this.popupTable.current?.update(true);
  }

  async onSearchPcode(searchQuery: string) {
    const { publicStore } = this.props;

    // 제품코드 팝업창 안열고 바로 조회
    const response = await ActionRepository.retrieve(
      'material',
      await publicStore.makeParams({
        sub: 'w_tb_ca501_01',
        wkactcd: '001',
        as_nm: searchQuery,
        psize: '',
        sulchi: '%',
        choice: '%',
        phm_mode: '%',
        agroup: '%',
        bgroup: '%',
        cgroup: '%',
      }),
    );

    // 제품코드 조회 후 결과 값 setState
    if (response?.data?.items?.length) {
      const result = response?.data?.items[0];
      await this.SS({
        popupPcode: result.phm_pcod,
        popupPname: result.phm_pnam,
        popupPsize: result.phm_size,
      });
    }

    await this.returnPopupRetrieve();
  }

  @action
  async popupSave() {
    const { actionStore: api } = this.props;

    if (this.state.popupDelDate === '00000000') {
      ConfirmWarning.show('경고', '일자를 선택해 주세요');
      return;
    }

    // 불출내역 확인 누를 시 저장
    await api.fxSave(
      'save',
      {
        sub: 'w_popup_da034_01',
        pcode: this.state.popupPcode,
        bandate: this.state.popupDelDate,
        items: this.state.popupData.filter((x) => x.chk === '1').map((x) => ({
          chk: x.chk,
          deldate: x.deldate,
          delnum: x.delnum,
          delseq: x.delseq,
          qty: x.qty,
          banqty: x.banqty,
        })),
      }, this.state.rowFocusedData.isNew,
    );
    await this.SS({ addModal: false, popupDelDate: '00000000' });
    await this.onRetrieveEvent();
  }

  @action
  async popupUpdateRows(rows: Array<ReturnModalModel>) {
    await this.SS({
      popupData: rows,
    });
  }

  render() {
    return (
      <ReturnRegistrationTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            rowFocusedData: new ReturnRegistrationModel({
              ...this.state.rowFocusedData,
              ...change,
            }, this.state.rowFocusedData.isNew),
          }, () => callback && callback());
        }}
      >
      </ReturnRegistrationTemplate>);
  }
}
