import * as React from 'react';
import { action } from 'mobx';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  CageReportDetailModel,
  CageReportModel,
} from './models';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import { BreakReportTemplate } from './CageReport.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PopupModel } from './models/PopupModel';
import { PageComponent } from '../../../../utils';

export enum BreakReportButtonClickedTypes {
  cancle,
  cancle1,
  cancle2,
  cancle3
}

export enum BreakReportButtonClickedTypeNames{
  b_cancle,
  b_cancle1,
  b_cancle2,
  b_cancle3
}

export enum CageReportItemChangeTypes {
  COMPDATE,
  ARRIVTIME,
  COMPTIME,
  ACTCD,
  EQUPCD,
  CHOOSE,
}

export enum CageReportItemChangeTypeNames {
  compdate,
  arrivtime,
  comptime,
  actcd,
  equpcd,
  choose,
}

export const imgStatePercentNames = [
  'percentImageSearch',
  'percentImageAct',
];


interface BreakReportState {

  // 검색조건
  gubun: string; // 구분
  stdate: string; // 기간
  enddate: string; // 기간
  searchQuery: string; // 검색어
  perid: string; // 담당자 사번
  pernm: string; // 담당자 이름
  sendflag: string; // 메일 구분
  crigubun: string; // 중대 보고
  src: string; // 프린트
  isVisibleAttachment: boolean;
  da006_cnt: number; // 수리공사 갯수
  e471_cnt: number; // 설치공사 갯수

  // 총계
  total: string;

  // 데이터 객체

  // 포커싱 데이터
  focusedBreakReport?: CageReportModel;
  focusedDetailBreakReport: CageReportDetailModel;
  lastSaveData: CageReportDetailModel;

  // 테이블 데이터 리스트
  BreakReportList: Array<CageReportModel>;

  // 구분종류
  wkcds: Array<any>;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isReported: boolean; // 결재상신 여부

  percentImageSearch?: number;
  percentImageAct?: number;

  //  갇힘고장 팝업 관련
  popupModal: boolean;
  focusedPopupData?:PopupModel;
  popupData?: Array<PopupModel>;

  // 검색조건
  gubunPopup: string; // 구분
  stdatePopup: string; // 기간
  enddatePopup: string; // 기간
  wadcntPopup: string;
  cltcdPopup: string; // 검색어
  divicdPopup: string; // 부서
  divinmPopup: string; // 부서명
  peridPopup: string; // 담당자 사번
  pernmPopup: string; // 담당자 이름
  contcdPopup: string;
  contnmPopup: string;
  as_nmPopup: string;
  // 팝업 trail
  cnt_tot: string;
  equpcnt_tot: string;
}

/*
* 컨트롤러
 * @window w_tb_e411_cri
 * @category 중대고장처리보고서
*/

export class CageReport extends PageComponent<PageProps, BreakReportState>
  implements PageToolEvents {
  updatedRows?: Array<CageReportModel>;

  updatedRows2?: Array<PopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  detailGrid: React.RefObject<GridLayout> = React.createRef();

  popupHumanTable: React.RefObject<TableLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  infinityPopup?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: `${year}${month}31`,
      gubun: '0',
      searchQuery: pageParams.searchQuery || '',
      sendflag: '%',
      crigubun: '%',
      perid: '%',
      total: '',
      focusedDetailBreakReport: new CageReportDetailModel(),
      breakResisterList: [],
      breakFieldList: [],
      stdatePopup: `${year - 1}${month}${date}`,
      enddatePopup: `${year}${month}${date}`,
      gubunPopup: '%',
      wadcntPopup: '3',
      cltcdPopup: '',
      peridPopup: '',
      divicdPopup: '',
      contcdPopup: '008',
      contnmPopup: '사람갇힘',

      // trail
      cnt_tot: '0',
      equpcnt_tot: '0',

      wkcds: [],
      isReported: false,
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_e021');
    data && this.setState({ wkcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        sendflag: this.state.sendflag,
        crigubun: this.state.crigubun,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          BreakReportList: [...this.state.BreakReportList, ...items],
          focusedBreakReport: new CageReportModel(items),
        }, next);
      },
      async () => {
        await this.SS({
          BreakReportList: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.focusedDetailBreakReport) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      BreakReportList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['waddate', 'wadnum'],
        [this.state.focusedDetailBreakReport?.cridate, this.state.focusedDetailBreakReport?.wadnum], type, true) || 0;
      const data = this.infinity?.box;
      this.setState({
        total: data?.total || '0',
      });
      if (this.state.BreakReportList && this.state.BreakReportList.length > index) {
        this.grid.current?.setFocus(index);
      }
    });
  }

  @action
  onRowFocusEvent(item: CageReportDetailModel) {
    if (item?.new === '1') {
      this.setState({
        focusedDetailBreakReport: this.state.lastSaveData,
        isReported: false,
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      { focusedDetailBreakReport: item },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            cridate: this.state.focusedDetailBreakReport?.cridate,
            crinum: this.state.focusedDetailBreakReport?.crinum,
          });

        await data && this.setState({
          focusedDetailBreakReport: new CageReportDetailModel(data),
        });

        if (data.items) {
          this.detailGrid.current?.setFocus(0);
        }
        // 결재상신 여부확인
        if (this.state.focusedDetailBreakReport?.appgubun === null
          || this.state.focusedDetailBreakReport?.appgubun === ''
          || this.state.focusedDetailBreakReport?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
      },
    );
  }

  @action
  async onButtonClicked(type: any) {
    const { actionStore: api } = this.props;

    const params = {
      itemname: BreakReportButtonClickedTypeNames[type],
      data: '',
      recedate: this.state.focusedDetailBreakReport?.recedate,
      new: this.state.focusedDetailBreakReport?.new,
    };

    if (type === BreakReportButtonClickedTypes.cancle) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          perid: this.state.focusedDetailBreakReport?.perid,
          actnm: this.state.focusedDetailBreakReport?.actnm,
          equpnm: this.state.focusedDetailBreakReport?.equpnm,
        },
      );
    }

    if (type === BreakReportButtonClickedTypes.cancle1) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          actnm: this.state.focusedDetailBreakReport?.actnm,
          equpnm: this.state.focusedDetailBreakReport?.equpnm,
        },
      );
    }

    if (type === BreakReportButtonClickedTypes.cancle2) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          actnm: this.state.focusedDetailBreakReport?.actnm,
          equpnm: this.state.focusedDetailBreakReport?.equpnm,
        },
      );
    }

    if (type === BreakReportButtonClickedTypes.cancle3) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          actnm: this.state.focusedDetailBreakReport?.actnm,
          equpnm: this.state.focusedDetailBreakReport?.equpnm,
        },
      );
    }
  }

  @action
  async itemChanged(item: any, type: number) {
    const { actionStore: api } = this.props;

    let data:any = {
      new: undefined,
    };

    const params = {
      itemname: CageReportItemChangeTypeNames[type],
      data: item,
      new: this.state.focusedDetailBreakReport?.new,
    };

    if (type === CageReportItemChangeTypeNames.compdate) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakReport?.compdate,
          recedate: this.state.focusedDetailBreakReport?.recedate,
        },
      );

      data && this.setState({
        focusedDetailBreakReport: new CageReportDetailModel({
          ...this.state.focusedDetailBreakReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === CageReportItemChangeTypeNames.arrivtime) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakReport?.arrivtime,
          recedate: this.state.focusedDetailBreakReport?.recedate,
          recetime: this.state.focusedDetailBreakReport?.recetime,
          comptime: this.state.focusedDetailBreakReport?.comptime,
          arrivdate: this.state.focusedDetailBreakReport?.arrivdate,
        },
      );

      data && this.setState({
        focusedDetailBreakReport: new CageReportDetailModel({
          ...this.state.focusedDetailBreakReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === CageReportItemChangeTypeNames.comptime) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakReport?.comptime,
          recedate: this.state.focusedDetailBreakReport?.recedate,
          recetime: this.state.focusedDetailBreakReport?.recetime,
          arrivtime: this.state.focusedDetailBreakReport?.arrivtime,
          arrivdate: this.state.focusedDetailBreakReport?.arrivdate,
        },
      );

      data && this.setState({
        focusedDetailBreakReport: new CageReportDetailModel({
          ...this.state.focusedDetailBreakReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === CageReportItemChangeTypeNames.actcd) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakReport?.actcd,
        },
      );

      data && this.setState({
        focusedDetailBreakReport: new CageReportDetailModel({
          ...this.state.focusedDetailBreakReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === CageReportItemChangeTypeNames.equpcd) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakReport?.equpcd,
        },
      );

      data && this.setState({
        focusedDetailBreakReport: new CageReportDetailModel({
          ...this.state.focusedDetailBreakReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === CageReportItemChangeTypeNames.choose) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakReport?.actcd,
          actcd: this.state.focusedDetailBreakReport?.actcd,
        },
      );

      data && this.setState({
        focusedDetailBreakReport: new CageReportDetailModel({
          ...this.state.focusedDetailBreakReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    await modalStore.openApprovalReferenceRemark(this.state.focusedDetailBreakReport?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    await modalStore.openApprovalRemark(this.state.focusedDetailBreakReport?.appnum);
  }

  @action
  async PopupModal(isOpen: boolean) {
    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }
    await this.popupRetrieve();
    this.setState({
      popupModal: isOpen,
      stdatePopup: `${year - 1}${month}${date}`,
      enddatePopup: `${year}${month}${date}`,
      gubunPopup: '%',
      wadcntPopup: '3',
      cltcdPopup: '',
      peridPopup: '',
      pernmPopup: '',
      divicdPopup: '',
      divinmPopup: '',
      contcdPopup: '008',
      contnmPopup: '사람갇힘',
    });
  }

  @action
  async popupDelete() {
    const { actionStore: api } = this.props;

    const chkData = this.state.popupData?.filter((x) => x.isChecked).map((x) => ({
      delchk: '1',
      compdate: x.compdate,
      compnum: x.compnum,
    }));

    if (!chkData?.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
      return;
    }

    const text = '선택한 내용을 목록에서 삭제하시겠습니까?';
    await api.fxDelete(
      'delete',
      text,
      {
        sub: 'w_popup_e411_cri',
        compdate: this.state.focusedPopupData?.compdate,
        compnum: this.state.focusedPopupData?.compnum,
        items: chkData,
      },
    );

    this.popupRetrieve();
  }

  @action
  async popupRetrieve() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinityPopup = new InfinityRetrieve(
      {
        sub: 'w_popup_e411_cri',
        stdate: this.state.stdatePopup,
        enddate: this.state.enddatePopup,
        as_nm: this.state.as_nmPopup,
        contcd: this.state.contcdPopup,
        divicd: this.state.divicdPopup,
        perid: this.state.peridPopup,
        gubun: this.state.gubunPopup,
      },
      (params) => api.fxExec('retrieve', params),
      async (items) => {
        await this.setState({
          popupData: [
            ...this.state.popupData || [],
            ...items.map((x) => new PopupModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          popupData: [],
        });
        await this.infinityPopup?.retrieveAll();
        await this.popupHumanTable.current?.update(false);
        await this.popupHumanTable.current?.setFocus(0);
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      popupData: [],
    }, async () => {
      await this.infinityPopup?.retrieveAll();
      this.setState({
        cnt_tot: this.infinityPopup?.box?.cnt_tot || '0',
        equpcnt_tot: this.infinityPopup?.box?.equpcnt_tot || '0',
      });

      await this.popupHumanTable.current?.update(true);
      await this.popupHumanTable.current?.setFocus(0);
    });
  }

  @action
  async popupSave() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix

    if (this.state.popupData?.length! < 1) {
      await this.setState({ popupModal: false });
      return;
    }

    if (!this.state.focusedPopupData) {
      ConfirmWarning.show('오류', '항목을 선택해주세요');
      return;
    }

    const data = await api.fxExec('save',
      {
        sub: 'w_popup_e411_cri',
        actcd: this.state.focusedPopupData?.actcd,
        actnm: this.state.focusedPopupData?.actnm,
        equpcd: this.state.focusedPopupData?.equpcd,
        equpnm: this.state.focusedPopupData?.equpnm,
        actpernm: this.state.focusedPopupData?.actpernm,
        perid: this.state.focusedPopupData?.perid,
        perid2: this.state.focusedPopupData?.perid2,
        recedate: this.state.focusedPopupData?.recedate,
        recenum: this.state.focusedPopupData?.recenum,
        recetime: this.state.focusedPopupData?.recetime,
        compdate: this.state.focusedPopupData?.compdate,
        compnum: this.state.focusedPopupData?.compnum,
        comptime: this.state.focusedPopupData?.comptime,
        arrivdate: this.state.focusedPopupData?.arrivdate,
        arrivtime: this.state.focusedPopupData?.arrivtime,
        resutime: this.state.focusedPopupData?.resutime,
        resulttime: this.state.focusedPopupData?.resulttime,
        resutottime: this.state.focusedPopupData?.resutottime,
        contcd: this.state.focusedPopupData?.contcd,
        contents: this.state.focusedPopupData?.contents,
        gregicd: this.state.focusedPopupData?.gregicd,
        regicd: this.state.focusedPopupData?.regicd,
        remocd: this.state.focusedPopupData?.remocd,
        remoremark: this.state.focusedPopupData?.remoremark,
        resucd: this.state.focusedPopupData?.resucd,
        resuremark: this.state.focusedPopupData?.resuremark,
        resultcd: this.state.focusedPopupData?.resultcd,
        faccd: this.state.focusedPopupData?.faccd,
        customer: this.state.focusedPopupData?.customer,
        remark: this.state.focusedPopupData?.remark,
        kcresultnm: this.state.focusedPopupData?.kcresultnm,
      });

    if (data) {
      await this.PopupModal(false);
      await this.onRetrieveEvent();
    }
  }

  @action
  onPopupRowFocusEvent(item: PopupModel) {
    this.setState({ focusedPopupData: item });
  }

  @action
  async onSaveEvent() {
    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      ...this.state.focusedDetailBreakReport,
    }, this.state.focusedDetailBreakReport?.new === '1')) {
      await this.onRetrieveEvent();
    }
  }

  // 결재상신
  @action
  async onReport() {
    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (user.pernm !== this.state.focusedDetailBreakReport?.inpernm) {
      ConfirmWarning.show('경고', '본인이 작성하지 않은 문서는 결재할 수 없습니다.');
      return;
    }

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    if (appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.CRI)) {
      // 결재상신
      const data = await api.exec(Category.MAINTENANCE, 'wb_appreport', {
        papercd: PAPERCD.CRI.toString(),
        appnum: this.state.focusedDetailBreakReport?.appnum,
        appgubun: this.state.focusedDetailBreakReport?.appgubun,
        cridate: this.state.focusedDetailBreakReport?.cridate,
        crinum: this.state.focusedDetailBreakReport?.crinum,
        inperid: this.state.focusedDetailBreakReport?.inperid,
        perid: this.state.focusedDetailBreakReport?.inperid,
        actnm: this.state.focusedDetailBreakReport?.actnm,
        title: '',
        refer: this.state.focusedDetailBreakReport?.refer,
        appflag,
      });
      data && await this.onRetrieveEvent();
    } if (appflag === 'cancel') {
      const data = await api.exec(Category.MAINTENANCE, 'wb_appreport', {
        papercd: PAPERCD.CRI.toString(),
        appnum: this.state.focusedDetailBreakReport?.appnum,
        appgubun: this.state.focusedDetailBreakReport?.appgubun,
        cridate: this.state.focusedDetailBreakReport?.cridate,
        crinum: this.state.focusedDetailBreakReport?.crinum,
        inperid: this.state.focusedDetailBreakReport?.inperid,
        perid: this.state.focusedDetailBreakReport?.inperid,
        actnm: this.state.focusedDetailBreakReport?.actnm,
        title: '',
        refer: this.state.focusedDetailBreakReport?.refer,
        appflag,
      });
      data && await this.onRetrieveEvent();
    }
  }


  @action
  async onNewEvent() {
    if (this.state.focusedDetailBreakReport?.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const newModel = new CageReportDetailModel(data, true);
      const listOne = new CageReportModel(newModel, true);

      this.setState({
        focusedDetailBreakReport: newModel,
        lastSaveData: newModel,
        BreakReportList: [
          listOne,
          ...this.state.BreakReportList,
        ],
        focusedBreakReport: listOne,
      }, async () => {
        await this.grid.current?.setFocus(0);
        await this.detailGrid.current?.setFocus(0);
      });
    }
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          waddate: this.state.focusedDetailBreakReport?.cridate,
          wadnum: this.state.focusedDetailBreakReport?.wadnum,
          appnum: this.state.focusedDetailBreakReport?.appnum,
          attcnt: this.state.focusedDetailBreakReport?.attcnt,
          appgubun: this.state.focusedDetailBreakReport?.appgubun,
          actnm: this.state.focusedDetailBreakReport?.actnm,
        }, false,
      );

      data && this.setState({
        focusedDetailBreakReport: new CageReportDetailModel({
          ...this.state.focusedDetailBreakReport,
          ...data,
        }, data.new),
      });

      await this.onRetrieveEvent();
    }
  }


  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const params = {
      cridate: this.state.focusedDetailBreakReport?.cridate,
      crinum: this.state.focusedDetailBreakReport?.crinum,
      appnum: this.state.focusedDetailBreakReport?.appnum,
      appgubun: this.state.focusedDetailBreakReport?.appgubun,
      actnm: this.state.focusedDetailBreakReport?.actnm,
    };
    if (this.state.isReported && this.state.focusedBreakReport?.appgubun !== '001') {
      await api.fxExec('delete', params, false);
    } else {
      // eslint-disable-next-line max-len
      const text = `문서번호: ${this.state.focusedDetailBreakReport?.appnum} 현장: ${this.state.focusedDetailBreakReport?.actnm}`;
      await api.delete(text, params) && await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }


  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.BreakReportList.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      src: this.state.src,
      cridate: this.state.focusedDetailBreakReport?.cridate,
      crinum: this.state.focusedDetailBreakReport?.crinum,
      wkactcd: this.state.focusedDetailBreakReport?.wkactcd,
    });
  }


  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */

  @action
  onUpdatedRows(rows: Array<CageReportModel>, updatedRows: Array<CageReportModel>) {
    this.updatedRows = updatedRows;
    this.setState({ BreakReportList: rows });
  }

  @action
  onUpdatedRows2(rows2: Array<PopupModel>, updatedRows2: Array<PopupModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ popupData: rows2 });
  }

  render() {
    return (
      <BreakReportTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            focusedDetailBreakReport: {
              ...this.state.focusedDetailBreakReport,
              ...change,
            },
          }, () => callback && callback());
        }}
      />
    );
  }
}
