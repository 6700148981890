import * as React from 'react';
import {
  FlexLayout,
  LabelBox,
  FormatNumberTextBox,
  TextBox,
  GridLayout,
  OptionBox,
  LayoutTitle,
  FormatTextBox,
  SearchLayout,
  DateTextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchBinding } from '../../../../models';
import { Format } from '../../../../utils/string';
import { Credit } from './Credit';


/**
 * 화면
 * @window w_s007
 *
 * @category 신용카드등록
 */
export const CreditTemplate: React.FC<TemplateProps<Credit>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        style={{ width: 460 }}
      >
        <FlexLayout>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="카드번호, 카드사, 카드사용자로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'cardnum',
              text: '카드번호',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.cardnum !== undefined ? Format.make(x.cardnum, '-', 4) : ''}
                </FlexLayout>
              ),
            },
            {
              id: 'cardnm',
              text: '카드명',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.cardnm}
                </FlexLayout>
              ),
            },
            {
              id: 'cardperson',
              text: '성명',
              width: 20,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.cardperson}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.creditList}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />
        <FlexLayout
          isVertical={true}
          weight={3}
        >
          <LayoutTitle>기본정보</LayoutTitle>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="카드구분"
              isNeed={true}
            >
              <OptionBox
                value={model.cardclafi}
                data={[
                  { value: '1', remark: '법인' },
                  { value: '2', remark: '개인' },
                ]}
                onChange={(item) => setData({ cardclafi: item.value })}
              />
            </LabelBox>

            <LabelBox
              title="카드번호"
              isNeed={true}
            >
              <FormatNumberTextBox
                char={'-'}
                charAt={4}
                value={model.cardnum}
                onChange={(value) => setData({ cardnum: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="카드사"
              isNeed={true}
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                textAlign="center"
                value={model.cardco}
                onChange={(value) => setData({ cardco: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_XCARD',
                  {},
                  true,
                  (item) => {
                    setData({ cardco: item.cd, cardnm: item.cdnm });
                  },
                )}
              />

              <TextBox
                textAlign="center"
                value={model.cardnm}
                onChange={(value) => setData({ cardnm: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="성명"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                textAlign="center"
                value={model.cardperid}
                onChange={(value) => setData({ cardperid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({ cardperid: item.cd, cardperson: item.cdnm });
                  },
                )}
              />

              <TextBox
                textAlign="center"
                value={model.cardperson}
                onChange={(value) => setData({ cardperson: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="발급일자">
              <DateTextBox
                textAlign="center"
                format="####-##-##"
                value={model.isudate || ''}
                onChange={(value) => setData({ isudate: value })}
              />
            </LabelBox>

            <LabelBox title="만기일자">
              <DateTextBox
                textAlign="center"
                format="####-##-##"
                value={model.expedate || ''}
                onChange={(value) => setData({ expedate: value })}
              />
            </LabelBox>

            <LabelBox title="사용여부">
              <OptionBox
                value={model.useyn}
                data={[
                  { value: '1', remark: '여' },
                  { value: '0', remark: '부' },
                ]}
                onChange={(item) => setData({ useyn: item.value })}
              />
            </LabelBox>

            <LabelBox title="정지일자">
              <DateTextBox
                textAlign="center"
                format="####-##-##"
                value={model.usestdate || ''}
                onChange={(value) => setData({ usestdate: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="결제은행"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                textAlign="center"
                value={model.stlbank}
                onChange={(value) => setData({ stlbank: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_AA040',
                  {},
                  true,
                  (item) => {
                    // eslint-disable-next-line max-len
                    setData({ stlbank: item.uv_arg2, stlbanknm: item.uv_arg3, stlacc: item.uv_arg1 });
                  },
                )}
              />
              <TextBox
                textAlign="center"
                value={model.stlbanknm}
                onChange={(value) => setData({ stlbanknm: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="결제계좌">
              <TextBox
                textAlign="center"
                value={model.stlacc}
                onChange={(value) => setData({ stlacc: value })}
              />
            </LabelBox>
            <LabelBox title="결제일자">
              <FormatTextBox
                textAlign="center"
                value={model.stldate ?? ''}
                format="##일"
                onChange={(value) => setData({ stldate: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="제출자와의관계">
              <TextBox
                textAlign="left"
                value={model.connection}
                onChange={(value) => setData({ connection: value })}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
