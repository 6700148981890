/**
 * 모델
 * @window w_popup_tb_e601w_sulchi_09
 * @category 설치계약달성현황
 */

export class SurPopupModel {
  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 대수
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 실측일
   *
   * **data type** varchar(8)
   */
  readonly surdate: string;

  /**
   * 실측자
   *
   * **data type** varchar(8)
   */
  readonly surpernm: string;

  /**
   * 여부
   *
   * **data type** varchar(8)
   */
  readonly surflag: string;

  /**
   * 설계자
   *
   * **data type** varchar(8)
   */
  readonly despernm: string;

  /**
   * 설계일
   *
   * **data type** varchar(8)
   */
  readonly desdate: string;

  /**
   * 여부
   *
   * **data type** varchar(8)
   */
  readonly desflag: string;

  /**
   * 계약일
   *
   * **data type** varchar(8)
   */
  readonly contdate: string;

  /**
   * 납기일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 기종
   *
   * **data type** varchar(8)
   */
  readonly jeoban: string;

  /**
   * 계약금
   *
   * **data type** varchar(8)
   */
  readonly contamt: string;

  /**
   * 선급금
   *
   * **data type** varchar(8)
   */
  readonly stamt: string;

  /**
   * 중도금
   *
   * **data type** varchar(8)
   */
  readonly midamt: string;

  /**
   * 잔금
   *
   * **data type** varchar(8)
   */
  readonly endamt: string;

  /**
   * 발주금액
   *
   * **data type** varchar(8)
   */
  readonly balamt: string;

  /**
   * 입금액
   *
   * **data type** varchar(8)
   */
  readonly rcvamt: string;

  /**
   * 잔액
   *
   * **data type** varchar(8)
   */
  readonly iamt: string;

  /**
   * 사양
   *
   * **data type** varchar(8)
   */
  readonly sizecd: string;

  /**
   * 옵션
   *
   * **data type** varchar(8)
   */
  readonly scoption: string;

  /**
   * 본전원입선일
   *
   * **data type** varchar(8)
   */
  readonly ipsundate: string;

  /**
   * 자재현장도착일
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   * 설치작업자
   *
   * **data type** varchar(8)
   */
  readonly scpernm: string;

  /**
   * 현재진행사항
   *
   * **data type** varchar(8)
   */
  readonly scremark: string;

  /**
   * 검사예정일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 검사일
   *
   * **data type** varchar(8)
   */
  readonly kcdate: string;

  /**
   * 합격필증
   *
   * **data type** varchar(8)
   */
  readonly okflag: string;

  /**
   * 건축허가일
   *
   * **data type** varchar(8)
   */
  readonly bilddate: string;

  /**
   * 승인도서
   *
   * **data type** varchar(8)
   */
  readonly okbook: string;

  /**
   * 준공도서
   *
   * **data type** varchar(8)
   */
  readonly compbook: string;

  /**
   * 모델인증서
   *
   * **data type** varchar(8)
   */
  readonly bildju: string;

  /**
   * 설치
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  constructor(data: any = {}) {
    this.actnm = data.actnm || '';
    this.qty = data.qty || '';
    this.surdate = data.surdate || '';
    this.surpernm = data.surpernm || '';
    this.surflag = data.surflag || '';
    this.desdate = data.desdate || '';
    this.despernm = data.despernm || '';
    this.desflag = data.desflag || '';
    this.contdate = data.contdate || '';
    this.enddate = data.enddate || '';
    this.jeoban = data.jeoban || '';
    this.contamt = data.contamt || '';
    this.stamt = data.stamt || '';
    this.midamt = data.midamt || '';
    this.endamt = data.endamt || '';
    this.balamt = data.balamt || '';
    this.rcvamt = data.rcvamt || '';
    this.iamt = data.iamt || '';
    this.sizecd = data.sizecd || '';
    this.scoption = data.scoption || '';
    this.ipsundate = data.ipsundate || '';
    this.baldate = data.baldate || '';
    this.scpernm = data.scpernm || '';
    this.scremark = data.scremark || '';
    this.stdate = data.stdate || '';
    this.kcdate = data.kcdate || '';
    this.okflag = data.okflag || '';
    this.bilddate = data.bilddate || '';
    this.okbook = data.okbook || '';
    this.compbook = data.compbook || '';
    this.bildju = data.bildju || '';
    this.state = data.state || '';
  }
}
