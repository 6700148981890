import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { CostClauseTemplate } from './CostClause.template';
import { CostClauseModel } from './CostClause.model';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import {
  ConfirmDelete,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';

export interface CostClauseListItem {
  custcd: string;
  spjangcd: string;
  gartcd: string;
  gartnm: string;
  new: string;
  artcd: string;
  wkgubun: string;
}

export interface CostClauseDetailListItem {
  gartcd: string;
  new: string;
  artcd: string;
  artnm: string;
  useyn: string;
  wkgubun: string;
}

export interface CostClauseState {
  searchQuery: string;
  jflags?: Array<any>;
  gflags?: Array<any>;
  wkgubuns?: Array<any>;
  costClauseList: Array<CostClauseListItem>;
  costClauseDetailList: Array<CostClauseDetailListItem>;
  focusedCostClause?: CostClauseListItem;
  data: CostClauseModel;
  focused?: CostClauseDetailListItem;
}

/**
 * 컨트롤러
 * @window TB_CA647
 * @category 비용항목등록
 */
export class CostClause extends PageComponent<PageProps, CostClauseState>
  implements PageToolEvents {
  updatedRows?: Array<CostClauseModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  gridIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      costClauseList: [],
      costClauseDetailList: [],
      data: new CostClauseModel(),
      searchQuery: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 정기유무
    const datas = await api.dropdown('wf_dd_ca510_801_01');

    if (!datas) return;
    this.setState({ jflags: datas.items });

    // 비용분류
    const datas2 = await api.dropdown('wf_dd_ca510_802_01');

    if (!datas2) return;
    this.setState({ gflags: datas2.items });

    // 매입구분 드롭다운
    const datas3 = await api.dropdown('wf_dd_ca510_114_01');

    if (!datas3) return;
    this.setState({ wkgubuns: datas3.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          costClauseList: [...this.state.costClauseList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          costClauseList: [],
        });

        const index = await this.infinity?.retrieveTo('gartcd', this.state.data.gartcd, type, true) || 0;
        this.state.costClauseList.length > index && this.grid.current?.setFocus(index);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      costClauseList: [],
    });
    if (!autoLoad) return;
    await this.infinity?.retrieveAll();
    this.table.current?.update(true);

    if (this.state.costClauseList.length > this.gridIndex) {
      this.grid.current?.setFocus(this.gridIndex);
    } else if (this.state.costClauseList.length > 0) {
      this.gridIndex = 0;
      this.grid.current?.setFocus(this.gridIndex);
    }
    this.state.data.isChanged = 'false';
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      data.new = '1';
      const newData: CostClauseDetailListItem = {
        gartcd: data.gartcd,
        new: '1',
        artcd: data.artcd,
        artnm: data.artnm,
        useyn: data.useyn,
        wkgubun: data.wkgubun,
      };

      data && this.setState({
        data: new CostClauseModel(data),
        costClauseDetailList: [newData],
        costClauseList: [data, ...this.state.costClauseList],
      }, async () => {
        await this.table.current?.update(false);
        await this.SS({ focusedCostClause: data });
        await this.grid.current?.setFocus(0);
        await this.table.current?.setFocus(0);
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    // @ts-ignore
    let newItem: boolean;
    if (this.state.data.isChanged === 'true') {
      newItem = false;
    } else {
      newItem = true;
    }

    if (await api.save({
      gartcd: this.state.data.gartcd,
      gartnm: this.state.data.gartnm,
      remark: this.state.data.remark,
      items: this.state.costClauseDetailList,
    }, newItem)) {
      this.state.data.isChanged = 'false';
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
    this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `${this.state.focusedCostClause?.gartcd} 코드 번호의 - ${this.state.focusedCostClause?.gartnm}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '하단 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      await api.delete(text2, this.state.focusedCostClause) && this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.costClauseList.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      gartcd: this.state.focusedCostClause?.gartcd,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.costClauseList.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
      gartcd: this.state.focusedCostClause?.gartcd,
    });
  }

  @action
  async onRowFocusEvent(item: CostClauseListItem, index: number) {
    this.gridIndex = index;

    this.setState({
      focusedCostClause: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const data = await api.exec(
        Category.GENERAL,
        'dw_1_RowFocuschanged',
        item,
      );
      data && this.setState({
        costClauseDetailList: data?.items || [],
        data,
      }, async () => {
        await this.table.current?.update(true);
        if (data.items?.length > 0) {
          this.table.current?.setFocus(0, 1);
        }
      });
    });
  }

  @action
  async onDetailRowFocusEvent(item: CostClauseDetailListItem) {
    this.setState({
      focused: item,
    });
  }

  @action
  async dw_2_new() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_2_new',
      {
        gartcd: this.state.data.gartcd,
      },
    );

    if (data) {
      const newData: CostClauseDetailListItem = {
        gartcd: data.gartcd,
        new: '1',
        artcd: data.artcd,
        artnm: data.artnm,
        useyn: data.useyn,
        wkgubun: data.wkgubun,
      };

      this.setState({
        focused: newData,
        costClauseDetailList: [
          newData,
          ...this.state.costClauseDetailList,
        ],
      }, async () => {
        await this.table.current?.update(false);
        this.table.current?.setFocus(0, 1);
      });
    }
  }

  @action
  async dw_2_delete() {
    const focusedItem = this.state.focused;

    if (focusedItem) {
      const text = `항목코드: ${this.state.focused?.artcd}, 항목명: ${this.state.focused?.artnm}`;

      if (await ConfirmDelete.show(text)) {
        const { actionStore: api } = this.props;

        await api.fxExec(
          'dw_2_delete',
          {
            gartcd: this.state.data.gartcd,
            artcd: this.state.focused?.artcd,
          },
        );
        this.onRetrieveEvent();
        // eslint-disable-next-line no-template-curly-in-string
        ConfirmSuccess.show('삭제', `${focusedItem.artnm}를 삭제하였습니다.`);
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ costClauseDetailList: rows });
  }

  render() {
    return (
      <CostClauseTemplate
        scope={this}
        update={(change) => this.setState(change)}
        // @ts-ignore
        modelUpdate={(change) => {
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          });
        }}
      />
    );
  }
}
