import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Enrollment } from '../Enrollment';
import { Date8 } from '../../../../../utils/time';
import EnrollmentHistoryModel from '../models/EnrollmentHistoryModel';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e451
 * @category 견적서등록
 */
export const EnrollmentHistoryGridHeader: GLHF<EnrollmentHistoryModel, Enrollment> = (scope) => ([
  {
    id: 'costdate',
    text: '견적일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.costdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'costnum',
    text: '번호',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.costnum}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope.state.gubuns?.filter((y) => y.code === x.gubun)[0]?.cnam}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '견적금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
  {
    id: 'constnm',
    text: '견적서명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.constnm}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt',
    text: '유효기간',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.iamt}개월
      </FlexLayout>
    ),
  },
  {
    id: 'addyn',
    text: '부가세포함',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.addyn === '0' ? '별도' : '포함'}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 300,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
]);
