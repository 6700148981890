import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { FormatTextBox } from '../../../../../components/forms/FormatTextBox';
import { FormatNumberTextBox } from '../../../../../components/forms/FormatNumberTextBox';
import { Sum } from '../../../../../utils/array';
import { Format } from '../../../../../utils/string';
import { StockStatement } from '../StockStatement';
import { ActModel } from '../models';

const Act: GridLayoutHeader<ActModel, StockStatement>[] = [
  {
    id: 'actdivinm',
    text: '부서명',
    width: 100,
    sum: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        소 계
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.actdivinm}
      </FlexLayout>
    ),
  },
  {
    id: 'actpernm',
    text: '현장담당자명',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.actpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'ibgdate',
    text: '입고일자',
    width: 100,
    sum: (x, data) => {
      const sumIbgcnt = Sum.groupLength(data, 'ibgdate', x, ['actdivinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {sumIbgcnt} 건
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.total}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.ibgdate}
      />
    ),
  },
  {
    id: 'ibgnum',
    text: '번호',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.ibgnum}
      </FlexLayout>
    ),
  },
  {
    id: 'ibgseq',
    text: '순번',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.ibgseq}
      </FlexLayout>
    ),
  },
  {
    id: 'projnm',
    text: '프로젝트',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.projnm}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '거래처명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'actdivinm',
    text: '담당자별그룹',
    isHidden: true,
    group: true,
    value: (x) => x.actdivinm,
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품목명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 150,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.psize}
      </FlexLayout>
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.punit}
      </FlexLayout>
    ),
  },
  {
    id: 'pserial',
    text: '품목시리얼번호',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pserial}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 70,
    sum: (x, data) => {
      const sumQty = Sum.byGroup(data, 'qty', x, ['actdivinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {sumQty}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.qty_tot || '0'}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.qty}
      </FlexLayout>
    ),
  },
  {
    id: 'balqty',
    text: '발주수량',
    width: 70,
    sum: (x, data) => {
      const sumBalqty = Sum.byGroup(data, 'balqty', x, ['actdivinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {sumBalqty}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.balqty_tot || '0'}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.balqty}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 70,
    render: (x) => (
      <FormatNumberTextBox
        char={','}
        charAt={3}
        textAlign="right"
        value={x.uamt}
      />
    ),
  },
  {
    id: 'samt',
    text: '공급금액',
    width: 70,
    trail: (data) => {
      const sumSamt = Sum.all(data, 'samt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(String(sumSamt))}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FormatNumberTextBox
        char={','}
        charAt={3}
        textAlign="right"
        value={x.samt}
      />
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 70,
    trail: (data) => {
      const sumTamt = Sum.all(data, 'tamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(String(sumTamt))}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FormatNumberTextBox
        char={','}
        charAt={3}
        textAlign="right"
        value={x.tamt}
      />
    ),
  },
  {
    id: 'mamt',
    text: '합계',
    width: 70,
    trail: (data) => {
      const sumMamt = Sum.all(data, 'mamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(String(sumMamt))}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FormatNumberTextBox
        char={','}
        charAt={3}
        textAlign="right"
        value={x.mamt}
      />
    ),
  },
  {
    id: 'bigo',
    text: '비고',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.bigo}
      </FlexLayout>
    ),
  },
];
export default Act;
