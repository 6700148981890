import { action } from 'mobx';
import * as React from 'react';
import { PartsCheckListModel } from './PartsCheckList.model';
import { PartsCheckListTemplate } from './PartsCheckList.template';
import { PageComponent } from '../../../../utils';
import {
  PageProps,
  PageToolEvents,
  RowUpdate,
} from '../../../../constants';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { ConfirmWarning } from '../../../../utils/confirm';
import { ProductPopupModel } from './ProductPopup.model';

interface PartsCheckListState {
  searchQuery: string,

  data: Array<PartsCheckListModel>
  focusedData: PartsCheckListModel

  popupData: Array<ProductPopupModel>
  popupFocusData: ProductPopupModel
  productPopupisVisible: boolean;
}

/**
 * 컨트롤러
 * @window w_tb_ca501_age
 * @category 부품채크리스트등록
 */

export class PartsCheckList extends PageComponent<PageProps, PartsCheckListState>
  implements PageToolEvents {
  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  instanceRowUpdate?: RowUpdate;

  instanceRow?: ProductPopupModel;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',

      data: [],
      focusedData: [],

      popupData: [],
      popupFocusData: [],
      productPopupisVisible: false,
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {},
      (params) => (
        api.retrieve(params)
      ),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new PartsCheckListModel(x))],
        });
      },
      // 정렬 작업
      async () => {
        await this.SS({
          data: [],
        });
      },
    );
    // 상단 조회 버튼 누를 시 기존 값 초기화 (리스트 중복 방지)
    await this.SS({
      data: [],
    });

    await this.infinity?.retrieveAll();
    await this.table.current?.update(true);

    // data가 있을시 focus
    if (this.state.data.length > 0) {
      this.table.current?.setFocus(0, 1);
    }
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    // data가 true면 기존 data에 새로운 new Data 추가
    if (data) {
      this.setState({
        data: [...this.state.data, new PartsCheckListModel(data, true)],
      });
    }

    // new Data 추가했으므로 update 하고 focus
    await this.table.current?.update(true);
    await this.table.current?.setFocus(this.state.data.length - 1, 1);
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data.length < 1) {
      ConfirmWarning.show('경고', '저장 할 내역이 없습니다');
      return;
    }

    // items에 data넘겨주고 조회
    if (await api.save({
      items: this.state.data,
    }, this.state.data[this.state.data.length - 1].isNew)) {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data.length < 1) {
      ConfirmWarning.show('경고', '삭제할 내역이 없습니다.');
      return;
    }

    const text = `채크코드: ${this.state.focusedData.agecode}\n 채크명: ${this.state.focusedData.agename}`;

    await api.delete(text, this.state.focusedData) && await this.onRetrieveEvent();
  }

  @action
  async onRowFocusEvent(item: PartsCheckListModel) {
    await this.SS({
      focusedData: item,
    });
  }

  @action
  async openProductPopupWithRow(rowUpdate: RowUpdate, isOpen: boolean) {
    this.instanceRowUpdate = rowUpdate;

    await this.openProductPopup(isOpen);
  }

  onPopupRowClick(row: ProductPopupModel) {
    this.instanceRow = row;
  }

  @action
  closeProductPoupWithData(item?: ProductPopupModel) {
    this.instanceRowUpdate && this.instanceRowUpdate({
      ...item,
      agecode: `${item?.agroup}${item?.bgroup}${item?.cgroup}`,
      agename: item?.cgroupnm,
    });
    this.openProductPopup(false);
  }

  @action
  async openProductPopup(isOpen: boolean) {
    await this.SS({
      productPopupisVisible: isOpen,
    });

    if (isOpen) {
      await this.onProductPopupRetrieve();
    }
  }

  @action
  async onProductPopupRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.retrieve(
      {
        sub: 'w_popup_pcode_group',
        as_nm: this.state.searchQuery,
      },
    );
    await this.SS({
      popupData: data?.items,
    });
    await this.grid.current?.forceRepaint();
    await this.grid.current?.setFocus(0);
  }

  @action
  async onItemChanged(value: any, name: string) {
    // itemname, data, new
    const { actionStore: api } = this.props;

    // state값 변경위해 분기
    if (name === 'age_year') {
      this.state.focusedData.age_year = value;
    } else if (name === 'age_month') {
      this.state.focusedData.age_month = value;
    }

    await this.setState({
      focusedData: this.state.focusedData,
    });
    await this.table.current?.update();

    const data = await api.fxExec(
      'dw_list_itemchanged',
      {
        new: this.state.focusedData.isNew ? '1' : '0',
        itemname: name,
        data: value,
        age_year: this.state.focusedData.age_year,
        age_month: this.state.focusedData.age_month,
      },
    );

    // 다른 것 itemchange시 age값이 안넘어와서 따로 분기
    if (name === 'age_year') {
      this.state.focusedData.age = data.age;
    } else if (name === 'age_month') {
      this.state.focusedData.age = data.age;
    }

    await this.SS({
      focusedData: this.state.focusedData,
    });
  }

  @action
  async onUpdatedRows(rows: Array<PartsCheckListModel>) {
    await this.SS({
      data: rows,
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data.length < 1) {
      ConfirmWarning.show('경고', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data.length < 1) {
      ConfirmWarning.show('경고', '엑셀로 전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
    });
  }

  render() {
    return <PartsCheckListTemplate scope={this} />;
  }
}

