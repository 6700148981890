import { action } from 'mobx';
import { RefObject } from 'react';
import * as React from 'react';
import { PageComponent } from '../../../../utils';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { MonthlyIncomeStatementTemplate } from './MonthlyIncomeStatement.template';
import { Today } from '../../../../utils/time';
import { GridLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { SalesPurchaseModel } from './models/SalesPurchase.model';
import { DepositPaymentModel } from './models/DepositPayment.model';
import { MisModalModel } from './models/MisModal.model';
import { MijModalModel } from './models/MijModal.model';
import { DepositModalModel } from './models/DepositModal.model';
import { PaymentModalModel } from './models/PaymentModal.model';

interface MonthlyIncomeStatementState {
  // 조회조건
  stdate: string;
  spjangcd: string;

  // tab Data
  salesPurchaseData: Array<SalesPurchaseModel>;
  depositPaymentData: Array<DepositPaymentModel>;

  // tab focused data
  salesPurchaseFocusedData: SalesPurchaseModel;
  depositPaymentFocusedData: DepositPaymentModel;

  // trail
  amt01_cha_tot: string;
  amt01_mij_tot: string;
  amt01_mis_tot: string;
  amt02_cha_tot: string;
  amt02_mij_tot: string;
  amt02_mis_tot: string;
  amt03_cha_tot: string;
  amt03_mij_tot: string;
  amt03_mis_tot: string;
  amt04_cha_tot: string;
  amt04_mij_tot: string;
  amt04_mis_tot: string;
  amt05_cha_tot: string;
  amt05_mij_tot: string;
  amt05_mis_tot: string;
  amt06_cha_tot: string;
  amt06_mij_tot: string;
  amt06_mis_tot: string;
  amt07_cha_tot: string;
  amt07_mij_tot: string;
  amt07_mis_tot: string;
  amt08_cha_tot: string;
  amt08_mij_tot: string;
  amt08_mis_tot: string;
  amt09_cha_tot: string;
  amt09_mij_tot: string;
  amt09_mis_tot: string;
  amt10_cha_tot: string;
  amt10_mij_tot: string;
  amt10_mis_tot: string;
  amt11_cha_tot: string;
  amt11_mij_tot: string;
  amt11_mis_tot: string;
  amt12_cha_tot: string;
  amt12_mij_tot: string;
  amt12_mis_tot: string;

  // 모달 Title 명
  modalTitle: string;

  // 모달 조회조건
  tab: string;
  stmon: string;
  endmon: string;
  title: string;
  searchQuery: string;
  gubun: string;

  // 팝업 증빙구분
  gubuns: Array<any>;

  // 매출 팝업창
  misModal: boolean;
  misModalData: Array<MisModalModel>;

  // 매입 팝업창
  mijModal: boolean;
  mijModalData: Array<MijModalModel>;

  // 입금 팝업창
  depositModal: boolean;
  depositModalData: Array<DepositModalModel>;

  // 지급 팝업창
  payModal: boolean;
  payModalData: Array<PaymentModalModel>;

  // Modal trail
  cnt_tot: string;
  misamt_tot: string;
  mijamt_tot: string;
  rcvamt_tot: string;
  sndamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca642w_15
 * @category 월별 손익계산서
 */
export class MonthlyIncomeStatement extends PageComponent<PageProps, MonthlyIncomeStatementState>
  implements PageToolEvents {
  infinityTab1?: InfinityRetrieve;

  infinityTab2?: InfinityRetrieve;

  infinityMisModal?: InfinityRetrieve;

  infinityMijModal?: InfinityRetrieve;

  infinityDepositModal?: InfinityRetrieve;

  infinityPayModal?: InfinityRetrieve;

  gridTab1: RefObject<GridLayout> = React.createRef();

  gridTab2: RefObject<GridLayout> = React.createRef();

  gridMisModal: RefObject<GridLayout> = React.createRef();

  gridMijModal: RefObject<GridLayout> = React.createRef();

  gridDepositModal: RefObject<GridLayout> = React.createRef();

  gridPayModal: RefObject<GridLayout> = React.createRef();

  tabIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      // 조회조건
      stdate: Today.year(),
      spjangcd: 'ZZ',

      // tab Data
      SalesPurchaseData: [],
      DepositPaymentData: [],

      // tab focused data
      salesPurchaseFocusedData: '',
      depositPaymentFocusedData: '',

      // trail
      amt01_cha_tot: '0',
      amt01_mij_tot: '0',
      amt01_mis_tot: '0',
      amt02_cha_tot: '0',
      amt02_mij_tot: '0',
      amt02_mis_tot: '0',
      amt03_cha_tot: '0',
      amt03_mij_tot: '0',
      amt03_mis_tot: '0',
      amt04_cha_tot: '0',
      amt04_mij_tot: '0',
      amt04_mis_tot: '0',
      amt05_cha_tot: '0',
      amt05_mij_tot: '0',
      amt05_mis_tot: '0',
      amt06_cha_tot: '0',
      amt06_mij_tot: '0',
      amt06_mis_tot: '0',
      amt07_cha_tot: '0',
      amt07_mij_tot: '0',
      amt07_mis_tot: '0',
      amt08_cha_tot: '0',
      amt08_mij_tot: '0',
      amt08_mis_tot: '0',
      amt09_cha_tot: '0',
      amt09_mij_tot: '0',
      amt09_mis_tot: '0',
      amt10_cha_tot: '0',
      amt10_mij_tot: '0',
      amt10_mis_tot: '0',
      amt11_cha_tot: '0',
      amt11_mij_tot: '0',
      amt11_mis_tot: '0',
      amt12_cha_tot: '0',
      amt12_mij_tot: '0',
      amt12_mis_tot: '0',

      // 모달 title
      modalTitle: '',

      tab: '',
      stmon: '',
      endmon: '',
      title: '',
      searchQuery: '',
      gubun: '',

      // 구분
      gubuns: [],

      // 매출 팝업창
      misModal: false,
      misModalData: [],

      mijModal: false,
      mijModalData: [],

      depositModal: false,
      depositModalData: [],

      payModal: false,
      payModalData: [],

      cnt_tot: '',
      misamt_tot: '',
      mijamt_tot: '',
      rcvamt_tot: '',
      sndamt_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_ca510_113_01');
    if (!data) return;
    this.setState({ gubuns: data?.items });
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    if (this.tabIndex === 0) {
      this.infinityTab1 = new InfinityRetrieve(
        {
          year: this.state.stdate,
          spjangcd: this.state.spjangcd,
        },
        (params) => api.fxExec('tab_1_retrieve', params),
        async (items) => {
          await this.SS({
            salesPurchaseData: [
              ...this.state.salesPurchaseData,
              ...items.map((item) => new SalesPurchaseModel(item)),
            ],
          });
        },
        async () => {
          await this.SS({
            salesPurchaseData: [],
          });
        },
      );
    } else if (this.tabIndex === 1) {
      this.infinityTab2 = new InfinityRetrieve(
        {
          year: this.state.stdate,
          spjangcd: this.state.spjangcd,
        },
        (params) => api.fxExec('tab_2_retrieve', params),
        (items) => {
          this.SS({
            depositPaymentData: [
              ...this.state.depositPaymentData,
              ...items.map((item) => new DepositPaymentModel(item)),
            ],
          });
        },
        async () => {
          await this.SS({
            depositPaymentData: [],
          });
        },
      );
    }

    if (this.tabIndex === 0) {
      await this.setState({
        salesPurchaseData: [],
      });
      await this.infinityTab1?.retrieveAll();
      await this.gridTab1.current?.setFocus(0);
      await this.gridTab1.current?.forceRepaint(true);
    } else if (this.tabIndex === 1) {
      await this.SS({
        depositPaymentData: [],
      });

      await this.infinityTab2?.retrieveAll();
      await this.gridTab2.current?.setFocus(0);
      await this.gridTab2.current?.forceRepaint(true);
    }

    await this.SS({
      amt01_cha_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt01_cha_tot || '0' : this.infinityTab2?.box?.amt01_cha_tot || '0',
      amt01_mij_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt01_mij_tot || '0' : this.infinityTab2?.box?.amt01_mij_tot || '0',
      amt01_mis_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt01_mis_tot || '0' : this.infinityTab2?.box?.amt01_mis_tot || '0',
      amt02_cha_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt02_cha_tot || '0' : this.infinityTab2?.box?.amt02_cha_tot || '0',
      amt02_mij_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt02_mij_tot || '0' : this.infinityTab2?.box?.amt02_mij_tot || '0',
      amt02_mis_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt02_mis_tot || '0' : this.infinityTab2?.box?.amt02_mis_tot || '0',
      amt03_cha_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt03_cha_tot || '0' : this.infinityTab2?.box?.amt03_cha_tot || '0',
      amt03_mij_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt03_mij_tot || '0' : this.infinityTab2?.box?.amt03_mij_tot || '0',
      amt03_mis_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt03_mis_tot || '0' : this.infinityTab2?.box?.amt03_mis_tot || '0',
      amt04_cha_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt04_cha_tot || '0' : this.infinityTab2?.box?.amt04_cha_tot || '0',
      amt04_mij_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt04_mij_tot || '0' : this.infinityTab2?.box?.amt04_mij_tot || '0',
      amt04_mis_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt04_mis_tot || '0' : this.infinityTab2?.box?.amt04_mis_tot || '0',
      amt05_cha_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt05_cha_tot || '0' : this.infinityTab2?.box?.amt05_cha_tot || '0',
      amt05_mij_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt05_mij_tot || '0' : this.infinityTab2?.box?.amt05_mij_tot || '0',
      amt05_mis_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt05_mis_tot || '0' : this.infinityTab2?.box?.amt05_mis_tot || '0',
      amt06_cha_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt06_cha_tot || '0' : this.infinityTab2?.box?.amt06_cha_tot || '0',
      amt06_mij_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt06_mij_tot || '0' : this.infinityTab2?.box?.amt06_mij_tot || '0',
      amt06_mis_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt06_mis_tot || '0' : this.infinityTab2?.box?.amt06_mis_tot || '0',
      amt07_cha_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt07_cha_tot || '0' : this.infinityTab2?.box?.amt07_cha_tot || '0',
      amt07_mij_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt07_mij_tot || '0' : this.infinityTab2?.box?.amt07_mij_tot || '0',
      amt07_mis_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt07_mis_tot || '0' : this.infinityTab2?.box?.amt07_mis_tot || '0',
      amt08_cha_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt08_cha_tot || '0' : this.infinityTab2?.box?.amt08_cha_tot || '0',
      amt08_mij_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt08_mij_tot || '0' : this.infinityTab2?.box?.amt08_mij_tot || '0',
      amt08_mis_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt08_mis_tot || '0' : this.infinityTab2?.box?.amt08_mis_tot || '0',
      amt09_cha_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt09_cha_tot || '0' : this.infinityTab2?.box?.amt09_cha_tot || '0',
      amt09_mij_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt09_mij_tot || '0' : this.infinityTab2?.box?.amt09_mij_tot || '0',
      amt09_mis_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt09_mis_tot || '0' : this.infinityTab2?.box?.amt09_mis_tot || '0',
      amt10_cha_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt10_cha_tot || '0' : this.infinityTab2?.box?.amt10_cha_tot || '0',
      amt10_mij_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt10_mij_tot || '0' : this.infinityTab2?.box?.amt10_mij_tot || '0',
      amt10_mis_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt10_mis_tot || '0' : this.infinityTab2?.box?.amt10_mis_tot || '0',
      amt11_cha_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt11_cha_tot || '0' : this.infinityTab2?.box?.amt11_cha_tot || '0',
      amt11_mij_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt11_mij_tot || '0' : this.infinityTab2?.box?.amt11_mij_tot || '0',
      amt11_mis_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt11_mis_tot || '0' : this.infinityTab2?.box?.amt11_mis_tot || '0',
      amt12_cha_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt12_cha_tot || '0' : this.infinityTab2?.box?.amt12_cha_tot || '0',
      amt12_mij_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt12_mij_tot || '0' : this.infinityTab2?.box?.amt12_mij_tot || '0',
      amt12_mis_tot: this.tabIndex === 0 ? this.infinityTab1?.box?.amt12_mis_tot || '0' : this.infinityTab2?.box?.amt12_mis_tot || '0',
    });
  }

  // tab 1 rowClick
  @action
  async onTab1RowClickEvent(item: SalesPurchaseModel) {
    await this.SS({
      salesPurchaseFocusedData: item,
    });
  }

  // tab 2 rowClick
  @action
  async onTab2RowClickEvent(item: DepositPaymentModel) {
    await this.SS({
      depositPaymentFocusedData: item,
    });
  }

  @action
  async onTabChange(index: number) {
    this.tabIndex = index;
    await this.onRetrieveEvent();
  }

  @action
  async onMisModalOpen(isOpen: boolean, stmon: string, endmon: string) {
    await this.SS({
      misModal: isOpen,
      modalTitle: '[매출]',
      tab: '1-mis',
      stmon: `${this.state.stdate}${stmon}`,
      endmon: `${this.state.stdate}${endmon}`,
      title: '1-mis',
    });

    await this.onMisModalRetrieve();
  }

  @action
  async onMisModalRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      misModalData: [],
    });

    this.infinityMisModal = new InfinityRetrieve(
      {
        sub: 'w_popup_ca642w_15',
        tab: this.state.tab,
        spjangcd: this.state.spjangcd,
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        title: this.state.title,
        as_nm: this.state.searchQuery,
        gubun: this.state.salesPurchaseFocusedData?.gubun === '00' ? '' : this.state.salesPurchaseFocusedData?.gubun,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          misModalData: [...this.state.misModalData || [], ...items.map((x) => new MisModalModel(x))],
        });
      },
      async () => {
        await this.SS({
          misModalData: [],
        });
      },
    );

    await this.infinityMisModal?.retrieveAll();
    await this.gridMisModal.current?.setFocus(0);
    await this.SS({
      cnt_tot: this.infinityMisModal?.box?.cnt_tot || '',
      misamt_tot: this.infinityMisModal?.box?.misamt_tot || '',
    });
  }

  @action
  async onMijModalOpen(isOpen: boolean, stmon: string, endmon: string) {
    await this.SS({
      mijModal: isOpen,
      modalTitle: '[매입]',
      tab: '1-mij',
      stmon: `${this.state.stdate}${stmon}`,
      endmon: `${this.state.stdate}${endmon}`,
      title: '1-mij',
    });

    await this.onMijModalRetrieve();
  }

  @action
  async onMijModalRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      mijModalData: [],
    });

    this.infinityMijModal = new InfinityRetrieve(
      {
        sub: 'w_popup_ca642w_15',
        tab: this.state.tab,
        spjangcd: this.state.spjangcd,
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        title: this.state.title,
        as_nm: this.state.searchQuery,
        gubun: this.state.salesPurchaseFocusedData?.gubun === '00' ? '' : this.state.salesPurchaseFocusedData?.gubun,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          mijModalData: [...this.state.mijModalData || [], ...items.map((x) => new MijModalModel(x))],
        });
      },
      async () => {
        await this.SS({
          mijModalData: [],
        });
      },
    );

    await this.infinityMijModal?.retrieveAll();
    await this.gridMijModal.current?.setFocus(0);
    await this.SS({
      cnt_tot: this.infinityMijModal?.box?.cnt_tot || '',
      mijamt_tot: this.infinityMijModal?.box?.mijamt_tot || '',
    });
  }

  @action
  async onDepositModalOpen(isOpen: boolean, stmon: string, endmon: string) {
    await this.SS({
      depositModal: isOpen,
      modalTitle: '[입금]',
      tab: '2-rcv',
      stmon: `${this.state.stdate}${stmon}`,
      endmon: `${this.state.stdate}${endmon}`,
      title: '2-rcv',
    });

    await this.onDepositModalRetrieve();
  }

  @action
  async onDepositModalRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      depositModalData: [],
    });

    this.infinityDepositModal = new InfinityRetrieve(
      {
        sub: 'w_popup_ca642w_15',
        tab: this.state.tab,
        spjangcd: this.state.spjangcd,
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        title: this.state.title,
        as_nm: this.state.searchQuery,
        gubun: this.state.depositPaymentFocusedData?.gubun === '00' ? '' : this.state.depositPaymentFocusedData?.gubun,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          depositModalData: [...this.state.depositModalData || [], ...items.map((x) => new DepositModalModel(x))],
        });
      },
      async () => {
        await this.SS({
          depositModalData: [],
        });
      },
    );

    await this.infinityDepositModal?.retrieveAll();
    await this.gridDepositModal.current?.setFocus(0);
    await this.SS({
      cnt_tot: this.infinityDepositModal?.box?.cnt_tot || '',
      rcvamt_tot: this.infinityDepositModal?.box?.rcvamt_tot || '',
    });
  }

  @action
  async onPaymentModalOpen(isOpen: boolean, stmon: string, endmon: string) {
    await this.SS({
      payModal: isOpen,
      modalTitle: '[지급]',
      tab: '2-snd',
      stmon: `${this.state.stdate}${stmon}`,
      endmon: `${this.state.stdate}${endmon}`,
      title: '2-snd',
    });

    await this.onPaymentModalRetrieve();
  }

  @action
  async onPaymentModalRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      payModalData: [],
    });

    this.infinityPayModal = new InfinityRetrieve(
      {
        sub: 'w_popup_ca642w_15',
        tab: this.state.tab,
        spjangcd: this.state.spjangcd,
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        title: this.state.title,
        as_nm: this.state.searchQuery,
        gubun: this.state.depositPaymentFocusedData?.gubun === '00' ? '' : this.state.depositPaymentFocusedData?.gubun,
      },
      (params) => api.retrieve(params),
      async (items) => {
        await this.SS({
          payModalData: [...this.state.payModalData, ...items.map((x) => new PaymentModalModel(x))],
        });
      },
      async () => {
        await this.SS({
          payModalData: [],
        });
      },
    );

    await this.infinityPayModal?.retrieveAll();
    await this.gridPayModal.current?.setFocus(0);
    await this.SS({
      cnt_tot: this.infinityPayModal?.box?.cnt_tot || '',
      sndamt_tot: this.infinityPayModal?.box?.sndamt_tot || '',
    });
  }

  render() {
    return <MonthlyIncomeStatementTemplate
      scope={this}
      />;
  }
}
