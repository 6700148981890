import { computed } from 'mobx';

/**
 * 매출상세 모델
 * @window w_tb_da023
 * @category 매출등록
 */
export class ExpenseModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 매출번호
   *
   * **data type** varchar(4)
   */
  misnum: string;

  /**
   * 미수구분
   *
   * **data type** varchar(1)
   */
  misgubun: string;

  /**
   * 현장명
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 회사구분코드
   *
   * **data type** varchar(3)
   */
  wkactcd: string;

  /**
   * 계약금액
   *
   * **data type** float
   */
  contamt: string;

  /**
   * 매출구분
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 귀속년월
   *
   * **data type** varchar(6)
   */
  yyyymm: string;

  /**
   * 회계적용
   *
   * **data type** varchar(1)
   */
  accyn: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 담당자명
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 공급가액
   *
   * **data type** float
   */
  amt: string;

  /**
   * 부가세
   *
   * **data type** float
   */
  addamt: string;

  /**
   * 매출금액
   *
   * **data type** float
   */
  misamt: string;

  /**
   * 차이금액
   *
   * **data type** float
   */
  chaamt: string;

  /**
   * 지급예정일
   *
   * **data type** varchar(8)
   */
  schdate: string;

  /**
   * 계산서발행
   *
   * **data type** varchar(2)
   */
  billkind: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(2)
   */
  taxcls: string;

  /**
   * 과세구분
   *
   * **data type** varchar(3)
   */
  taxgubun: string;

  /**
   * 지로구분
   *
   * **data type** varchar(1)
   */
  jirogubun: string;

  /**
   * 현금지급
   *
   * **data type** float
   */
  hamt: string;

  /**
   * 어음
   *
   * **data type** float
   */
  eamt: string;

  /**
   * 어음번호
   *
   * **data type** varchar(12)
   */
  enum: string;

  /**
   * 어음만기
   *
   * **data type** varchar(8)
   */
  edate: string;

  /**
   * 어음일자
   *
   * **data type** varchar(8)
   */
  eidate: string;

  /**
   * 어음거래처
   *
   * **data type** varchar(13)
   */
  ecltcd: string;

  /**
   * 수표
   *
   * **data type** float
   */
  samt: string;

  /**
   * 수표번호
   *
   * **data type** varchar(12)
   */
  snum: string;

  /**
   * 예금
   *
   * **data type** float
   */
  bamt: string;

  /**
   * 입금은행
   *
   * **data type** varchar(8)
   */
  bankcd: string;

  /**
   * 카드금액
   *
   * **data type** float
   */
  damt: string;

  /**
   * 카드은행
   *
   * **data type** varchar(10)
   */
  cardcd: string;

  /**
   * 카드효율
   *
   * **data type** float
   */
  persent: string;

  /**
   * 카드번호
   *
   * **data type** varchar(16)
   */
  cardno: string;

  /**
   * 기타구분
   *
   * **data type** varchar(5)
   */
  ggubun: string;

  /**
   * 기타금액
   *
   * **data type** float
   */
  gamt: string;

  /**
   * 지로금액
   *
   * **data type** float
   */
  jamt: string;

  /**
   * 지로수수료
   *
   * **data type** float
   */
  jmar: string;

  /**
   * 지로번호
   *
   * **data type** varchar(13)
   */
  jnum: string;

  /**
   * 지로은행
   *
   * **data type** varchar(8)
   */
  jbankcd: string;

  /**
   * ~어음만기
   *
   * **data type** float
   */
  dcamt: string;

  /**
   * 지로번호
   *
   * **data type** varchar(10)
   */
  pubnum: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  recivstatus: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  tax_spnum: string;

  /**
   * 전표일자
   *
   * **data type** varchar(8)
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  spnum: string;

  /**
   * 적요
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 세금계산서비고(적요)
   *
   * **data type** varchar(255)
   */
  bigo: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 지로번호
   *
   * **data type** varchar(10)
   */
  jcltno: string;

  /**
   * 선납구분
   *
   * **data type** varchar(1)
   */
  sunflag: string;

  /**
   * 선급금액
   *
   * **data type** float
   */
  sunamt: string;

  /**
   * 선납채크
   *
   * **data type** char(1)
   */
  sunchk: string;

  /**
   * 계산서발행
   *
   * **data type** char(1)
   */
  billgubun: string;

  /**
   * 구분
   *
   * **data type** char(1)
   */
  compflag: string;

  /**
   * 공사일자
   *
   * **data type** char(8)
   */
  compdate: string;

  /**
   * 공사번호
   *
   * **data type** varchar(3)
   */
  compnum: string;

  /**
   * 출고구분
   *
   * **data type** varchar(1)
   */
  delflag: string;

  /**
   * 출고일자
   *
   * **data type** varchar(8)
   */
  deldate: string;

  /**
   * 출고번호
   *
   * **data type** varchar(4)
   */
  delnum: string;

  /**
   * 계정코드
   *
   * **data type** varchar(4)
   */
  acccd: string;

  /**
   * 지로월
   *
   * **data type** varchar(1)
   */
  ungijun: string;

  /**
   * 지로채크
   *
   * **data type** varchar(1)
   */
  jirochk: string;

  /**
   * 지로납기일
   *
   * **data type** varchar(8)
   */
  jirodate: string;

  /**
   * 예전미수일자
   *
   * **data type** varchar(8)
   */
  bemisdate: string;

  /**
   * 예전미수번호
   *
   * **data type** varchar(4)
   */
  bemisnum: string;

  /**
   * 입금처리
   *
   * **data type** varchar(1)
   */
  misflag: string;

  /**
   * 예금수수료
   *
   * **data type** float
   */
  bmar: string;

  /**
   * 발행구분
   *
   * **data type** varchar(1)
   */
  receiptyn: string;

  /**
   * 입고일자
   *
   * **data type** varchar(8)
   */
  ibgdate: string;

  /**
   * ems연결코드
   *
   * **data type** varchar(13)
   */
  emcltcd: string;

  /**
   * ems연결코드
   *
   * **data type** varchar(13)
   */
  emactcd: string;

  /**
   * ems연결코드
   *
   * **data type** varchar(50)
   */
  emactcd2: string;

  /**
   * ems연결코드
   *
   * **data type** varchar(50)
   */
  emseq: string;

  /**
   * ems연결코드
   *
   * **data type** varchar(50)
   */
  emtaxbillno: string;

  /**
   * 프로젝트
   *
   * **data type** varchar(10)
   */
  projno: string;

  /**
   * 현장명2
   *
   * **data type** varchar(255)
   */
  actnm: string;

  /**
   * 거래처명주소
   *
   * **data type** varchar(255)
   */
  cltnm: string;

  /**
   * 회사구분2
   *
   * **data type** varchar(100)
   */
  wkactnm: string;

  /**
   * 귀속부서2
   *
   * **data type** varchar(100)
   */
  divinm: string;

  /**
   * 담당자명2
   *
   * **data type** varchar(100)
   */
  pernm: string;

  /**
   * 프로젝트2
   *
   * **data type** varchar(255)
   */
  projnm: string;

  /**
   * 지로출력채크
   *
   * **data type** varchar(1)
   */
  prtchk: string;

  /**
   * 매출처리취소
   *
   * **data type** varchar(1)
   */
  cancleflag: string;

  /**
   * 도장채크
   *
   * **data type** varchar(1)
   */
  stamp: string;

  /**
   * 현대금액
   *
   * **data type** float
   */
  hdamt: string;

  /**
   * 프로젝트현장코드
   *
   * **data type** varchar(13)
   */
  proactcd: string;

  /**
   * 설치선급금구분
   *
   * **data type** varchar(1)
   */
  scstflag: string;

  /**
   * 설치선급일자
   *
   * **data type** varchar(8)
   */
  scstdate: string;

  /**
   * 설치중도구분
   *
   * **data type** varchar(1)
   */
  scmidflag: string;

  /**
   * 설치중도일자
   *
   * **data type** varchar(8)
   */
  scmiddate: string;

  /**
   * 설치완료구분
   *
   * **data type** varchar(1)
   */
  scendflag: string;

  /**
   * 설치완료일자
   *
   * **data type** varchar(8)
   */
  scenddate: string;

  /**
   * 설치계약일자
   *
   * **data type** varchar(8)
   */
  orddate: string;

  /**
   * 설치계약번호
   *
   * **data type** varchar(4)
   */
  ordnum: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  flag: string;

  /**
   * 현대다른거비고
   *
   * **data type** varchar(255)
   */
  hd_diff_remark: string;

  /**
   * 지로도장
   *
   * **data type** varchar(1)
   */
  jiro_stamp: string;

  /**
   * 계산서다이렉트
   *
   * **data type** varchar(3)
   */
  tax_direct: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  bigo2: string;

  /**
   * 예금관리연결코드
   *
   * **data type** varchar(50)
   */
  imseq: string;

  /**
   * 지로출력순번
   *
   * **data type** float
   */
  giro_prtseq: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  seq: string;

  /**
   * 품목코드
   *
   * **data type** varchar(20)
   */
  pcode: string;

  /**
   * 품명
   *
   * **data type** varchar(255)
   */
  pname: string;

  /**
   * 규격
   *
   * **data type** varchar(255)
   */
  psize: string;

  /**
   * 단위
   *
   * **data type** varchar(50)
   */
  punit: string;

  /**
   * 수량
   *
   * **data type** number
   */
  qty: string;

  /**
   * 단가
   *
   * **data type** number
   */
  uamt: string;

  /**
   * 계정코드2
   *
   * **data type** number
   */
  accnm: string;

  /**
   * 매출일자
   *
   * **data type** varchar(20)
   */
  datetime: string;

  /**
   * 수금예정일
   *
   * **data type** varchar(20)
   */
  datetime3: string;

  /**
   * 입금은행2
   *
   * **data type** varchar(20)
   */
  banknm: string;

  /**
   * 입금은행3
   *
   * **data type** varchar(20)
   */
  accnum: string;

  /**
   * 계산서발행 셀렉트박스
   *
   * **data type** varchar(20)
   */
  taxflag: string;

  /**
   * 공사불러오기
   *
   * **data type** varchar(1)
   */
  construction: string;

  /**
   * 변경
   *
   * **data type** varchar(10)
   */
  tax_spdate: string;

  /**
   * -
   *
   * **data type** varchar(1)
   */
  total3: string;

  /**
   * -
   *
   * **data type** varchar(10)
   */
  total4: string;

  email: string;

  emailtitle: string;

  toname: string;

  subject: string;

  rcvamt: string;

  rcvflag: string;

  scstnum: string;

  scmidnum: string;

  scendnum: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.tax_spdate = data.tax_spdate || '';
    this.cltcd = data.cltcd || '';
    this.total3 = data.total3 || '';
    this.total4 = data.total4 || '';
    this.construction = data.construction || '';
    this.cltnm = data.cltnm || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.misgubun = data.misgubun || '';
    this.actcd = data.actcd || '';
    this.wkactcd = data.wkactcd || '';
    this.contamt = data.contamt || '';
    this.gubun = data.gubun || '';
    this.yyyymm = data.yyyymm || '';
    this.accyn = data.accyn || '';
    this.divicd = data.divicd || '';
    this.perid = data.perid || '';
    this.amt = data.amt || '';
    this.addamt = data.addamt || '';
    this.misamt = data.misamt || '';
    this.chaamt = data.chaamt || '';
    this.schdate = data.schdate || '';
    this.billkind = data.billkind || '';
    this.taxcls = data.taxcls || '';
    this.taxgubun = data.taxgubun || '';
    this.jirogubun = data.jirogubun || '';
    this.hamt = data.hamt || '';
    this.eamt = data.eamt || '';
    this.enum = data.enum || '';
    this.edate = data.edate || '';
    this.eidate = data.eidate || '';
    this.ecltcd = data.ecltcd || '';
    this.samt = data.samt || '';
    this.snum = data.snum || '';
    this.bamt = data.bamt || '';
    this.bankcd = data.bankcd || '';
    this.damt = data.damt || '';
    this.cardcd = data.cardcd || '';
    this.persent = data.persent || '';
    this.cardno = data.cardno || '';
    this.ggubun = data.ggubun || '';
    this.gamt = data.gamt || '';
    this.jamt = data.jamt || '';
    this.jmar = data.jmar || '';
    this.jnum = data.jnum || '';
    this.spnum = data.spnum || '';
    this.jbankcd = data.jbankcd || '';
    this.dcamt = data.dcamt || '';
    this.pubnum = data.pubnum || '';
    this.recivstatus = data.recivstatus || '';
    this.tax_spnum = data.tax_spnum || '';
    this.acc_spdate = data.acc_spdate || '';
    this.remark = data.remark || '';
    this.bigo = data.bigo || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.jcltno = data.jcltno || '';
    this.sunflag = data.sunflag || '';
    this.sunamt = data.sunamt || '';
    this.sunchk = data.sunchk || '';
    this.billgubun = data.billgubun || '';
    this.compflag = data.compflag || '';
    this.compdate = data.compdate || '';
    this.compnum = data.compnum || '';
    this.delflag = data.delflag || '';
    this.deldate = data.deldate || '';
    this.delnum = data.delnum || '';
    this.acccd = data.acccd || '';
    this.ungijun = data.ungijun || '';
    this.jirochk = data.jirochk || '';
    this.jirodate = data.jirodate || '';
    this.jirodate = data.jirodate || '';
    this.bemisdate = data.bemisdate || '';
    this.bemisnum = data.bemisnum || '';
    this.misflag = data.misflag || '';
    this.bmar = data.bmar || '';
    this.receiptyn = data.receiptyn || '';
    this.ibgdate = data.ibgdate || '';
    this.emcltcd = data.emcltcd || '';
    this.emactcd = data.emactcd || '';
    this.emactcd2 = data.emactcd2 || '';
    this.emseq = data.emseq || '';
    this.emtaxbillno = data.emtaxbillno || '';
    this.projno = data.projno || '';
    this.actnm = data.actnm || '';
    this.cltnm = data.cltnm || '';
    this.wkactnm = data.wkactnm || '';
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.projnm = data.projnm || '';
    this.prtchk = data.prtchk || '';
    this.cancleflag = data.cancleflag || '';
    this.stamp = data.stamp || '';
    this.hdamt = data.hdamt || '';
    this.proactcd = data.proactcd || '';
    this.scstflag = data.scstflag || '';
    this.scstdate = data.scstdate || '';
    this.scmidflag = data.scmidflag || '';
    this.scmiddate = data.scmiddate || '';
    this.scendflag = data.scendflag || '';
    this.scenddate = data.scenddate || '';
    this.orddate = data.orddate || '';
    this.ordnum = data.ordnum || '';
    this.flag = data.flag || '';
    this.hd_diff_remark = data.hd_diff_remark || '';
    this.jiro_stamp = data.jiro_stamp || '';
    this.tax_direct = data.tax_direct || '';
    this.bigo2 = data.bigo2 || '';
    this.imseq = data.imseq || '';
    this.giro_prtseq = data.giro_prtseq || '';
    this.seq = data.seq || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.uamt = data.uamt || '';
    this.accnm = data.accnm || '';
    this.datetime = data.datetime || '';
    this.datetime3 = data.datetime3 || '';
    this.banknm = data.banknm || '';
    this.accnum = data.accnum || '';
    this.taxflag = data.taxflag || '';
    this.email = data.email || '';
    this.emailtitle = data.emailtitle || '';
    this.toname = data.toname || '';
    this.subject = data.subject || '';
    this.rcvamt = data.rcvamt || '';
    this.rcvflag = data.rcvflag || '';
    this.scstnum = data.scstnum || '';
    this.scmidnum = data.scmidnum || '';
    this.scendnum = data.scendnum || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
