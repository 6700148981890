import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e401
 * @category 고장접수등록
 */

export class PopupModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 순서
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 접수자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 접수자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 배정거절자
   *
   * **data type** varchar(8)
   */
  readonly reperid: string;

  /**
   * 배정거절자
   *
   * **data type** varchar(8)
   */
  readonly repernm: string;

  /**
   * 배정거절사유
   *
   * **data type** varchar(3)
   */
  readonly remark: string;

  /**
   * 접수일자
   *
   * **data type** varchar(3)
   */
  readonly recedate: string;

  /**
   * 접수번호
   *
   * **data type** varchar(3)
   */
  readonly recenum: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.recedate = data.recedate || '';
    this.recenum = data.recenum || '';
    this.seq = data.seq || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.reperid = data.reperid || '';
    this.repernm = data.repernm || '';
    this.remark = data.remark || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
