import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents, RetrieveFocusType } from '../../../../constants';
import { StatusTemplate } from './Status.template';
import { StatusModel } from './Status.model';
import { InfinityRetrieve } from '../../../../models';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';
import { TableLayout } from '../../../../components';

interface StatusState {
  searchQuery: string;
  clttype: string;
  data: Array<StatusModel>;
  focusedData?: StatusModel;
  holiflag?:string;
}

/**
 * 컨트롤러
 * @window w_tb_xclient_01
 * @category 거래처현황
 */
export class Status extends PageComponent<PageProps, StatusState>
  implements PageToolEvents {
  updatedRows?: Array<StatusModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      clttype: '%',
      data: [],
    };
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        as_clttype: this.state.clttype,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new StatusModel(x))],
        });
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
        if (this.state.data.length) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['cltcd', 'cltnm'],
        [this.state.focusedData?.cltcd, this.state.focusedData?.cltnm], type, true) || 0;
      this.state.data && this.state.data.length > index && this.table.current?.setFocus(index);
      this.SS({
        holiflag: this.infinity?.box?.holiflag || '0',
      });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      as_clttype: this.state.clttype,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
      as_clttype: this.state.clttype,
    });
  }

  // 계약금 발행
  async openResister(item: StatusModel) {
    const { publicStore } = this.props;
    publicStore.go('/w_tb_xclient', {
      searchQuery: item.cltnm,
      clttype: '1',
    });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  onRowFocusEvent(item: StatusModel) {
    this.setState({ focusedData: item });
  }

  render() {
    return <StatusTemplate scope={this} />;
  }
}
