import * as React from 'react';
import { action } from 'mobx';
import { InfinityRetrieve } from '../../../../models/common';
import { FileStoreTemplate } from './FileStore.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  ConfirmDelete,
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  FileStoreDetailModel,
  FileStoreModel,
} from './models';
import { PageComponent } from '../../../../utils/layout';

interface ReferenceBoxState {

  // 검색조건
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  searchQuery: string; // 검색어
  appnm: string;
  perid: string;
  pernm: string;
  filenm: string;
  new: string;

  focusedFileStore: FileStoreModel;
  fileStoreList: Array<FileStoreModel>;

  focusedFileStoreDetail: FileStoreDetailModel;
  fileStoreDetailList: Array<FileStoreDetailModel>;

  isFileModalVisible: boolean;
}

/*
 * 컨트롤러
 * @window w_tb_e080_attach_01
 * @category 파일보관함[첨부파일]
*/

export class FileStore extends PageComponent<PageProps, ReferenceBoxState>
  implements PageToolEvents {
  updatedRows?: Array<FileStoreModel>;

  updatedRows2?: Array<FileStoreDetailModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  detailTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    // state 기본값 정의
    this.state = props.state || {
      stdate: '19700101', // 기간(시작)
      enddate: `${year}1231`, // 기간(끝)
      searchQuery: '',
      appnm: '파일보관',
      perid: '',
      pernm: '',
      filenm: '',
      new: '0',

      fileStoreList: [],
      fileStoreDetailList: [],

      isFileModalVisible: false,
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }


  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        appnm: this.state.appnm,
        perid: this.state.perid,
        pernm: this.state.pernm,
        filenm: this.state.filenm,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          fileStoreList: [...this.state.fileStoreList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          fileStoreList: [],
          fileStoreDetailList: [],
        });
        await this.detailTable.current?.update(true);

        await this.infinity?.retrieveAll();
        if (this.state.fileStoreList && this.state.fileStoreList?.length > 0) {
          await this.table.current?.update();
          this.table.current?.setFocus(0, 3);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      fileStoreList: [],
      fileStoreDetailList: [],
      new: '0',
    }, async () => {
      await this.detailTable.current?.update(true);
      await this.infinity?.retrieveAll();
      if (this.state.fileStoreList && this.state.fileStoreList?.length > 0) {
        this.onRowFocusEvent(this.state.fileStoreList[0]);
        await this.detailTable.current?.update();
        await this.table.current?.update();
        this.table.current?.setFocus(0, 3);
      }
    });
  }

  @action
  async onRowFocusEvent(item: FileStoreModel) {
    const { actionStore: api } = this.props;

    await this.setState({ focusedFileStore: item });

    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        appnm: this.state.appnm,
        perid: this.state.perid,
        pernm: this.state.pernm,
        filenm: this.state.filenm,
        appnum: this.state.focusedFileStore?.appnum,
      },
      (params) => api.fxExec('dw_1_RowFocuschanged', params),
      (items) => {
        this.setState({
          fileStoreDetailList: [
            ...this.state.fileStoreDetailList,
            ...items.map((x: any) => new FileStoreDetailModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          fileStoreDetailList: [],
        });

        await this.infinity2?.retrieve();
        if (this.state.fileStoreDetailList && this.state.fileStoreDetailList?.length > 0) {
          await this.detailTable.current?.update(true);
          this.detailTable.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      fileStoreDetailList: [],
    }, async () => {
      await this.detailTable.current?.update(true);
      await this.infinity2?.retrieve();

      if (this.state.fileStoreDetailList && this.state.fileStoreDetailList?.length > 0) {
        await this.detailTable.current?.update(true);
        this.detailTable.current?.setFocus(0, 0);
      }
    });
  }

  @action
  onRowFocusEvent2(item:FileStoreDetailModel) {
    this.setState({ focusedFileStoreDetail: item });
  }

  @action
  onUpdatedRows(rows: Array<FileStoreModel>, updatedRows: Array<FileStoreModel>) {
    this.updatedRows = updatedRows;
    this.setState({ fileStoreList: rows });
  }

  @action
  onUpdatedRows2(rows: Array<FileStoreDetailModel>, updatedRows: Array<FileStoreDetailModel>) {
    this.updatedRows2 = updatedRows;
    this.setState({ fileStoreDetailList: rows });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if ((this.updatedRows?.filter((x) => !x.title).length || 0) > 0) {
      ConfirmWarning.show('경고', '제목/폴더명을 입력하세요');
      return;
    }

    if (await api.save({
      items: this.updatedRows,
    }, false)) {
      this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      fileStoreList: [new FileStoreModel(data, true), ...this.state.fileStoreList],
      fileStoreDetailList: [],
      new: '1',
    }, async () => {
      await this.table.current?.update();
      this.table.current?.setFocus(0, 3);
      this.detailTable.current?.update(true);
      this.detailTable.current?.setFocus(0, 0);
    });
  }

  @action
  async openFileModal() {
    this.setState({
      isFileModalVisible: true,
    });
  }

  @action
  async closeFileModal() {
    this.setState({
      isFileModalVisible: false,
    }, () => this.onRowFocusEvent(this.state.focusedFileStore));
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `문서구분: ${this.state.focusedFileStore?.appnm}, 제목/폴더명: ${this.state.focusedFileStore?.title}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '제목/폴더를 삭제하면 첨부한 파일도 전부 삭제 됩니다.\n그래도 삭제하시겠습니까?';
      await api.delete(text2, this.state.focusedFileStore) && this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;

    if (!this.updatedRows2?.length) {
      ConfirmWarning.show('오류', '삭제할 파일이 없습니다!');
      return;
    }
    const data = await api?.attachment(
      {
        items: this.updatedRows2,
      },
      'wb_delete',
    );
    if (data) {
      ConfirmSuccess.showPbMessage(data);
      this.updatedRows2 = [];
      this.onRetrieveEvent();
    } else {
      ConfirmFail.show('오류', '서버 오류로 첨부파일이 삭제되지 않았습니다!');
    }
  }

  render() {
    return (
      <FileStoreTemplate scope={this}/>
    );
  }
}
