import * as React from 'react';
import { action } from 'mobx';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  ReferenceBoxDetailModel, ReferenceBoxModel,
} from './models';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { ReferenceBoxTemplate } from './ReferenceBox.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ConfirmSuccess, ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

interface ReferenceBoxState {

  // 검색조건
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  searchQuery: string; // 검색어
  gubun: string; // 결재구분
  papercd: string; // 문서구분
  focusIndex: number;

  // 데이터 객체
  focusedReferenceBox: ReferenceBoxModel;
  referenceBoxList: Array<ReferenceBoxModel>;

  papercds: Array<any>;

  focusedDetail: ReferenceBoxDetailModel;
  detailList: Array<ReferenceBoxDetailModel>;

  // 팝업
  remark: string;
  isReferenceModalVisible: boolean;
  url?: string;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  pushTotalCheck: boolean;
  pushTotalCheck2: boolean;
  attcnt: string;
}

/*
 컨트롤러
 * @window w_tb_e080w_03
 * @category 수신참조함
*/
export class ReferenceBox extends PageComponent<PageProps, ReferenceBoxState>
  implements PageToolEvents {
  updatedRows?: Array<ReferenceBoxModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  detailGrid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    const lastDate = new Date(2019, month, 0).getDate();

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}0101`, // 기간(시작)
      enddate: `${year}${month}${lastDate}`, // 기간(끝)
      gubun: '0', // 결재구분
      papercd: '%', // 문서구분
      searchQuery: '',
      focusIndex: 0,

      fileDetailModal: false,

      referenceBoxList: [],
      detailList: [],

      remark: '',
      attcnt: '0',
      isReferenceModalVisible: false,
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_ca510_620_02');

    if (!data) return;

    this.setState({ papercds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        appgubun: this.state.gubun,
        papercd: this.state.papercd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          referenceBoxList: [...this.state.referenceBoxList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          referenceBoxList: [],
          detailList: [],
          pushTotalCheck: false,
          pushTotalCheck2: false,
        });

        await this.infinity?.retrieve();
        if (this.state.referenceBoxList && this.state.referenceBoxList?.length > 0) {
          await this.table.current?.update(true);
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      referenceBoxList: [],
      detailList: [],
      pushTotalCheck: false,
      pushTotalCheck2: false,
      attcnt: '0',
    }, async () => {
      await this.infinity?.retrieve();
      await this.table.current?.update(true);
      this.table.current?.setFocus(0, 0);
    });
  }

  @action
  onRowFocusEvent(item: ReferenceBoxModel, index: number) {
    const { actionStore: api } = this.props;

    this.setState({
      focusedReferenceBox: item,
      focusIndex: index,
    },
    () => {
      this.infinity2 = new InfinityRetrieve({
        appnum: this.state.focusedReferenceBox?.appnum,
      },
      (params) => api.fxExec('dw_1_RowFocuschanged', params),
      (items) => {
        this.setState({
          detailList: items,
        });
      },
      async () => {
        await this.SS({
          detailList: [],
        });

        await this.infinity2?.retrieveAll();
        if (this.state.detailList && this.state.detailList?.length > 0) {
          await this.detailGrid.current?.setFocus(0);
        }
      });
      this.infinity2?.retrieveAll();
      this.detailGrid.current?.setFocus(0);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (await api.save({
      items: this.state.referenceBoxList, // chk? referflag?
    }, this.state.focusedReferenceBox?.new === '1')) {
      this.updatedRows = [];
      this.onRetrieveEvent();
    }
  }

  @action
  async onPrintEvent() {
    const checkData: any = [];
    this.state.referenceBoxList?.forEach((x: any) => {
      if (x.prtchk === '1') {
        checkData.push(new ReferenceBoxModel({
          ...x,
        }));
      }
    });

    if (!ConfirmWarning.assert(checkData, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({ items: checkData });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.referenceBoxList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      appgubun: this.state.gubun,
      papercd: this.state.papercd,
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    this.setState({
      pushTotalCheck: checked,
      referenceBoxList: this.state.referenceBoxList?.map((x) => new ReferenceBoxModel({
        ...x,
        prtchk: checked === true ? '1' : '0',
      })),
    }, () => this.table.current?.update(true));
  }

  @action
  async updateCheckAllToggle2(checked: boolean) {
    this.setState({
      pushTotalCheck2: checked,
      referenceBoxList: this.state.referenceBoxList?.map((x) => new ReferenceBoxModel({
        ...x,
        referflag: checked === true ? '1' : '0',
      })),
    }, () => this.table.current?.update(true));
  }

  @action
  fileModal(isOpen: boolean) {
    this.setState({ fileDetailModal: isOpen });
    this.modalRetrieve();
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;
    const data = await api.retrieve(
      {
        sub: 'w_popup_e080_refer',
        papercd: this.state.focusedReferenceBox?.papercd,
        appnum: this.state.focusedReferenceBox?.appnum,
        perid: this.state.focusedReferenceBox?.repoperid.replace(/p/gi, ''),
      },
    );
    data && this.setState({
      remark: data?.remark,
      attcnt: data?.attcnt,
    });
  }

  @action
  async modalPrint() {
    const { actionStore: api } = this.props;

    await api.printWithElmanManager({
      sub: 'w_popup_e080_refer',
      papercd: this.state.focusedReferenceBox?.papercd,
      appnum: this.state.focusedReferenceBox?.appnum,
      perid: this.state.focusedReferenceBox?.repoperid.replace(/p/gi, ''),
    });
  }

  @action
  async modalConfirm() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_refer',
      {
        sub: 'w_popup_e080_refer',
        appnum: this.state.focusedReferenceBox?.appnum,
        papercd: this.state.focusedReferenceBox?.papercd,
        remark: this.state.remark || '',
      });
    if (data) {
      ConfirmSuccess.show(data.titlebox, data.messagebox);
      await this.closeReferenceModal();
    }
  }

  @action
  async openReferenceModal() {
    const { user } = this.props.publicStore;
    const paper = this.state.focusedReferenceBox;

    if (!paper) {
      ConfirmWarning.show('오류', '결재할 문서가 없습니다');
      return;
    }

    this.setState({
      isReferenceModalVisible: true,
      url: `https://api2.elmansoft.com/paper/ND${paper.papercd}/view?custcd=${user.custcd}&appnum=${paper.appnum}`,
    }, () => this.modalRetrieve());
  }

  @action
  async closeReferenceModal() {
    this.setState({
      isReferenceModalVisible: false,
    }, () => {
      this.updatedRows = [];
      this.onRetrieveEvent();
    });
  }

  @action
  onUpdatedRows(rows: Array<ReferenceBoxModel>, updatedRows: Array<ReferenceBoxModel>) {
    this.updatedRows = updatedRows;
    this.setState({ referenceBoxList: rows });
  }

  render() {
    return (
      <ReferenceBoxTemplate scope={this}/>
    );
  }
}
