import {
  RouterStore as BaseRouterStore,
  syncHistoryWithStore,
} from 'mobx-react-router';
import { ObservableHistory } from 'mobx-observable-history';

export default class RouterStore extends BaseRouterStore {
  constructor(history?: ObservableHistory) {
    super();
    if (history) {
      this.history = syncHistoryWithStore(history, this);
    }
  }
}
