import * as React from 'react';
import { observer } from 'mobx-react';
import {
  BsChevronLeft,
  BsChevronRight,
  IoSquareOutline,
  IoCheckboxOutline,
} from 'react-icons/all';
import { action } from 'mobx';
import { findDOMNode } from 'react-dom';
import {
  Button,
  FlexLayout,
} from '../../components';
import { ModalStore } from '../../stores';
import { JoinClassName } from '../../utils/string';
import cStyle from '../../components/forms/DateTextBox/DateTextBox.module.scss';
import { DateTextBoxMode } from '../../components/forms/DateTextBox/DateTextBox';
import {
  Date8,
  Today,
} from '../../utils/time';

interface ModalCalendarProps {
  modalStore: ModalStore;
}

interface ModalCalendarStates {
  x: number;
  y: number;
  isTodayCheckHover: boolean;
}

@observer
export class ModalCalendar extends React.Component<ModalCalendarProps, ModalCalendarStates> {
  constructor(props: ModalCalendarProps, context: any) {
    super(props, context);
    this.state = {
      x: 0,
      y: 0,
      isTodayCheckHover: false,
    };
  }

  @action
  update() {
    const { modalStore } = this.props;
    const ref = modalStore.refCalendar;
    const state = ref?.state;

    let x = 0;
    let y = 0;
    const f = this.props.modalStore.publicStore.zoomF;

    if (ref && ref.isMount) {
      // @ts-ignore
      const rect = findDOMNode(ref).getBoundingClientRect();
      x = rect.left + state?.popupLeft;
      y = rect.top;

      if (x + ((modalStore?.comboBoxWidth || 0) * f) > window.innerWidth) {
        x = window.innerWidth - ((modalStore?.comboBoxWidth || 0) * f);
      }
    }

    this.setState({
      x: x / f,
      y: y / f,
    });
  }

  render() {
    const { modalStore } = this.props;
    const ref = modalStore.refCalendar;
    const state = ref?.state;
    const today = Today.date();

    return (
      <div
        id="calendar"
        className={JoinClassName.make([
          cStyle.calendar,
        ])}
        style={{
          opacity: modalStore.isVisibleCalendar ? 1 : 0,
          transform: `translateX(${this.state.x}px) translateY(${state?.isPopupForward
            ? `calc(37px + ${this.state.y}px)`
            : `calc(-100% + ${this.state.y}px)`})`,
        }}
        onMouseDown={() => ref?.setDoNotClose()}
      >
        {state?.mode === DateTextBoxMode.YEAR && <div className={cStyle.defaultContainer}>
          {state?.data.map((x) => <div
            key={x.data}
            onClick={() => ref?.selectCalendarItem(x.data)}
            className={ref?.props.value === x.data ? cStyle.active : null}
          >
            {x.year}
          </div>)}
        </div>}

        {state?.mode === DateTextBoxMode.MONTH && <div className={cStyle.defaultContainer}>
          <div className={cStyle.header}>
            <FlexLayout>
              <FlexLayout size={55} onClick={() => ref?.prevPage()}><BsChevronLeft /></FlexLayout>
              <FlexLayout onClick={() => ref?.prevDepth()}>{state?.temp.substr(0, 4)}년</FlexLayout>
              <FlexLayout size={55} onClick={() => ref?.nextPage()}><BsChevronRight /></FlexLayout>
            </FlexLayout>
          </div>
          {state?.data.map((x) => <div
            key={x.data}
            onClick={() => ref?.selectCalendarItem(x.data)}
            className={ref?.props.value === x.data ? cStyle.active : null}
          >
            {x.month}
          </div>)}
        </div>}

        {state?.mode === DateTextBoxMode.DATE
          && <div className={JoinClassName.make([cStyle.defaultContainer, cStyle.date])}
        >
          {/* eslint-disable-next-line max-len */}
          <div className={cStyle.header}>
            <FlexLayout>
              <FlexLayout size={55} onClick={() => ref?.prevPage()}><BsChevronLeft /></FlexLayout>
              <FlexLayout onClick={() => ref?.prevDepth()}>
                {state?.temp.substr(0, 4)}년 {parseInt(state?.temp.substr(4, 2), 10)}월
              </FlexLayout>
              <FlexLayout size={55} onClick={() => ref?.nextPage()}><BsChevronRight /></FlexLayout>
            </FlexLayout>
          </div>
          <div className={cStyle.none}>일</div>
          <div className={cStyle.none}>월</div>
          <div className={cStyle.none}>화</div>
          <div className={cStyle.none}>수</div>
          <div className={cStyle.none}>목</div>
          <div className={cStyle.none}>금</div>
          <div className={cStyle.none}>토</div>
          {state?.data.map((x) => <div
            key={x.data}
            onMouseDown={() => ref?.selectCalendarItem(x.data)}
            className={JoinClassName.make([
              ref?.rawData === x.data ? cStyle.active : null,
              today === x.data ? cStyle.today : null,
              x.other ? cStyle.other : null,
            ])}
          >
            {x.date}
          </div>)}

          <div className={cStyle.footer}>
            오늘
            &nbsp;&nbsp;
            {Date8.withKor()}
            &nbsp;&nbsp;
            <Button
              isIcon={true}
              onClick={() => {
                ref?.selectCalendarItem(Date8.make());
                this.setState({ isTodayCheckHover: false });
              }}
              onMouseOver={() => this.setState({ isTodayCheckHover: true })}
              onMouseOut={() => this.setState({ isTodayCheckHover: false })}
            >
              {this.state.isTodayCheckHover
                ? <IoCheckboxOutline size={20} />
                : <IoSquareOutline
                  color="var(--color-secondary)"
                  size={20}
                />
              }
            </Button>
          </div>
        </div>}
      </div>
    );
  }
}
