import { computed } from 'mobx';

/**
 * 사용자별 ERP사용건수 모델
 * @window w_tb_xlogin_02_02
 * @category 사용자별접속현황
 */
export class UserERPModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(4)
   */
  divinm: string;

  /**
   * ID
   *
   * **data type** varchar(1)
   */
  userid: string;

  /**
   * 사용자명
   *
   * **data type** varchar(3)
   */
  pernm: string;

  /**
   * 접속일자
   *
   * **data type** varchar(4)
   */
  londtdate: string;

  /**
   * 사용내역
   *
   * **data type** varchar(3)
   */
  winnm: string;

  /**
   * 건수
   *
   * **data type** varchar(3)
   */
  cnt: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.divinm = data.divinm || '';
    this.userid = data.userid || '';
    this.pernm = data.pernm || '';
    this.londtdate = data.londtdate || '';
    this.winnm = data.winnm || '';
    this.cnt = data.cnt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
