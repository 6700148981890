import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_e411
 * @category 고장다발처리보고서
 */

export class BreakReportDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly rptnum: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly equpcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly carcd: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly wkcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly wktext: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly frtime: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly totime: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly km: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   *+
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inpernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly edudate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly dedunum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly lkdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly lknum: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly rptdate: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  readonly recenum: string;

  /**
   *  접수일자
   *
   * **data type** varchar(?)
   */
  readonly datetime: string;

  /**
   *  고장일자
   *
   * **data type** varchar(?)
   */
  readonly hitchdate: string;

  /**
   *  고장시간
   *
   * **data type** varchar(?)
   */
  readonly hitchhour: string;

  /**
   *  담당자
   *
   * **data type** varchar(?)
   */
  readonly actperid: string;

  /**
   *  장기미수금
   *
   * **data type** varchar(?)
   */
  readonly misamt03: string;

  /**
   *  현장주소
   *
   * **data type** varchar(?)
   */
  readonly zipcode: string;

  /**
   *  현장주소
   *
   * **data type** varchar(?)
   */
  readonly address: string;

  /**
   *  현장주소
   *
   * **data type** varchar(?)
   */
  readonly address2: string;

  /**
   *  현장담당자
   *
   * **data type** varchar(?)
   */
  readonly actpernm: string;

  /**
   *  호기명
   *
   * **data type** varchar(?)
   */
  readonly elno: string;

  /**
   *  고장내용
   *
   * **data type** varchar(?)
   */
  readonly contcd: string;

  /**
   *  고장내용
   *
   * **data type** varchar(?)
   */
  readonly contnm: string;

  /**
   *  점검처리
   *
   * **data type** varchar(?)
   */
  readonly planstate: string;

  /**
   *  고장상세내용
   *
   * **data type** varchar(?)
   */
  readonly contents: string;

  /**
   *  사람갇힘일자
   *
   * **data type** varchar(?)
   */
  readonly datetime3: string;

  /**
   *  사람갇힘
   *
   * **data type** varchar(?)
   */
  readonly trouble: string;

  /**
   *  갇힘시간
   *
   * **data type** varchar(?)
   */
  readonly troubletime: string;

  /**
   *  갇힘사람수
   *
   * **data type** varchar(?)
   */
  readonly troublesu: string;

  /**
   *  접수자
   *
   * **data type** varchar(?)
   */
  readonly reperid: string;

  /**
   *  접수자명
   *
   * **data type** varchar(?)
   */
  readonly repernm: string;

  /**
   *  배정예정자
   *
   * **data type** varchar(?)
   */
  readonly perid: string;

  /**
   *  배정예정자
   *
   * **data type** varchar(?)
   */
  readonly pernm: string;

  /**
   *  배정자부서
   *
   * **data type** varchar(?)
   */
  readonly divicd: string;

  /**
   *  배정자부서
   *
   * **data type** varchar(?)
   */
  readonly divinm: string;

  /**
   *  배정예정자
   *
   * **data type** varchar(?)
   */
  readonly perid2: string;

  /**
   *  배정예정자
   *
   * **data type** varchar(?)
   */
  readonly pernm2: string;

  /**
   *  배정자부서
   *
   * **data type** varchar(?)
   */
  readonly divicd2: string;

  /**
   *  배정자부서
   *
   * **data type** varchar(?)
   */
  readonly divinm2: string;

  /**
   *  배정자근태
   *
   * **data type** varchar(?)
   */
  readonly atdnm: string;

  /**
   *  배정자구분
   *
   * **data type** varchar(?)
   */
  readonly okflag: string;

  /**
   *  근거리직원1
   *
   * **data type** varchar(?)
   */
  readonly nearpernm1: string;

  /**
   *  근거리직원1
   *
   * **data type** varchar(?)
   */
  readonly rdistance1: string;

  /**
   *  근거리직원2
   *
   * **data type** varchar(?)
   */
  readonly nearpernm2: string;

  /**
   *  근거리직원2
   *
   * **data type** varchar(?)
   */
  readonly rdistance2: string;

  /**
   *  근거리직원3
   *
   * **data type** varchar(?)
   */
  readonly nearpernm3: string;

  /**
   *  근거리직원3
   *
   * **data type** varchar(?)
   */
  readonly rdistance3: string;

  /**
   *  근거리직원1
   *
   * **data type** varchar(?)
   */
  readonly nearperid1: string;

  /**
   *  근거리직원2
   *
   * **data type** varchar(?)
   */
  readonly nearperid2: string;

  /**
   *  근거리직원3
   *
   * **data type** varchar(?)
   */
  readonly nearperid3: string;

  /**
   *  고객안심문자
   *
   * **data type** varchar(?)
   */
  readonly recetelnum: string;

  /**
   *  접수시간
   *
   * **data type** varchar(?)
   */
  readonly recetime: string;

  /**
   *  접수시간
   *
   * **data type** varchar(?)
   */
  readonly recedate: string;

  /**
   *  접수자코드
   *
   * **data type** varchar(?)
   */
  readonly receperid: string;

  /**
   *  접수자명
   *
   * **data type** varchar(?)
   */
  readonly recepernm: string;

  /**
   *  연관검색 체크
   *
   * **data type** varchar(?)
   */
  readonly contchk: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly wkactcd: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly urltoken: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly mpclafi: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly cltcd: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly dong: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly ho: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly troubledate: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly troublesms: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly resultck: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly catchdate: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly catchtime: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly pushflag: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly pushokflag: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly recetelflag: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly rDistance1: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly rDistance2: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly rDistance3: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly nearlat1: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly nearlat2: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly nearlat3: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly nearlng1: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly nearlng2: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly nearlng3: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly incentive: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly pay1: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly pay2: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly pay3: string;

  /**
   *  다발보고일자
   *
   * **data type** varchar(?)
   */
  readonly waddate: string;

  /**
   *  보고시간
   *
   * **data type** varchar(?)
   */
  readonly wadtime: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly wadnum: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly appnum: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly arrivdate: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly arrivtime: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly resutime: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly compdate: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly compnum: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly comptime: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly resulttime: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly wad: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly resultcd: string;

  /**
   *  뭔지모름
   *
   * **data type** varchar(?)
   */
  readonly resultnm: string;

  /**
   *  고장부위
   *
   * **data type** varchar(?)
   */
  readonly gregicd: string;

  /**
   *  고장부위
   *
   * **data type** varchar(?)
   */
  readonly greginm: string;

  /**
   *  고장부위상세
   *
   * **data type** varchar(?)
   */
  readonly regicd: string;

  /**
   *  고장요인
   *
   * **data type** varchar(?)
   */
  readonly remocd: string;

  /**
   *  고장요인
   *
   * **data type** varchar(?)
   */
  readonly remonm: string;

  /**
   *  고장원인
   *
   * **data type** varchar(?)
   */
  readonly faccd: string;

  /**
   *  고장원인
   *
   * **data type** varchar(?)
   */
  readonly facnm: string;

  /**
   *  처리내용
   *
   * **data type** varchar(?)
   */
  readonly resucd: string;

  /**
   *  처리내용
   *
   * **data type** varchar(?)
   */
  readonly resunm: string;

  /**
   *  고장부위상세
   *
   * **data type** varchar(?)
   */
  readonly reginm: string;

  /**
   *  고장부위상세
   *
   * **data type** varchar(?)
   */
  readonly remoremark: string;

  /**
   *  고장부위상세
   *
   * **data type** varchar(?)
   */
  readonly resuremark: string;

  /**
   *  고장부위상세
   *
   * **data type** varchar(?)
   */
  readonly customer: string;

  /**
   *  ?
   *
   * **data type** varchar(?)
   */
  readonly refer: string;

  /**
   *  ?
   *
   * **data type** varchar(?)
   */
  readonly appgubun: string;

  /**
   *  ?
   *
   * **data type** varchar(?)
   */
  readonly attcnt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.perid = data.perid || '';
    this.perid2 = data.perid2 || '';
    this.rptnum = data.rptnum || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.carcd = data.carcd || '';
    this.wkcd = data.wkcd || '';
    this.wktext = data.wktext || '';
    this.frtime = data.frtime || '';
    this.totime = data.totime || '';
    this.km = data.km || '';
    this.remark = data.remark || '';
    this.plandate = data.plandate || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.edudate = data.edudate || '';
    this.dedunum = data.dedunum || '';
    this.lkdate = data.lkdate || '';
    this.lknum = data.lknum || '';
    this.rptdate = data.rptdate || '';
    this.recetime = data.recetime || '';
    this.recenum = data.recenum || '';
    this.receperid = data.receperid || '';
    this.recepernm = data.recepernm || '';
    this.datetime = data.datetime || '';
    this.hitchhour = data.hitchhour || '';
    this.actperid = data.actperid || '';
    this.misamt03 = data.misamt03 || '';
    this.zipcode = data.zipcode || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.actpernm = data.actpernm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.elno = data.elno || '';
    this.contcd = data.contcd || '';
    this.contnm = data.contnm || '';
    this.planstate = data.planstate || '';
    this.contents = Fix.newline(data.contents) || '';
    this.contchk = data.contchk || '0';
    this.datetime3 = data.datetime3 || '';
    this.trouble = data.trouble || '0';
    this.troubletime = data.troubletime || '';
    this.troublesu = data.troublesu || '';
    this.reperid = data.reperid || '';
    this.repernm = data.repernm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.pernm2 = data.pernm2 || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.divicd2 = data.divicd2 || '';
    this.divinm2 = data.divinm2 || '';
    this.atdnm = data.atdnm || '';
    this.okflag = data.okflag || '';
    this.nearpernm1 = data.nearpernm1 || '';
    this.nearpernm2 = data.nearpernm2 || '';
    this.nearpernm3 = data.nearpernm3 || '';
    this.nearperid1 = data.nearperid1 || '';
    this.nearperid2 = data.nearperid2 || '';
    this.nearperid3 = data.nearperid3 || '';
    this.rdistance1 = data.rdistance1 || '';
    this.rdistance2 = data.rdistance2 || '';
    this.rdistance3 = data.rdistance3 || '';
    this.recetelnum = data.recetelnum || '';
    this.recedate = data.recedate || '';
    this.hitchdate = data.hitchdate || '';
    this.wkactcd = data.wkactcd || '';
    this.urltoken = data.urltoken || '';
    this.mpclafi = data.mpclafi || '';
    this.cltcd = data.cltcd || '';
    this.dong = data.dong || '';
    this.ho = data.ho || '';
    this.troubledate = data.troubledate || '';
    this.troublesms = data.troublesms || '';
    this.resultck = data.resultck || '';
    this.catchdate = data.catchdate || '';
    this.catchtime = data.catchtime || '';
    this.pushflag = data.pushflag || '';
    this.pushokflag = data.pushokflag || '';
    this.recetelflag = data.recetelflag || '';
    this.rDistance1 = data.rDistance1 || '';
    this.rDistance2 = data.rDistance2 || '';
    this.rDistance3 = data.rDistance3 || '';
    this.nearlat1 = data.nearlat1 || '';
    this.nearlat2 = data.nearlat2 || '';
    this.nearlat3 = data.nearlat3 || '';
    this.nearlng1 = data.nearlng1 || '';
    this.nearlng2 = data.nearlng2 || '';
    this.nearlng3 = data.nearlng3 || '';
    this.incentive = data.incentive || '';
    this.pay1 = data.pay1 || '';
    this.pay2 = data.pay2 || '';
    this.pay3 = data.pay3 || '';
    this.waddate = data.waddate || '';
    this.wadtime = data.wadtime || '';
    this.wadnum = data.wadnum || '';
    this.appnum = data.appnum || '';
    this.arrivdate = data.arrivdate || '';
    this.arrivtime = data.arrivtime || '';
    this.resutime = data.resutime || '';
    this.compdate = data.compdate || '';
    this.compnum = data.compnum || '';
    this.comptime = data.comptime || '';
    this.resulttime = data.resulttime || '';
    this.wad = data.wad || '';
    this.resultcd = data.resultcd || '';
    this.resultnm = data.resultnm || '';
    this.gregicd = data.gregicd || '';
    this.greginm = data.greginm || '';
    this.regicd = data.regicd || '';
    this.reginm = data.reginm || '';
    this.remocd = data.remocd || '';
    this.remonm = data.remonm || '';
    this.faccd = data.faccd || '';
    this.facnm = data.facnm || '';
    this.resucd = data.resucd || '';
    this.resunm = data.resunm || '';
    this.remoremark = data.remoremark || '';
    this.resuremark = data.resuremark || '';
    this.customer = data.customer || '';
    this.refer = data.refer || '';
    this.appgubun = data.appgubun || '';
    this.attcnt = data.attcnt || '';
    this.mpclafi = data.mpclafi || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
