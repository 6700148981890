import * as React from 'react';
import { RiKakaoTalkFill } from 'react-icons/ri';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  CustomAttachmentBox,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TabLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Appgubuns,
  Comps,
  Compyns,
  Enrollment,
} from './Enrollment';
import { generateEnrollmentTab } from './Enrollment.tab';
import { EnrollmentGridHeader } from './headers/EnrollmentGridHeader';
import { SearchDateRange } from '../../../../components/search';
import { SearchBinding } from '../../../../models';
import EnrollmentModel from './models/EnrollmentModel';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { EnrollmentModalOrderMaterialsTableHeader } from './headers/EnrollmentModalOrderMaterialsTableHeader';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { EnrollmentModalSelfInspectTableHeader } from './headers/EnrollmentModalSelfInspectTableHeader';
import { EnrollmentModalAmountTableHeader } from './headers/EnrollmentModalAmountTableHeader';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_e451
 * @category 견적서등록
 */
export const EnrollmentTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => {
  const content = scope.state?.content;

  const { user } = scope.props.publicStore;

  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  const setContent = (data: any, callback?: () => void) => scope?.setState({
    content: new EnrollmentModel({
      ...content,
      ...data,
    }, scope.state.content?.isNew),
  }, callback);

  return (
    <FlexLayout isVertical={true}>
      {/* Search 1 */}
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => scope.setState({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout
          size={372}
          style={{ marginLeft: 21 }}
        >
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            textAlign="center"
            value={scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={1.3}
            value={scope.state.divinm}
            textAlign="center"
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout size={389}>
          <span style={Global.STYLE_COLOR_RED}>담당자</span>
          <TextBox
            textAlign="center"
            value={scope.state.perid}
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={1.3}
            value={scope.state.pernm}
            textAlign="center"
            readonly={true}
          />
        </FlexLayout>

        {
          scope.state.kakaoyn === '1'
            ? <Button onClick={() => scope.onKakaoButtonClicked(true)}>
              <FlexLayout style={{ width: 80 }}>
                <RiKakaoTalkFill size={30}></RiKakaoTalkFill>
                <span style={{ paddingLeft: 8 }}>전송</span>
              </FlexLayout>
          </Button>
            : null
        }

        <Button onClick={() => scope.onFaxButtonClicked()}><div style={{ width: 80 }}>FAX</div></Button>

        <Button onClick={() => scope.onEmailButtonClicked()}><div style={{ width: 80 }}>EMAIL</div></Button>

        <Button onClick={() => scope.onSelfInspectOpen()}>
          <div style={{ width: 80 }}>점검부적합</div>
        </Button>
      </SearchLayout>

      {/* Search 2 */}
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        isSub={true}
      >
        <FlexLayout weight={0.74}>
          <span>검색어</span>
          <TextBox
            style={{ marginLeft: 10 }}
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 거래처, 견적서명으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={401}>
          <span>승인구분</span>
          <ComboBox
            value={scope.state.appgubun}
            data={Appgubuns?.map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => scope.setState({ appgubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={388}>
          <span style={{ marginLeft: 29 }}>구분</span>
          <ComboBox
            value={scope.state.gubun}
            data={scope.state.gubunsAll?.map((x) => new ComboBoxModel(x.artcd, x.artnm))}
            onSelect={(option) => scope.setState({ gubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <Button onClick={() => scope.onMaterialOrderRetrieve()}><div style={{ width: 80 }}>자재청구서</div></Button>
        <Button onClick={() => setData({ isVisibleCopy: true })}><div style={{ width: 80 }}>견적서복사</div></Button>

        <Button
          onClick={() => scope.onSubmitButtonClicked()}
          style={scope.state.content?.appgubun === '101' ? {
            opacity: 0.5,
          } : {}}
        >
          <div style={{ width: 80 }}>{scope.state.content?.isReported ? '상신취소' : '결재상신'}</div>
        </Button>
      </SearchLayout>


      {/* Contents */}
      <FlexLayout isVertical={true}>
        {/* Header */}
        <FlexLayout>
          <FlexLayout>
            {/* Left - GridLayout */}
            <FlexLayout size={380}>
              <GridLayout
                ref={scope.grid}
                header={EnrollmentGridHeader(scope)}
                headerHeight={Global.LAYOUT_SEARCH_HEIGHT_1}
                rowHeight={48}
                data={scope.state.data}
                onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
                infinityHandler={scope.infinity}
              />
            </FlexLayout>

            {/* Right - Freeform */}
            <FlexLayout
              isVertical={true}
              isScroll={true}
              disabled={!scope.state.data.length}
              disabledText="신규로 추가하거나 거래처를 선택해주세요"
            >
              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox
                  title="견적일자"
                  isNeed={true}
                >
                  <DateTextBox
                    readonly={!content?.isNew}
                    value={content?.costdate}
                    format="####-##-##"
                    textAlign="center"
                    onChange={(v) => setContent({ costdate: v })}
                  />

                  <TextBox
                    textAlign="center"
                    value={content?.costnum}
                    readonly={true}
                  />
                </LabelBox>

                <LabelBox
                  title="문서번호"
                >
                  <FormatTextBox
                    format="##-######-###-####"
                    value={user.papernum + content?.appnum}
                    textAlign="center"
                    readonly={true}
                  />
                </LabelBox>

                <LabelBox
                  title="견적구분"
                >
                  <ComboBox
                    textAlign="center"
                    value={content?.gubun}
                    data={scope.state.gubuns?.map((x) => new ComboBoxModel(x.code, x.cnam))}
                    onSelect={(option) => scope.headerItemChanged('gubun', option.value)}
                  />
                </LabelBox>

                <LabelBox
                  title="견적담당"
                  style={Global.STYLE_COLOR_RED}
                >
                  <TextBox
                    textAlign="center"
                    value={content?.costperid}
                    onChange={(value) => setContent({ costperid: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_JA001',
                      {},
                      true,
                      (item) => {
                        setContent({
                          costperid: item.cd,
                          costpernm: item.cdnm,
                        });
                      },
                    )}
                  />

                  <TextBox
                    value={content?.costpernm}
                    textAlign="center"
                    readonly={true}
                  />
                </LabelBox>

                <LabelBox
                  title="견적상태"
                >
                  <FlexLayout>
                    <CheckBox
                      value={content?.compyn3 === '1'}
                      onChange={(v) => scope.headerItemChanged('compyn3', v ? '1' : '0')}
                    />

                    <FlexLayout
                      align="center"
                      justify="start"
                      style={{
                        ...Global.STYLE_COLOR_RED,
                        paddingLeft: 3,
                      }}
                    >
                      폐기
                    </FlexLayout>
                  </FlexLayout>

                  <TextBox
                    value={Comps.filter((y) => content?.compyn === y.value)[0]?.remark}
                    color={ Compyns.filter((y) => y.value === content?.compyn)[0]?.color}
                    textAlign="center"
                    readonly={true}
                  />
                </LabelBox>

                <LabelBox
                  title="회사구분"
                  style={Global.STYLE_COLOR_RED}
                >
                  <TextBox
                    value={content?.wkactcd}
                    textAlign="center"
                    onChange={(value) => setContent({ wkactcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E018_1',
                      {},
                      true,
                      (item) => {
                        setContent({
                          wkactcd: item.cd,
                          wkactnm: item.cdnm,
                        });
                      },
                    )}
                  />

                  <TextBox
                    value={content?.wkactnm}
                    textAlign="center"
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox
                  title="현장명"
                  style={Global.STYLE_COLOR_RED}
                  footer={
                    <FlexLayout style={{ marginTop: 24 }}>
                      <LabelBox
                        title={content?.contgubunnm}
                        size={80}
                      >
                      </LabelBox>
                    </FlexLayout>
                  }
                >
                  <TextBox
                    value={content?.actcd}
                    textAlign="center"
                    onChange={(value) => setContent({ actcd: value })}
                    onEnter={() => scope.onRetrieveEvent()}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E601_1',
                      {},
                      true,
                      (item) => {
                        setContent({
                          actcd: item.cd,
                          actnm: item.cdnm,
                          cltcd: item.uv_arg1,
                          cltnm: item.uv_arg3,
                        });
                        scope.headerItemChanged('actcd', item.cd);
                      },
                    )}
                  />

                  <TextBox
                    value={content?.actnm}
                    weight={5.5}
                    readonly={true}
                  />
                </LabelBox>

                <LabelBox
                  title="고객명"
                  style={Global.STYLE_COLOR_RED}
                  isNeed={true}
                >
                  <TextBox
                    textAlign="center"
                    value={content?.cltcd}
                    onChange={(value) => setContent({ cltcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_XCLIENT',
                      { uv_arg1: '2' },
                      true,
                      (item) => {
                        setContent({
                          cltcd: item.cd,
                          cltnm: item.cdnm,
                        });
                      },
                    )}
                  />

                  <TextBox
                    value={content?.cltnm}
                    weight={5.5}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox
                  title="견적서명"
                  isNeed={true}
                  weight={3}
                >
                  <TextBox
                    value={content?.constnm}
                    onChange={(v) => setContent({ constnm: v })}
                  />
                </LabelBox>

                <LabelBox title="고객부서/이름">
                  <TextBox
                    value={content?.perdivinm}
                    onChange={(v) => setContent({ perdivinm: v })}
                  />

                  <TextBox
                    value={content?.pernm}
                    onChange={(v) => setContent({ pernm: v })}
                  />
                </LabelBox>

                <LabelBox title="고객연락처">
                  <TextBox
                    value={content?.perhp}
                    onChange={(v) => setContent({ perhp: v })}
                  />
                </LabelBox>

                <LabelBox
                  title="고객메일주소"
                >
                  <TextBox
                    value={content?.permail}
                    onChange={(v) => setContent({ permail: v })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox title="부가세구분">
                  <OptionBox
                    value={content?.addyn}
                    data={[
                      { value: '1', remark: '포함' },
                      { value: '0', remark: '별도' },
                    ]}
                    onChange={(item) => setContent({ addyn: item.value })}
                  />
                </LabelBox>

                <LabelBox title="공급가액">
                  <FormatNumberTextBox
                    textAlign="center"
                    value={content?.samt}
                    readonly={true}
                  />
                </LabelBox>

                <LabelBox title="부가세액">
                  <FormatNumberTextBox
                    textAlign="center"
                    value={content?.tamt}
                    readonly={true}
                  />
                </LabelBox>

                <LabelBox title="합계금액">
                  <FormatNumberTextBox
                    textAlign="center"
                    value={content?.mamt}
                    readonly={true}
                  />
                </LabelBox>

                <LabelBox title="할인율">
                  <FormatTextBox
                    textAlign="center"
                    format='###%'
                    value={content?.saleyul}
                    onChange={(value) => scope.headerItemChanged('saleyul', value)}
                  />
                </LabelBox>

                <LabelBox title="최종금액(할인된금액)">
                  <FormatNumberTextBox
                    textAlign="center"
                    value={content?.saleamt}
                    onChange={(value) => scope.headerItemChanged('saleamt', value)}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox title="공사완료일">
                  <DateTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={content?.compdate}
                    onChange={(v) => setContent({ compdate: v })}
                  />
                </LabelBox>

                <LabelBox title="공사시작일">
                  <DateTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={content?.stdate}
                    onChange={(v) => setContent({ stdate: v })}
                  />
                </LabelBox>

                <LabelBox title="공사만료일">
                  <DateTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={content?.enddate}
                    onChange={(v) => setContent({ enddate: v })}
                  />
                </LabelBox>

                <LabelBox title="부품내역">
                  <FlexLayout>
                    <CheckBox
                      value={content?.evidyn === '1'}
                      onChange={(v) => setContent({ evidyn: v ? '1' : '0' })}
                    />

                    <FlexLayout
                      align="center"
                      justify="start"
                      style={{
                        paddingLeft: 3,
                      }}
                    >
                      별첨
                    </FlexLayout>
                  </FlexLayout>
                </LabelBox>

                <LabelBox title="유효기간">
                  <FormatTextBox
                    textAlign="center"
                    format="## 개월"
                    value={content?.iamt}
                    onChange={(v) => setContent({ iamt: v })}
                  />
                </LabelBox>

                <LabelBox
                  title="첨부파일"
                  footer={
                    <FlexLayout>
                      <Button
                        isIcon={true}
                        theme={BUTTON_THEMES.BLUE}
                        onClick={() => setData({ isVisibleAttachment: true })}
                      >
                        <RiFolder2Line size={17} />
                      </Button>
                    </FlexLayout>
                  }
                >
                  <FlexLayout
                    style={{ color: content?.attcnt === '0' ? 'var(--color-red)' : 'var(--color-blue)' }}
                    align="center"
                    justify="center"
                  >
                    {content?.attcnt === '0' ? '0건 없음' : `${content?.attcnt}건 있음`}
                  </FlexLayout>
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox
                  title="특기사항"
                  weight={3}
                >
                  <TextBox
                    value={content?.remark}
                    onChange={(v) => setContent({ remark: v })}
                  />
                </LabelBox>

                <LabelBox
                  title="수신참조"
                  weight={2}
                  footer={
                    <FlexLayout>
                      <Button
                        onClick={() => scope.openReferSelector()}
                        isIcon={true}
                      >
                        <RiFolder2Line size={17} />
                      </Button>
                    </FlexLayout>
                  }
                >
                  <TextBox
                    value={content?.refer}
                    readonly={true}
                  />
                </LabelBox>

                <LabelBox title="작성자명">
                  <TextBox
                    value={content?.inperid}
                    textAlign="center"
                    readonly={true}
                  />

                  <TextBox
                    value={content?.inpernm}
                    textAlign="center"
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>


        {/* Detail */}
        <FlexLayout
          weight={0.88}
          disabled={!scope.state.data.length}
          disabledText="신규로 추가하거나 거래처를 선택해주세요"
        >
          <FlexLayout>
            <TabLayout
              ref={scope.tab}
              scope={scope}
              tabs={generateEnrollmentTab(scope)}
              onChange={(tab, index) => scope.onTabChange(tab, index)}
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>


      {/* 첨부파일 */}
      <ModalView
        width={800}
        isVisible={scope.state.isVisibleAttachment}
        onClose={() => setData({ isVisibleAttachment: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <CustomAttachmentBox
            window="w_popup_e451_img"
            params={{
              appnum: content?.appnum,
              spjangcd: content?.spjangcd,
              costdate: content?.costdate,
              costnum: content?.costnum,
            }}
            isEnabledTitle={true}
            isEnabledPreview={true}
            onChangeCount={async (count) => {
              // state
              setContent({ attcnt: count.toString() });
            }}
          />

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => setData({ isVisibleAttachment: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 자재청구서 */}
      <ModalView
        width={800}
        isVisible={scope.state.isVisibleOrderMaterial}
        onClose={() => setData({ isVisibleOrderMaterial: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <FlexLayout>
            <TableLayout
              ref={scope.tableModalOrderMaterials}
              header={EnrollmentModalOrderMaterialsTableHeader(scope)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.dataModalOrderMaterials}
              onChange={(rows) => setData({ dataModalOrderMaterials: rows })}
            />
          </FlexLayout>

          <FlexLayout
            size={Global.LAYOUT_TITLE_HEIGHT_1}
            isVertical={true}
          >
            <SubHeader>
              <div>
                <span>일자</span>
                <span>
                  <DateTextBox
                    format="####-##-##"
                    textAlign="center"
                    value={scope.state.modalOrderMaterialsDate || '19700101'}
                    onChange={(v) => setData({ modalOrderMaterialsDate: v })}
                  />
                </span>
              </div>

              <div>
                <span>비고</span>
                <span>
                  <TextBox
                    value={scope.state.modalOrderMaterialsRemark}
                    onChange={(v) => setData({ modalOrderMaterialsRemark: v })}
                    style={{
                      width: 300,
                    }}
                  />
                </span>
              </div>

              <FlexLayout justify="end">
                <Button onClick={() => scope.onMaterialOrderSubmit()}>확인</Button>
                <Button onClick={() => setData({ isVisibleOrderMaterial: false })}>닫기</Button>
                <Button onClick={() => scope.onReport()}>결재라인</Button>
              </FlexLayout>
            </SubHeader>

            <FlexLayout isVertical={true}>
              <FlexLayout>
                <FlexLayout size={210}>
                </FlexLayout>
                <FlexLayout
                  style={{ color: 'var(--color-blue)' }}
                  justify="left"
                >
                  * 확인을 누르면 기존 결재라인대로 바로 자재청구서가 작성되어 결재요청까지 요청한상태로 작성됩니다.
                </FlexLayout>
              </FlexLayout>

              <FlexLayout>
                <FlexLayout size={210}>
                </FlexLayout>
                <FlexLayout
                  style={{ color: 'var(--color-blue)' }}
                  justify="left"
                >
                  * 결재라인을 바꾸실경우(수신참조 추가등) 결재라인버튼을 눌러 미리 변동해주세요.
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 견적서 복사 */}
      <ModalView
        width={250}
        height={150}
        isVisible={scope.state.isVisibleCopy}
        onClose={() => setData({ isVisibleCopy: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <LayoutTitle>견적서 복사</LayoutTitle>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="견적일자"
              isNeed={true}
            >
              <DateTextBox
                format="####-##-##"
                textAlign="center"
                value={scope.state.copyDate}
                onChange={(v) => setData({ copyDate: v })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.RED}
              onClick={() => setData({ isVisibleCopy: false })}
            >
              취소
            </Button>

            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.onCopyButtonClicked()}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 점검부적합 */}
      <ModalView
        width={1000}
        isVisible={scope.state.isVisibleSelfInspect}
        onClose={() => setData({ isVisibleSelfInspect: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout size={190}>
              <span>기간</span>
              <DateTextBox
                format="####-##"
                textAlign="center"
                value={scope.state.modalSelfInspectSearchStmon}
                onChange={(value) => setData({ modalSelfInspectSearchStmon: value })}
                onEnter={() => scope.onSelfInspectRetrieve()}
                head={(
                  <button
                    onClick={() => {
                      setData({
                        yearmon: new Date6Calculator(scope.state.modalSelfInspectSearchStmon).add(-1).toString(),
                      },
                      () => scope.onSelfInspectRetrieve());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>
                )}
                trail={(
                  <button
                    onClick={() => {
                      setData({
                        yearmon: new Date6Calculator(scope.state.modalSelfInspectSearchStmon).add().toString(),
                      },
                      () => scope.onSelfInspectRetrieve());
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>
                )}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={scope.state.modalSelfInspectSearchQuery}
                onChange={(value) => setData({ modalSelfInspectSearchQuery: value })}
                onEnter={() => scope.onSelfInspectRetrieve()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout>
              <span style={Global.STYLE_COLOR_RED}>부서</span>
              <TextBox
                textAlign="center"
                value={scope.state.modalSelfInspectSearchDivicd}
                onChange={(value) => setData({ modalSelfInspectSearchDivicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      modalSelfInspectSearchDivicd: item.cd,
                      modalSelfInspectSearchDivinm: item.cdnm,
                    });
                    scope.onSelfInspectRetrieve();
                  },
                )}
                isDisabledTrackingStateChange={true}
              />

              <TextBox
                value={scope.state.modalSelfInspectSearchDivinm}
                textAlign="center"
                readonly={true}
              />
            </FlexLayout>

            <FlexLayout>
              <span style={Global.STYLE_COLOR_RED}>담당자</span>
              <TextBox
                textAlign="center"
                value={scope.state.modalSelfInspectSearchPerid}
                onChange={(value) => setData({ modalSelfInspectSearchPerid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      modalSelfInspectSearchPerid: item.cd,
                      modalSelfInspectSearchPernm: item.cdnm,
                    });
                    scope.onSelfInspectRetrieve();
                  },
                )}
                isDisabledTrackingStateChange={true}
              />

              <TextBox
                value={scope.state.modalSelfInspectSearchPernm}
                textAlign="center"
                readonly={true}
              />
            </FlexLayout>
            <FlexLayout size={158} justify="center">
              <span style={{ color: 'var(--color-red)' }}>
                * 선택란에 품목을 &nbsp;&nbsp;&nbsp;넣어주세요</span>
            </FlexLayout>
          </SearchLayout>

          <FlexLayout>
            <TableLayout
              ref={scope.tableModalSelfInspect}
              header={EnrollmentModalSelfInspectTableHeader(scope)}
              headerHeight={Global.LAYOUT_SEARCH_HEIGHT_1}
              rowHeight={48}
              infinityHandler={scope.selfInspectInfinity}
              data={scope.state.dataModalSelfInspect}
              onChange={(rows) => setData({ dataModalSelfInspect: rows })}
            />
          </FlexLayout>

          <FlexLayout
            size={Global.LAYOUT_TITLE_HEIGHT_1}
            isVertical={true}
          >
            <SubHeader>
              <div>
                <span>일자</span>
                <span>
                  <DateTextBox
                    format="####-##-##"
                    textAlign="center"
                    value={scope.state.modalSelfInspectDate || '19700101'}
                    onChange={(v) => setData({ modalSelfInspectDate: v })}
                    isDisabledTrackingStateChange={true}
                  />
                </span>
              </div>

              <div>
                <span>구분</span>
                <span style={{ width: 145 }}>
                  <ComboBox
                    value={scope.state.modalSelfInspectGubun}
                    data={scope.state.gubuns?.map((x) => new ComboBoxModel(x.code, x.cnam))}
                    onSelect={(option) => setData({ modalSelfInspectGubun: option.value })}
                    isDisabledTrackingStateChange={true}
                  />
                </span>
              </div>

              <FlexLayout justify="end">
                <Button onClick={() => scope.onSelfInspectSubmit()}>확인</Button>
                <Button onClick={() => setData({ isVisibleSelfInspect: false })}>닫기</Button>
              </FlexLayout>
            </SubHeader>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 견적단가 계산기 팝업 */}
      <ModalView
        width={1000}
        isVisible={scope.state.isVisibleAmount}
        onClose={() => setData({ isVisibleAmount: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <FlexLayout>
            <TableLayout
              ref={scope.tableModalAmount}
              header={EnrollmentModalAmountTableHeader(scope)}
              headerHeight={Global.LAYOUT_GRID_HEIGHT_1}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.dataModalAmount}
              onChange={(rows) => setData({ dataModalAmount: rows })}
            />
          </FlexLayout>

          <FlexLayout
            size={Global.LAYOUT_TITLE_HEIGHT_1}
            isVertical={true}
          >
            <SubHeader>
              <FlexLayout justify="end">
                <Button onClick={() => scope.saveModalAmount()}>확인</Button>
                <Button onClick={() => setData({ isVisibleAmount: false })}>닫기</Button>
              </FlexLayout>
            </SubHeader>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 인쇄 */}
      <ModalView
        width={580}
        height={145}
        isVisible={scope.state.isVisiblePrintType}
        onClose={() => setData({ isVisiblePrintType: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <LayoutTitle>첨부파일선택</LayoutTitle>
          <FlexLayout>
            <OptionBox
              value={scope.state.printType}
              data={[
                { value: '0', remark: '견적서' },
                { value: '1', remark: '부품교체확인서' },
                { value: '2', remark: '작업완료확인서' },
                { value: '3', remark: '계약서' },
              ]}
              onChange={(item) => scope.SS({ printType: item.value.toString(), remarkContent: item.remark })}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.choosePrintTypeDone()}>확인</Button>
              <Button onClick={() => scope.SS({ isVisiblePrintType: false })}>취소</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      {/* 견적단가 모달 */}
      <ModalView
        isVisible={scope.state.materialDetailModal}
        onClose={() => scope.materialModal(false)}
        width={1300}
        height={800}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SubHeader>
            <div>
              견적단가검색
            </div>

            <FlexLayout justify='right'>
            </FlexLayout>
          </SubHeader>
          <FlexLayout isVertical={true}>
            <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
              <SearchDateRange
                stdate={scope.state.modalStdate}
                enddate={scope.state.modalEnddate}
                onChange={(date) => scope.setState({ modalStdate: date.stdate, modalEnddate: date.enddate })}
              />

              <Button onClick={() => scope.onMaterialModalRetrieve()}><div style={{ width: 80 }}>조회</div></Button>
            </SearchLayout>
          </FlexLayout>
          <FlexLayout weight={14}>
            <TableLayout
              header={[
                {
                  id: 'costdate',
                  text: '견적일자',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.costdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'costnum',
                  text: '번호',
                  width: 5,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {(x.costnum)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 15,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {(x.actnm)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pcode',
                  text: '품목코드',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {(x.pcode)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pname',
                  text: '품목명',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {(x.pname)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'psize',
                  text: '규격',
                  width: 8,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.psize}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'psize2',
                  text: '규격',
                  width: 8,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.psize}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'punit',
                  text: '단위',
                  width: 5,
                  render: (x) => (
                    <FlexLayout
                      justify="start"
                      align="center"
                    >
                      {x.punit}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'uamt',
                  text: '견적단가',
                  width: 8,
                  render: (x) => (
                    <FlexLayout
                      justify="end"
                      align="center"
                    >
                      {Format.number(x.uamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'phm_mamt',
                  text: '매입단가',
                  width: 8,
                  render: (x) => (
                    <FlexLayout
                      justify="end"
                      align="center"
                    >
                      {Format.number(x.phm_mamt)}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.materialModalData || []}
              infinityHandler={scope.infinity}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              ref={scope.tableMaterialModal}
            />
          </FlexLayout>
          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.materialModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.kakaoModal}
        onClose={() => scope.onKakaoButtonClicked(false)}
        width={1000}
        height={100}
        zIndex={-2}
      >
        <FlexLayout isVertical={true}>
          <SubHeader>
            <FlexLayout align="center">
              첨부파일선택
            </FlexLayout>
          </SubHeader>

          <FlexLayout>
            <FlexLayout>
              <FlexLayout>
                <FlexLayout justify="right">
                  <CheckBox
                    value={scope.state.gubun1 === '1'}
                    onChange={(value) => scope.kakaoSendType(value, 'gubun1')}
                  />
                </FlexLayout>
                <FlexLayout align="center" justify="left">
                  견적서
                </FlexLayout>
              </FlexLayout>

              <FlexLayout>
                <FlexLayout justify="right">
                  <CheckBox
                    value={scope.state.gubun2 === '1'}
                    onChange={(value) => scope.kakaoSendType(value, 'gubun2')}
                  />
                </FlexLayout>
                <FlexLayout align="center" justify="left">
                  부품교체확인서
                </FlexLayout>
              </FlexLayout>

              <FlexLayout>
                <FlexLayout justify="right">
                  <CheckBox
                    value={scope.state.gubun3 === '1'}
                    onChange={(value) => scope.kakaoSendType(value, 'gubun3')}
                  />
                </FlexLayout>
                <FlexLayout align="center" justify="left">
                  작업완료확인서
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout justify="end" size={400}>
              <Button style={{ width: 100 }} onClick={() => scope.onKakaoSend()}>확인</Button>
              <Button style={{ width: 100 }} theme={BUTTON_THEMES.RED} onClick={() => scope.onKakaoButtonClicked(false)}>취소</Button>
            </FlexLayout>
          </FlexLayout>

        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.contractPopupVisible}
        width={580}
        height={145}
        zIndex={-2}
        onClose={() => scope.SS({ contractPopupVisible: false })}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>선택</LayoutTitle>

          <FlexLayout>
            <FlexLayout>
              <FlexLayout justify="end" align="center">계약일</FlexLayout>
              <FlexLayout>
                <OptionBox
                  value={scope.state.contractPopupDateGubun}
                  data={[
                    { value: '1', remark: '표시' },
                    { value: '0', remark: '미표시' },
                  ]}
                  onChange={(item) => scope.SS({ contractPopupDateGubun: item.value.toString() })}
                />
              </FlexLayout>
            </FlexLayout>
            <FlexLayout
              justify="center"
              align="center"
            >
              <span>하자기간</span>
              <TextBox
                value={scope.state.contractPopupHajagigan}
                onChange={(value) => scope.SS({ contractPopupHajagigan: value })}
                isDisabledTrackingStateChange={true}
                size={60}
                textAlign="right"
                style={{ border: '1px solid lightgray', height: 28 }}
              />
              <span>년</span>
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.contractPrint()}>확인</Button>
              <Button onClick={() => scope.SS({ contractPopupVisible: false, contractPopupHajagigan: '3' })}>취소</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
