import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { MonthAverageModel } from './MonthAverage.model';
import { MonthAverageTemplate } from './MonthAverage.template';
import {
  InfinityRetrieve,
  SpjangItem,
} from '../../../../models/common';
import { Today } from '../../../../utils/time';
import { GridLayout } from '../../../../components';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';

interface MonthAverageState {
  year: string;
  spjangcd?: string;
  spjangcds?: Array<SpjangItem>;

  // data
  data: Array<MonthAverageModel>;
  focused?: MonthAverageModel;

  // trail
  averamt01_tot: string;
  averamt02_tot: string;
  averamt03_tot: string;
  averamt04_tot: string;
  averamt05_tot: string;
  averamt06_tot: string;
  averamt07_tot: string;
  averamt08_tot: string;
  averamt09_tot: string;
  averamt10_tot: string;
  averamt11_tot: string;
  averamt12_tot: string;
  mon01_tot: string;
  mon02_tot: string;
  mon03_tot: string;
  mon04_tot: string;
  mon05_tot: string;
  mon06_tot: string;
  mon07_tot: string;
  mon08_tot: string;
  mon09_tot: string;
  mon10_tot: string;
  mon11_tot: string;
  mon12_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_da052w_05
 * @category 월평균보수료
 */
export class MonthAverage extends PageComponent<PageProps, MonthAverageState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  graph?: any;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      spjangcd: 'ZZ',
      data: [],

      // trail
      averamt01_tot: '0',
      averamt02_tot: '0',
      averamt03_tot: '0',
      averamt04_tot: '0',
      averamt05_tot: '0',
      averamt06_tot: '0',
      averamt07_tot: '0',
      averamt08_tot: '0',
      averamt09_tot: '0',
      averamt10_tot: '0',
      averamt11_tot: '0',
      averamt12_tot: '0',
      mon01_tot: '0',
      mon02_tot: '0',
      mon03_tot: '0',
      mon04_tot: '0',
      mon05_tot: '0',
      mon06_tot: '0',
      mon07_tot: '0',
      mon08_tot: '0',
      mon09_tot: '0',
      mon10_tot: '0',
      mon11_tot: '0',
      mon12_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 사업장 리스트
    const data = await api.dropdown('wf_dd_spjangcd_02');
    if (!data) return;
    this.setState({ spjangcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRefresh() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_refresh');

    if (data?.titlebox === '성공') {
      await ConfirmWarning.show('확인', '데이터 갱신에 최대 3분정도 소요될수 있습니다. \n3분후에 다시 조회해주세요!');
    }
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    this.graph = `https://api.elmansoft.com/chart/multi.php?database=weberp&window=w_tb_01&type=0&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_year=${this.state.year}`;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        spjangcd: this.state.spjangcd,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new MonthAverageModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieveAll();

      this.SS({
        averamt01_tot: this.infinity?.box?.averamt01_tot || '0',
        averamt02_tot: this.infinity?.box?.averamt02_tot || '0',
        averamt03_tot: this.infinity?.box?.averamt03_tot || '0',
        averamt04_tot: this.infinity?.box?.averamt04_tot || '0',
        averamt05_tot: this.infinity?.box?.averamt05_tot || '0',
        averamt06_tot: this.infinity?.box?.averamt06_tot || '0',
        averamt07_tot: this.infinity?.box?.averamt07_tot || '0',
        averamt08_tot: this.infinity?.box?.averamt08_tot || '0',
        averamt09_tot: this.infinity?.box?.averamt09_tot || '0',
        averamt10_tot: this.infinity?.box?.averamt10_tot || '0',
        averamt11_tot: this.infinity?.box?.averamt11_tot || '0',
        averamt12_tot: this.infinity?.box?.averamt12_tot || '0',
        mon01_tot: this.infinity?.box?.mon01_tot || '0',
        mon02_tot: this.infinity?.box?.mon02_tot || '0',
        mon03_tot: this.infinity?.box?.mon03_tot || '0',
        mon04_tot: this.infinity?.box?.mon04_tot || '0',
        mon05_tot: this.infinity?.box?.mon05_tot || '0',
        mon06_tot: this.infinity?.box?.mon06_tot || '0',
        mon07_tot: this.infinity?.box?.mon07_tot || '0',
        mon08_tot: this.infinity?.box?.mon08_tot || '0',
        mon09_tot: this.infinity?.box?.mon09_tot || '0',
        mon10_tot: this.infinity?.box?.mon10_tot || '0',
        mon11_tot: this.infinity?.box?.mon11_tot || '0',
        mon12_tot: this.infinity?.box?.mon12_tot || '0',
      });

      if (this.state.data && this.state.data?.length > 0) {
        await this.grid.current?.setFocus(0);
      }
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
      spjangcd: this.state.spjangcd,
    });
  }

  @action
  onRowFocusEvent(item: MonthAverageModel) {
    this.setState({ focused: item });
  }

  render() {
    return <MonthAverageTemplate scope={this} />;
  }
}
