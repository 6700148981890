import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  FlexLayout,
  TextBox,
  GridLayout,
  SearchLayout,
  FormatTextBox,
  DateTextBox, FormatNumberTextBox, TelnumTextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchBinding } from '../../../../models';
import { Revocation } from './Revocation';
import { Date8 } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_e101w_04
 * @category 유지보수해지현황
 */
export const RevocationTemplate: React.FC<TemplateProps<Revocation>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={175}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => setData({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout weight={2}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            textAlign="center"
            value={scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            value={scope.state.divinm}
            readonly={true}
            onChange={(value) => setData({ divinm: value })}
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>담당자</span>
          <TextBox
            value={scope.state.pernm}
            textAlign="center"
            onChange={(value) => setData({ pernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ perid: item.cd, pernm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'actcd',
            text: '현장코드',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합 계
              </FlexLayout>
            ),
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 150,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.total} 건
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.actnm}
              </FlexLayout>
            ),
          },
          {
            id: 'qty',
            text: '대수',
            width: 60,
            trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.qty_tot} 건
                </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '담당자',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pernm}
              </FlexLayout>
            ),
          },
          {
            id: 'contpernm',
            text: '계약자',
            width: 70,
          },
          {
            id: 'clsrecedate',
            text: '해지일',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.clsrecedate)}
              </FlexLayout>
            ),
          },
          {
            id: 'clsnm',
            text: '해지사유',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.clsnm}
              </FlexLayout>
            ),
          },
          {
            id: 'clsremark',
            text: '해지상세사유',
            width: 150,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.clsremark}
              </FlexLayout>
            ),
          },
          {
            id: 'amt',
            text: '보수료',
            width: 100,
            trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.amt_tot}
                </FlexLayout>
            ),
            render: (x) => (
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={x.amt}
              />
            ),
          },
          {
            id: 'monamt',
            text: '월정보수료',
            width: 100,
            render: (x) => (
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={x.monamt}
              />
            ),
          },
          {
            id: 'stdate',
            text: '시작일',
            width: 100,
            render: (x) => (
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={x.stdate}
              />
            ),
          },
          {
            id: 'enddate',
            text: '종료일',
            width: 100,
            render: (x) => (
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={x.enddate}
              />
            ),
          },
          {
            id: 'contgubun',
            text: '계약구분',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.contgubuns.filter((y) => y.code === x.contgubun)[0]?.cnam}
              </FlexLayout>
            ),
          },
          {
            id: 'actpernm',
            text: '현장담당자',
            width: 100,
          },
          {
            id: 'hp',
            text: '담당전화번호',
            width: 120,
            render: (x) => (
              <TelnumTextBox
                textAlign="center"
                value={x.hp}
              />
            ),
          },
          {
            id: 'address',
            text: '주소',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.address}
              </FlexLayout>
            ),
          },
        ]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
        data={scope.state.data}
      />
    </FlexLayout>
  );
};
