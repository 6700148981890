import Swal from 'sweetalert2';
import { Fix } from '../string';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

export default class Warning {
  static show(title: string, text: string) {
    swalWithBootstrapButtons.fire({
      title,
      html: Fix.newline(text).split('\n').join('<BR/>'),
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: '확인',
      reverseButtons: true,
    });
  }

  static showPbMessage(data: any) {
    this.show(data.titlebox, data.messagebox);
  }

  /**
   * 조건에 따른 경고창
   *
   * condition이 성공일 때 true를 반환하고
   * 실패일때 false를 반환하면서 경고창을 띄웁니다.
   *
   * @param condition
   * @param title
   * @param text
   */
  static assert(condition: any, title: string, text: string): boolean {
    if (condition) return true;

    Warning.show(title, text);
    return false;
  }
}
