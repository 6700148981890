import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e471
 * @category 공사등록
 */
export default class EnrollmentMaterialsModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly addamt: string;

  /**
   * 공사금액
   *
   * **data type** varchar(8)
   */
  readonly compamt: string;

  /**
   * 공사일
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 공사번호
   *
   * **data type** varchar(8)
   */
  readonly compnum: string;

  /**
   * 동
   *
   * **data type** varchar(8)
   */
  readonly dong: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 호
   *
   * **data type** varchar(8)
   */
  readonly ho: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 유무상
   *
   * **data type** varchar(8)
   */
  readonly myn: string;

  /**
   * 품목코드
   *
   * **data type** varchar(8)
   */
  readonly pcode: string;

  /**
   * 품목명
   *
   * **data type** varchar(8)
   */
  readonly pname: string;

  /**
   * 시리얼번호
   *
   * **data type** varchar(8)
   */
  readonly pserial: string;

  /**
   * 규격
   *
   * **data type** varchar(8)
   */
  readonly psize: string;

  /**
   * 단위
   *
   * **data type** varchar(8)
   */
  readonly punit: string;

  /**
   * 수량
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 사업자코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 단가
   *
   * **data type** varchar(8)
   */
  readonly uamt: string;


  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.addamt = data.addamt || '';
    this.compamt = data.compamt || '';
    this.compdate = data.compdate || '';
    this.compnum = data.compnum || '';
    this.dong = data.dong || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.ho = data.ho || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.myn = data.myn || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.pserial = data.pserial || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.seq = data.seq || '';
    this.spjangcd = data.spjangcd || '';
    this.uamt = data.uamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
