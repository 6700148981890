import { FlexLayout } from '../../../../../components';
import { GridLayoutHeader } from '../../../../../constants';
import { HistoryModel } from '../models';
import { ElevatorInfo } from '../ElevatorInfo';
import { Date8 } from '../../../../../utils/time';

const Gubuns = [
  { value: '01', remark: '자체점검' },
  { value: '02', remark: '정기검사' },
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
];


// 담당자이력정보
const History: GridLayoutHeader<HistoryModel, ElevatorInfo>[] = [
  {
    id: 'recorddate',
    text: '발생일자',
    width: 30,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.recorddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '발생구분',
    width: 30,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Gubuns.filter((y) => y.value === x.gubun)[0]?.remark || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '작업내용',
    width: 200,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="left"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '작업자',
    width: 30,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
];

export default History;
