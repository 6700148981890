export class RollbackItem {
  /**
   * ID
   *
   * **data type** varchar(8)
   */
  readonly id: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 사용자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 계정
   *
   * **data type** varchar(8)
   */
  readonly userid: string;

  /**
   * IP
   *
   * **data type** varchar(8)
   */
  readonly ipaddr: string;

  /**
   * 대메뉴
   *
   * **data type** varchar(8)
   */
  readonly category: string;

  /**
   * 윈도우
   *
   * **data type** varchar(8)
   */
  readonly window: string;

  /**
   * 부윈도우
   *
   * **data type** varchar(8)
   */
  readonly sub: string;

  /**
   * action/function
   *
   * **data type** varchar(8)
   */
  readonly type: string;

  /**
   * 메서드
   *
   * **data type** varchar(8)
   */
  readonly action_name: string;

  /**
   * 메서드
   *
   * **data type** varchar(8)
   */
  readonly function_name: string;

  /**
   * state 그 자체
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력시간
   *
   * **data type** varchar(8)
   */
  readonly intime: string;

  /**
   * Datetime
   *
   * **data type** varchar(8)
   */
  readonly created: string;


  constructor(data: any = {}) {
    this.id = data.id || '';
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.userid = data.userid || '';
    this.ipaddr = data.ipaddr || '';
    this.category = data.category || '';
    this.window = data.window || '';
    this.sub = data.sub || '';
    this.type = data.type || '';
    this.action_name = data.action_name || '';
    this.function_name = data.function_name || '';
    this.state = data.state || '';
    this.indate = data.indate || '';
    this.intime = data.intime || '';
    this.created = data.created || '';
  }
}
