import * as React from 'react';
import { observer } from 'mobx-react';
import style from '../AuthorizedScreen.module.scss';
import {
  ModalView,
  FlexLayout,
  SearchLayout,
  GridLayout,
  Button,
} from '../../components';
import { ModalStore } from '../../stores';
import { Global } from '../../constants';
import { Date8, Time4 } from '../../utils/time';
import { SearchDateRange } from '../../components/search';

interface ModalRollbackProps {
  modalStore: ModalStore;
}

@observer
export class ModalRollback extends React.Component<ModalRollbackProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={1000}
        isVisible={modalStore.isVisibleRollback}
        onClose={() => modalStore.closeRollbackSelector()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <SearchDateRange
              stdate={modalStore.rollbackSearchStdate}
              enddate={modalStore.rollbackSearchEnddate}
              onChange={(date) => modalStore.rollbackSearchDate(date.stdate, date.enddate)}
            />
          </SearchLayout>

          <GridLayout
            header={[
              {
                id: 'indate',
                text: '날짜',
                width: 20,
                render: (x: any) => (
                  <FlexLayout justify="center" align="center">
                    {Date8.withDash(x.indate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'intime',
                text: '시간',
                width: 20,
                render: (x: any) => (
                  <FlexLayout justify="center" align="center">
                    {Time4.withDash(x.intime)}
                  </FlexLayout>
                ),
              },
              {
                id: 'ipaddr',
                text: 'IP',
                width: 40,
              },
              {
                id: 'perid',
                text: '사원번호',
                width: 20,
              },
            ]}
            infinityHandler={modalStore.rollbackSelectorInfinity}
            data={modalStore.rollbackSelectorData || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(_: any, index: number) => modalStore.onRollbackSelectorRowFocusEvent(index)}
          />

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnDanger}
              isFull={true}
              onClick={() => modalStore.closeRollbackSelector()}
            >
              취소
            </Button>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => modalStore.doRollback()}
            >
              롤백
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
