import React from 'react';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { PartsCheckList } from './PartsCheckList';
import {
  Button,
  FlexLayout,
  GridLayout,
  ModalView,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';


export const PartsCheckListTemplate: React.FC<TemplateProps<PartsCheckList>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      data={scope.state.data}
      header={[
        {
          id: 'agecode',
          color: 'var(--color-red)',
          text: '채크코드',
          width: 80,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.agecode}
              readonly={!x.isNew}
              textAlign="center"
              onChange={(value) => rowUpdate({ agecode: value })}
              onEnter={(value) => scope.setState({ searchQuery: value }, () => scope.openProductPopupWithRow(rowUpdate, true))}
              onDoubleClick={() => scope.openProductPopupWithRow(rowUpdate, true)}
            />
          ),
        },
        {
          id: 'agename',
          text: '채크분류명',
          render: (x, rowUpdate) => (
            <TextBox
              value={x.agename}
              textAlign="center"
              onChange={(value) => rowUpdate({ agename: value })}
            >
            </TextBox>
          ),
        },
        {
          id: 'modenm',
          text: '물품분류',
          render: (x) => (
            <FlexLayout
              align="center"
              justify="center"
            >
              {x.modenm}
            </FlexLayout>
          ),
        },
        {
          id: 'agroupnm',
          text: '대분류',
          render: (x) => (
            <FlexLayout
              align="center"
              justify="center"
            >
              {x.agroupnm}
            </FlexLayout>
          ),
        },
        {
          id: 'bgroupnm',
          text: '중분류',
          render: (x) => (
            <FlexLayout
              align="center"
              justify="center"
            >
              {x.bgroupnm}
            </FlexLayout>
          ),
        },
        {
          id: 'cgroupnm',
          text: '소분류',
          render: (x) => (
            <FlexLayout
              align="center"
              justify="center"
            >
              {x.cgroupnm}
            </FlexLayout>
          ),
        },
        {
          id: 'age_life',
          text: '제품수명',
          width: 150,
          render: (x) => (
            <FlexLayout>
              <TextBox
                value={x.age_year}
                textAlign="right"
                onChange={(value) => scope.onItemChanged(value, 'age_year') }
              >
              </TextBox>

              <FlexLayout
                align="center"
                justify="center"
              >
                (년)
              </FlexLayout>

              <TextBox
                value={x.age_month}
                textAlign="right"
                onChange={(value) => scope.onItemChanged(value, 'age_month')}
              >
              </TextBox>
              <FlexLayout
                align="center"
                justify="center"
              >
                (개월)
              </FlexLayout>
            </FlexLayout>
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 350,
          render: (x, rowUpdate) => (
            <TextBox
              value={x.remark}
              textAlign="left"
              onChange={(value) => rowUpdate({ remark: value })}
            >
            </TextBox>
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows) => {
        scope.onUpdatedRows(rows);
      }}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
    >
    </TableLayout>

    <ModalView
      isVisible={scope.state.productPopupisVisible}
      onClose={() => scope.setState({
        productPopupisVisible: false,
        popupData: [],
        searchQuery: '',
      })}
      width={1000}
      height={600}
      zIndex={-1}
    >
      <SubHeader>
        <FlexLayout
          justify="left"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          품목코드
        </FlexLayout>
      </SubHeader>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={600}>
          <span>검색어</span>
          <TextBox
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onProductPopupRetrieve()}
            isDisabledTrackingStateChange={true}
          >
          </TextBox>
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'modenm',
              text: '물품분류',
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.modenm}
                </FlexLayout>
              ),
            },
            {
              id: 'agroupnm',
              text: '대분류',
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.agroupnm}
                </FlexLayout>
              ),
            },
            {
              id: 'bgroupnm',
              text: '중분류',
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.bgroupnm}
                </FlexLayout>
              ),
            },
            {
              id: 'cgroupnm',
              text: '소분류',
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.cgroupnm}
                </FlexLayout>
              ),
            },
          ]}
          onRowClick={(row) => scope.onPopupRowClick(row)}
          onRowDoubleClick={(row) => scope.closeProductPoupWithData(row)}
          data={scope.state.popupData}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        />
      </FlexLayout>

      <SubHeader>
        <FlexLayout justify="end">
          <Button onClick={() => scope.closeProductPoupWithData(scope.instanceRow)}>확인</Button>
          <Button onClick={() => scope.setState({ searchQuery: '', popupData: [], productPopupisVisible: false })}>닫기</Button>
        </FlexLayout>
      </SubHeader>
    </ModalView>
  </FlexLayout>
);
