import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e045
 * @category 사전점검스케쥴
 */
export default class ScheduleModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 사용자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 계획일
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 문서결재상태
   *
   * **data type** varchar(8)
   */
  readonly appgubun: string;

  /**
   * 결재일자
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 문서번호
   *
   * **data type** varchar(8)
   */
  readonly appnum: string;

  /**
   * 결재자
   *
   * **data type** varchar(8)
   */
  readonly appperid: string;

  /**
   * 결재 비고
   *
   * **data type** varchar(8)
   */
  readonly appremark: string;

  /**
   * 자동
   *
   * **data type** varchar(8)
   */
  readonly autoflag: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 작성일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 검사일
   *
   * **data type** varchar(8)
   */
  readonly kcdate: string;

  /**
   * 기록
   *
   * **data type** varchar(8)
   */
  readonly kclog: string;

  /**
   * 검사자
   *
   * **data type** varchar(8)
   */
  readonly kcperid: string;

  /**
   * 검사자2
   *
   * **data type** varchar(8)
   */
  readonly kcperid2: string;

  /**
   * 검사자명
   *
   * **data type** varchar(8)
   */
  readonly kcpernm: string;

  /**
   * 검사자명2
   *
   * **data type** varchar(8)
   */
  readonly kcpernm2: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 수신참조
   *
   * **data type** varchar(8)
   */
  readonly refer: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 설치자
   *
   * **data type** varchar(8)
   */
  readonly scpernm: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  readonly new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.perid = data.perid || '';
    this.plandate = data.plandate || '';
    this.state = data.state || '';
    this.appgubun = data.appgubun || '';
    this.appdate = data.appdate || '';
    this.appnum = data.appnum || '';
    this.appperid = data.appperid || '';
    this.appremark = data.appremark || '';
    this.autoflag = data.autoflag || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.kcdate = data.kcdate || '';
    this.kclog = data.kclog || '';
    this.kcperid = data.kcperid || '';
    this.kcperid2 = data.kcperid2 || '';
    this.kcpernm = data.kcpernm || '';
    this.kcpernm2 = data.kcpernm2 || '';
    this.pernm = data.pernm || '';
    this.plandate = data.plandate || '';
    this.refer = data.refer || '';
    this.remark = data.remark || '';
    this.scpernm = data.scpernm || '';
    this.seq = data.seq || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
