

export class ConstructionModalModel {
  /**
   *  담당자
   *
   */
  readonly pernm: string

  /**
   *  견적일자
   *
   */
  readonly costdate: string

  /**
   *  현장명
   *
   */
  readonly actnm: string

  /**
   *  구분
   *
   */
  readonly gubun: string

  /**
   *  공사명
   *
   */
  readonly constnm: string

  /**
   *  견적금액
   *
   */
  readonly costamt: string

  /**
   *  상태
   *
   */
  readonly compyn: string

  /**
   *  매출금액
   *
   */
  readonly misamt: string

  constructor(data: any = {}) {
    this.pernm = data.pernm || '';
    this.costdate = data.costdate || '';
    this.actnm = data.actnm || '';
    this.gubun = data.gubun || '';
    this.constnm = data.constnm || '';
    this.costamt = data.costamt || '';
    this.compyn = data.compyn || '';
    this.misamt = data.misamt || '';
  }
}
