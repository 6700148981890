import React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { InventoryMovement } from './InventoryMovement';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import { SearchBinding } from '../../../../models';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_ca602w_02
 * @category 재고이동내역
 */
export const InventoryMovementTemplate: React.FC<TemplateProps<InventoryMovement>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={160}>
        <span>년도</span>
        <DateTextBox
          value={scope.state.year}
          textAlign="center"
          format="####"
          onChange={(value) => scope.SS({ year: value })}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
        >
        </DateTextBox>
      </FlexLayout>

      <FlexLayout size={260}>
        <span style={{ color: 'var(--color-red)' }}>창고</span>
        <TextBox
          value={scope.state.store}
          textAlign="center"
          onChange={(value) => scope.SS({ store: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_CA504',
            {},
            true,
            (item) => {
              scope.setState({
                store: item.cd,
                storenm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
        >
        </TextBox>
        <TextBox
          value={scope.state.storenm}
          readonly={true}
        >
        </TextBox>
      </FlexLayout>

      <FlexLayout size={500}>
        <span>검색어</span>
        <TextBox
          value={scope.state.searchQuery}
          onChange={(value) => scope.SS({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
        >
        </TextBox>
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'pcode',
              text: '제품코드',
              width: 60,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pcode}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'pname',
              text: '제품명',
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.pname}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout>
                  {scope.state.cnt_tot} 건
                </FlexLayout>
              ),
            },
            {
              id: 'psize',
              text: '규격',
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.psize}
                </FlexLayout>
              ),
            },
            {
              id: 'punit',
              text: '단위',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.punit}
                </FlexLayout>
              ),
            },
            {
              id: 'numcnt',
              text: '개수',
              width: 40,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Format.number(x.numcnt)}
                </FlexLayout>
              ),
            },
            {
              id: 'outcnt',
              text: '출고',
              width: 40,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={x.outcnt !== '0' ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                >
                  {Format.number(x.outcnt)}
                </FlexLayout>
              ),
            },
            {
              id: 'qty',
              text: '현재고',
              width: 40,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={x.qty === '0' ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                >
                  {Format.number(x.qty)}
                </FlexLayout>
              ),
            },
          ]}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.productList}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        >
        </GridLayout>
      </FlexLayout>

      <FlexLayout size={220}>
        <GridLayout
          ref={scope.grid2}
          header={[
            {
              id: 'num',
              text: '고유번호(바코드)',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.num}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계 {scope.state.barcode_cnt_tot} 건
                </FlexLayout>
              ),
            },
            {
              id: 'qty',
              text: '현재고수량',
              width: 60,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={x.qty === '0' ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                >
                  {x.qty}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.qty_tot}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.barcodeData}
          onRowClick={(item) => scope.onRowFocusEvent2(item)}
        >
        </GridLayout>
      </FlexLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid3}
          header={[
            {
              id: 'gubun',
              text: '구분',
              width: 40,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={x.gubun === 'x' ? { color: 'var(--color-black)' }
                    : x.gubun === 'i' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
                >
                  {x.gubun === 'x' ? '실사'
                    : x.gubun === 'i' ? '입고' : '출고'}
                </FlexLayout>
              ),
            },
            {
              id: 'idate',
              text: '발생일자',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.idate)}
                </FlexLayout>
              ),
            },
            {
              id: 'inum',
              text: '발생번호',
              width: 60,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.inum}
                </FlexLayout>
              ),
            },
            {
              id: 'qty',
              text: '수량',
              width: 40,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.qty)}
                </FlexLayout>
              ),
            },
            {
              id: 'mamt',
              text: '금액',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '거래처(입고시) / 현장며(출고시)',
              width: 120,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '수령자명',
              width: 60,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.barcodeDataDetail}
        >
        </GridLayout>
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
);
