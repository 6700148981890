import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox,
  ComboBoxModel,
  ComboBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Complaint } from './Complaint';

/**
 * 화면
 * @window w_tb_e300
 * @category 민원관리코드
 */
export const ComplaintTemplate: React.FC<TemplateProps<Complaint>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        { id: 'civilcd', text: '민원코드', width: 40 },
        {
          id: 'civilnm',
          text: '민원명',
          width: 60,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.civilnm}
              onChange={(value) => rowUpdate({ civilnm: value })}
            />
          ),
        },
        {
          id: 'flag',
          text: '민원구분',
          width: 30,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.flag || ''}
              style={{
                padding: '0 0 0 6px',
                fontSize: 12,
                color:
                  x.flag === '0'
                    ? 'var(--color-blue)'
                    : 'var(--color-red)',
              }}
              data={[
                { value: '0', remark: '고객민원' },
                { value: '1', remark: '자체민원' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ flag: option.value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 100,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="left"
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => scope.onUpdatedRows(rows, updatedRows)}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      data={scope.state.data}
    />
  </FlexLayout>
);
