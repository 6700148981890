import React from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/all';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {
  CircleBadge,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { MonthBreakOften } from './MonthBreakOften';
import { SearchBinding } from '../../../../models';
import { Sum } from '../../../../utils/array';
import { Date8 } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_e411w_08
 * @category 월별다발고장현황
 */
export const MonthBreakOftenTemplate: React.FC<TemplateProps<MonthBreakOften>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          format="####"
          value={scope.state.year}
          textAlign="center"
          onChange={(value) => scope.SS({ year: value })}
          onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
        >
        </DateTextBox>
      </FlexLayout>

      <FlexLayout size={400}>
        <span>현장명</span>
        <TextBox
          value={scope.state.searchQuery}
          onChange={(value) => scope.SS({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
        >
        </TextBox>
      </FlexLayout>

      <FlexLayout size={240}>
        <span>고장다발기준</span>
        <FlexLayout>
          <FlexLayout style={{ color: 'var(--color-red)' }}>
            월
            <FlexLayout size={50}>
              <TextBox
                color="#FF0000"
                value={scope.state.wadcnt}
                onChange={(value) => scope.SS({ wadcnt: value })}
              >
              </TextBox>
            </FlexLayout>
            회 이상 호기
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout size={200}>
        <span>월발생기준</span>
        <FlexLayout>
          <FlexLayout>
            <FlexLayout size={50}>
              <TextBox
                value={scope.state.combo}
                onChange={(value) => scope.SS({ combo: value })}
              >
              </TextBox>
            </FlexLayout>
            달 이상
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout size={260}>
        <span style={{ color: 'var(--color-red)' }}>부서</span>
        <TextBox
          textAlign="center"
          value={scope.state.divicd}
          onChange={(value) => scope.SS({ divicd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              scope.SS({
                divicd: item.cd,
                divinm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        >
        </TextBox>
        <TextBox
          value={scope.state.divinm}
          readonly={true}
          onChange={(value) => scope.SS({ divinm: value })}
        >
        </TextBox>
      </FlexLayout>

      <FlexLayout size={260}>
        <span style={{ color: 'var(--color-red)' }}>담당</span>
        <TextBox
          textAlign="center"
          value={scope.state.perid}
          onChange={(value) => scope.SS({ perid: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.SS({
                perid: item.cd,
                pernm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        >
        </TextBox>
        <TextBox
          value={scope.state.pernm}
          readonly={true}
          onChange={(value) => scope.SS({ pernm: value })}
        >
        </TextBox>
      </FlexLayout>
    </SearchLayout>

    <GridLayout
      ref={scope.grid}
      header={[
        {
          id: 'divinmgroup',
          text: '담당그룹',
          isHidden: true,
          group: true,
          value: (x) => x.divinm,
        },
        {
          id: 'actnm',
          text: '현장명',
          width: 300,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.actnm}
            </FlexLayout>
          ),
          trail: () => (
            <FlexLayout>
              합 계
            </FlexLayout>
          ),
        },
        {
          id: 'equpnm',
          text: '호기명',
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.equpnm}
            </FlexLayout>
          ),
        },
        {
          id: 'divinm',
          text: '담당부서',
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.divinm}
            </FlexLayout>
          ),
          sum: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.divinm}
            </FlexLayout>
          ),
        },
        {
          id: 'pernm',
          text: '담당자',
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.pernm}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumPernm = Sum.groupLength(data, 'pernm', x, ['divinm']);
            return (
              <FlexLayout
                justify="center"
                align="center"
              >
                {sumPernm} 건
              </FlexLayout>
            );
          },
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {scope.state.cnt_tot} 건
            </FlexLayout>
          ),
        },
        {
          id: 'comppernm',
          text: '처리자',
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-blue)' }}
              justify="center"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.comppernm}
            </FlexLayout>
          ),
        },
        {
          id: 'repernm',
          text: '접수자',
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-green)' }}
              justify="center"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.repernm}
            </FlexLayout>
          ),
        },
        {
          id: 'wadmax',
          text: '최대횟수',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="center"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.wadmax} 회
            </FlexLayout>
          ),
        },
        {
          id: 'combo',
          text: '월발생',
          width: 70,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              <span style={{ fontWeight: 'bold' }}>{x.combo} 달</span>
            </FlexLayout>
          ),
        },
        {
          id: 'mon01',
          text: '1월',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="right"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.mon01}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumMon01 = Sum.byGroup(data, 'mon01', x, ['divinm']);
            return (
              <FlexLayout
                style={{ color: '#FF00FF' }}
                justify="right"
                align="center"
              >
                {sumMon01 !== 0 ? sumMon01 : ''}
              </FlexLayout>
            );
          },
          trail: (x) => {
            const sumMon01 = Sum.all(x, 'mon01');
            return (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF00FF' }}
              >
                {sumMon01 !== 0 ? sumMon01 : ''}
              </FlexLayout>
            );
          },
        },
        {
          id: 'mon02',
          text: '2월',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="right"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.mon02}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumMon02 = Sum.byGroup(data, 'mon02', x, ['divinm']);
            return (
              <FlexLayout
                style={{ color: '#FF00FF' }}
                justify="right"
                align="center"
              >
                {sumMon02 !== 0 ? sumMon02 : ''}
              </FlexLayout>
            );
          },
          trail: (x) => {
            const sumMon02 = Sum.all(x, 'mon02');
            return (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF00FF' }}
              >
                {sumMon02 !== 0 ? sumMon02 : ''}
              </FlexLayout>
            );
          },
        },
        {
          id: 'mon03',
          text: '3월',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="right"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.mon03}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumMon03 = Sum.byGroup(data, 'mon03', x, ['divinm']);
            return (
              <FlexLayout
                style={{ color: '#FF00FF' }}
                justify="right"
                align="center"
              >
                {sumMon03 !== 0 ? sumMon03 : ''}
              </FlexLayout>
            );
          },
          trail: (x) => {
            const sumMon03 = Sum.all(x, 'mon03');
            return (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF00FF' }}
              >
                {sumMon03 !== 0 ? sumMon03 : ''}
              </FlexLayout>
            );
          },
        },
        {
          id: 'mon04',
          text: '4월',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="right"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.mon04}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumMon04 = Sum.byGroup(data, 'mon04', x, ['divinm']);
            return (
              <FlexLayout
                style={{ color: '#FF00FF' }}
                justify="right"
                align="center"
              >
                {sumMon04 !== 0 ? sumMon04 : ''}
              </FlexLayout>
            );
          },
          trail: (x) => {
            const sumMon04 = Sum.all(x, 'mon04');
            return (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF00FF' }}
              >
                {sumMon04 !== 0 ? sumMon04 : ''}
              </FlexLayout>
            );
          },
        },
        {
          id: 'mon05',
          text: '5월',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="right"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.mon05}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumMon05 = Sum.byGroup(data, 'mon05', x, ['divinm']);
            return (
              <FlexLayout
                style={{ color: '#FF00FF' }}
                justify="right"
                align="center"
              >
                {sumMon05 !== 0 ? sumMon05 : ''}
              </FlexLayout>
            );
          },
          trail: (x) => {
            const sumMon05 = Sum.all(x, 'mon05');
            return (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF00FF' }}
              >
                {sumMon05 !== 0 ? sumMon05 : ''}
              </FlexLayout>
            );
          },
        },
        {
          id: 'mon06',
          text: '6월',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="right"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.mon06}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumMon06 = Sum.byGroup(data, 'mon06', x, ['divinm']);
            return (
              <FlexLayout
                style={{ color: '#FF00FF' }}
                justify="right"
                align="center"
              >
                {sumMon06 !== 0 ? sumMon06 : ''}
              </FlexLayout>
            );
          },
          trail: (x) => {
            const sumMon06 = Sum.all(x, 'mon06');
            return (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF00FF' }}
              >
                {sumMon06 !== 0 ? sumMon06 : ''}
              </FlexLayout>
            );
          },
        },
        {
          id: 'mon07',
          text: '7월',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="right"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.mon07}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumMon07 = Sum.byGroup(data, 'mon07', x, ['divinm']);
            return (
              <FlexLayout
                style={{ color: '#FF00FF' }}
                justify="right"
                align="center"
              >
                {sumMon07 !== 0 ? sumMon07 : ''}
              </FlexLayout>
            );
          },
          trail: (x) => {
            const sumMon07 = Sum.all(x, 'mon07');
            return (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF00FF' }}
              >
                {sumMon07 !== 0 ? sumMon07 : ''}
              </FlexLayout>
            );
          },
        },
        {
          id: 'mon08',
          text: '8월',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="right"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.mon08}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumMon08 = Sum.byGroup(data, 'mon08', x, ['divinm']);
            return (
              <FlexLayout
                style={{ color: '#FF00FF' }}
                justify="right"
                align="center"
              >
                {sumMon08 !== 0 ? sumMon08 : ''}
              </FlexLayout>
            );
          },
          trail: (x) => {
            const sumMon08 = Sum.all(x, 'mon08');
            return (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF00FF' }}
              >
                {sumMon08 !== 0 ? sumMon08 : ''}
              </FlexLayout>
            );
          },
        },
        {
          id: 'mon09',
          text: '9월',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="right"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.mon09}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumMon09 = Sum.byGroup(data, 'mon09', x, ['divinm']);
            return (
              <FlexLayout
                style={{ color: '#FF00FF' }}
                justify="right"
                align="center"
              >
                {sumMon09 !== 0 ? sumMon09 : ''}
              </FlexLayout>
            );
          },
          trail: (x) => {
            const sumMon09 = Sum.all(x, 'mon09');
            return (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF00FF' }}
              >
                {sumMon09 !== 0 ? sumMon09 : ''}
              </FlexLayout>
            );
          },
        },
        {
          id: 'mon10',
          text: '10월',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="right"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.mon10}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumMon10 = Sum.byGroup(data, 'mon10', x, ['divinm']);
            return (
              <FlexLayout
                style={{ color: '#FF00FF' }}
                justify="right"
                align="center"
              >
                {sumMon10 !== 0 ? sumMon10 : ''}
              </FlexLayout>
            );
          },
          trail: (x) => {
            const sumMon10 = Sum.all(x, 'mon10');
            return (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF00FF' }}
              >
                {sumMon10 !== 0 ? sumMon10 : ''}
              </FlexLayout>
            );
          },
        },
        {
          id: 'mon11',
          text: '11월',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="right"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.mon11}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumMon11 = Sum.byGroup(data, 'mon11', x, ['divinm']);
            return (
              <FlexLayout
                style={{ color: '#FF00FF' }}
                justify="right"
                align="center"
              >
                {sumMon11 !== 0 ? sumMon11 : ''}
              </FlexLayout>
            );
          },
          trail: (x) => {
            const sumMon11 = Sum.all(x, 'mon11');
            return (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF00FF' }}
              >
                {sumMon11 !== 0 ? sumMon11 : ''}
              </FlexLayout>
            );
          },
        },
        {
          id: 'mon12',
          text: '12월',
          width: 70,
          render: (x) => (
            <FlexLayout
              style={{ color: 'var(--color-red)' }}
              justify="right"
              align="center"
              onDoubleClick={() => scope.onOpenModal(true)}
            >
              {x.mon12}
            </FlexLayout>
          ),
          sum: (x, data) => {
            const sumMon12 = Sum.byGroup(data, 'mon12', x, ['divinm']);
            return (
              <FlexLayout
                style={{ color: '#FF00FF' }}
                justify="right"
                align="center"
              >
                {sumMon12 !== 0 ? sumMon12 : ''}
              </FlexLayout>
            );
          },
          trail: (x) => {
            const sumMon12 = Sum.all(x, 'mon12');
            return (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF00FF' }}
              >
                {sumMon12 !== 0 ? sumMon12 : ''}
              </FlexLayout>
            );
          },
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.monthBreakOftenList}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
    >
    </GridLayout>

    <ModalView
      zIndex={-2}
      isVisible={scope.state.isvisibleModal}
      width={1200}
      onClose={() => scope.onCloseModal()}
    >
      <FlexLayout
        style={{ padding: 8 }}
        isVertical={true}
      >
        <LayoutTitle>[팝업]월별다발고장현황-상세</LayoutTitle>
        <GridLayout
          ref={scope.modalGrid}
          header={[
            {
              id: 'yearmongroup',
              text: '년월별그룹',
              isHidden: true,
              group: true,
              value: (x) => `${x.recedate.substr(0, 4)} - ${x.recedate.substr(4, 2)}`,
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
              sum: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.recedate.substr(0, 4)} - {x.recedate.substr(4, 2)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합계
                </FlexLayout>
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 120,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {x.equpnm}
                  <CircleBadge>
                    {Number(x.wadcnt) > 1 && <span
                      style={{ backgroundColor: 'var(--color-red)' }}
                    >
                      {Number(x.wadcnt) > 1 ? x.wadcnt : ''}
                    </span>}
                    {Number(x.lastcnt) > 1 && <span
                      style={{ backgroundColor: 'var(--color-blue)' }}
                    >
                      {Number(x.lastcnt) > 1 ? x.lastcnt : ''}
                    </span>}
                  </CircleBadge>
                </FlexLayout>
              ),
              sum: (x, data) => {
                const sumCnt = Sum.groupLength(data, 'equpnm', x, ['yearmongroup']);
                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {sumCnt} 건
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.modal_cnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'actdivinm',
              text: '담당부서명',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actdivinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                </FlexLayout>
              ),
            },
            {
              id: 'actpernm',
              text: '담당자',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actpernm}
                </FlexLayout>
              ),
            },
            {
              id: 'seq',
              text: '처리',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.seq} 차
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '처리자',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'repernm',
              text: '접수자',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.repernm}
                </FlexLayout>
              ),
            },
            {
              id: 'recedate',
              text: '접수일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.recedate)}
                </FlexLayout>
              ),
            },
            {
              id: 'recetime',
              text: '접수시간',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.recetime.substr(0, 2)}:{x.recetime.substr(2, 2)}
                </FlexLayout>
              ),
            },
            {
              id: 'compdate',
              text: '완료일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.compdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'comptime',
              text: '완료시간',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.comptime.substr(0, 2)}:{x.comptime.substr(2, 2)}
                </FlexLayout>
              ),
            },
            {
              id: 'arrivdate',
              text: '도착일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.arrivdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'arrivtime',
              text: '도착시간',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.arrivtime.substr(0, 2)}:{x.arrivtime.substr(2, 2)}
                </FlexLayout>
              ),
            },
            {
              id: 'cnt',
              text: '대수',
              width: 60,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.cnt}
                </FlexLayout>
              ),
            },
            {
              id: 'ascnt',
              text: '건수',
              width: 60,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.ascnt}
                </FlexLayout>
              ),
            },
            {
              id: 'contnm',
              text: '고장내용',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.contnm}
                </FlexLayout>
              ),
            },
            {
              id: 'contremark',
              text: '고장내용상세',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.contremark}
                </FlexLayout>
              ),
            },
            {
              id: 'resunm',
              text: '처리내용',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.resunm}
                </FlexLayout>
              ),
            },
            {
              id: 'resuremark',
              text: '처리내용상세',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.resuremark}
                </FlexLayout>
              ),
            },
            {
              id: 'remonm',
              text: '고장요인',
              width: 150,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remonm}
                </FlexLayout>
              ),
            },
            {
              id: 'remoremark',
              text: '고장요인상세',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remoremark}
                </FlexLayout>
              ),
            },
            {
              id: 'facnm',
              text: '고장원인',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.facnm}
                </FlexLayout>
              ),
            },
            {
              id: 'greginm',
              text: '고장부위',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.greginm}
                </FlexLayout>
              ),
            },
            {
              id: 'reginm',
              text: '고장부위상세',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.reginm}
                </FlexLayout>
              ),
            },
            {
              id: 'resultnm',
              text: '처리방법',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.resultnm}
                </FlexLayout>
              ),
            },
            {
              id: 'resutime',
              text: '대응시간',
              width: 80,
              render: (x) => (
                <FlexLayout
                  style={{ paddingRight: 16 }}
                  justify="right"
                  align="center"
                >
                  {x.resutime}
                </FlexLayout>
              ),
            },
            {
              id: 'resulttime',
              text: '처리시간',
              width: 80,
              render: (x) => (
                <FlexLayout
                  style={{ paddingRight: 16 }}
                  justify="right"
                  align="center"
                >
                  {x.resulttime}
                </FlexLayout>
              ),
            },
            {
              id: 'result',
              text: '진행내역',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.result === '0' ? '미완료' : '완료'}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.modalData}
        >
        </GridLayout>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);
