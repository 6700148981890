/**
 * 모델
 * @window w_tb_e471w_02
 * @category 공사결과보고서
 */
export default class ResultModalModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly flagnm: string;

  /**
   * 코드
   *
   * **data type** varchar(8)
   */
  readonly pcode: string;

  /**
   * 품명
   *
   * **data type** varchar(8)
   */
  readonly pname: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  readonly projnm: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   * 단위
   *
   * **data type** varchar(8)
   */
  readonly punit: string;

  /**
   * 수량
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 공사명
   *
   * **data type** varchar(8)
   */
  readonly title: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.flagnm = data.flagnm || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.samt = data.samt || '';
    this.spjangcd = data.spjangcd || '';
    this.title = data.title || '';
  }
}
