import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Effort } from './Effort';
import { TabModel } from '../../../../models';
import { EffortTabKcTemplate } from './tabs/EffortTabKc.template';
import { EffortTabEndTemplate } from './tabs/EffortTabEnd.template';

/**
 * 화면 > 탭
 * @window w_tb_e601w_sulchi_06
 * @category 월공수현황
 */
export const EffortTab: Array<TabLayoutDataItem<Effort>> = [
  {
    tab: new TabModel('KC', FaCodeBranch, '검사월'),
    template: (scope) => <EffortTabKcTemplate scope={scope} />,
  },
  {
    tab: new TabModel('END', FaCodeBranch, '납기월'),
    template: (scope) => <EffortTabEndTemplate scope={scope} />,
  },
];
