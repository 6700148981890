import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca640_import
 * @category 매입등록[국세청]
 */
export class BuyNationalTaxServiceModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 고객명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  mijdate: string;

  /**
   * 매입번호
   *
   * **data type** varchar(4)
   */
  mijnum: string;

  /**
   * 순서
   *
   * **data type** varchar(3)
   */
  seq: string;

  /**
   * 품목코드
   *
   * **data type** varchar(20)
   */
  pcode: string;

  /**
   * 거래명세표-품명
   *
   * **data type** varchar(255)
   */
  pname: string;

  /**
   * 규격
   *
   * **data type** varchar(100)
   */
  psize: string;

  /**
   * 단위
   *
   * **data type** varchar(50)
   */
  punit: string;

  /**
   * 수량
   *
   * **data type** number
   */
  qty: string;

  /**
   * 단가
   *
   * **data type** number
   */
  uamt: string;

  /**
   * 금액
   *
   * **data type** number
   */
  samt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 합계금액
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  bigo: string;

  /**
   * 프로젝트
   *
   * **data type** varchar(10)
   */
  projno: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(255)
   */
  projnm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(13)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(255)
   */
  actnm: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 거래처명
   *
   * **data type** varchar(20)
   */
  cltnm: string;

  /**
   * 구분
   *
   * **data type** varchar(10)
   */
  gubun: string;

  /**
   * 매입여부
   *
   * **data type** varchar(10)
   */
  mijchk: string;

  /**
   * 총공급가액
   *
   * **data type** number
   */
  totsamt: string;

  /**
   * 총부가세액
   *
   * **data type** number
   */
  tottamt: string;

  /**
   * 총금액
   *
   * **data type** number
   */
  totmamt: string;

  /**
   * 품목명
   *
   * **data type** varchar(100)
   */
  remark: string;

  /**
   * 규격
   *
   * **data type** number
   */
  size: string;

  /**
   * 발급일자
   *
   * **data type** varchar(8)
   */
  tax_spdate: string;

  /**
   * 사업자번호
   *
   * **data type** varchar(20)
   */
  saupnum: string;

  /**
   * 대표자명
   *
   * **data type** varchar(20)
   */
  prenm: string;

  /**
   * 메일
   *
   * **data type** varchar(30)
   */
  taxmail: string;

  /**
   * 항목코드
   *
   * **data type** varchar(10)
   */
  artcd: string;

  /**
   * 항목명
   *
   * **data type** varchar(10)
   */
  artnm: string;

  /**
   * 계정코드
   *
   * **data type** varchar(10)
   */
  acccd: string;

  /**
   * 계정명
   *
   * **data type** varchar(10)
   */
  accnm: string;

  /**
   * 종류
   *
   * **data type** varchar(10)
   */
  gubun2: string;

  /**
   * 발행구분
   *
   * **data type** varchar(10)
   */
  bhflag: string;

  /**
   * 청구구분
   *
   * **data type** varchar(10)
   */
  receiptyn: string;

  /**
   * 매입/처리
   *
   * **data type** varchar(10)
   */
  mijflag: string;

  /**
   * 체크
   *
   * **data type** varchar(1)
   */
  chk: string;

  /**
   * -
   *
   * **data type** varchar(1)
   */
  sndamt: string;

  /**
   * 체크
   *
   * **data type** varchar(1)
   */
  mischk: string;

  /**
   * 체크
   *
   * **data type** varchar(1)
   */
  delchk: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * -
   *
   * **data type** number
   */
  misnum: string;

  /**
   * -
   *
   * **data type** number
   */
  jsaupnum: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  accdate: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  misamt: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  beimseq: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  misflag: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  cancleflag: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  rcvamt: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  rmisdate: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  rmisnum: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  rtax_spdate: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  rtax_spnum: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  recivstatus: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.rtax_spdate = data.rtax_spdate || '';
    this.rtax_spnum = data.rtax_spnum || '';
    this.recivstatus = data.recivstatus || '';
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.chk = data.chk || '';
    this.rmisnum = data.rmisnum || '';
    this.beimseq = data.beimseq || '';
    this.rmisdate = data.rmisdate || '';
    this.misnum = data.misnum || '';
    this.jsaupnum = data.jsaupnum || '';
    this.rcvamt = data.rcvamt || '';
    this.misflag = data.misflag || '';
    this.cancleflag = data.cancleflag || '';
    this.misamt = data.misamt || '';
    this.mischk = data.mischk || '';
    this.accdate = data.accdate || '';
    this.misdate = data.misdate || '';
    this.delchk = data.delchk || '';
    this.sndamt = data.sndamt || '';
    this.mijflag = data.mijflag || '';
    this.totsamt = data.totsamt || '';
    this.receiptyn = data.receiptyn || '';
    this.bhflag = data.bhflag || '';
    this.artcd = data.artcd || '';
    this.gubun2 = data.gubun2 || '';
    this.accnm = data.accnm || '';
    this.acccd = data.acccd || '';
    this.artnm = data.artnm || '';
    this.taxmail = data.taxmail || '';
    this.prenm = data.prenm || '';
    this.tax_spdate = data.tax_spdate || '';
    this.saupnum = data.saupnum || '';
    this.size = data.size || '';
    this.remark = data.remark || '';
    this.totmamt = data.totmamt || '';
    this.tottamt = data.tottamt || '';
    this.cltnm = data.cltnm || '';
    this.mijchk = data.mijchk || '';
    this.gubun = data.gubun || '';
    this.cltcd = data.cltcd || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.seq = data.seq || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mijamt = data.mijamt || '';
    this.bigo = data.bigo || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
