import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e470
 * @category 공사계획등록
 */
export default class PlanPerModel {
  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 처리자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 처리자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 계획일
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.custcd = data.custcd || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.plandate = data.plandate || '';
    this.remark = data.remark || '';
    this.seq = data.seq || '';
    this.spjangcd = data.spjangcd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
