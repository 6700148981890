import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Order } from '../Order';
import OrderModel from '../models/OrderModel';
import { Date8 } from '../../../../../utils/time';

const ibgs = [
  { key: '0', value: '미입고', color: 'var(--color-red)' },
  { key: '1', value: '입고', color: 'var(--color-blue)' },
];

const sites = [
  { key: '', value: '' },
  { key: '1', value: '현장' },
  { key: '2', value: '공장' },
];

const gubuns = [
  { key: '0', value: '미발주', color: 'var(--color-red)' },
  { key: '1', value: '발주중', color: 'var(--color-olive)' },
  { key: '2', value: '발주', color: 'var(--color-blue)' },
];

const states = [
  { key: '0', value: '', color: 'inherit' },
  { key: '1', value: '설치', color: 'var(--color-olive)' },
  { key: '2', value: '보수', color: 'var(--color-blue)' },
  { key: '3', value: '설치종료', color: 'var(--color-red)' },
];

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e601w_sulchi_05
 * @category 설치발주현황
 */
export const OrderGridHeader: GLHF<OrderModel, Order> = () => ([
  {
    id: 'state',
    text: '상태',
    width: 80,
    render: (x) => {
      const fp = states.filter((y) => y.key === x.state);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'projnm',
    text: '프로젝트',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.projno}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '설치현장명',
    width: 300,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'orddate',
    text: '수주일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.orddate ? Date8.withDash(x.orddate) : ''}
      </FlexLayout>
    ),
  },
  {
    id: 'balflag',
    text: '발주상태',
    width: 100,
    render: (x) => {
      const fp = gubuns.filter((y) => y.key === x.balflag);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'cltnm',
    text: '발주처',
    width: 200,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'baldate',
    text: '발주일',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.baldate ? Date8.withDash(x.baldate) : ''}
      </FlexLayout>
    ),
  },
  {
    id: 'deldate',
    text: '입고요청일',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.deldate ? Date8.withDash(x.deldate) : ''}
      </FlexLayout>
    ),
  },
  {
    id: 're_ibgdate',
    text: '입고예정일',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.re_ibgdate ? Date8.withDash(x.re_ibgdate) : ''}
      </FlexLayout>
    ),
  },
  {
    id: 're_ibgflag',
    text: '입고예정구분',
    width: 100,
    render: (x) => {
      const fp = ibgs.filter((y) => y.key === x.re_ibgflag);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'tot_re_ibgflag',
    text: '장소구분',
    width: 100,
    render: (x) => {
      const fp = sites.filter((y) => y.key === x.tot_re_ibgflag);
      const nm = fp.length > 0 ? fp[0].value : '';

      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'bigo',
    text: '납품장소',
    width: 400,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.bigo}
      </FlexLayout>
    ),
  },
]);
