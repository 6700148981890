import { FlexLayout } from '../../components';

export default class AutoPadding {
  static make(item: any): any {
    let rowItem = item;

    if (rowItem && rowItem?.type?.DOM_NAME === 'FlexLayout') {
      const p = rowItem.props;
      if (!p.isVertical && p.align?.toUpperCase() === 'CENTER') {
        if (
          (p.justify?.toUpperCase() === 'LEFT' || p.justify?.toUpperCase() === 'START')
          && (!p.style?.padding && !p.style?.paddingLeft)
        ) {
          if (p.style) {
            p.style.paddingLeft = 12;
          } else {
            rowItem = <FlexLayout style={{ paddingLeft: 12 }}>{rowItem}</FlexLayout>;
          }
        } else if (
          (p.justify?.toUpperCase() === 'RIGHT' || p.justify?.toUpperCase() === 'END')
          && (!p.style?.padding && !p.style?.paddingRight)
        ) {
          if (p.style) {
            p.style.paddingRight = 12;
          } else {
            rowItem = <FlexLayout style={{ paddingRight: 12 }}>{rowItem}</FlexLayout>;
          }
        }
      }
    }

    return rowItem;
  }
}
