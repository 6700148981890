import * as React from 'react';
import { action } from 'mobx';
import { FunctionRepository } from '../../../../repositories';
import { PageProps, PageToolEvents } from '../../../../constants';
import { CreditTemplate } from './Credit.template';
import { CreditModel } from './Credit.model';
import { AcccdItem, InfinityRetrieve, SpjangItem } from '../../../../models';
import { GridLayout } from '../../../../components';
import { PageComponent } from '../../../../utils';

export interface CreditListItem {
  custcd: string;
  spjangcd: string;
  cardnm: string;
  cardnum: string;
  cardperson: string;
  new: string;
}

interface CreditState {
  creditList: Array<CreditListItem>;
  focusedCredit?: CreditListItem;
  data: CreditModel;
  searchQuery: string;
  acccds?: Array<AcccdItem>;
  spjangcds?: Array<SpjangItem>;
  credit?: Array<any>;
}

/**
 * 컨트롤러
 * @window w_s007
 * @category 신용카드등록
 */
export class Credit extends PageComponent<PageProps, CreditState>
  implements PageToolEvents {
  updatedRows?: Array<CreditModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      data: [],
      creditList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(autoLoad: boolean = true) {
    const { actionStore: api } = this.props;
    const data = await api.retrieve({
      as_nm: this.state.searchQuery,
    });

    data && await this.SS({
      creditList: data.items,
    });
    if (!autoLoad) return;
    data.items.length > 0 && this.grid.current?.setFocus(0);
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    const { actionStore: api } = this.props;
    const data = await api.new();

    this.setState({
      creditList: [...this.state.creditList, new CreditModel(data, true)],
      focusedCredit: new CreditModel(data, true),
      data: new CreditModel(data, true),
    });
    await this.grid.current?.setFocus(this.state.creditList.length - 1);
    await this.grid.current?.forceRepaint();
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const item = {
      spjangcd: this.state.data.spjangcd,
      cardnum: this.state.data.cardnum,
    };

    if (await api.save(this.state.data, false)) {
      await this.onRetrieveEvent();
      const selected = this.state.creditList
        .filter((x) => x.cardnum === item.cardnum)[0];
      this.onRowFocusEvent(selected);
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focusedCredit?.cardnm} - ${this.state.focusedCredit?.cardnum}`;
    await api.delete(text, this.state.focusedCredit) && this.onRetrieveEvent();
  }



  @action
  onRowFocusEvent(item: CreditListItem) {
    if (item.new === '1') {
      return;
    }

    this.setState({
      focusedCredit: item,
    }, async () => {
      const { publicStore } = this.props;
      const data = await publicStore.request(async () => FunctionRepository.exec(
        'general',
        'dw_1_RowFocuschanged',
        await publicStore.makeParams(item),
      ));
      data && this.setState({ data });
    });
  }

  render() {
    return (
      <CreditTemplate
        scope={this}
        update={(change) => {
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          });
        }}
      />
    );
  }
}
