import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Format } from '../../../../../utils/string';
import { Date8 } from '../../../../../utils/time';
import { GiveModel } from '../models';
import { Project } from '../Project';

// 지급내역
const Give: GridLayoutHeader<GiveModel, Project>[] = [
  {
    id: 'mijdate',
    text: '매입일자',
    width: 8,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.mijdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'mijnum',
    text: '번호',
    width: 5,
  },
  {
    id: 'seq',
    text: '순번',
    width: 5,
  },
  {
    id: 'acccd',
    text: '계정과목',
    width: 9,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.acccds?.filter((y) => y.acccd === x.acccd)[0]?.accnm}
      </FlexLayout>
    ),
  },
  {
    id: 'snddate',
    text: '지급일자',
    width: 8,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.snddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'sndnum',
    text: '번호',
    width: 5,
  },
  {
    id: 'mijcltnm',
    text: '지급처명',
    width: 18,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.mijcltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품목명',
    width: 16,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt',
    text: '매입금액',
    width: 8,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_mijamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mijamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'sndamt',
    text: '지급금액',
    width: 8,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_sndamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.sndamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt',
    text: '잔액(미지급금액)',
    width: 10,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_iamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.iamt)}
      </FlexLayout>
    ),
  },
];

export default Give;
