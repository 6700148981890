import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { YearSeverancePay } from './YearSeverancePay';
import { SearchBinding } from '../../../../models/common';
import { Format } from '../../../../utils/string';
import { Date8 } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_pb301_02
 * @category 년퇴직금현황
 */
export const YearSeverancePayTemplate: React.FC<TemplateProps<YearSeverancePay>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>년도</span>
          <DateTextBox
            value={scope.state.year}
            textAlign="center"
            format="####"
            onChange={(value) => setData({ year: value })}
            onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  setData({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() + 1);
                  setData({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span style={{ color: 'var(--color-red)' }}>사원명</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            value={scope.state.pernm}
            textAlign="center"
            onChange={(value) => setData({ pernm: value })}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <GridLayout
        ref={scope.grid}
        headerHeight={50}
        header={[
          {
            id: 'no',
            text: '순번',
            width: 5,
          },
          {
            id: 'divinm',
            text: '부서명',
            width: 5,
          },
          {
            id: 'entdate',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  justify="center"
                  style={{
                    color: 'var(--color-blue)',
                    marginTop: 5,
                  }}
                >
                  입사일
                </FlexLayout>
                <FlexLayout
                  align="center"
                  justify="center"
                  style={{
                    color: 'var(--color-red)',
                    marginBottom: 5,
                  }}
                >
                  퇴사일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.entdate)}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.rtdate ? 'var(--color-red)' : '' }}
                >
                  {!x.dayterm2 ? Date8.withDash(x.rtdate) : Date8.withDash(x.dayterm2)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'sex',
            text: '성별',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.sex === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.sex === '1' ? '남' : '여'}
              </FlexLayout>
            ),
          },
          {
            id: 'rspnm',
            text: '직책',
            width: 5,
          },
          {
            id: 'pernm',
            text: '성명',
            width: 5,
          },
          {
            id: 'beamt',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{
                    color: '#7F7A2C',
                    marginTop: 6,
                  }}
                >
                  이월
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ marginBottom: 6 }}
                >
                  적립금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.beamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt01',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: '#7F7A2C' }}
                >
                  01월
                </FlexLayout>
                <FlexLayout align="center">
                  적립금액
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  정산금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                style={scope.getColotEvent(x.rtdate, x.year, '01')}
              >
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.retireamt01)}
                </FlexLayout>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.m_retireamt01)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt02',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: '#7F7A2C' }}
                >
                  02월
                </FlexLayout>
                <FlexLayout align="center">
                  적립금액
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  정산금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                style={scope.getColotEvent(x.rtdate, x.year, '02')}
              >
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.retireamt02)}
                </FlexLayout>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.m_retireamt02)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt03',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: '#7F7A2C' }}
                >
                  03월
                </FlexLayout>
                <FlexLayout align="center">
                  적립금액
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  정산금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                style={scope.getColotEvent(x.rtdate, x.year, '03')}
              >
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.retireamt03)}
                </FlexLayout>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.m_retireamt03)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt04',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: '#7F7A2C' }}
                >
                  04월
                </FlexLayout>
                <FlexLayout align="center">
                  적립금액
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  정산금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                style={scope.getColotEvent(x.rtdate, x.year, '04')}
              >
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.retireamt04)}
                </FlexLayout>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.m_retireamt04)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt05',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: '#7F7A2C' }}
                >
                  05월
                </FlexLayout>
                <FlexLayout align="center">
                  적립금액
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  정산금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                style={scope.getColotEvent(x.rtdate, x.year, '05')}
              >
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.retireamt05)}
                </FlexLayout>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.m_retireamt05)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt06',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: '#7F7A2C' }}
                >
                  06월
                </FlexLayout>
                <FlexLayout align="center">
                  적립금액
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  정산금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                style={scope.getColotEvent(x.rtdate, x.year, '06')}
              >
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.retireamt06)}
                </FlexLayout>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.m_retireamt06)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt07',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: '#7F7A2C' }}
                >
                  07월
                </FlexLayout>
                <FlexLayout align="center">
                  적립금액
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  정산금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                style={scope.getColotEvent(x.rtdate, x.year, '07')}
              >
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.retireamt07)}
                </FlexLayout>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.m_retireamt07)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt08',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: '#7F7A2C' }}
                >
                  08월
                </FlexLayout>
                <FlexLayout align="center">
                  적립금액
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  정산금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                style={scope.getColotEvent(x.rtdate, x.year, '08')}
              >
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.retireamt08)}
                </FlexLayout>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.m_retireamt08)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt09',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: '#7F7A2C' }}
                >
                  09월
                </FlexLayout>
                <FlexLayout align="center">
                  적립금액
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  정산금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                style={scope.getColotEvent(x.rtdate, x.year, '09')}
              >
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.retireamt09)}
                </FlexLayout>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.m_retireamt09)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt10',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: '#7F7A2C' }}
                >
                  10월
                </FlexLayout>
                <FlexLayout align="center">
                  적립금액
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  정산금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                style={scope.getColotEvent(x.rtdate, x.year, '10')}
              >
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.retireamt10)}
                </FlexLayout>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.m_retireamt10)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt11',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: '#7F7A2C' }}
                >
                  11월
                </FlexLayout>
                <FlexLayout align="center">
                  적립금액
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  정산금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                style={scope.getColotEvent(x.rtdate, x.year, '11')}
              >
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.retireamt11)}
                </FlexLayout>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.m_retireamt11)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt12',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: '#7F7A2C' }}
                >
                  12월
                </FlexLayout>
                <FlexLayout align="center">
                  적립금액
                </FlexLayout>
                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  정산금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                style={scope.getColotEvent(x.rtdate, x.year, '12')}
              >
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.retireamt12)}
                </FlexLayout>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.m_retireamt12)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'ttot_samt',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  justify="center"
                  style={{
                    color: 'var(--color-blue)',
                    marginTop: 6,
                  }}
                >
                  합계
                </FlexLayout>
                <FlexLayout
                  align="end"
                  justify="center"
                  style={{
                    color: 'var(--color-blue)',
                    marginBottom: 6,
                  }}
                >
                  총적립금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 10,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ backgroundColor: '#B2EBF4' }}
              >
                {Format.number(x.ttot_samt)}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={50}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
  );
};
