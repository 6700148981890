/**
 * 모델
 * @window w_popup_tb_e601w_sulchi_09
 * @category 설치계약달성현황
 */

export class CostPopupModel {
  /**
   * 견적일자
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 공사명
   *
   * **data type** varchar(8)
   */
  readonly costnm: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly costpernm: string;

  /**
   * 계약구분
   *
   * **data type** varchar(8)
   */
  readonly contflag: string;

  /**
   * 매출구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 공사명
   *
   * **data type** varchar(8)
   */
  readonly constnm: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 공급가액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 공사금액
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * 제어반
   *
   * **data type** varchar(8)
   */
  readonly jeoban: string;

  /**
   * 인승
   *
   * **data type** varchar(8)
   */
  readonly nperson: string;

  /**
   * 층수
   *
   * **data type** varchar(8)
   */
  readonly number: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  constructor(data: any = {}) {
    this.costdate = data.costdate || '';
    this.costnm = data.costnm || '';
    this.costpernm = data.costpernm || '';
    this.contflag = data.contflag || '';
    this.gubun = data.gubun || '';
    this.costnum = data.costnum || '';
    this.constnm = data.constnm || '';
    this.actnm = data.actnm || '';
    this.state = data.state || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.jeoban = data.jeoban || '';
    this.nperson = data.nperson || '';
    this.number = data.number || '';
    this.remark = data.remark || '';
  }
}
