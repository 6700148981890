export default class Today {
  static date(): string {
    const now = new Date();
    const month = now.getMonth() + 1;
    const dt = now.getDate();
    return `${now.getFullYear()}${month < 10 ? `0${month}` : month}${
      dt < 10 ? `0${dt}` : dt
    }`;
  }

  static year(): string {
    return Today.date().substr(0, 4);
  }

  static mon(): string {
    return Today.date().substr(4, 2);
  }

  static yearMon(): string {
    return Today.date().substr(0, 6);
  }
}
