import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { PeriodModel } from '../models';
import { EndContract } from '../EndContract';
import {
  Button,
  FormatTextBox,
  TelnumTextBox,
} from '../../../../../components';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';
import { Format } from '../../../../../utils/string';
import { Date8 } from '../../../../../utils/time';

// 거래처별
const Period: GridLayoutHeader<PeriodModel, EndContract>[] = [
  {
    id: 'no',
    text: 'No',
    width: 50,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.no}
      </FlexLayout>
    ),
  },
  {
    id: 'stdate',
    text: '시작일자',
    width: 110,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.stdate}
      />
    ),
  },
  {
    id: 'enddate',
    text: '만료일자',
    width: 110,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.enddate}
      />
    ),
  },
  {
    id: 'bosunm',
    text: '보수업체명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.bosunm}
      </FlexLayout>
    ),
  },
  {
    id: 'wkactcd',
    text: '회사구분',
    width: 80,
    render: (x, scope) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.wkactcd !== '001' ? { color: 'var(--color-violet)' } : ''}
      >
        {scope?.state.wkactcds?.filter((y) => y.wkactcd === x.wkactcd)[0]?.wkactnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnum',
    text: '협력코드',
    width: 100,
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.total} 건
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '관리대수',
    width: 80,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.qty_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 80,
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 80,
  },
  {
    id: 'pernm3',
    text: '기록',
    width: 50,
    render: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <Button
          style={{
            height: '20px',
            width: '40px',
            marginTop: 10,
          }}
          theme={BUTTON_THEMES.BLUE}
          onClick={() => scope?.historyPopupModal(true)}
        >
          조회
        </Button>
      </FlexLayout>
    ),
  },
  {
    id: 'deljugi',
    text: '청구주기',
    width: 80,
    render: (x, scope) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope?.state.deljugis?.filter((y) => y.com_code === x.deljugi)[0]?.com_cnam || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'monamt',
    text: '대당금액(월 청구금액)',
    width: 130,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.equpamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {x.equpamt}
      </FlexLayout>
    ),
  },
  {
    id: 'moamt',
    text: '월청구금액',
    width: 130,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.monamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.monamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'amt',
    text: '계약금액',
    width: 130,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.amt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.monamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'contgubun',
    text: '계약구분',
    width: 90,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.contgs?.filter((y) => y.code === x.contg)[0]?.cnam || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'contg',
    text: '계약분류',
    width: 90,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.contgubuns?.filter((y) => y.code === x.contgubun)[0]?.cnam || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'picflag',
    text: '계약서',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.picflag === '0' ? '무' : '유'}
      </FlexLayout>
    ),
  },
  {
    id: 'areanm',
    text: '지역명',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.areanm}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '매출구분',
    width: 100,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'bildyd',
    text: '건물용도',
    width: 130,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.bildyds?.filter((y) => y.buildcd === x.bildyd)[0]?.buildnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'mgubun',
    text: '유무상',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.mgubun === '1' ? '유' : '무'}
      </FlexLayout>
    ),
  },
  {
    id: 'contdate',
    text: '계약일자',
    width: 110,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <FormatTextBox
          format="####-##-##"
          textAlign="center"
          value={x.contdate}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'yy',
    text: '년차',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.yy}
      </FlexLayout>
    ),
  },
  {
    id: 'tel',
    text: '전화번호',
    width: 120,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <TelnumTextBox
          textAlign="center"
          value={x.tel}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'hp',
    text: '휴대폰번호',
    width: 120,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <TelnumTextBox
          textAlign="center"
          value={x.hp}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'taxgubun',
    text: '세금구분',
    width: 90,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.taxgubuns?.filter((y) => y.com_code === x.taxgubun)[0]?.com_cnam}
      </FlexLayout>
    ),
  },
  {
    id: 'taxflag',
    text: '증빙구분',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.taxflag === '1' ? '발행' : '미발행'}
      </FlexLayout>
    ),
  },
  {
    id: 'addyn',
    text: '부가세포함',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.addyn === '1' ? '포함' : '별도'}
      </FlexLayout>
    ),
  },
  {
    id: 'dedate',
    text: '청구서발행일',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.dedate}
      </FlexLayout>
    ),
  },
  {
    id: 'jirogubun',
    text: '지로발행',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.jirogubun === '1' ? '발행' : '미발행'}
      </FlexLayout>
    ),
  },
  {
    id: 'zipcode',
    text: '우편번호',
    width: 60,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.zipcode}
      </FlexLayout>
    ),
  },
  {
    id: 'address',
    text: '주소',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.address}
      </FlexLayout>
    ),
  },
  {
    id: 'address2',
    text: '상세주소',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.address2}
      </FlexLayout>
    ),
  },
  {
    id: 'title',
    text: '최근계약기록내역',
    width: 300,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.title}
      </FlexLayout>
    ),
  },
  {
    id: 'hdate',
    text: '최근기록일',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.hdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'hispernm',
    text: '최근계약작성자',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.hispernm}
      </FlexLayout>
    ),
  },
];

export default Period;
