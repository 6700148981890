import { FcCalculator } from 'react-icons/all';
import {
  Button, CheckBox, DateTextBox, FlexLayout, FormatNumberTextBox, TextBox,
} from '../../../../../components';
import { Enrollment } from '../Enrollment';
import { TLHF } from '../../../../../constants';
import EnrollmentDetailModel from '../models/EnrollmentDetailModel';
import { SearchBinding } from '../../../../../models';
import { Sum } from '../../../../../utils/array';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e451
 * @category 견적서등록
 */
export const EnrollmentDetailTableHeader: TLHF<EnrollmentDetailModel, Enrollment> = (scope) => ([
  {
    id: 'costcd',
    text: '코드',
    width: 70,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref, _, index) => (
      index === 0
        ? <TextBox
          ref={ref}
          textAlign="center"
          value={x.costcd}
          readonly={true}
        />
        : <TextBox
          ref={ref}
          textAlign="center"
          value={x.costcd}
          onChange={(v) => rowUpdate({ costcd: v })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_E450',
            {},
            true,
            (item) => {
              scope.tableDetailItemChanged(rowUpdate, 'costcd', item.cd, x, {
                costcd: item.cd,
                costnm: item.cdnm,
                unit: item.uv_arg1,
                qty: item.uv_arg2,
                remark: item.uv_arg4,
              });
            },
          )}
        />
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'costnm',
    text: '구분',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.costnm}
        onChange={(v) => rowUpdate({ costnm: v })}
      />
    ),
  },
  {
    id: 'size',
    text: '규격',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.size}
        onChange={(v) => rowUpdate({ size: v })}
      />
    ),
  },
  {
    id: 'unit',
    text: '단위',
    width: 60,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.unit}
        onChange={(v) => rowUpdate({ unit: v })}
      />
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 60,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="center"
        value={x.qty}
        onChange={(value) => scope.tabAutoCalc({ ...x, qty: value }, rowUpdate)}
      />
    ),
  },
  {
    id: 'calc',
    text: '계산',
    width: 60,
    render: (_, _2, _3, _4, index) => (
      <Button
        isIcon={true}
        isFull={true}
        onClick={() => scope.openPopupAmount(index)}
      >
        <FcCalculator size={21} />
      </Button>
    ),
  },
  {
    id: 'autoexec',
    text: '수동계산',
    width: 80,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.autoexec === '1'}
        onChange={(v) => rowUpdate({ autoexec: v ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.uamt}
        onChange={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate, true)}
      />
    ),
  },
  {
    id: 'samt',
    text: '금액',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.samt}
        onChange={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, false, true)}
      />
    ),
    trail: (data) => {
      const sumSamt = Sum.all(data, 'samt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumSamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'addamt',
    text: '부가세',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.addamt}
        onChange={(value) => scope.tabAutoCalc({ ...x, addamt: value }, rowUpdate, false, false, true)}
      />
    ),
    trail: (data) => {
      const sumAddamt = Sum.all(data, 'addamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumAddamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'costamt',
    text: '견적금액',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.costamt}
        onChange={(v) => scope.tabCostAmtCalc({ ...x, costamt: v }, rowUpdate)}
      />
    ),
    trail: (data) => {
      const sumCostamt = Sum.all(data, 'costamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumCostamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'remark',
    text: '비고',
    width: 250,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
  {
    id: 'ibgdate',
    text: '입고일자',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        ref={ref}
        value={x.ibgdate}
        onChange={(v) => rowUpdate({ ibgdate: v })}
      />
    ),
  },
]);
