import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { SortationCodeTemplate } from './SortationCode.template';
import { SortationCodeModel } from './SortationCode.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface SortationCodeState {
  data: Array<SortationCodeModel>;
  focused?: SortationCodeModel;

  // 매입구분
  wkgubuns?: Array<any>;
}

/**
 * 컨트롤러
 * @window w_tb_da020
 * @category 매출구분코드 등록
 */
export class SortationCode extends PageComponent<PageProps, SortationCodeState>
  implements PageToolEvents {
  updatedRows?: Array<SortationCodeModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 매입구분
    const data = await api.dropdown('wf_dd_ca510_114_01');
    this.SS({ wkgubuns: data?.items || [] });

    if (!data) return;

    // 선행되어야 하는 서버 호출이 모두 성공한 경우 [조회]
    await this.onRetrieveEvent();
  }

    @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        wkgubun: this.state.focused?.wkgubun,
      },
      (params) => (
        api.retrieve(params)
      ),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new SortationCodeModel(x))],
        }, () => this.table.current?.update());
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieve();
        await this.table.current?.update(true);
        await this.table.current?.setFocus(0, 2);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
    });

    await this.infinity?.retrieve();
    await this.table.current?.update(true);
    await this.table.current?.setFocus(0, 2);
  }

    @action
    async onNewEvent() {
      const { actionStore: api } = this.props;
      const data = await api.new();

      data && this.setState({
        data: [new SortationCodeModel(data, true), ...this.state.data],
      }, async () => {
        await this.table.current?.update(true);
        this.table.current?.setFocus(this.state.data.length - 1, 1);
      });
    }

    @action
    async onSaveEvent() {
      const { actionStore: api } = this.props;
      if (await api.save({
        items: this.updatedRows,
      }, true)) {
        this.updatedRows = [];
        this.table.current?.resetUpdates();
      }
    }

    @action
    async onDeleteEvent() {
      const { actionStore: api } = this.props;
      const text = `${this.state.focused?.artcd} - ${this.state.focused?.artnm}`;
      await api.delete(text, this.state.focused) && await this.onRetrieveEvent();
    }

    @action
    async onPrintEvent() {
      const { actionStore: api } = this.props;

      if (!ConfirmWarning.assert(this.state.data.length > 0,
        '오류', '출력할 내역이 없습니다.')) {
        return;
      }
      await api.printWithElmanManager();
    }

    @action
    async onExcelEvent() {
      const { actionStore: api } = this.props;

      if (!ConfirmWarning.assert(this.state.data.length > 0,
        '오류', '엑셀변환할 내역이 없습니다.')) {
        return;
      }
      await api.excel();
    }

    @action
    onRowFocusEvent(item: SortationCodeModel) {
      this.setState({ focused: item });
    }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
    onUpdatedRows(rows: Array<SortationCodeModel>, updatedRows: Array<SortationCodeModel>) {
      this.updatedRows = updatedRows;
      this.setState({ data: rows });
    }

  render() {
    return <SortationCodeTemplate
      scope={this}
    />;
  }
}
