import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  CheckBox,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TableLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  MonthInput,
  MonthInputTabDataStateNames,
} from './MonthInput';
import {
  Global,
  TemplateProps,
} from '../../../../constants';

/**
 * 화면
 * @window w_input_da026w_02
 * @category 월별입금현황
 */
export const MonthInputTemplate: React.FC<TemplateProps<MonthInput>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>년도</span>
            <DateTextBox
              value={scope.state.year}
              textAlign="center"
              format="####"
              onChange={(value) => setData({ year: value })}
              onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() - 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() + 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={500}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder={scope.state.holderName}
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'cnam',
                text: '매출구분',
                width: 80,

              },
              {
                id: 'chk',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={scope.state.pushTotalCheck}
                      onChange={async (value) => scope
                        .updateCheckAllToggle(value)}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
                width: 20,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.chk === '1'}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.data}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
          />
        </FlexLayout>

        <FlexLayout
          isVertical={true}
          weight={5}
        >
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <TabView
              tabs={scope.tabs}
              focused={scope.state.focusedTab}
              onChange={(tab) => scope.onTabChange(tab)}
            />
          </FlexLayout>
          <FlexLayout weight={1.5}>
            <GridLayout
              scope={scope}
              ref={scope.grid}
              header={scope.tabHeaders[scope.tabIndex]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity}
              onRowClick={(item) => scope.onRowFocusEvent(item)}
              // @ts-ignore
              data={scope.state[MonthInputTabDataStateNames[scope.tabIndex]] || [] }
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
