import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_pb404
 * @category 교육보고서
 */

export class EducationModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 일자
   *
   * **data type** varchar(8)
   */
  readonly edudate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  readonly edunum: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  readonly title: string;

  /**
   * 시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 종료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 교육자
   *
   * **data type** varchar(255)
   */
  readonly pernm: string;

  /**
   * 교육받은자
   *
   * **data type** varchar(255)
   */
  readonly edupernm: string;

  /**
   * 교육내용
   *
   * **data type** varchar(256)
   */
  readonly remark: string;

  /**
   * 결재일자
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 상태
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   * 결재자
   *
   * **data type** varchar(10)
   */
  readonly appperid: string;

  /**
   * 결재비고
   *
   * **data type** varchar(255)
   */
  readonly appremark: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 교육분류
   *
   * **data type** varchar(4)
   */
  readonly gubun: string;

  /**
   * 첨부여부
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  /**
   * 참조
   *
   * **data type** varchar(255)
   */
  readonly refer: string;

  /**
   * 상신시간
   *
   * **data type** varchar(6)
   */
  readonly apptime: string;

  /**
   * 작성자
   *
   * **data type** varchar(30)
   */
  readonly inpernm: string;

  /**
   * 직위
   *
   * **data type** varchar(20)
   */
  readonly rspnm: string;

  /**
   * 부서
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   * 교육기간

   *
   * **data type** varchar(8)
   */
  readonly stdatetime: string;

  /**
   *
   교육기간
   *
   * **data type** varchar(8)
   */
  readonly enddatetime: string;

  /**
   *
   첨부파일
   *
   * **data type** varchar(8)
   */
  readonly attcnt: number;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.edudate = data.edudate || '';
    this.edunum = data.edunum || '';
    this.title = data.title || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.edupernm = data.edupernm || '';
    this.remark = Fix.newline(data.remark) || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.appnum = data.appnum || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.gubun = data.gubun || '';
    this.picflag = data.picflag || '';
    this.refer = data.refer || '';
    this.apptime = data.apptime || '';
    this.inpernm = data.inpernm || '';
    this.rspnm = data.rspnm || '';
    this.divinm = data.divinm || '';
    this.stdatetime = data.stdatetime || '';
    this.enddatetime = data.enddatetime || '';
    this.attcnt = data.attcnt || 0;

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
