import { computed } from 'mobx';

/**
 * 승강기형식 모델
 * @window w_tb_e001
 * @category 승강기관리코드
 */
export class ElevatorFormModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 코드
   *
   * **data type** varchar(3)
   */
  readonly elifcd: string;

  /**
   * 엘리베이터구분명
   *
   * **data type** varchar(20)
   */
  readonly elifnm: string;

  /**
   * 분류코드
   *
   * **data type** char(1)
   */
  readonly class: string;

  /**
   * 사용유무
   *
   * **data type** char(1)
   */
  readonly useyn: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  readonly remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.elifcd = data.elifcd || '';
    this.elifnm = data.elifnm || '';
    this.class = data.class || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
