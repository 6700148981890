import * as React from 'react';
import { action } from 'mobx';
import { inject } from 'mobx-react';
import mStyle from './OptionBox.module.scss';
import { JoinClassName } from '../../../utils/string';
import { LayoutProps } from '../../../constants';
import { FlexLayout } from '../../layout';
import { RadioBox } from '../RadioBox';
import { PublicStore } from '../../../stores';

export interface OptionItem {
  value: string | number; // key
  remark: string;
}

export interface OptionBoxActions extends LayoutProps {
  onChange: (selected: OptionItem) => any;
}

export interface OptionBoxProps extends OptionBoxActions {
  publicStore?: PublicStore;
  value: string | number;
  data: Array<OptionItem>;
  isDisabledTrackingStateChange?: boolean;
  isVertical?: boolean;
  readonly?: boolean;
}

@inject('publicStore')
export class OptionBox extends React.Component<OptionBoxProps> {
  @action
  callOnChange(value: OptionItem) {
    const { publicStore } = this.props;
    this.props.onChange && this.props.onChange(value);

    if (!this.props.isDisabledTrackingStateChange) {
      publicStore?.setStateChanged(true);
    }
  }

  render() {
    const {
      className,
      style,
      weight,
      size,
      value,
      data,
      onChange,
    } = this.props;

    return (
      <FlexLayout
        className={JoinClassName.make([
          className,
          this.props.readonly ? mStyle.readonly : false,
        ])}
        weight={weight}
        size={size}
        style={style}
        isVertical={this.props.isVertical}
      >
        {data.map((item) => (
          <FlexLayout
            justify="center"
            align="center"
            key={item.value}
            className={JoinClassName.make([
              mStyle.item,
              item.value === value ? mStyle.active : false,
            ])}
            onClick={() => onChange(item)}
          >
            <RadioBox
              value={value === item.value}
            />
            <span>{item.remark}</span>
          </FlexLayout>
        ))}
      </FlexLayout>
    );
  }
}
