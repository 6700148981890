/**
 * 팝업모델
 * @window w_tb_e512w_01
 * @category 당직현황
 */
export class ModalDataModel {
  /**
   * 회사코드
   *
   * **data type** varchar()
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar()
   */
  readonly spjangcd: string;

  /**
   * 부서코드
   *
   * **data type** varchar()
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar()
   */
  readonly divinm: string;

  /**
   * 담당자
   *
   * **data type** varchar()
   */
  readonly perid: string;

  /**
   * 담당자명
   *
   * **data type** varchar()
   */
  readonly pernm: string;

  /**
   * 현장코드
   *
   * **data type** varchar()
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar()
   */
  readonly actnm: string;

  /**
   * 대수
   *
   * **data type** varchar()
   */
  readonly qty: string;

  /**
   * 금액
   *
   * **data type** varchar()
   */
  readonly amt: string;

  /**
   * 계약분류
   *
   * **data type** varchar()
   */
  readonly contg: string;

  /**
   * 계약구분
   *
   * **data type** varchar()
   */
  readonly contgubun: string;

  /**
   * 시작일
   *
   * **data type** varchar()
   */
  readonly stdate: string;

  /**
   * 종료일
   *
   * **data type** varchar()
   */
  readonly enddate: string;

  /**
   * 계약부서
   *
   * **data type** varchar()
   */
  readonly contdivinm: string;

  /**
   * 계약자
   *
   * **data type** varchar()
   */
  readonly contpernm: string;

  /**
   * 구분
   *
   * **data type** varchar()
   */
  readonly flag: string;

  /**
   * 월
   *
   * **data type** varchar()
   */
  readonly mon: string;

  /**
   * 계획금액
   *
   * **data type** varchar()
   */
  readonly planamt: string;

  /**
   * 실적(매출)금액
   *
   * **data type** varchar()
   */
  readonly misamt: string;

  /**
   * 계획내용
   *
   * **data type** varchar()
   */
  readonly plan_remark: string;

  /**
   * 실적내용
   *
   * **data type** varchar()
   */
  readonly remark: string;

  /**
   * 호기코드
   *
   * **data type** varchar()
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar()
   */
  readonly equpnm: string;

  /**
   * 승강기번호
   *
   * **data type** varchar()
   */
  readonly elno: string;

  /**
   * 점검계획일
   *
   * **data type** varchar()
   */
  readonly plandate: string;

  /**
   * 점검일
   *
   * **data type** varchar()
   */
  readonly rptdate: string;

  /**
   * B건수
   *
   * **data type** varchar()
   */
  readonly bcnt: string;

  /**
   * C건수
   *
   * **data type** varchar()
   */
  readonly ccnt: string;

  /**
   * 담당부서명
   *
   * **data type** varchar()
   */
  readonly actdivinm: string;

  /**
   * 담당자
   *
   * **data type** varchar()
   */
  readonly actpernm: string;

  /**
   * 처리
   *
   * **data type** varchar()
   */
  readonly seq: string;

  /**
   * 처리자2
   *
   * **data type** varchar()
   */
  readonly pernm2: string;

  /**
   * 배정자
   *
   * **data type** varchar()
   */
  readonly recepernm: string;

  /**
   * 접수자
   *
   * **data type** varchar()
   */
  readonly repernm: string;

  /**
   * 접수일자
   *
   * **data type** varchar()
   */
  readonly recedate: string;

  /**
   * 접수시간
   *
   * **data type** varchar()
   */
  readonly recetime: string;

  /**
   * 완료일자
   *
   * **data type** varchar()
   */
  readonly compdate: string;

  /**
   * 완료시간
   *
   * **data type** varchar()
   */
  readonly comptime: string;

  /**
   * 도착일자
   *
   * **data type** varchar()
   */
  readonly arrivdate: string;

  /**
   * 도착시간
   *
   * **data type** varchar()
   */
  readonly arrivtime: string;

  /**
   * 고장내용
   *
   * **data type** varchar()
   */
  readonly contnm: string;

  /**
   * 고장내용상세
   *
   * **data type** varchar()
   */
  readonly contremark: string;

  /**
   * 처리내용
   *
   * **data type** varchar()
   */
  readonly resunm: string;

  /**
   * 처리내용상세
   *
   * **data type** varchar()
   */
  readonly resuremark: string;

  /**
   * 고장요인
   *
   * **data type** varchar()
   */
  readonly remonm: string;

  /**
   * 고장요인상세
   *
   * **data type** varchar()
   */
  readonly remoremark: string;

  /**
   * 고장원인
   *
   * **data type** varchar()
   */
  readonly facnm: string;

  /**
   * 고장부위
   *
   * **data type** varchar()
   */
  readonly greginm: string;

  /**
   * 고장부위상세
   *
   * **data type** varchar()
   */
  readonly reginm: string;

  /**
   * 처리방법
   *
   * **data type** varchar()
   */
  readonly resultnm: string;

  /**
   * 대응(분)
   *
   * **data type** varchar()
   */
  readonly resutime: string;

  /**
   * 처리(분)
   *
   * **data type** varchar()
   */
  readonly resulttime: string;

  /**
   * 비운행(분)
   *
   * **data type** varchar()
   */
  readonly stoptime: string;

  /**
   * 설치년도
   *
   * **data type** varchar()
   */
  readonly reyyyymm: string;

  /**
   * 진행
   *
   * **data type** varchar()
   */
  readonly statenm: string;

  /**
   * 결과
   *
   * **data type** varchar()
   */
  readonly result: string;

  /**
   * 구분
   *
   * **data type** varchar()
   */
  readonly gubun: string;

  /**
   * 검사일자
   *
   * **data type** varchar()
   */
  readonly kcdate: string;

  /**
   * 검사자
   *
   * **data type** varchar()
   */
  readonly kcpernm: string;

  /**
   * 검사기관
   *
   * **data type** varchar()
   */
  readonly kcspnm: string;

  /**
   * 답변일
   *
   * **data type** varchar()
   */
  readonly hdate: string;

  /**
   * 전월미수금
   *
   * **data type** varchar()
   */
  readonly beamt: string;

  /**
   * 당월미수금
   *
   * **data type** varchar()
   */
  readonly iamt5: string;

  /**
   * 수금액
   *
   * **data type** varchar()
   */
  readonly rcvamt: string;

  /**
   * 다발고장
   *
   * **data type** varchar()
   */
  readonly wadcnt: string;

  /**
   * 동일고장
   *
   * **data type** varchar()
   */
  readonly lastcnt: string;

  /**
   * 견적금액
   *
   * **data type** varchar()
   */
  readonly costamt: string;

  /**
   * 견적제목
   *
   * **data type** varchar()
   */
  readonly cost_remark: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.qty = data.qty || '';
    this.amt = data.amt || '';
    this.contg = data.contg || '';
    this.contgubun = data.contgubun || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.contdivinm = data.contdivinm || '';
    this.contpernm = data.contpernm || '';
    this.flag = data.flag || '';
    this.mon = data.mon || '';
    this.planamt = data.planamt || '';
    this.misamt = data.misamt || '';
    this.plan_remark = data.plan_remark || '';
    this.remark = data.remark || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.elno = data.elno || '';
    this.plandate = data.plandate || '';
    this.rptdate = data.rptdate || '';
    this.bcnt = data.bcnt || '';
    this.ccnt = data.ccnt || '';
    this.actdivinm = data.actdivinm || '';
    this.actpernm = data.actpernm || '';
    this.seq = data.seq || '';
    this.pernm2 = data.pernm2 || '';
    this.recepernm = data.recepernm || '';
    this.repernm = data.repernm || '';
    this.recedate = data.recedate || '';
    this.recetime = data.recetime || '';
    this.compdate = data.compdate || '';
    this.comptime = data.comptime || '';
    this.arrivdate = data.arrivdate || '';
    this.arrivtime = data.arrivtime || '';
    this.contnm = data.contnm || '';
    this.contremark = data.contremark || '';
    this.resunm = data.resunm || '';
    this.resuremark = data.resuremark || '';
    this.remonm = data.remonm || '';
    this.remoremark = data.remoremark || '';
    this.facnm = data.facnm || '';
    this.greginm = data.greginm || '';
    this.reginm = data.reginm || '';
    this.resultnm = data.resultnm || '';
    this.resutime = data.resutime || '';
    this.resulttime = data.resulttime || '';
    this.stoptime = data.stoptime || '';
    this.reyyyymm = data.reyyyymm || '';
    this.statenm = data.statenm || '';
    this.result = data.result || '';
    this.gubun = data.gubun || '';
    this.kcdate = data.kcdate || '';
    this.kcpernm = data.kcpernm || '';
    this.kcspnm = data.kcspnm || '';
    this.hdate = data.hdate || '';
    this.beamt = data.beamt || '';
    this.iamt5 = data.iamt5 || '';
    this.rcvamt = data.rcvamt || '';
    this.wadcnt = data.wadcnt || '';
    this.lastcnt = data.lastcnt || '';
    this.costamt = data.costamt || '';
    this.cost_remark = data.cost_remark || '';
  }
}
