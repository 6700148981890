import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { WorkCodeModel } from './WorkCode.model';
import { WorkCodeTemplate } from './WorkCode.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

export interface WorkCodeListItem {
  kukcd: string;
}

interface WorkCodeState {
  searchQuery: string;
  kukcd: string;
  kuknm: string;
  upflags?: Array<any>;
  data: WorkCodeModel
  workCodekList: Array<WorkCodeListItem>;
  focused?: WorkCodeModel;
}

/**
 * 컨트롤러
 * @window @window TB_E021
 * @category 업무코드등록
 */
export class WorkCode extends PageComponent<PageProps, WorkCodeState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      workCodekList: [],
      kukcd: '01',
      kuknm: '총무팀',
      data: new WorkCodeModel(),
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 상위부서
    if (!this.state.searchQuery) {
      const datas = await api.fxExec(
        'open',
      );
      datas && this.setState({
        data: datas,
        searchQuery: datas.kukcd,
      });
      this.state.data.searchQuery = this.state.searchQuery;
    }
    if (!this.state.data.searchQuery) return;

    // 업무구분
    const datas = await api.dropdown('wf_dd_e022', { kukcd: this.state.searchQuery });

    if (!datas) return;
    this.setState({ upflags: datas.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.kukcd,
        kukcd: this.state.kukcd,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          workCodekList: [...this.state.workCodekList, ...items],
        });
      },
      async () => {
        await this.SS({
          workCodekList: [],
        });

        await this.infinity?.retrieve();
        this.table.current?.update(true);
        this.state.workCodekList.length > 0 && this.table.current?.setFocus(0, 0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      workCodekList: [],
    }, async () => {
      await this.infinity?.retrieve();
      this.table.current?.update(true);
      this.state.workCodekList.length > 0 && this.table.current?.setFocus(0, 0);
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      kukcd: this.state.kukcd,
    });

    if (data.messagebox) {
      ConfirmWarning.show('경고', '상위업무코드를 먼저 선택하세요.');
      return;
    }
    data.modalCheck = '1';

    data && this.setState({
      workCodekList: [
        ...this.state.workCodekList,
        data,
      ],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(this.state.workCodekList.length - 1, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
    await this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `업무코드: ${this.state.focused?.busicd} 업무명: ${this.state.focused?.businm}`;
    await api.delete(text, this.state.focused) && this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.workCodekList?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      kukcd: this.state.data.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.workCodekList?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
      kukcd: this.state.data.searchQuery,
    });
  }

  @action
  onRowFocusEvent(item: WorkCodeModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <WorkCodeTemplate
        scope={this}
        update={(change) => {
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          });
        }}
      />
    );
  }
}
