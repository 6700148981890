import { GLHF } from '../../../../../constants';
import { FlexLayout, FormatTextBox } from '../../../../../components';
import { PaySales } from '../PaySales';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 탭 > 월별 수주매출내역 - 그리드 레이아웃
 * @window w_tb_e101w_06
 * @category 보수수주매출통계
 */
export const TabMonthGridHeader: GLHF<any, PaySales> = (scope) => ([
  {
    id: 'mon',
    text: '월',
    width: 16,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##"
        value={x.mon}
      />
    ),
  },
  {
    id: 'contamt',
    text: '수주금액',
    width: 8,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {scope?.state.tab_contamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.contamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'averamt',
    text: '평균보수료',
    width: 8,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.tab_contamt12_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.averamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '대수',
    width: 8,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_qty_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.qty)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty_new',
    text: '신규',
    width: 8,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.tab_qty_new_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.qty_new)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty_cls',
    text: '해지',
    width: 8,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {scope?.state.tab_qty_cls_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.qty_cls)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty_fm',
    text: 'FM대수',
    width: 8,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.tab_qty_fm_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.qty_fm)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty_long',
    text: '장기대수',
    width: 8,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {scope?.state.tab_qty_long_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.qty_long)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty_pog',
    text: '일반대수',
    width: 8,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_qty_pog_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.qty_pog)}
      </FlexLayout>
    ),
  },
  {
    id: 'jamt',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          잔액
        </FlexLayout>

        <FlexLayout>
          (수주-입금)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 8,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_jamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.jamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt2',
    text: '매출',
    width: 8,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_misamt2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt2)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt2',
    text: '입금',
    width: 8,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.tab_rcvamt2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.rcvamt2)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt2',
    text: '미수금',
    width: 8,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {scope?.state.tab_iamt2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.iamt2)}
      </FlexLayout>
    ),
  },
]);
