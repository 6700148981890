import { computed } from 'mobx';

/**
 * 메인모델
 * @window w_tb_da050
 * @category 회사목표계획등록
 */
export class MainModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 계획월
   *
   * **data type** char(6)
   */
  planmon: string;

  /**
   * 목표금액
   *
   * **data type** number
   */
  samt: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 달성률
   *
   * **data type** number
   */
  yul: string;

  /**
   * 차액
   *
   * **data type** number
   */
  notamt: string;

  /**
   * 차액합계
   *
   * **data type** number
   */
  notamt_tot: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.planmon = data.planmon || '';
    this.samt = data.samt || '';
    this.misamt = data.misamt || '';
    this.yul = data.yul || '';
    this.remark = data.remark || '';
    this.spjangnm = data.spjangnm || '';
    this.notamt = data.notamt || '';
    this.notamt_tot = data.notamt_tot || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
