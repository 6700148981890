export default class ApiService {
  /**
   * Make url for request api
   * @param category ex) general
   * @param windowName ex) w_tb_pb404
   * @param action <command|function> ex) command
   * @param actionName ex) tool
   */
  static make(
    category: string,
    windowName: string,
    action: string,
    actionName: string,
  ): string {
    return `/${category}/${windowName}/${action}/${actionName}`;
  }

  static notNull(
    data: any,
  ): any {
    if (!data) return {};

    const result = {};
    Object.keys(data).forEach((k) => {
      // @ts-ignore
      result[k] = String(data[k] || '');
    });

    return result;
  }
}
