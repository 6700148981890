import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e451
 * @category 견적서등록
 */
export default class EnrollmentModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 견적항목 부가세 합계
   *
   * **data type** varchar(8)
   */
  readonly addamt_tot: string;

  /**
   * 부품내역등록 부가세 합계
   *
   * **data type** varchar(8)
   */
  readonly addamt_tot2: string;

  /**
   * 주소
   *
   * **data type** varchar(8)
   */
  readonly address: string;

  /**
   * 상세주소
   *
   * **data type** varchar(8)
   */
  readonly address2: string;

  /**
   * 부가세구분
   *
   * **data type** varchar(8)
   */
  readonly addyn: string;

  /**
   * 결재일
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 결재상태
   *
   * **data type** varchar(8)
   */
  readonly appgubun: string;

  /**
   * 문서번호
   *
   * **data type** varchar(8)
   */
  readonly appnum: string;

  /**
   * 결재자
   *
   * **data type** varchar(8)
   */
  readonly appperid: string;

  /**
   * 결재권자 지시사항
   *
   * **data type** varchar(8)
   */
  readonly appremark: string;

  /**
   * 결재시간
   *
   * **data type** varchar(8)
   */
  readonly apptime: string;

  /**
   * 첨부파일
   *
   * **data type** varchar(8)
   */
  readonly attcnt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly chk: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  readonly cltnm: string;

  /**
   * 준공일
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 작업자부서
   *
   * **data type** varchar(8)
   */
  readonly compdivicd: string;

  /**
   * 작업자부서명
   *
   * **data type** varchar(8)
   */
  readonly compdivinm: string;

  /**
   * 준공상태
   *
   * **data type** varchar(8)
   */
  readonly compflag: string;

  /**
   * 작업자코드
   *
   * **data type** varchar(8)
   */
  readonly compperid: string;

  /**
   * 작업자명
   *
   * **data type** varchar(8)
   */
  readonly comppernm: string;

  /**
   * 견적상태
   *
   * **data type** varchar(8)
   */
  readonly compyn: string;

  /**
   * 완료유무
   *
   * **data type** varchar(8)
   */
  readonly compyn3: string;

  /**
   * 견적서명
   *
   * **data type** varchar(8)
   */
  readonly constnm: string;

  /**
   * 계약구분
   *
   * **data type** varchar(8)
   */
  readonly contgubun: string;

  /**
   * 계약구분 이름
   *
   * **data type** varchar(8)
   */
  readonly contgubunnm: string;

  /**
   * 견적항목 견적합계
   *
   * **data type** varchar(8)
   */
  readonly costamt_tot: string;

  /**
   * 부품내역등록 견적합계
   *
   * **data type** varchar(8)
   */
  readonly costamt_tot2: string;

  /**
   * 견적일
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 고객연락처
   *
   * **data type** varchar(8)
   */
  readonly costhp: string;

  /**
   * 견적번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 견적자
   *
   * **data type** varchar(8)
   */
  readonly costperid: string;

  /**
   * 견적자명
   *
   * **data type** varchar(8)
   */
  readonly costpernm: string;

  /**
   * 고객명
   *
   * **data type** varchar(8)
   */
  readonly customer: string;

  /**
   * 공사만료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly evidyn: string;

  /**
   * 팩스번호
   *
   * **data type** varchar(8)
   */
  readonly faxnum: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 유효기간
   *
   * **data type** varchar(8)
   */
  readonly iamt: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 입력자명
   *
   * **data type** varchar(8)
   */
  readonly inpernm: string;

  /**
   * 합계금액
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly nohuseq: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly oldcompyn: string;

  /**
   * 고객부서
   *
   * **data type** varchar(8)
   */
  readonly perdivinm: string;

  /**
   * 고객 팩스
   *
   * **data type** varchar(8)
   */
  readonly perfax: string;

  /**
   * 고객번호
   *
   * **data type** varchar(8)
   */
  readonly perhp: string;

  /**
   * 고객메일
   *
   * **data type** varchar(8)
   */
  readonly permail: string;

  /**
   * 고객명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 고객번호
   *
   * **data type** varchar(8)
   */
  readonly pertel: string;

  /**
   * 계획일
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 계획유무
   *
   * **data type** varchar(8)
   */
  readonly planflag: string;

  /**
   * 청구일자
   *
   * **data type** varchar(8)
   */
  readonly pumdate: string;

  /**
   * 청구상태
   *
   * **data type** varchar(8)
   */
  readonly pumflag: string;

  /**
   * 청구번호
   *
   * **data type** varchar(8)
   */
  readonly pumnum: string;

  /**
   * 부품내역등록 수량 합계
   *
   * **data type** varchar(8)
   */
  readonly qty_tot2: string;

  /**
   * 확인서 수령여부
   *
   * **data type** varchar(8)
   */
  readonly receflag: string;

  /**
   * 참조자
   *
   * **data type** varchar(8)
   */
  readonly refer: string;

  /**
   * 메일참조
   *
   * **data type** varchar(8)
   */
  readonly refmail: string;

  /**
   * 참조명
   *
   * **data type** varchar(8)
   */
  readonly refnm: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 할인금액
   *
   * **data type** varchar(8)
   */
  readonly saleamt: string;

  /**
   * 할인유무
   *
   * **data type** varchar(8)
   */
  readonly saleflag: string;

  /**
   * 할인율
   *
   * **data type** varchar(8)
   */
  readonly saleyul: string;

  /**
   * 공급가액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 견적항목 공급가액 합계
   *
   * **data type** varchar(8)
   */
  readonly samt_tot: string;

  /**
   * 부품내역등록 공급가액 합계
   *
   * **data type** varchar(8)
   */
  readonly samt_tot2: string;

  /**
   * 사업자코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 상태코드
   *
   * **data type** varchar(8)
   */
  readonly statecd: string;

  /**
   * 공사시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 부가세액
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 부가세구분
   *
   * **data type** varchar(8)
   */
  readonly taxflag: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(8)
   */
  readonly taxyn: string;

  /**
   * 문서번호
   *
   * **data type** varchar(8)
   */
  readonly textnum: string;

  /**
   * 견적금액
   *
   * **data type** varchar(8)
   */
  readonly total: string;

  /**
   * 견적금액
   *
   * **data type** varchar(8)
   */
  readonly total2: string;

  /**
   * 견적금액
   *
   * **data type** varchar(8)
   */
  readonly total3: string;

  /**
   * 자사구분
   *
   * **data type** varchar(8)
   */
  readonly wkactcd: string;

  /**
   * 자사구분명
   *
   * **data type** varchar(8)
   */
  readonly wkactnm: string;

  /**
   * 우편번호
   *
   * **data type** varchar(8)
   */
  readonly zipcode: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly costdivinm: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.addamt_tot = data.addamt_tot || '';
    this.addamt_tot2 = data.addamt_tot2 || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.addyn = data.addyn || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appnum = data.appnum || '';
    this.appperid = data.appperid || '';
    this.appremark = data.appremark || '';
    this.apptime = data.apptime || '';
    this.attcnt = data.attcnt || '';
    this.chk = data.chk || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.compdate = data.compdate || '';
    this.compdivicd = data.compdivicd || '';
    this.compdivinm = data.compdivinm || '';
    this.compflag = data.compflag || '';
    this.compperid = data.compperid || '';
    this.comppernm = data.comppernm || '';
    this.compyn = data.compyn || '';
    this.compyn3 = data.compyn3 || '';
    this.constnm = data.constnm || '';
    this.contgubun = data.contgubun || '';
    this.contgubunnm = data.contgubunnm || '';
    this.costamt_tot = data.costamt_tot || '';
    this.costamt_tot2 = data.costamt_tot2 || '';
    this.costdate = data.costdate || '';
    this.costhp = data.costhp || '';
    this.costnum = data.costnum || '';
    this.costperid = data.costperid || '';
    this.costpernm = data.costpernm || '';
    this.customer = data.customer || '';
    this.enddate = data.enddate || '';
    this.evidyn = data.evidyn || '';
    this.faxnum = data.faxnum || '';
    this.gubun = data.gubun || '';
    this.iamt = data.iamt || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.mamt = data.mamt || '';
    this.nohuseq = data.nohuseq || '';
    this.oldcompyn = data.oldcompyn || '';
    this.perdivinm = data.perdivinm || '';
    this.perfax = data.perfax || '';
    this.perhp = data.perhp || '';
    this.permail = data.permail || '';
    this.pernm = data.pernm || '';
    this.pertel = data.pertel || '';
    this.plandate = data.plandate || '';
    this.planflag = data.planflag || '';
    this.pumdate = data.pumdate || '';
    this.pumflag = data.pumflag || '';
    this.pumnum = data.pumnum || '';
    this.qty_tot2 = data.qty_tot2 || '';
    this.receflag = data.receflag || '';
    this.refer = data.refer || '';
    this.refmail = data.refmail || '';
    this.refnm = data.refnm || '';
    this.remark = data.remark || '';
    this.saleamt = data.saleamt || '';
    this.saleflag = data.saleflag || '';
    this.saleyul = data.saleyul || '';
    this.samt = data.samt || '';
    this.samt_tot = data.samt_tot || '';
    this.samt_tot2 = data.samt_tot2 || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.statecd = data.statecd || '';
    this.stdate = data.stdate || '';
    this.tamt = data.tamt || '';
    this.taxflag = data.taxflag || '';
    this.taxyn = data.taxyn || '';
    this.textnum = data.textnum || '';
    this.total = data.total || '';
    this.total2 = data.total2 || '';
    this.total3 = data.total3 || '';
    this.wkactcd = data.wkactcd || '';
    this.wkactnm = data.wkactnm || '';
    this.zipcode = data.zipcode || '';
    this.costdivinm = data.costdivinm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }

  @computed
  get isReported() {
    return this.appgubun && (this.appgubun !== '' && this.appgubun !== '131' && this.compyn3 === '0');
  }

  @computed
  get isReportedCancelable() {
    return this.isReported && this.appgubun === '001';
  }
}
