import { computed } from 'mobx';

/**
 * 현금내역 모델
 * @window w_tb_ca642w_11
 * @category 일일마감
 */
export class CashModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 지급처명
   *
   * **data type** varchar(100)
   */
  cltnm: string;

  /**
   * 발생일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 발행년월
   *
   * **data type** varchar(8)
   */
  mismon: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  misnum: string;

  /**
   * 적요
   *
   * **data type** char(303)
   */
  remark: string;

  /**
   * 수금액
   *
   * **data type** decimal
   */
  misamt: string;

  /**
   * 지급액
   *
   * **data type** decimal
   */
  mijamt: string;

  /**
   * 잔액
   *
   * **data type** decimal
   */
  resuamt: string;

  /**
   * 합계금액
   *
   * **data type** decimal
   */
  totamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.misdate = data.misdate || '';
    this.mismon = data.mismon || '';
    this.misnum = data.misnum || '';
    this.remark = data.remark || '';
    this.misamt = data.misamt || '';
    this.mijamt = data.mijamt || '';
    this.resuamt = data.resuamt || '';
    this.totamt = data.totamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
