import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { YearPayModel } from './YearPay.model';
import { YearPayTemplate } from './YearPay.template';
import { InfinityRetrieve } from '../../../../models/common';
import { Today } from '../../../../utils/time';
import { GridLayout } from '../../../../components';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';
import MD5 from '../../../../utils/string/MD5';

interface YearPayState {
  // 검색조건
  yearmon: string;
  spjangcd: string;
  offiyn: string;
  rtclafi: string;

  // data
  data: Array<YearPayModel>;
  focused?: YearPayModel;
  img?: string;
  name?: string;

  // modal
  textDetailModal: boolean;

  // trail
  tot_samt01_tot: string;
  tot_tamt01_tot: string;
  tot_mamt01_tot: string;
  tot_samt02_tot: string;
  tot_tamt02_tot: string;
  tot_mamt02_tot: string;
  tot_samt03_tot: string;
  tot_tamt03_tot: string;
  tot_mamt03_tot: string;
  tot_samt04_tot: string;
  tot_tamt04_tot: string;
  tot_mamt04_tot: string;
  tot_samt05_tot: string;
  tot_tamt05_tot: string;
  tot_mamt05_tot: string;
  tot_samt06_tot: string;
  tot_tamt06_tot: string;
  tot_mamt06_tot: string;
  tot_samt07_tot: string;
  tot_tamt07_tot: string;
  tot_mamt07_tot: string;
  tot_samt08_tot: string;
  tot_tamt08_tot: string;
  tot_mamt08_tot: string;
  tot_samt09_tot: string;
  tot_tamt09_tot: string;
  tot_mamt09_tot: string;
  tot_samt10_tot: string;
  tot_tamt10_tot: string;
  tot_mamt10_tot: string;
  tot_samt11_tot: string;
  tot_tamt11_tot: string;
  tot_mamt11_tot: string;
  tot_samt12_tot: string;
  tot_tamt12_tot: string;
  tot_mamt12_tot: string;
  tot_samt_tot: string;
  tot_tamt_tot: string;
  tot_mamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb300_03
 * @category 년급여현황
 */
export class YearPay extends PageComponent<PageProps, YearPayState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  graph?: any;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      // search
      yearmon: Today.yearMon(),
      spjangcd: 'ZZ',
      offiyn: '%',
      rtclafi: '%',

      // data
      data: [],
      name: '',

      // trail
      tot_samt01_tot: '',
      tot_tamt01_tot: '',
      tot_mamt01_tot: '',
      tot_samt02_tot: '',
      tot_tamt02_tot: '',
      tot_mamt02_tot: '',
      tot_samt03_tot: '',
      tot_tamt03_tot: '',
      tot_mamt03_tot: '',
      tot_samt04_tot: '',
      tot_tamt04_tot: '',
      tot_mamt04_tot: '',
      tot_samt05_tot: '',
      tot_tamt05_tot: '',
      tot_mamt05_tot: '',
      tot_samt06_tot: '',
      tot_tamt06_tot: '',
      tot_mamt06_tot: '',
      tot_samt07_tot: '',
      tot_tamt07_tot: '',
      tot_mamt07_tot: '',
      tot_samt08_tot: '',
      tot_tamt08_tot: '',
      tot_mamt08_tot: '',
      tot_samt09_tot: '',
      tot_tamt09_tot: '',
      tot_mamt09_tot: '',
      tot_samt10_tot: '',
      tot_tamt10_tot: '',
      tot_mamt10_tot: '',
      tot_samt11_tot: '',
      tot_tamt11_tot: '',
      tot_mamt11_tot: '',
      tot_samt12_tot: '',
      tot_tamt12_tot: '',
      tot_mamt12_tot: '',
      tot_samt_tot: '',
      tot_tamt_tot: '',
      tot_mamt_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { user } = this.props.publicStore;

    this.onRetrieveEvent();
    this.graph = `https://api.elmansoft.com/chart/money.php?window=money&type=0&as_custcd=${user.custcd}&as_spjangcd=${this.state.spjangcd}&as_perid=${user.perid}`;
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
        spjangcd: this.state.spjangcd,
        offiyn: this.state.offiyn,
        rtclafi: this.state.rtclafi,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new YearPayModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['no', 'perid'],
        [this.state.focused?.no, this.state.focused?.perid], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.grid.current?.setFocus(index);
        this.onRowFocusEvent(this.state.data[0]);
      }

      this.SS({
        tot_samt01_tot: this.infinity?.box?.tot_samt01_tot || '0',
        tot_tamt01_tot: this.infinity?.box?.tot_tamt01_tot || '0',
        tot_mamt01_tot: this.infinity?.box?.tot_mamt01_tot || '0',
        tot_samt02_tot: this.infinity?.box?.tot_samt02_tot || '0',
        tot_tamt02_tot: this.infinity?.box?.tot_tamt02_tot || '0',
        tot_mamt02_tot: this.infinity?.box?.tot_mamt02_tot || '0',
        tot_samt03_tot: this.infinity?.box?.tot_samt03_tot || '0',
        tot_tamt03_tot: this.infinity?.box?.tot_tamt03_tot || '0',
        tot_mamt03_tot: this.infinity?.box?.tot_mamt03_tot || '0',
        tot_samt04_tot: this.infinity?.box?.tot_samt04_tot || '0',
        tot_tamt04_tot: this.infinity?.box?.tot_tamt04_tot || '0',
        tot_mamt04_tot: this.infinity?.box?.tot_mamt04_tot || '0',
        tot_samt05_tot: this.infinity?.box?.tot_samt05_tot || '0',
        tot_tamt05_tot: this.infinity?.box?.tot_tamt05_tot || '0',
        tot_mamt05_tot: this.infinity?.box?.tot_mamt05_tot || '0',
        tot_samt06_tot: this.infinity?.box?.tot_samt06_tot || '0',
        tot_tamt06_tot: this.infinity?.box?.tot_tamt06_tot || '0',
        tot_mamt06_tot: this.infinity?.box?.tot_mamt06_tot || '0',
        tot_samt07_tot: this.infinity?.box?.tot_samt07_tot || '0',
        tot_tamt07_tot: this.infinity?.box?.tot_tamt07_tot || '0',
        tot_mamt07_tot: this.infinity?.box?.tot_mamt07_tot || '0',
        tot_samt08_tot: this.infinity?.box?.tot_samt08_tot || '0',
        tot_tamt08_tot: this.infinity?.box?.tot_tamt08_tot || '0',
        tot_mamt08_tot: this.infinity?.box?.tot_mamt08_tot || '0',
        tot_samt09_tot: this.infinity?.box?.tot_samt09_tot || '0',
        tot_tamt09_tot: this.infinity?.box?.tot_tamt09_tot || '0',
        tot_mamt09_tot: this.infinity?.box?.tot_mamt09_tot || '0',
        tot_samt10_tot: this.infinity?.box?.tot_samt10_tot || '0',
        tot_tamt10_tot: this.infinity?.box?.tot_tamt10_tot || '0',
        tot_mamt10_tot: this.infinity?.box?.tot_mamt10_tot || '0',
        tot_samt11_tot: this.infinity?.box?.tot_samt11_tot || '0',
        tot_tamt11_tot: this.infinity?.box?.tot_tamt11_tot || '0',
        tot_mamt11_tot: this.infinity?.box?.tot_mamt11_tot || '0',
        tot_samt12_tot: this.infinity?.box?.tot_samt12_tot || '0',
        tot_tamt12_tot: this.infinity?.box?.tot_tamt12_tot || '0',
        tot_mamt12_tot: this.infinity?.box?.tot_mamt12_tot || '0',
        tot_samt_tot: this.infinity?.box?.tot_samt_tot || '0',
        tot_tamt_tot: this.infinity?.box?.tot_tamt_tot || '0',
        tot_mamt_tot: this.infinity?.box?.tot_mamt_tot || '0',
      });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stmon: this.state.yearmon,
      spjangcd: this.state.spjangcd,
      offiyn: this.state.offiyn,
      rtclafi: this.state.rtclafi,
    });
  }

  @action
  onRowFocusEvent(item: YearPayModel) {
    const { user } = this.props.publicStore;

    this.setState({ focused: item });
    this.graph = `https://api.elmansoft.com/chart/money.php?window=money&type=0&as_custcd=${user.custcd}&as_spjangcd=${this.state.spjangcd}&as_perid=${item?.perid}`;
  }

  async imageRequest(date: any) {
    const { actionStore: api } = this.props;

    if (!this.state.focused?.mijflag || this.state.focused?.mijflag === '0') {
      ConfirmWarning.show('오류', `${this.state.focused?.year}년 ${date}월 ${this.state.focused?.pernm}님은 매입,지급된 내역이 없습니다`);
      return;
    }

    const file = await api.fxFile(
      'tb_pic_retrieve',
      {
        stmon: this.state.focused?.year + date,
        perid: this.state.focused?.perid,
        src: MD5.make(`${this.state.focused?.year + date}${this.state.focused?.perid}`),
      },
    );

    if (file.size < 1) {
      return;
    }

    this.setState({
      img: `data:application/pdf;base64,${file.data}`,
    });
    this.textModal(true);
  }

  // grid 색상
  getColotEvent(rtdate: any, year: any, date: any) {
    if (rtdate && rtdate.slice(0, 6) === year + date) {
      return { backgroundColor: '#FFEAEA' };
    } if (rtdate && rtdate.slice(0, 6) < year + date) {
      return { backgroundColor: '' };
    }
    return { backgroundColor: '#E6FFFF' };
  }

  @action
  onFocusEvent() {
    this.state.data?.forEach((x: any, n) => {
      if (x.pernm.indexOf(this.state.name) !== -1) {
        this.grid.current?.setFocus(n);
      }
    });
  }

  @action
  textModal(isOpen: boolean) {
    this.setState({ textDetailModal: isOpen });
  }

  render() {
    return (
      <YearPayTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
