/**
 * 모델
 * @window w_tb_e451
 * @category 견적서등록
 */
export default class EnrollmentSelfInspectModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly actmail: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly actperhp: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly actpernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly addamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly chk: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly costamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly elno: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly evcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly evcd_gubun: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly kcperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly kcperid2: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly kcpernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly kcpernm2: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly myn: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pcode: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly planmon: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pname: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly psize: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly punit: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly remark2: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly resultcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly resultnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly rptdate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly uamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly wkactcd: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actmail = data.actmail || '';
    this.actnm = data.actnm || '';
    this.actperhp = data.actperhp || '';
    this.actpernm = data.actpernm || '';
    this.addamt = data.addamt || '';
    this.chk = data.chk || '';
    this.cltcd = data.cltcd || '';
    this.costamt = data.costamt || '';
    this.elno = data.elno || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.evcd = data.evcd || '';
    this.evcd_gubun = data.evcd_gubun || '';
    this.kcperid = data.kcperid || '';
    this.kcperid2 = data.kcperid2 || '';
    this.kcpernm = data.kcpernm || '';
    this.kcpernm2 = data.kcpernm2 || '';
    this.myn = data.myn || '';
    this.pcode = data.pcode || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.plandate = data.plandate || '';
    this.planmon = data.planmon || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.remark = data.remark || '';
    this.remark2 = data.remark2 || '';
    this.resultcd = data.resultcd || '';
    this.resultnm = data.resultnm || '';
    this.rptdate = data.rptdate || '';
    this.samt = data.samt || '';
    this.seq = data.seq || '';
    this.state = data.state || '';
    this.uamt = data.uamt || '';
    this.wkactcd = data.wkactcd || '';
  }
}
