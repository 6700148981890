import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { StaffScoreTemplate } from './StaffScore.template';
import { StaffScoreModel } from './StaffScore.model';
import { AcccdItem, InfinityRetrieve, SpjangItem } from '../../../../models';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { PageComponent } from '../../../../utils';

interface StaffScoreState {
  searchQuery: string;
  spjangcd: string;
  rtclafi: string;
  data: Array<StaffScoreModel>;
  banks?: Array<any>;
  acccds?: Array<AcccdItem>;
  spjangcds?: Array<SpjangItem>;
  focusedData?: StaffScoreModel;
}

/**
 * 컨트롤러
 * @window w_tb_ja001_talent
 * @category 직원점수권한
 */
export class StaffScore extends PageComponent<PageProps, StaffScoreState>
  implements PageToolEvents {
  updatedRows?: Array<StaffScoreModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      spjangcd: '%',
      data: [],
    };
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 세번째 서버 요청
    // 사업장 리스트 가져오기
    const data = await api.dropdown('wf_dd_spjangcd_02');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ spjangcds: data.items });

    // 선행되어야 하는 서버 호출이 모두 성공한 경우 [조회]
    await this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      spjangcd: this.state.focusedData?.spjangcd,
      items: this.updatedRows?.map((x) => ({
        new: x.new || '0',
        perid: x.perid || this.state.focusedData?.perid,
        talent: x.talent || this.state.focusedData?.talent,
        // seq: `${y + 1}`,
      })),
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        spjangcd: this.state.spjangcd,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new StaffScoreModel(x))],
        });
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
        this.table.current?.update(false);
        this.table.current?.setFocus(0, 1);
      },
    );

    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.table.current?.update(false);
      this.table.current?.setFocus(0, 1);
    });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  onRowFocusEvent(item: StaffScoreModel) {
    this.setState({ focusedData: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<StaffScoreModel>, updatedRows: Array<StaffScoreModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return <StaffScoreTemplate scope={this} />;
  }
}
