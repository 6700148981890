import { Global, TLHF } from '../../../../../constants';
import {
  CheckBox, FlexLayout,
} from '../../../../../components';
import { Enrollment } from '../Enrollment';
import { Format } from '../../../../../utils/string';
import { Date8 } from '../../../../../utils/time';
import {
  Gubuns,
} from '../../../estimate/Status/Status';


const Contgubuns = [
  { value: '01', remark: 'FM계약' },
  { value: '02', remark: '다년계약' },
  { value: '03', remark: '일반계약' },
  { value: '04', remark: '상주' },
  { value: '05', remark: '외주' },
];

const Compgubuns = [
  { value: '0', remark: '신규' },
  { value: '1', remark: '공사' },
];


/**
 * 화면 > 테이블 레이아웃 A
 * @window w_tb_e471
 * @category 공사등록
 */
export const EnrollmentEstimateTableHeader: TLHF<any, Enrollment> = () => ([
  {
    id: 'chk',
    text: '삭제',
    width: 50,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.chk === '1'}
        onChange={(v) => rowUpdate({ chk: v ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'compgubun',
    text: '공사구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.compgubun === '0' ? { color: 'var(--color-blue)' } : ''}
      >
        {Compgubuns.filter((y) => y.value === x.compgubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'costdate',
    text: '견적일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.costdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'costnum',
    text: '번호',
    width: 70,
  },
  {
    id: 'gubun',
    text: '공사구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'contgubun',
    text: '계약구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
      >
        {Contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'constnm',
    text: '견적서명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.constnm}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.tamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'mamt',
    text: '합계금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'saleamt',
    text: '할인금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.saleamt)}
      </FlexLayout>
    ),
  },
  {
    id: 's1flag',
    text: '견적담당',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.s1flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.s1flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 'ks1flag',
    text: '견적고객',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.ks1flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.ks1flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 's2flag',
    text: '부품담당',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.s2flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.s2flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 'ks2flag',
    text: '부품고객',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.ks2flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.ks2flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 's3flag',
    text: '공사담당',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.s3flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.s3flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 'ks3flag',
    text: '공사고객',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.ks3flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.ks3flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 'ks3flag',
    text: '공사고객',
    width: 70,
    render: (x) => (
        <FlexLayout
            align="center"
            justify="center"
            style={x.ks3flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
        >
          {x.ks3flag === '1' ? 'O' : 'X'}
        </FlexLayout>
    ),
  },
  {
    id: 'ks1s1',
    text: (
        <FlexLayout isVertical={true}>
          <FlexLayout>견적</FlexLayout>
          <FlexLayout>
            <FlexLayout>담당</FlexLayout>
            <FlexLayout>고객</FlexLayout>
          </FlexLayout>
        </FlexLayout>
    ),
    width: 160,
    render: (x) => (
      <FlexLayout>
        <FlexLayout
          justify="center"
          align="center"
        >
          {x.ks1indate}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          {x.s1indate}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'ks2s2',
    text: (
        <FlexLayout isVertical={true}>
          <FlexLayout>부품</FlexLayout>
          <FlexLayout>
            <FlexLayout>담당</FlexLayout>
            <FlexLayout>고객</FlexLayout>
          </FlexLayout>
        </FlexLayout>
    ),
    width: 160,
    render: (x) => (
        <FlexLayout>
          <FlexLayout
              justify="center"
              align="center"
          >
            {x.ks2indate}
          </FlexLayout>

          <FlexLayout
              justify="center"
              align="center"
          >
            {x.s2indate}
          </FlexLayout>
        </FlexLayout>
    ),
  },
  {
    id: 'ks3s3',
    text: (
        <FlexLayout isVertical={true}>
          <FlexLayout>공사</FlexLayout>
          <FlexLayout>
            <FlexLayout>담당</FlexLayout>
            <FlexLayout>고객</FlexLayout>
          </FlexLayout>
        </FlexLayout>
    ),
    width: 160,
    render: (x) => (
        <FlexLayout>
          <FlexLayout
              justify="center"
              align="center"
          >
            {x.ks3indate}
          </FlexLayout>

          <FlexLayout
              justify="center"
              align="center"
          >
            {x.s3indate}
          </FlexLayout>
        </FlexLayout>
    ),
  },
]);
