import FloorPlanModel from '../models/FloorPlanModel';
import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { FloorPlan } from '../FloorPlan';

/**
 * 화면 > 그리드 레이아웃
 * @window w_tb_e601_dp_01
 * @category 설치현장도면
 */

// 계약구분
export const States = [
  { key: '0', value: '보수' },
  { key: '1', value: '설치' },
  { key: '2', value: '보수' },
  { key: '3', value: '설치종료' },
];
export const FloorPlanGridHeader: GLHF<FloorPlanModel, FloorPlan> = () => ([
  {
    id: 'seq',
    text: '구분',
    width: 15,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.state === '2' && '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {States.filter((y) => y.key === x.state)[0]?.value}
      </FlexLayout>
    ),
  },
  {
    id: 'scactnm',
    text: '설치현장명',
    width: 55,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.scactnm || x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 15,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'cnt',
    text: '파일수',
    width: 15,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.cnt}
      </FlexLayout>
    ),
  },
]);
