import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Contract } from './Contract';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { Format } from '../../../../utils/string';

const contgubuns = [
  { value: '01', remark: 'FM계약' },
  { value: '02', remark: '다년계약' },
  { value: '03', remark: '일반계약' },
  { value: '04', remark: '상주' },
  { value: '05', remark: '외주' },
];

const contgs = [
  { value: '01', remark: '신규' },
  { value: '02', remark: '연장' },
  { value: '03', remark: '재계약' },
  { value: '04', remark: '해지' },
  { value: '05', remark: '회수' },
  { value: '06', remark: '타사' },
];

const wkactcds = [
  { value: '001', remark: '자사' },
  { value: '002', remark: '현대' },
  { value: '003', remark: '오티스' },
  { value: '004', remark: '티센' },
  { value: '005', remark: '미쓰비시' },
];

/**
 * 화면
 * @window w_tb_e101_monend
 * @category 계약월마감
 */
export const ContractTemplate: React.FC<TemplateProps<Contract>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>월</span>
            <DateTextBox
              value={scope.state.yearmon}
              textAlign="center"
              format="####년 ##월"
              onChange={(value) => {
                setData({ yearmon: value },
                  () => scope.onRetrieveEvent());
              }}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout/>
          <Button onClick={() => scope.onBtnEvent()}>
            <div style={{ width: 80 }}>{!scope.state.data.length ? '마감' : '취소'}</div>
          </Button>
        </SearchLayout>
      </FlexLayout>

      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'wkactcd',
            text: '회사구분',
            width: 7,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합계
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                align='center'
                justify='center'
              >
                {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'actcd',
            text: '코드',
            width: 7,
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 24,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.actnm}
              </FlexLayout>
            ),
          },
          {
            id: 'contdate',
            text: '계약일자',
            width: 7,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.contdate)}
              </FlexLayout>
            ),
          },
          {
            id: 'stdate',
            text: '시작일자',
            width: 7,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.stdate)}
              </FlexLayout>
            ),
          },
          {
            id: 'enddate',
            text: '만료일자',
            width: 7,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.enddate)}
              </FlexLayout>
            ),
          },
          {
            id: 'moncnt',
            text: '개월',
            width: 5,
          },
          {
            id: 'qty',
            text: '대수',
            width: 5,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.qty_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'contgubun',
            text: '계약조건',
            width: 7,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'amt',
            text: '금액',
            width: 7,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.amt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.amt)}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '담당',
            width: 7,
          },
          {
            id: 'contg',
            text: '계약분류',
            width: 9,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {contgs.filter((y) => y.value === x.contg)[0]?.remark}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      />
    </FlexLayout>
  );
};
