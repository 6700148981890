import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Report } from './Report';
import { TabModel } from '../../../../models';
import { ReportTabTemplate } from './tabs/ReportTab.template';

/**
 * 화면 > 탭
 * @window w_tb_b035
 * @category 샘플페이지
 */
export const ReportTab: Array<TabLayoutDataItem<Report>> = [
  {
    tab: new TabModel('REPORT', FaCodeBranch, '작업내용'),
    template: (scope) => <ReportTabTemplate scope={scope} />,
  },
];
