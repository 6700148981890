import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Button } from '../../components/forms/Button';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { Global } from '../../constants';
import { GridLayout } from '../../components/layout/GridLayout';
import { SubHeader } from '../../components/layout/SubHeader';
import { Date8 } from '../../utils/time';

interface ModalNotifyProps {
  modalStore: ModalStore;
}

@observer
export class ModalNotify extends React.Component<ModalNotifyProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={
          (modalStore.individualSelected?.gubun !== '5'
            && modalStore.individualSelected?.gubun !== '8'
            && modalStore.individualSelected?.gubun !== '9'
            && modalStore.individualSelected?.gubun !== '10'
            && modalStore.individualSelected?.gubun !== '11'
            && modalStore.individualSelected?.gubun !== '16'
            && modalStore.individualSelected?.gubun !== '39')
            ? 600 : 1200
      }
        height={600}
        isVisible={modalStore.isVisibleIndividualSelector}
        onClose={() => modalStore.closeIndividualSelector()}
      >
        <FlexLayout style={{ padding: 4 }}>
          <FlexLayout isVertical={true}>
            <LayoutTitle>알림</LayoutTitle>
            <GridLayout
              header={[
                {
                  id: 'spjangnm',
                  text: '사업자명',
                  width: 40,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.spjangnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'gubunnm',
                  text: '구분',
                  width: 40,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.gubunnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'cnt',
                  text: '건수',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <div style={{ color: 'var(--color-red)' }}>{x.cnt}</div>
                      <div>건</div>
                    </FlexLayout>
                  ),
                },
              ]}
              data={modalStore?.individualData || []}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowDoubleClick={() => modalStore.onSaveEvent()}
              onRowClick={(item) => modalStore.onIndividualRowFocusEvent(item)}
            />

            <SubHeader>
              <FlexLayout justify="end">
                <Button onClick={() => modalStore.onSaveEvent()}>확인</Button>
                <Button onClick={() => modalStore.closeIndividualSelector()}>닫기</Button>
              </FlexLayout>
            </SubHeader>
          </FlexLayout>

          {
            (modalStore.individualSelected?.gubun !== '5'
              && modalStore.individualSelected?.gubun !== '8'
              && modalStore.individualSelected?.gubun !== '9'
              && modalStore.individualSelected?.gubun !== '10'
              && modalStore.individualSelected?.gubun !== '11'
              && modalStore.individualSelected?.gubun !== '16'
              && modalStore.individualSelected?.gubun !== '39')
              ? ''
              : <FlexLayout isVertical={true}>
              <LayoutTitle></LayoutTitle>
              {
                modalStore.individualSelected?.gubun === '5'
                  ? <GridLayout
                    header={[
                      {
                        id: 'actnm',
                        text: '현장명',
                        width: 40,
                        render: (x) => (
                          <FlexLayout
                            justify="left"
                            align="center"
                          >
                            {x.actnm}
                          </FlexLayout>
                        ),
                      },
                      {
                        id: 'equpnm',
                        text: '호기명',
                        width: 20,
                        render: (x) => (
                          <FlexLayout
                            justify="center"
                            align="center"
                          >
                            {x.equpnm}
                          </FlexLayout>
                        ),
                      },
                      {
                        id: 'contcd',
                        text: '고장내용',
                        width: 40,
                        render: (x) => (
                          <FlexLayout
                            justify="left"
                            align="center"
                          >
                            {x.contcd}
                          </FlexLayout>
                        ),
                      },
                    ]}
                    data={modalStore?.individualDetailData || []}
                    rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                    onRowDoubleClick={() => modalStore.onSaveEvent()}
                    onRowClick={(item) => modalStore.onIndividualRowFocusEvent2(item)}
                  />
                  : modalStore.individualSelected?.gubun === '8' || modalStore.individualSelected?.gubun === '9'
                    ? <GridLayout
                      header={[
                        {
                          id: 'actnm',
                          text: '현장명',
                          width: 60,
                          render: (x) => (
                            <FlexLayout
                              justify="left"
                              align="center"
                            >
                              {x.actnm}
                            </FlexLayout>
                          ),
                        },
                        {
                          id: 'equpnm',
                          text: '호기명',
                          width: 20,
                          render: (x) => (
                            <FlexLayout
                              justify="center"
                              align="center"
                            >
                              {x.equpnm}
                            </FlexLayout>
                          ),
                        },
                        {
                          id: 'kcdate',
                          text: '점검일자',
                          width: 20,
                          render: (x) => (
                            <FlexLayout
                              justify="center"
                              align="center"
                            >
                              {Date8.withDash(x.kcdate)}
                            </FlexLayout>
                          ),
                        },
                      ]}
                      data={modalStore?.individualDetailData || []}
                      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                      onRowDoubleClick={() => modalStore.onSaveEvent()}
                      onRowClick={(item) => modalStore.onIndividualRowFocusEvent2(item)}
                    />
                    : modalStore.individualSelected?.gubun === '10'
                      ? <GridLayout
                        header={[
                          {
                            id: 'actnm',
                            text: '현장명',
                            width: 60,
                            render: (x) => (
                              <FlexLayout
                                justify="left"
                                align="center"
                              >
                                {x.actnm}
                              </FlexLayout>
                            ),
                          },
                          {
                            id: 'equpnm',
                            text: '호기명',
                            width: 20,
                            render: (x) => (
                              <FlexLayout
                                justify="center"
                                align="center"
                              >
                                {x.equpnm}
                              </FlexLayout>
                            ),
                          },
                          {
                            id: 'plandate',
                            text: '점검계획일',
                            width: 20,
                            render: (x) => (
                              <FlexLayout
                                justify="center"
                                align="center"
                              >
                                {Date8.withDash(x.plandate)}
                              </FlexLayout>
                            ),
                          },
                        ]}
                        data={modalStore?.individualDetailData || []}
                        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                        onRowDoubleClick={() => modalStore.onSaveEvent()}
                        onRowClick={(item) => modalStore.onIndividualRowFocusEvent2(item)}
                      />
                      : modalStore.individualSelected?.gubun === '11'
                        ? <GridLayout
                          header={[
                            {
                              id: 'actnm',
                              text: '현장명',
                              width: 40,
                              render: (x) => (
                                <FlexLayout
                                  justify="left"
                                  align="center"
                                >
                                  {x.actnm}
                                </FlexLayout>
                              ),
                            },
                            {
                              id: 'equpnm',
                              text: '호기명',
                              width: 20,
                              render: (x) => (
                                <FlexLayout
                                  justify="center"
                                  align="center"
                                >
                                  {x.equpnm}
                                </FlexLayout>
                              ),
                            },
                            {
                              id: 'error_message',
                              text: '전송오류메시지',
                              width: 40,
                              render: (x) => (
                                <FlexLayout
                                  justify="left"
                                  align="center"
                                  style={{ color: 'var(--color-red)' }}
                                >
                                  {x.error_message}
                                </FlexLayout>
                              ),
                            },
                          ]}
                          data={modalStore?.individualDetailData || []}
                          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                          onRowDoubleClick={() => modalStore.onSaveEvent()}
                          onRowClick={(item) => modalStore.onIndividualRowFocusEvent2(item)}
                        />
                        : modalStore.individualSelected?.gubun === '16'
                          ? <GridLayout
                            header={[
                              {
                                id: 'actnm',
                                text: '해지된 현장명',
                                width: 35,
                                render: (x) => (
                                  <FlexLayout
                                    justify="left"
                                    align="center"
                                  >
                                    {x.actnm}
                                  </FlexLayout>
                                ),
                              },
                              {
                                id: 'cltnm',
                                text: '거래처명',
                                width: 35,
                                render: (x) => (
                                  <FlexLayout
                                    justify="left"
                                    align="center"
                                  >
                                    {x.cltnm}
                                  </FlexLayout>
                                ),
                              },
                              {
                                id: 'autonum',
                                text: '자동이체번호',
                                width: 30,
                                render: (x) => (
                                  <FlexLayout
                                    justify="left"
                                    align="center"
                                  >
                                    {x.autonum}
                                  </FlexLayout>
                                ),
                              },
                            ]}
                            data={modalStore?.individualDetailData || []}
                            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                            onRowDoubleClick={() => modalStore.onSaveEvent()}
                            onRowClick={(item) => modalStore.onIndividualRowFocusEvent2(item)}
                          />
                          : <GridLayout
                            header={[
                              {
                                id: 'actnm',
                                text: '설치현장명',
                                width: 60,
                                render: (x) => (
                                  <FlexLayout
                                    justify="left"
                                    align="center"
                                  >
                                    {x.actnm}
                                  </FlexLayout>
                                ),
                              },
                              {
                                id: 'haja_stdate',
                                text: '시작일자',
                                width: 20,
                                render: (x) => (
                                  <FlexLayout
                                    justify="center"
                                    align="center"
                                  >
                                    {Date8.withDash(x.haja_stdate)}
                                  </FlexLayout>
                                ),
                              },
                              {
                                id: 'haja_enddate',
                                text: '종료일자',
                                width: 20,
                                render: (x) => (
                                  <FlexLayout
                                    justify="center"
                                    align="center"
                                  >
                                    {Date8.withDash(x.haja_enddate)}
                                  </FlexLayout>
                                ),
                              },
                            ]}
                            data={modalStore?.individualDetailData || []}
                            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                            onRowDoubleClick={() => modalStore.onSaveEvent()}
                            onRowClick={(item) => modalStore.onIndividualRowFocusEvent2(item)}
                          />
              }
              <SubHeader>
              </SubHeader>
            </FlexLayout>
          }
        </FlexLayout>
      </ModalView>
    );
  }
}
