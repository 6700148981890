import { computed } from 'mobx';

/**
 * 모델
 * @window w_p3181
 * @category 사원명부조회
 */
export class StaffListModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 사원번호
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   * 직급
   *
   * **data type** varchar(20)
   */
  readonly rspnm: string;

  /**
   * 주민번호
   *
   * **data type** varchar(13)
   */
  readonly rnum: string;

  /**
   * 명함발매
   *
   * **data type** varchar(1)
   */
  readonly cardflag: string;

  /**
   * 최종학력
   *
   * **data type** varchar(30)
   */
  readonly schnm: string;

  /**
   * 입사일
   *
   * **data type** char(8)
   */
  readonly entdate: string;

  /**
   * 퇴사일
   *
   * **data type** varchar(8)
   */
  readonly rtdate: string;

  /**
   * 근속년수
   *
   * **data type** long
   */
  readonly dayterm: string;

  /**
   * 진급년수
   *
   * **data type** long
   */
  readonly promoteterm2: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly dayterm2: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly DAYTERM: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly DAYTERM2: string;

  /**
   * 전화번호
   *
   * **data type** varchar(27)
   */
  readonly tel: string;

  /**
   * 핸드폰번호
   *
   * **data type** varchar(20)
   */
  readonly handphone: string;

  /**
   * 은행
   *
   * **data type** varchar(10)
   */
  readonly banknm: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(50)
   */
  readonly accnum: string;

  /**
   * 나이
   *
   * **data type** long
   */
  readonly agegap: string;

  /**
   * 재/퇴직
   *
   * **data type** varchar(3)
   */
  readonly rtclafi: string;

  /**
   * 모름
   *
   * **data type** varchar(90)
   */
  readonly zipadres: string;

  /**
   * 모름
   *
   * **data type** varchar(90)
   */
  readonly adres: string;

  /**
   * 위도
   *
   * **data type** varchar(16)
   */
  readonly lat: string;

  /**
   * 경도
   *
   * **data type** varchar(16)
   */
  readonly lng: string;

  /**
   * 전공
   *
   * **data type** varchar(30)
   */
  readonly majo: string;

  /**
   * 자격증
   *
   * **data type** char(1)
   */
  readonly licence: string;

  /**
   * 이메일
   *
   * **data type** varchar(30)
   */
  readonly email: string;

  /**
   * 순번
   *
   * **data type** string
   */
  readonly seq: string;

  /**
   * 상의(Size)
   *
   * **data type** string
   */
  readonly springsize: string;

  /**
   * 하의(Size)
   *
   * **data type** string
   */
  readonly summersize: string;

  /**
   * 신발(Size)
   *
   * **data type** string
   */
  readonly footsize: string;

  /**
   * data new 를 위함
   */
  readonly new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.rspnm = data.rspnm || '';
    this.rnum = data.rnum || '';
    this.cardflag = data.cardflag || '';
    this.schnm = data.schnm || '';
    this.entdate = data.entdate || '';
    this.rtdate = data.rtdate || '';
    this.dayterm = data.dayterm || '';
    this.dayterm2 = data.dayterm2 || '';
    this.DAYTERM = data.DAYTERM || '';
    this.DAYTERM2 = data.DAYTERM2 || '';
    this.tel = data.tel || '';
    this.handphone = data.handphone || '';
    this.banknm = data.banknm || '';
    this.accnum = data.accnum || '';
    this.agegap = data.agegap || '';
    this.rtclafi = data.rtclafi || '';
    this.zipadres = data.zipadres || '';
    this.adres = data.adres || '';
    this.lat = data.lat || '';
    this.lng = data.lng || '';
    this.email = data.email || '';
    this.majo = data.majo || '';
    this.licence = data.licence || '';
    this.email = data.email || '';
    this.springsize = data.springsize || '';
    this.summersize = data.summersize || '';
    this.footsize = data.footsize || '';
    this.seq = data.seq || '';
    this.promoteterm2 = data.promoteterm2 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
