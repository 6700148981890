import * as React from 'react';
import { IFrame } from '../../../components/forms/IFrame';
import { PageProps } from '../../../constants';

export class Dash extends React.Component<PageProps> {
  componentDidMount(): void {
    this.props.publicStore.setCurrentPage(this);
  }

  render() {
    const { user } = this.props.publicStore;
    const url = `https://api.elmansoft.com/erpdash/?utf8=1&custcd=${user.custcd}&spjangcd=${user.spjangcd}&pernm=${user.pernm}&perid=${user.perid}&divicd=${user.divicd}&divinm=${user.divinm}&theme=${user.theme}&reader=0&kukcd=${user.kukcd}`;

    return <IFrame src={url} />;
  }
}
