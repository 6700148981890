import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  FlexLayout,
  TextBox,
  TableLayout,
  SearchLayout,
  DateTextBox,
  FormatTextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { DailyCheck } from './DailyCheck';
import { Date8 } from '../../../../utils/time';
import { SearchBinding } from '../../../../models';

/**
 * 화면
 * @window w_tb_e034
 * @category 일상점검등록
 */
export const DailyCheckTemplate: React.FC<TemplateProps<DailyCheck>> = ({
  scope,
  // update,
}) =>
// const setData = update!;

  // eslint-disable-next-line implicit-arrow-linebreak
  (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={250}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={225}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

      </SearchLayout>

      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'kclog',
              text: '일지',
              width: 80,
            },
            {
              id: 'plandate',
              text: '점검일자',
              width: 80,
              render: (x, rowUpdate, ref) => (
                <DateTextBox
                  format="####-##-##"
                  textAlign="center"
                  transparent={true}
                  ref={ref}
                  value={x.plandate}
                  onChange={(value) => rowUpdate({ plandate: value })}/>
              ),
            },
            {
              id: 'actcd',
              text: '현장코드',
              width: 80,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.actcd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ actcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_E601_1',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        actcd: item.cd,
                        actnm: item.cdnm,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 270,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.actnm}
                  textAlign="left"
                  onChange={(value) => rowUpdate({ actnm: value })}
                />
              ),
            },
            {
              id: 'equpcd',
              text: '호기코드',
              color: 'var(--color-red)',
              width: 70,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.equpcd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ equpcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_E611',
                    { uv_arg1: x.actcd },
                    true,
                    (item) => {
                      rowUpdate({
                        equpcd: item.cd,
                        equpnm: item.cdnm,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  textAlign="center"
                  value={x.equpnm}
                  onChange={(value) => rowUpdate({ equpnm: value })}
                />
              ),
            },
            {
              id: 'evcd',
              text: '점검기종',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {scope.state.evcds.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                </FlexLayout>
              ),
            },
            {
              id: 'sttime',
              text: '시작시간',
              width: 70,
              render: (x, rowUpdate, ref) => (
                <FormatTextBox
                  ref={ref}
                  format="##:##"
                  textAlign="center"
                  value={x.sttime}
                  onChange={(value) => rowUpdate({ sttime: value })}/>
              ),
            },
            {
              id: 'endtime',
              text: '종료시간',
              width: 70,
              render: (x, rowUpdate, ref) => (
                <FormatTextBox
                  format="##:##"
                  textAlign="center"
                  ref={ref}
                  value={x.endtime}
                  onChange={(value) => rowUpdate({ endtime: value })}/>
              ),
            },
            {
              id: 'perid',
              text: '처리자',
              width: 80,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  textAlign="center"
                  value={x.perid}
                  onChange={(value) => rowUpdate({ perid: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        perid: item.cd,
                        pernm: item.cdnm,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'pernm',
              text: '처리자명',
              width: 80,
              render: (x) => (
                <TextBox
                  textAlign="center"
                  value={x.pernm}
                />
              ),
            },
            {
              id: 'remark',
              text: '특이사항',
              width: 300,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.remark}
                  textAlign="left"
                  onChange={(value) => rowUpdate({ remark: value })}
                />
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows, updatedRows) => scope.onUpdatedRows(rows, updatedRows)}
          infinityHandler={scope.infinity}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          data={scope.state.data}
        />
      </FlexLayout>
    </FlexLayout>
  );

