import { GLHF, KTNET_STATES } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Collect } from '../Collect';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 탭 > 수금내역 - 그리드 레이아웃
 * @window w_tb_e601w_sulchi_02
 * @category 설치수금현황
 */
export const CollectTabCollectGridHeader: GLHF<any, Collect> = (scope) => ([
  {
    id: 'misdate',
    text: '매출일자',
    width: 100,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.misdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'misnum',
    text: '번호',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.misnum}
      </FlexLayout>
    ),
  },
  {
    id: 'seq',
    text: '순번',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.seq}
      </FlexLayout>
    ),
  },
  {
    id: 'acccd',
    text: '계정과목',
    width: 100,
    render: (x) => {
      const fp = scope.acccds.filter((y) => y.acccd === x.acccd);
      const nm = fp.length > 0 ? fp[0].accnm : '';

      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'rcvdate',
    text: '수금일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.rcvdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvnum',
    text: '번호',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.rcvnum}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '적요',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '전자세금계산서',
    width: 120,
    render: (x) => {
      const fp = KTNET_STATES.filter((y) => y.value === (x.recivstatus?.trim() || ''));
      const nm = fp.length > 0 ? fp[0].remark : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{
            color,
          }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'misamt',
    text: '미수금액',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.all(scope.state.dataCollect, 'misamt'))}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.misamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '수금액',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.all(scope.state.dataCollect, 'rcvamt'))}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.rcvamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt',
    text: '잔액',
    width: 120,
    render: (x) => {
      const amt = (parseInt(x.misamt, 10) || 0) - (parseInt(x.rcvamt, 10) || 0);
      return (
        <FlexLayout
          align="center"
          justify="end"
        >
          {Format.number(amt) || ''}
        </FlexLayout>
      );
    },
  },
]);
