import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox,
  ComboBox,
  ComboBoxModel,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { BuildingUse } from './BuildingUse';


/**
 * 화면
 * @window w_tb_e029
 * @category 건물용도 등록
 */
export const BuildingUseTemplate: React.FC<TemplateProps<BuildingUse>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        { id: 'buildcd', text: '코드', width: 20 },
        {
          id: 'buildnm',
          text: '건물용도명',
          width: 80,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.buildnm}
              textAlign="left"
              onChange={(value) => rowUpdate({ buildnm: value })}
            />
          ),
        },
        {
          id: 'contbild',
          text: '유지관리비별 구분',
          width: 50,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.contbild}
              textAlign="left"
              style={{
                padding: '0 0 0 6px',
                fontSize: 12,
              }}
              data={scope.state.contbilds?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
              onSelect={(option) => rowUpdate({ contbild: option.value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 15,
          render: (x, rowUpdate) => (
            <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 100,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="left"
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      data={scope.state.data}
    />
  </FlexLayout>
);
