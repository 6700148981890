import { CheckBox, FlexLayout } from '../../../../../components';
import { TLHF } from '../../../../../constants';
import { Gubun, Status } from '../Status';

/**
 * 화면 - 좌측 체크 리스트
 * @window w_preview_da006
 * @category 수주현황
 */
export const StatusContentTableHeader: TLHF<any, Status> = (scope) => ([
  {
    id: 'code',
    text: '매출구분',
    width: 70,
    render: (x) => {
      const fd = Gubun.filter((z) => z.code === x.code);
      const gubunnm = fd.length > 0 ? fd[0].remark : '';

      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          {gubunnm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'checked',
    text: (
      <FlexLayout
        justify="center"
        align="center"
      >
        <CheckBox
          noMargin
          value={scope.state.allChecked}
          onChange={() => scope.toggleAllChecked()}
        />
      </FlexLayout>
    ),
    width: 30,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.chk === '1'}
        onChange={(v) => rowUpdate({ chk: v ? '1' : '0' })}
      />
    ),
  },
]);
