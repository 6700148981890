import * as React from 'react';
import { observer } from 'mobx-react';
import Scrollbars from 'react-custom-scrollbars';
import {
  AiOutlineMenuUnfold,
  AiTwotoneStar,
} from 'react-icons/ai';
import style from './SideMenu.module.scss';
import {
  FavoriteMenuStore,
  HeaderTabStore,
  PublicStore,
  RouterStore,
} from '../../../stores';
import { SideMenuItem } from './SideMenu.item';
import { Button } from '../../forms';
import { FlexLayout } from '../../layout';

interface SideMenuActions {
  // editTodo: (id: number, data: Partial<HeaderTopModel>) => any;
  // deleteTodo: (id: number) => any;
}

interface SideMenuProps extends SideMenuActions {
  publicStore: PublicStore;
  routerStore: RouterStore;
  headerTabStore: HeaderTabStore;
  favoriteMenuStore: FavoriteMenuStore;
}

@observer
export class SideMenu extends React.Component<SideMenuProps> {
  componentDidMount() {
    setTimeout(() => this.props.favoriteMenuStore.retrieve(), 1000);
  }

  openFavorite() {
    this.props.publicStore.withLoading(async () => {
      this.props.publicStore.openFavoriteMenu();
      await this.props.favoriteMenuStore.retrieve();
    });
  }

  render() {
    const { publicStore, favoriteMenuStore } = this.props;
    const { currentMenu, isFavoriteMenuVisible } = publicStore;

    const childComponents = currentMenu.primary.children?.map((item) => (
      <SideMenuItem
        key={item.id}
        menu={item}
        onSelect={(x) => publicStore.go(x)}
      />
    ));

    const favoriteChildComponents = favoriteMenuStore.menus.map((item) => (
      <SideMenuItem
        key={item.id}
        menu={item}
        onSelect={(x) => publicStore.go(x)}
        isFavorite={true}
      />
    ));

    return (
      <div className={style.container}>
        <div className={style.title}>
          {isFavoriteMenuVisible ? <AiTwotoneStar /> : <currentMenu.primary.icon />}
          <p>{isFavoriteMenuVisible ? '북마크' : currentMenu.primary.text}</p>
        </div>

        <Scrollbars
          className={style.child}
          style={{
            height: 'calc(100% - 68px - 48px)',
          }}
          renderTrackVertical={(props) => (
            <div {...props} className="track-vertical" />
          )}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical reverse" />
          )}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          {isFavoriteMenuVisible ? favoriteChildComponents : childComponents}
        </Scrollbars>

        <FlexLayout className={style.buttons}>
          <Button
            isFull={true}
            isMaterialTheme={true}
            onClick={() => this.openFavorite()}
          >
            <AiTwotoneStar
              size={20}
              style={{ marginLeft: 2 }}
            />
            <span>북마크</span>
          </Button>

          <Button
            isFull={true}
            isMaterialTheme={true}
            onClick={() => publicStore.openDefaultMenu()}
          >
            <AiOutlineMenuUnfold
              size={20}
              style={{ marginLeft: 2 }}
            />
            <span>메뉴</span>
          </Button>
        </FlexLayout>
      </div>
    );
  }
}
