import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Format } from '../../../../../utils/string';
import { SiteModel } from '../models';
import { Receivable } from '../Receivable';

// 현장별
const Site: GridLayoutHeader<SiteModel, Receivable>[] = [
  {
    id: 'actcd',
    text: '현장코드',
    width: 10,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 40,
    trail: (_, scope) => (
        <FlexLayout
          justify="left"
          align="center"
        >
          건수: {scope?.state.total}
        </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'beamt',
    text: '전일미수',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.beamt)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.beamt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '매출액',
    width: 10,
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {scope?.state.misamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '입금액',
    width: 10,
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {scope?.state.rcvamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.rcvamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'resuamt',
    text: '미수잔액',
    width: 10,
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {scope?.state.resuamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.resuamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {x.remark}
      </FlexLayout>
    ),
  },
];

export default Site;
