import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/all';
import {
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  SearchLayout,
  TableLayout, TelnumTextBox,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Application } from './Application';
import { Date6Calculator } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';

/**
 * 화면
 * @window w_tb_e073w_01
 * @category 검사신청서
 */

const prtflags = [
  { value: '0', remark: '미신청' },
  { value: '1', remark: '신청' },
];

export const ApplicationTemplate: React.FC<TemplateProps<Application>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        style={{ width: 1400 }}
      >
        <FlexLayout size={400}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stmon}
            textAlign="center"
            format="#### - ##"
            onChange={(value) => { scope.setState({ stmon: value }, () => scope.onRetrieveEvent()); }}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                scope.setState({
                  stmon: new Date6Calculator(scope.state.stmon).add(-1).toString(),
                  endmon: new Date6Calculator(scope.state.endmon).add(-1).toString(),
                }, () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                scope.setState({
                  stmon: new Date6Calculator(scope.state.stmon).add().toString(),
                  endmon: new Date6Calculator(scope.state.endmon).add().toString(),
                }, () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
          <DateTextBox
            value={scope.state.endmon}
            textAlign="center"
            format="#### - ##"
            onChange={(value) => { scope.setState({ endmon: value }, () => scope.onRetrieveEvent()); }}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                scope.setState({
                  stmon: new Date6Calculator(scope.state.stmon).add(-1).toString(),
                  endmon: new Date6Calculator(scope.state.endmon).add(-1).toString(),
                }, () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                scope.setState({
                  stmon: new Date6Calculator(scope.state.stmon).add().toString(),
                  endmon: new Date6Calculator(scope.state.endmon).add().toString(),
                }, () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={200}>
          <span>계약분류</span>
          <ComboBox
            value={scope.state.contgubun}
            data={scope.state.contgubuns?.map((y: any) => new ComboBoxModel(y.code, y.cnam))}
            onSelect={(option) => scope.setState({ contgubun: option.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={200}>
        <span>신청구분</span>
          <ComboBox
            value={scope.state.prtflag}
            data={[
              { value: '%', remark: '전체' },
              { value: '0', remark: '미신청' },
              { value: '1', remark: '신청' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => scope.setState({ prtflag: option.value },
              () => { scope.onRetrieveEvent(); })
            }
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={300}>
          <span style={{ marginRight: 20 }}>검색어</span>
          <TextBox
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>회사구분</span>
          <TextBox
            value={scope.state.wkactcd}
            textAlign="center"
            onChange={(value) => scope.setState({ wkactcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E018_1',
              {},
              true,
              (item) => {
                scope.setState({
                  wkactcd: item.cd,
                  wkactnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.5}
            value={scope.state.wkactnm}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>
      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'chk',
            text: '선택',
            width: 50,
            render: (x, rowUpdate) => (
              <CheckBox
                value={x.chk === '1'}
                onChange={(v) => rowUpdate({ chk: v ? '1' : '0' })}
                isDisabledTrackingStateChange={true}

              />
            ),
          },
          {
            id: 'prtflag',
            text: '신청구분',
            width: 75,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합계
              </FlexLayout>
            ),
            render: (x, rowUpdate) => (
              <ComboBox
                value={x.prtflag}
                style={{ color: x.prtflag === '0' ? 'var(--color-red)' : 'var(--color-blue)' }}
                data={prtflags.map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => { rowUpdate({ prtflag: option.value }); }}
                isDisabledTrackingStateChange={true}

              />
            ),
          },
          {
            id: 'contgubun',
            text: '계약분류',
            width: 75,
            render: (x, rowUpdate) => (
              <ComboBox
                value={x.contgubun}
                data={scope.state.deatilcontgubun.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                onSelect={(option) => { rowUpdate({ contgubun: option.value }); }}
                isDisabledTrackingStateChange={true}
              />
            ),
          },
          {
            id: 'state',
            text: '검사상태',
            width: 75,
            trail: () => (
              <FlexLayout
                align="center"
                justify="center"
              >
                건수: {scope.state.total}
              </FlexLayout>
            ),
            render: (x, rowUpdate) => (
              <ComboBox
                value={x.state}
                style={{
                  color: x.state === '0' ? 'var(--color-red)'
                    : x.state === '1' ? 'var(--color-green)' : 'var(--color-blue)',
                }}
                data={[
                  { value: '0', remark: '만료' },
                  { value: '1', remark: '계획' },
                  { value: '2', remark: '완료' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => { rowUpdate({ state: option.value }); }}
                isDisabledTrackingStateChange={true}

              />
            ),
          },
          {
            id: 'kcenddate',
            text: '유효기간만료일',
            width: 100,
            trail: () => (
              <FlexLayout
                align="center"
                justify="center"
                style={{ color: 'var(--color-red' }}
              >
                미신청: {scope.state.prtflag1_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FormatTextBox
                textAlign="center"
                format="####-##-##"
                value={x.kcenddate}
              />
            ),
          },
          {
            id: 'actcd',
            text: '현장코드',
            width: 75,
            trail: () => (
              <FlexLayout
                align="center"
                justify="center"
                style={{ color: 'var(--color-gray)' }}
              >
                신청: {scope.state.prtflag0_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <TextBox
                value={x.actcd}
                textAlign="center"
              />
            ),
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 200,
            render: (x) => (
              <TextBox
                value={x.actnm}
                textAlign="left"
              />
            ),
          },
          {
            id: 'pernm',
            text: '담당자',
            width: 75,
            render: (x) => (
              <TextBox
                value={x.pernm}
                textAlign="center"
              />
            ),
          },
          {
            id: 'wkactcd',
            text: '회사',
            width: 50,
            render: (x, rowUpdate) => (
              <ComboBox
                value={x.wkactcd}
                data={scope.state.wkactcds.map((y) => new ComboBoxModel(y.wkactcd, y.wkactnm))}
                style={{ color: x.wkactcd === '001' ? 'var(--color-black)' : 'var(--color-green)' }}
                onSelect={(option) => { rowUpdate({ wkactcd: option.value }); }}
              />
            ),
          },
          {
            id: 'cltnm',
            text: '거래처명',
            width: 200,
            render: (x) => (
              <TextBox
                value={x.cltnm}
                textAlign="left"
                />
            ),
          },
          {
            id: 'qty',
            text: '개수',
            width: 50,
            trail: () => (
              <FlexLayout
                align="center"
                justify="center"
              >
                {scope.state.cnt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <TextBox
                value={x.qty}
                textAlign="center"
              />
            ),
          },
          {
            id: 'areanm',
            text: '지역명',
            width: 75,
            render: (x) => (
              <TextBox
                value={x.areanm}
                textAlign="left"
              />
            ),
          },
          {
            id: 'gareanm',
            text: '검사담당부서',
            width: 75,
            render: (x) => (
              <TextBox
                value={x.gareanm}
                textAlign="left"
              />
            ),
          },
          {
            id: 'faxnum',
            text: '검사담당부서fax',
            width: 100,
            render: (x) => (
              <TelnumTextBox
                textAlign='center'
                value={x.faxnum || ''}
              />
            ),
          },
        ]}
        data={scope.state.applicationList}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
);
