import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Worker } from '../Worker';
import { Date6, Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 그탭 - 지급현황 > 그리드
 * @window w_tb_xusers_share
 * @category 외주설치자등록
 */
export const WorkerTabPaymentsGridHeader: GLHF<any, Worker> = (scope) => ([
  {
    id: 'mijdate',
    text: '매입일자',
    width: 90,
    sum: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date6.withDash(x.mijmon)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.mijdate)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'mijmon',
    text: '매입월',
    value: (x) => x.mijdate.substr(0, 6),
    width: 80,
    isHidden: true,
    group: true,
  },
  {
    id: 'mijnum',
    text: '번호',
    width: 70,
  },
  {
    id: 'seq',
    text: '순번',
    width: 70,
  },
  {
    id: 'acccd',
    text: '계정과목',
    width: 100,
    render: (x) => {
      const fp = scope.acccds.filter((y) => y.acccd === x.acccd);
      const nm = fp.length > 0 ? fp[0].accnm : '';

      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'snddate',
    text: '지급일자',
    width: 90,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.snddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'sndnum',
    text: '번호',
    width: 70,
  },
  {
    id: 'mijcltnm',
    text: '지급처명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.mijcltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품목명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt',
    text: '매입금액',
    width: 120,
    sum: (x, data) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(data, 'mijamt', x, ['mijmon']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.mijamt_tot)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mijamt, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'sndamt',
    text: '지급금액',
    width: 120,
    sum: (x, data) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(data, 'sndamt', x, ['mijmon']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.sndamt_tot)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.sndamt, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt',
    text: '잔액(미지급금액)',
    width: 120,
    sum: (x, data) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(data, 'iamt', x, ['mijmon']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.iamt_tot)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.iamt, true)}
      </FlexLayout>
    ),
  },
]);
