import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca900
 * @category 송장등록
 */

export class InvoiceModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 년도
   *
   * **data type** varchar(4)
   */
  readonly year: string;

  /**
   * 순번
   *
   * **data type** varchar(4)
   */
  readonly seq: string;

  /**
   * 번호
   *
   * **data type** varchar(20)
   */
  readonly num: string;

  /**
   * 발송일
   *
   * **data type** varchar(8)
   */
  invodate: string;

  /**
   * 발송처
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   * 발송처명
   *
   * **data type** varchar(255)
   */
  readonly cltnm: string;

  /**
   * 발송자
   *
   * **data type** varchar(20)
   */
  readonly perid: string;

  /**
   * 바송자명
   *
   * **data type** varchar(100)
   */
  readonly pernm: string;

  /**
   * 내용
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   * 연락처
   *
   * **data type** varchar(255)
   */
  readonly tel: string;

  /**
   * 받는이
   *
   * **data type** varchar(50)
   */
  readonly toname: string;

  /**
   * 송장번호
   *
   * **data type** varchar(0)
   */
  readonly makenum: string;

  /**
   * 택배회사명
   *
   * **data type** varchar(100)
   */
  readonly invocltnm: string;

  /**
   * 요청자명
   *
   * **data type** varchar(100)
   */
  readonly repernm: string;

  /**
   * 구분
   *
   * **data type** varchar(4)
   */
  readonly gubun: string;

  /**
   * 첨부여부
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.year = data.year || '';
    this.seq = data.seq || '';
    this.num = data.num || '';
    this.invodate = data.invodate || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.remark = data.remark || '';
    this.tel = data.tel || '';
    this.toname = data.toname || '';
    this.makenum = data.makenum || '';
    this.invocltnm = data.invocltnm || '';
    this.repernm = data.repernm || '';
    this.gubun = data.gubun || '';
    this.picflag = data.picflag || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
