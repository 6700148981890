import * as React from 'react';
import {
  Button, FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Regist } from '../Regist';
import { RegistTabGridBalHeader } from '../headers';

/**
 * 화면 > 상세 > 탭 - 발주내역
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabBal: React.FC<TemplateProps<Regist>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout
        size={Global.LAYOUT_BUTTON_HEIGHT_1}
        justify="end"
      >
        <Button width={85} onClick={() => scope.openBal()}>발주서</Button>
      </FlexLayout>

      <GridLayout
        ref={scope.gridBal}
        header={RegistTabGridBalHeader(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.tabDataBal}
        onRowClick={(_, index) => scope.onGridBalFocused(index)}
      />
    </FlexLayout>
  );
};
