import { computed } from 'mobx';

/**
 * 부서별모델
 * @window w_tb_monend_incentive
 * @category 월실적
 */
export class DepartmentModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 부서명
   *
   * **data type** char(40)
   */
  divinm: string;

  /**
   * 담당자
   *
   * **data type** char(30)
   */
  pernm: string;

  /**
   * 개인실적점수
   *
   * **data type** number
   */
  totamt2: string;

  /**
   * 부서별평균점수
   *
   * **data type** number
   */
  totaveramt: string;

  /**
   * 전기식인승관리대수
   *
   * **data type** number
   */
  qty01: string;

  /**
   * 점수
   *
   * **data type** number
   */
  planamt01: string;

  /**
   * 점검한대수
   *
   * **data type** number
   */
  totplancnt01: string;

  /**
   * 지원받은점검
   *
   * **data type** number
   */
  jrececnt01: string;

  /**
   * 지원한점검
   *
   * **data type** char(4)
   */
  jcompcnt01: string;

  /**
   * 전기식화물관리대수
   *
   * **data type** number
   */
  qty01_2: string;

  /**
   * 점수2
   *
   * **data type** number
   */
  planamt01_2: string;

  /**
   * 점검한대수2
   *
   * **data type** number
   */
  totplancnt01_2: string;

  /**
   * 지원받은점검2
   *
   * **data type** number
   */
  jrececnt01_2: string;

  /**
   * 지원한점검2
   *
   * **data type** number
   */
  jcompcnt01_2: string;

  /**
   * 유압식인승관리대수
   *
   * **data type** number
   */
  qty02: string;

  /**
   * 점수3
   *
   * **data type** number
   */
  planamt02: string;

  /**
   * 점검한대수3
   *
   * **data type** number
   */
  totplancnt02: string;

  /**
   * 지원받은점검3
   *
   * **data type** number
   */
  jrececnt02: string;

  /**
   * 지원한점검3
   *
   * **data type** number
   */
  jcompcnt02: string;

  /**
   * 유압식화물관리대수
   *
   * **data type** number
   */
  qty02_2: string;

  /**
   * 점수4
   *
   * **data type** number
   */
  planamt02_2: string;

  /**
   * 점검한대수4
   *
   * **data type** number
   */
  totplancnt02_2: string;

  /**
   * 지원받은점검4
   *
   * **data type** number
   */
  jrececnt02_2: string;

  /**
   * 지원한점검4
   *
   * **data type** number
   */
  jcompcnt02_2: string;

  /**
   * 에스컬레이터관리대수
   *
   * **data type** number
   */
  qty03: string;

  /**
   * 점수5
   *
   * **data type** number
   */
  planamt03: string;

  /**
   * 점검한대수5
   *
   * **data type** number
   */
  totplancnt03: string;

  /**
   * 지원받은점검5
   *
   * **data type** number
   */
  jrececnt03: string;

  /**
   * 지원한점검5
   *
   * **data type** number
   */
  jcompcnt03: string;

  /**
   * 덤웨이터(주차기)관리대수
   *
   * **data type** number
   */
  qty04: string;

  /**
   * 점수6
   *
   * **data type** number
   */
  planamt04: string;

  /**
   * 점검한대수6
   *
   * **data type** number
   */
  totplancnt04: string;

  /**
   * 지원받은점검6
   *
   * **data type** number
   */
  jrececnt04: string;

  /**
   * 지원한점검6
   *
   * **data type** number
   */
  jcompcnt04: string;

  /**
   * 휠체어리프트관리대수
   *
   * **data type** number
   */
  qty05: string;

  /**
   * 점수7
   *
   * **data type** number
   */
  planamt05: string;

  /**
   * 점검한대수7
   *
   * **data type** number
   */
  totplancnt05: string;

  /**
   * 지원받은점검7
   *
   * **data type** number
   */
  jrececnt05: string;

  /**
   * 지원한점검7
   *
   * **data type** number
   */
  jcompcnt05: string;

  /**
   * 주차설비작업용관리대수
   *
   * **data type** number
   */
  qty10: string;

  /**
   * 점수8
   *
   * **data type** number
   */
  planamt10: string;

  /**
   * 점검한대수8
   *
   * **data type** number
   */
  totplancnt10: string;

  /**
   * 지원받은점검8
   *
   * **data type** number
   */
  jrececnt10: string;

  /**
   * 지원한점검8
   *
   * **data type** number
   */
  jcompcnt10: string;

  /**
   * 총관리대수
   *
   * **data type** number
   */
  totqty: string;

  /**
   * 총점검대수
   *
   * **data type** number
   */
  compute_26: string;

  /**
   * 점검실적점수
   *
   * **data type** number
   */
  planamttot: string;

  /**
   * 담당고장건수
   *
   * **data type** number
   */
  rececnt: string;

  /**
   * 담당고장건수-금액
   *
   * **data type** number
   */
  receamt: string;

  /**
   * 담당고장건수
   *
   * **data type** number
   */
  wadcnt: string;

  /**
   * 담당고장건수-금액
   *
   * **data type** number
   */
  wadamt: string;

  /**
   * 담당고장건수(제외)
   *
   * **data type** number
   */
  rececnt_ex: string;

  /**
   * 다발고장(제외)
   *
   * **data type** number
   */
  wadcnt_ex: string;

  /**
   * 고장처리
   *
   * **data type** number
   */
  rececompcnt: string;

  /**
   * 고장처리-금액
   *
   * **data type** number
   */
  rececompamt: string;

  /**
   * 고장실적점수
   *
   * **data type** number
   */
  receamttot: string;

  /**
   * 고장처리(사람갇힘)
   *
   * **data type** number
   */
  trouble: string;

  /**
   * 배정거절(사람같힘)
   *
   * **data type** number
   */
  trouble_c: string;

  /**
   * 사람갇힘합계
   *
   * **data type** number
   */
  troubletot: string;

  /**
   * 긴급출동실적점수
   *
   * **data type** number
   */
  troubleamtexectot: string;

  /**
   * 접수건수
   *
   * **data type** number
   */
  minwoncnt: string;

  /**
   * 미처리건수
   *
   * **data type** number
   */
  minwonnotcnt: string;

  /**
   * 처리건수
   *
   * **data type** number
   */
  minwoncompcnt: string;

  /**
   * 민원처리실적점수
   *
   * **data type** number
   */
  minwonamttot: string;

  /**
   * 합격
   *
   * **data type** number
   */
  kc001cnt: string;

  /**
   * 합격-점수
   *
   * **data type** number
   */
  kc001amt: string;

  /**
   * 조건부
   *
   * **data type** number
   */
  kc002cnt: string;

  /**
   * 조건부-점수
   *
   * **data type** number
   */
  kc002amt: string;

  /**
   * 불합격
   *
   * **data type** number
   */
  kc003cnt: string;

  /**
   * 불합격-점수
   *
   * **data type** number
   */
  kc003amt: string;

  /**
   * 검사실적점수
   *
   * **data type** number
   */
  kcamttot: string;

  /**
   * 장기(10년)신규계약
   *
   * **data type** number
   */
  vlongcnt: string;

  /**
   * 장기(10년)신규계약-점수
   *
   * **data type** number
   */
  vlongamt: string;

  /**
   * 다년(5년)신규계약
   *
   * **data type** number
   */
  longcnt: string;

  /**
   * 다년(5년)신규계약-점수
   *
   * **data type** number
   */
  longamt: string;

  /**
   * FM신규계약
   *
   * **data type** number
   */
  fmcnt: string;

  /**
   * FM신규계약-점수
   *
   * **data type** number
   */
  fmamt: string;

  /**
   * 일반신규계약
   *
   * **data type** number
   */
  pogcnt: string;

  /**
   * 일반신규계약-점수
   *
   * **data type** number
   */
  pogamt: string;

  /**
   * 무상신규계약
   *
   * **data type** number
   */
  mucnt: string;

  /**
   * 무상신규계약-점수
   *
   * **data type** number
   */
  muamt: string;

  /**
   * 설치신규계약
   *
   * **data type** number
   */
  sccnt: string;

  /**
   * 설치신규계약-점수
   *
   * **data type** number
   */
  scamt: string;

  /**
   * 장기(10년)재계약
   *
   * **data type** number
   */
  vlongcnt03: string;

  /**
   * 장기(10년)재계약-점수
   *
   * **data type** number
   */
  vlong03amt: string;

  /**
   * 다년(5년재계약)
   *
   * **data type** number
   */
  longcnt03: string;

  /**
   * 다년(5년재계약)-점수
   *
   * **data type** number
   */
  long03amt: string;

  /**
   * FM재계약
   *
   * **data type** number
   */
  fmcnt03: string;

  /**
   * FM재계약-점수
   *
   * **data type** number
   */
  fm03amt: string;

  /**
   * 익월재계약
   *
   * **data type** number
   */
  pogcnt03: string;

  /**
   * 익월재계약-점수
   *
   * **data type** number
   */
  pog03amt: string;

  /**
   * 계약금액인상건수
   *
   * **data type** number
   */
  pluscnt: string;

  /**
   * 계약금액인상건수-점수
   *
   * **data type** number
   */
  plusamt: string;

  /**
   * 무->유상재계약
   *
   * **data type** number
   */
  yucnt03: string;

  /**
   * 무->유상재계약-점수
   *
   * **data type** number
   */
  yu03amt: string;

  /**
   * 해약/중지
   *
   * **data type** number
   */
  clscnt: string;

  /**
   * 해약/중지-점수
   *
   * **data type** number
   */
  clsamt: string;

  /**
   * 전자계약서건수
   *
   * **data type** number
   */
  eleccnt: string;

  /**
   * 전자계약서건수-점수
   *
   * **data type** number
   */
  elecamt: string;

  /**
   * 계약실적점수
   *
   * **data type** number
   */
  contamttot: string;

  /**
   * 영업건수
   *
   * **data type** number
   */
  businesscnt: string;

  /**
   * 영업건수-점수
   *
   * **data type** number
   */
  businessamt: string;

  /**
   * 견적건수
   *
   * **data type** number
   */
  costcnt: string;

  /**
   * 견적건수-점수
   *
   * **data type** number
   */
  costamt: string;

  /**
   * 공사,부품금액(공사[수주]기준)
   *
   * **data type** number
   */
  compamt: string;

  /**
   * 공사,부품이익금액(공사[수주]기준)
   *
   * **data type** number
   */
  comp_iamt: string;

  /**
   * 공사,부품금액-처리(확정/적용된금액)
   *
   * **data type** number
   */
  comp_uamt: string;

  /**
   * 공사,부품 계약실적 점수
   *
   * **data type** number
   */
  comp_iamttot: string;

  /**
   * 시내출장건수
   *
   * **data type** number
   */
  comp_contamt: string;

  /**
   * 시내출장일수
   *
   * **data type** number
   */
  outdatenotcnt: string;

  /**
   * 시외출장건수
   *
   * **data type** number
   */
  outcnt: string;

  /**
   * 시외출장일수
   *
   * **data type** number
   */
  outdatecnt: string;

  /**
   * 출장점수
   *
   * **data type** number
   */
  outamttot: string;

  /**
   * 개선제안
   *
   * **data type** number
   */
  propose: string;

  /**
   * 개선제안-점수
   *
   * **data type** number
   */
  proposetot: string;

  /**
   * 교육한건수
   *
   * **data type** number
   */
  educnt: string;

  /**
   * 교육한건수-점수
   *
   * **data type** number
   */
  eduamt: string;

  /**
   * 교육실적점수
   *
   * **data type** number
   */
  eduamttot: string;

  /**
   * 당일미작성
   *
   * **data type** number
   */
  e037not: string;

  /**
   * 당일미작성-점수
   *
   * **data type** number
   */
  e037tot: string;

  /**
   * 소유(권한)달란트
   *
   * **data type** number
   */
  talent: string;

  /**
   * 달란트주기
   *
   * **data type** number
   */
  p_like: string;

  /**
   * 사용점수
   *
   * **data type** number
   */
  give_talent: string;

  /**
   * 받은점수
   *
   * **data type** number
   */
  rece_talent: string;

  /**
   * 받은점수달란트점수
   *
   * **data type** number
   */
  talenttot: string;


  /**
   * 차감하기
   *
   * **data type** number
   */
  p_dislike: string;

  /**
   * 받은건수
   *
   * **data type** number
   */
  minus: string;

  /**
   * 받은점수/차감된점수
   *
   * **data type** number
   */
  minustot: string;

  /**
   * 개인실적점수
   *
   * **data type** number
   */
  totamt: string;

  /**
   * 부서별평균점수
   *
   * **data type** number
   */
  compute_254: string;

  /**
   * 누적점수
   *
   * **data type** number
   */
  accamt: string;

  /**
   * 적용점수
   *
   * **data type** number
   */
  rtclafiuseamt: string;

  /**
   * 부서별평균적용점수
   *
   * **data type** number
   */
  compute_255: string;

  /**
   * 본인확인함
   *
   * **data type** number
   */
  okflag: string;

  /**
   * -
   *
   * **data type** number
   */
  edurececnt: string;

  /**
   * -
   *
   * **data type** number
   */
  edureceamt: string;

  /**
   * -
   *
   * **data type** number
   */
  comp_contamttot: string;

  /**
   * -
   *
   * **data type** number
   */
  outnotcnt: string;

  /**
   * 차감하기
   *
   * **data type** char(1)
   */
  p_minus: string;

  /**
   * 부서별평균적용점수
   *
   * **data type** number
   */
  bungitotaveramt: string;

  /**
   * 총점검대수
   *
   * **data type** number
   */
  totplancnttot: string;

  /**
   * 총점검대수
   *
   * **data type** char(5)
   */
  rtclafi: string;

  /**
   * 입력자
   *
   * **data type** char(10)
   */
  perid: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  perid2: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  perid3: string;

  /**
   * 근속
   *
   * **data type** char(10)
   */
  dayterm2: string;

  /**
   * 적용
   *
   * **data type** char(10)
   */
  totyulnm: string;

  /**
   * 적용
   *
   * **data type** char(10)
   */
  totyul: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.p_minus = data.p_minus || '';
    this.pernm = data.pernm || '';
    this.perid = data.perid || '';
    this.perid2 = data.perid2 || '';
    this.perid3 = data.perid3 || '';
    this.rtclafi = data.rtclafi || '';
    this.totplancnttot = data.totplancnttot || '';
    this.divinm = data.divinm || '';
    this.bungitotaveramt = data.bungitotaveramt || '';
    this.totamt2 = data.totamt2 || '';
    this.totaveramt = data.totaveramt || '';
    this.qty01 = data.qty01 || '';
    this.planamt01 = data.planamt01 || '';
    this.totplancnt01 = data.totplancnt01 || '';
    this.jrececnt01 = data.jrececnt01 || '';
    this.jcompcnt01 = data.jcompcnt01 || '';
    this.qty01_2 = data.qty01_2 || '';
    this.planamt01_2 = data.planamt01_2 || '';
    this.pernm = data.pernm || '';
    this.totplancnt02 = data.totplancnt02 || '';
    this.totplancnt01_2 = data.totplancnt01_2 || '';
    this.spjangnm = data.spjangnm || '';
    this.jrececnt01_2 = data.jrececnt01_2 || '';
    this.jcompcnt01_2 = data.jcompcnt01_2 || '';
    this.qty02 = data.qty02 || '';
    this.planamt02 = data.planamt02 || '';
    this.jrececnt02 = data.jrececnt02 || '';
    this.jcompcnt02 = data.jcompcnt02 || '';
    this.qty02_2 = data.qty02_2 || '';
    this.planamt02_2 = data.planamt02_2 || '';
    this.totplancnt02_2 = data.totplancnt02_2 || '';
    this.jrececnt02_2 = data.jrececnt02_2 || '';
    this.jcompcnt02_2 = data.jcompcnt02_2 || '';
    this.qty03 = data.qty03 || '';
    this.planamt03 = data.planamt03 || '';
    this.totplancnt03 = data.totplancnt03 || '';
    this.jrececnt03 = data.jrececnt03 || '';
    this.jcompcnt03 = data.jcompcnt03 || '';
    this.qty04 = data.qty04 || '';
    this.vlongamt = data.vlongamt || '';
    this.planamt04 = data.planamt04 || '';
    this.totplancnt04 = data.totplancnt04 || '';
    this.jrececnt04 = data.jrececnt04 || '';
    this.jcompcnt04 = data.jcompcnt04 || '';
    this.qty05 = data.qty05 || '';
    this.planamt05 = data.planamt05 || '';
    this.totplancnt05 = data.totplancnt05 || '';
    this.jrececnt05 = data.jrececnt05 || '';
    this.jcompcnt05 = data.jcompcnt05 || '';
    this.qty10 = data.qty10 || '';
    this.planamt10 = data.planamt10 || '';
    this.totplancnt10 = data.totplancnt10 || '';
    this.jrececnt10 = data.jrececnt10 || '';
    this.jcompcnt10 = data.jcompcnt10 || '';
    this.totqty = data.totqty || '';
    this.compute_26 = data.compute_26 || '';
    this.planamttot = data.planamttot || '';
    this.rececnt = data.rececnt || '';
    this.receamt = data.receamt || '';
    this.wadcnt = data.wadcnt || '';
    this.wadamt = data.wadamt || '';
    this.rececnt_ex = data.rececnt_ex || '';
    this.wadcnt_ex = data.wadcnt_ex || '';
    this.rececompcnt = data.rececompcnt || '';
    this.rececompamt = data.rececompamt || '';
    this.receamttot = data.receamttot || '';
    this.trouble = data.trouble || '';
    this.trouble_c = data.trouble_c || '';
    this.troubletot = data.troubletot || '';
    this.troubleamtexectot = data.troubleamtexectot || '';
    this.minwoncnt = data.minwoncnt || '';
    this.minwonnotcnt = data.minwonnotcnt || '';
    this.minwoncompcnt = data.minwoncompcnt || '';
    this.minwonamttot = data.minwonamttot || '';
    this.kc001cnt = data.kc001cnt || '';
    this.kc001amt = data.kc001amt || '';
    this.kc002cnt = data.kc002cnt || '';
    this.kc002amt = data.kc002amt || '';
    this.kc003cnt = data.kc003cnt || '';
    this.kc003amt = data.kc003amt || '';
    this.kcamttot = data.kcamttot || '';
    this.vlongcnt = data.vlongcnt || '';
    this.longcnt = data.longcnt || '';
    this.longamt = data.longamt || '';
    this.fmcnt = data.fmcnt || '';
    this.fmamt = data.fmamt || '';
    this.pogcnt = data.pogcnt || '';
    this.pogamt = data.pogamt || '';
    this.mucnt = data.mucnt || '';
    this.muamt = data.muamt || '';
    this.sccnt = data.sccnt || '';
    this.scamt = data.scamt || '';
    this.vlongcnt03 = data.vlongcnt03 || '';
    this.vlong03amt = data.vlong03amt || '';
    this.longcnt03 = data.longcnt03 || '';
    this.long03amt = data.long03amt || '';
    this.fmcnt03 = data.fmcnt03 || '';
    this.fm03amt = data.fm03amt || '';
    this.pogcnt03 = data.pogcnt03 || '';
    this.pog03amt = data.pog03amt || '';
    this.pluscnt = data.pluscnt || '';
    this.plusamt = data.plusamt || '';
    this.yucnt03 = data.yucnt03 || '';
    this.yu03amt = data.yu03amt || '';
    this.clscnt = data.clscnt || '';
    this.clsamt = data.clsamt || '';
    this.eleccnt = data.eleccnt || '';
    this.elecamt = data.elecamt || '';
    this.contamttot = data.contamttot || '';
    this.businesscnt = data.businesscnt || '';
    this.businessamt = data.businessamt || '';
    this.costcnt = data.costcnt || '';
    this.costamt = data.costamt || '';
    this.compamt = data.compamt || '';
    this.comp_iamt = data.comp_iamt || '';
    this.comp_uamt = data.comp_uamt || '';
    this.comp_iamttot = data.comp_iamttot || '';
    this.comp_contamt = data.comp_contamt || '';
    this.comp_contamttot = data.comp_contamttot || '';
    this.outnotcnt = data.outnotcnt || '';
    this.outdatenotcnt = data.outdatenotcnt || '';
    this.outcnt = data.outcnt || '';
    this.outdatecnt = data.outdatecnt || '';
    this.outamttot = data.outamttot || '';
    this.propose = data.propose || '';
    this.proposetot = data.proposetot || '';
    this.educnt = data.educnt || '';
    this.eduamt = data.eduamt || '';
    this.edurececnt = data.edurececnt || '';
    this.edureceamt = data.edureceamt || '';
    this.eduamttot = data.eduamttot || '';
    this.e037not = data.e037not || '';
    this.e037tot = data.e037tot || '';
    this.talent = data.talent || '';
    this.p_like = data.p_like || '';
    this.give_talent = data.give_talent || '';
    this.rece_talent = data.rece_talent || '';
    this.talenttot = data.talenttot || '';
    this.p_dislike = data.p_dislike || '';
    this.minus = data.minus || '';
    this.minustot = data.minustot || '';
    this.totamt = data.totamt || '';
    this.totamt = data.totamt || '';
    this.compute_254 = data.compute_254 || '';
    this.accamt = data.accamt || '';
    this.rtclafiuseamt = data.planartclafiuseamtmt03 || '';
    this.compute_255 = data.compute_255 || '';
    this.okflag = data.okflag || '';
    this.dayterm2 = data.dayterm2 || '';
    this.totyulnm = data.totyulnm || '';
    this.totyul = data.totyul || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
