import * as React from 'react';
import {
  AddressButton,
  Button,
  DefaultLayout,
  FlexLayout, FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  TelnumTextBox,
  TextBox,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Worker } from '../Worker';
import { SearchBinding } from '../../../../../models';
import WorkerModel from '../models/WorkerModel';
import { WorkerTabDefaultIDModalGridHeader } from '../headers/WorkerTabDefaultIDModalGridHeader';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';

/**
 * 화면 > 탭 - 기본정보
 * @window w_tb_xusers_share
 * @category 외주설치자등록
 */
export const WorkerTabDefaultTemplate: React.FC<TemplateProps<Worker>> = ({
  scope,
}) => {
  const content = scope.state?.content;
  const setContent = (data: any) => scope?.setState({
    content: new WorkerModel({
      ...content,
      ...data,
    }, content?.isNew),
  });

  return (
    <FlexLayout isVertical={true}>
      <LayoutTitle>기본정보</LayoutTitle>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox
          title="아이디"
          isNeed={true}
          style={Global.STYLE_COLOR_RED}
        >
          <TextBox
            value={content.id}
            textAlign="center"
            readonly={!content.isNew}
            onChange={(v) => scope.headerItemChanged('id', v)}
            onDoubleClick={() => scope.openModalID()}
            onEnter={(v) => scope.openModalID(v)}
          />
        </LabelBox>

        <LabelBox
          title="성명"
          isNeed={true}
        >
          <TextBox
            textAlign="center"
            value={content.pernm}
            onChange={(v) => setContent({ pernm: v })}
          />
        </LabelBox>

        <LabelBox title="이메일">
          <TextBox
            value={content?.email}
            onChange={(v) => setContent({ email: v })}
          />
        </LabelBox>

        <LabelBox title="핸드폰번호">
          <TelnumTextBox
            textAlign="center"
            value={content?.hpnum}
            onChange={(v) => setContent({ hpnum: v })}
          />
        </LabelBox>

        <LabelBox title="사업자구분">
          <OptionBox
            value={content?.rflag || '1'}
            data={[
              { value: '0', remark: '개인' },
              { value: '1', remark: '사업자' },
            ]}
            onChange={(item) => setContent({ rflag: item.value })}
          />
        </LabelBox>

        <LabelBox title={content?.rflag === '1' ? '사업자번호' : '주민번호'}>
          <FormatTextBox
            format={content?.rflag === '1' ? '###-##-#####' : '######-#######'}
            textAlign="center"
            value={content?.rnum}
            onChange={(v) => setContent({ rnum: v })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox
          title="주소"
          weight={3}
          style={Global.STYLE_COLOR_RED}
          footer={<FlexLayout>
            <AddressButton
              publicStore={scope.props.publicStore}
              modalStore={scope.props.modalStore}
              onChange={(address) => setContent({
                zipcd: address.zonecode,
                oldcltadres: address.address,
                cltadres2: address.jibunAddress,
                lat: address.y,
                lng: address.x,
              })}
            >
              주소검색
            </AddressButton>

            <DefaultLayout
              size={260}
              style={{ marginLeft: 16 }}
            >

            </DefaultLayout>

            <DefaultLayout
              size={150}
              style={{ marginLeft: 16 }}
            >
              {`위도: ${content.lat}`}
            </DefaultLayout>

            <DefaultLayout size={150}>
              {`경도: ${content.lng}`}
            </DefaultLayout>
          </FlexLayout>}
        >
          <TextBox
            textAlign="center"
            value={content.zipcd}
            onChange={(value) => setContent({ zipcd: value })}
            readonly={true}
          />

          <TextBox
            weight={3}
            value={content.address}
            readonly={true}
            onChange={(value) => setContent({ address: value })}
          />

          <TextBox
            weight={3}
            value={content?.address2}
            onChange={(value) => setContent({ address2: value })}
          />
        </LabelBox>

        <LabelBox
          title="연결거래처"
          style={Global.STYLE_COLOR_RED}
          weight={3}
        >
          <TextBox
            textAlign="center"
            value={content.cltcd}
            onChange={(value) => setContent({ cltcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_XCLIENT',
              {},
              true,
              (item) => {
                setContent({
                  cltcd: item.cd,
                  cltnm: item.cdnm,
                });
                scope.headerItemChanged('cltcd', item.cd);
              },
            )}
          />
          <TextBox
            weight={2}
            value={content.cltnm}
            readonly={true}
            onChange={(value) => setContent({ cltnm: value })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout>
        <LabelBox title="기타사항(특이사항)">
          <TextBox
            isMultiline={true}
            value={content?.remark}
            onChange={(v) => setContent({ remark: v })}
          />
        </LabelBox>
      </FlexLayout>

      <ModalView
        isVisible={scope.state.isVisibleIDModal}
        width={800}
        onClose={() => scope.SS({ isVisibleIDModal: false })}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={scope.state.searchQueryID}
                onChange={(value) => scope.SS({ searchQueryID: value })}
                onEnter={() => scope.onIDRetrieveEvent()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          </SearchLayout>

          <FlexLayout>
            <GridLayout
              ref={scope.gridID}
              header={WorkerTabDefaultIDModalGridHeader(scope)}
              data={scope.state.dataID}
              infinityHandler={scope.infinityID}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowClick={(item) => scope.onGridIDRowClicked(item)}
              onRowDoubleClick={() => scope.onClickModalIDSuccess()}
            />
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.SS({ isVisibleIDModal: false })}
            >
              닫기
            </Button>
            <Button
              isFull={true}
              onClick={() => scope.onClickModalIDSuccess()}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
