import * as React from 'react';
import {
  Button,
  CalendarLayout,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  DefaultLayout,
  FlexLayout,
  GridLayout, LabelBox,
  LayoutTitle,
  ModalView,
  SearchLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchBinding } from '../../../../models/common';
import { Routine } from './Routine';
import { CalendarLayoutDataModel } from '../../../../models/component';
import { RoutineModel } from './Routine.model';
import { Date6, Date8, Time4 } from '../../../../utils/time';
import style from '../../../../containers/AuthorizedScreen.module.scss';

/**
 * 화면
 * @window w_tb_e073
 * @category 검사스케쥴
 */
export const RoutineTemplate: React.FC<TemplateProps<Routine>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={130}>
        <span>월</span>
        <DateTextBox
          format="####-##"
          textAlign="center"
          value={scope.state.stmon}
          onChange={(v) => scope.setState({ stmon: v }, () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <FlexLayout>
        <span>구분</span>
        <ComboBox
          value={scope.state.state}
          data={[
            { value: '%', remark: '전체' },
            { value: '0', remark: '만료' },
            { value: '1', remark: '계획' },
            { value: '2', remark: '검사완료' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ state: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout weight={2}>
        <span style={{ color: 'var(--color-red)' }}>현장</span>
        <TextBox
          value={scope.state.actcd}
          textAlign="center"
          onChange={(value) => scope.setState({ actcd: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_E601_1',
            {},
            true,
            (item) => {
              scope.setState({ actcd: item.cd, actnm: item.cdnm });
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={2}
          value={scope.state.actnm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>

      <FlexLayout>
        <span style={{ color: 'var(--color-red)' }}>부서</span>
        <TextBox
          value={scope.state.divicd}
          textAlign="center"
          onChange={(value) => scope.setState({ divicd: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              scope.setState({ divicd: item.cd, divinm: item.cdnm });
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={1.4}
          value={scope.state.divinm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>

      <FlexLayout>
        <span style={{ color: 'var(--color-red)' }}>담당자</span>
        <TextBox
          value={scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.setState({ pernm: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({ perid: item.cd, pernm: item.cdnm });
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={1.4}
          value={scope.state.pernm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>

      <Button
        onClick={() => scope.openExpiredModal()}
        badge={`${scope.state.expiredData?.length || ''}`}
      >
        만료
      </Button>
      <Button onClick={() => scope.openWebSite()}>
        정보센터확인
      </Button>
    </SearchLayout>

    <FlexLayout>
      <CalendarLayout
        month={scope.state.stmon}
        data={scope.state.data}
        render={(c, items: CalendarLayoutDataModel<RoutineModel>[]) => (
          <DefaultLayout style={{ cursor: 'pointer' }}>
            <div>
              <span style={{ color: 'var(--color-blue)' }}>완료 {scope.dayRemarks[c.data]?.done || 0} </span>
              <span style={{ color: 'var(--color-green)' }}>계획 {scope.dayRemarks[c.data]?.plan || 0} </span>
              <span style={{ color: 'var(--color-red)' }}>만료 {scope.dayRemarks[c.data]?.yet || 0}</span>
            </div>
            {items.slice(0, 6).map((x, i) => (
              <div key={i}>
                <span style={{ color: scope.getItemColor(x.data) }}>
                  [{scope.getItemPrefix(x.data)}]
                </span>
                {x.data.actnm}
              </div>
            ))}
          </DefaultLayout>
        )}
        onDateClick={(date) => {
          // CalendarItem의 data는 여유 공간인데 CalendarLayout에서는 8자리 날짜가 들어있습니다
          scope.openInspectModal(date.data);
        }}
      />
    </FlexLayout>


    {/* 만료 모달 */}
    <ModalView
      width={800}
      isVisible={scope.state.isExpiredModalVisible}
      onClose={() => scope.setState({ isExpiredModalVisible: false })}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle>{Date6.withDash(scope.state.stmon)} 정기검사 미계획</LayoutTitle>

        <GridLayout
          header={[
            {
              id: 'state',
              text: '상태',
              width: 70,
              render: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  만료
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 150,
              render: (x) => (
                <FlexLayout
                  justify="start"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 80,
            },
            {
              id: 'elno',
              text: '승강기번호',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.elno?.substr(0, 4)}-{x.elno?.substr(4, 3)}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              width: 70,
            },
            {
              id: 'kcenddate',
              text: '유효기간만료일',
              width: 90,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.kcenddate)}
                </FlexLayout>
              ),
            },
            {
              id: 'kcpernm',
              text: '검사기관(검사자)',
              width: 120,
            },
            {
              id: 'kcspnm',
              text: '검사기관',
              width: 120,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.getInspectAgencyText(x.kcspnm)}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.expiredData}
        />

        <FlexLayout className={style.buttons} size={38}>
          <Button
            className={style.btnSuccess}
            isFull={true}
            onClick={() => {
              scope.setState({ isExpiredModalVisible: false });
            }}
          >
            닫기
          </Button>
        </FlexLayout>
      </FlexLayout>
    </ModalView>


    {/* 검사계획 모달 */}
    <ModalView
      width={800}
      isVisible={scope.state.isInspectModalVisible}
      onClose={() => scope.setState({ isInspectModalVisible: false })}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle>{Date8.withDash(scope.state.inspectDate)} 검사계획</LayoutTitle>

        <GridLayout
          header={[
            {
              id: 'state',
              text: '상태',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{
                    color: scope.getItemColor(x),
                  }}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {x.state === '2' ? '완료' : x.state === '1' ? '계획등록' : '만료'}
                </FlexLayout>
              ),
            },
            {
              id: 'planflag',
              text: '계획확정',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{
                    color: x.state === '0' ? 'var(--color-red)' : 'var(--color-blue)',
                  }}
                >
                  {x.state === '0' ? '미확정' : '확정'}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 150,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 80,
            },
            {
              id: 'elno',
              text: '승강기번호',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.elno?.substr(0, 4)}-{x.elno?.substr(4, 3)}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              width: 80,
            },
            {
              id: 'plandate',
              text: '검사계획일',
              width: 90,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.plandate)}
                </FlexLayout>
              ),
            },
            {
              id: 'kcenddate',
              text: '유효기간만료일',
              width: 90,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.kcenddate)}
                </FlexLayout>
              ),
            },
            {
              id: 'plantime',
              text: '검사시간',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Time4.withDash(x.plantime)}
                </FlexLayout>
              ),
            },
            {
              id: 'kcpernm',
              text: '검사기관(검사자)',
              width: 200,
            },
            {
              id: 'result',
              text: '검사결과(합격유무)',
              width: 120,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.getInspectResultText(x.result)}
                </FlexLayout>
              ),
            },
            {
              id: 'kcspnm',
              text: '검사기관',
              width: 120,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.getInspectAgencyText(x.kcspnm)}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.inspectData}
          onRowDoubleClick={(item) => scope.openInspectDetailModal(item)}
        />

        <FlexLayout className={style.buttons} size={38}>
          <Button
            className={style.btnSuccess}
            isFull={true}
            onClick={() => {
              scope.setState({ isInspectModalVisible: false });
            }}
          >
            닫기
          </Button>
        </FlexLayout>
      </FlexLayout>
    </ModalView>


    {/* 검사계획 상세 모달 */}
    <ModalView
      width={800}
      isVisible={scope.state.isInspectDetailModalVisible}
      onClose={() => scope.setState({ isInspectDetailModalVisible: false })}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle>상세현황</LayoutTitle>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="현장명">
            <TextBox
              value={scope.state.inspectDetailItem?.actnm}
              readonly={true}
              transparent={true}
            />
          </LabelBox>

          <LabelBox title="호기명">
            <TextBox
              value={`${scope.state.inspectDetailItem?.equpnm
              }[${scope.state.inspectDetailItem?.elno?.substr(0, 4)
              }-${scope.state.inspectDetailItem?.elno?.substr(4, 3)}]`}
              readonly={true}
              transparent={true}
            />
          </LabelBox>
        </FlexLayout>

        <GridLayout
          header={[
            {
              id: 'article',
              text: '검사항목',
              width: 10,
            },
            {
              id: 'title',
              text: '검사항목명',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="start"
                  align="center"
                >
                  {x.title}
                </FlexLayout>
              ),
            },
            {
              id: 'descript',
              text: '불합내역',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="start"
                  align="center"
                >
                  {x.descript}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '검사자 불합의견',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="start"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.inspectDetailData}
        />

        <FlexLayout className={style.buttons} size={38}>
          <Button
            className={style.btnSuccess}
            isFull={true}
            onClick={() => {
              scope.setState({ isInspectDetailModalVisible: false });
            }}
          >
            닫기
          </Button>
        </FlexLayout>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);
