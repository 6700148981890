import * as React from 'react';
import {
  AddressButton, CheckBox, DateTextBox, FlexLayout, FormatNumberTextBox, LabelBox, LayoutTitle, TextBox,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Contract } from '../Contract';
import { SearchBinding, SearchBindingProjects } from '../../../../../models';
import ContractDefaultModel from '../models/ContractDefaultModel';

/**
 * 화면 > 탭 - 하도급계약서[기본]
 * @window w_tb_e101_share
 * @category 외주계약등록
 */
export const ContractTabDefaultTemplate: React.FC<TemplateProps<Contract>> = ({
  scope,
}) => {
  const content = scope.state?.content;
  const setContent = (data: any, callback?: () => void) => scope?.setState({
    content: new ContractDefaultModel({
      ...content,
      ...data,
    }, content?.isNew),
  }, callback);

  return (
    <FlexLayout isVertical={true}>
      <LayoutTitle>공사 하도급 계약서</LayoutTitle>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox
          title="계약자"
          isNeed={true}
          style={Global.STYLE_COLOR_RED}
        >
          <TextBox
            value={content?.id}
            textAlign="center"
            onChange={(v) => scope.itemChanged('id', v)}
            onDoubleClick={() => scope.openModalID()}
            onEnter={(v) => scope.openModalID(v)}
          />

          <TextBox
            value={content?.pernm}
            textAlign="center"
            readonly={true}
          />
        </LabelBox>

        <LabelBox
          title="프로젝트"
          isNeed={true}
          style={Global.STYLE_COLOR_RED}
        >
          <TextBox
            size={90}
            textAlign="center"
            value={content?.projno}
            onChange={(value) => setContent({ projno: value })}
            bindSearchModal={new SearchBindingProjects(
              scope.props.modalStore,
              {},
              true,
              (item) => {
                setContent({
                  projno: item.projno,
                  projnm: item.projnm,
                  actcd: item.actcd,
                  actnm: item.actnm,
                }, () => scope.itemChanged('projno', item.projno));
              },
              true,
            )}
          />

          <TextBox
            value={content?.projnm}
            readonly={true}
          />
        </LabelBox>

        <LabelBox
          title="현장"
          style={Global.STYLE_COLOR_RED}
        >
          <TextBox
            value={content?.actcd}
            textAlign="center"
            readonly={true}
            onChange={(value) => setContent({ actcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E601_1',
              {},
              true,
              (item) => {
                setContent({
                  actcd: item.cd,
                  actnm: item.cdnm,
                }, () => scope.itemChanged('actcd', item.cd));
              },
            )}
          />

          <TextBox
            weight={2}
            value={content.actnm}
            readonly={true}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={106}>
        <LabelBox title="공사범위">
          <FlexLayout
            isVertical={true}
            style={{
              padding: 3,
            }}
          >
            <FlexLayout>
              <FlexLayout align="center">
                <CheckBox
                  value={content?.range1 === '1'}
                  onChange={(v) => setContent({ range1: v ? '1' : '0' })}
                />
                <span style={{ paddingLeft: 3 }}>① 엘리베이터 기계설치 공사일체</span>
              </FlexLayout>

              <FlexLayout align="center">
                <CheckBox
                  value={content?.range2 === '1'}
                  onChange={(v) => setContent({ range2: v ? '1' : '0' })}
                />
                <span style={{ paddingLeft: 3 }}>② 엘리베이터 핀제거 및 부대공사 포함</span>
              </FlexLayout>

              <FlexLayout align="center">
                <CheckBox
                  value={content?.range3 === '1'}
                  onChange={(v) => setContent({ range3: v ? '1' : '0' })}
                />
                <span style={{ paddingLeft: 3 }}>③ 엘리베이터 시운전 및 완성검사 참관 및 지적사항 처리</span>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout>
              <FlexLayout align="center">
                <CheckBox
                  value={content?.range4 === '1'}
                  onChange={(v) => setContent({ range4: v ? '1' : '0' })}
                />
                <span style={{ paddingLeft: 3 }}>④ 엘리베이터 전기닥트 설치 포함</span>
              </FlexLayout>

              <FlexLayout align="center">
                <CheckBox
                  value={content?.range5 === '1'}
                  onChange={(v) => setContent({ range5: v ? '1' : '0' })}
                />
                <span style={{ paddingLeft: 3 }}>⑤ 자재반입, 설치중, 설치완료 사진 촬영 - 설계담당자 송부</span>
              </FlexLayout>

              <FlexLayout />
            </FlexLayout>
          </FlexLayout>
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox
          title="공사장소"
          weight={4}
          style={Global.STYLE_COLOR_RED}
          footer={<FlexLayout>
            <AddressButton
              publicStore={scope.props.publicStore}
              modalStore={scope.props.modalStore}
              onChange={(address) => setContent({
                zipcd: address.zonecode,
                address: address.address,
                address2: address.jibunAddress,
              })}
            >
              주소검색
            </AddressButton>
          </FlexLayout>}
        >
          <TextBox
            textAlign="center"
            value={content?.zipcd}
            readonly={true}
          />

          <TextBox
            weight={2}
            value={content?.address}
            onChange={(value) => setContent({ address: value })}
          />

          <TextBox
            weight={2}
            value={content?.address2}
            onChange={(value) => setContent({ address2: value })}
          />
        </LabelBox>

        <LabelBox
          title="공사기간"
          footer={
            <FlexLayout>① 착공일</FlexLayout>
          }
        >
          <DateTextBox
            format="####-##-##"
            textAlign="center"
            value={content?.baldate}
            onChange={(v) => setContent({ baldate: v })}
          />
        </LabelBox>

        <LabelBox title="② 준공일">
          <DateTextBox
            format="####-##-##"
            textAlign="center"
            value={content?.kcdate}
            onChange={(v) => setContent({ kcdate: v })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox title="설치기성금액">
          <FormatNumberTextBox
            textAlign="right"
            value={content?.mamt}
            onChange={(v) => scope.itemChanged('mamt', v)}
          />
        </LabelBox>

        <LabelBox title="공급가액">
          <FormatNumberTextBox
            textAlign="right"
            value={content?.samt}
            onChange={(v) => setContent({ samt: v })}
          />
        </LabelBox>

        <LabelBox title="부가세액">
          <FormatNumberTextBox
            textAlign="right"
            value={content?.tamt}
            onChange={(v) => setContent({ tamt: v })}
          />
        </LabelBox>

        <LabelBox title="계약금-공사금 30%">
          <FormatNumberTextBox
            textAlign="right"
            value={content?.stamt}
            onChange={(v) => setContent({ stamt: v })}
          />
        </LabelBox>

        <LabelBox title="계약보증금(율)-공사금 10%">
          <FormatNumberTextBox
            textAlign="right"
            value={content?.stmamt}
            onChange={(v) => setContent({ stmamt: v })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox
          title="[수급사업자] 주소"
          weight={4}
          style={Global.STYLE_COLOR_RED}
          footer={<FlexLayout>
            <AddressButton
              publicStore={scope.props.publicStore}
              modalStore={scope.props.modalStore}
              onChange={(address) => setContent({
                custzipcd: address.zonecode,
                custadres: address.address,
                custadres2: address.jibunAddress,
              })}
            >
              주소검색
            </AddressButton>
          </FlexLayout>}
        >
          <TextBox
            textAlign="center"
            value={content?.custzipcd}
            readonly={true}
          />

          <TextBox
            weight={2}
            value={content?.custadres}
            onChange={(value) => setContent({ custadres: value })}
          />

          <TextBox
            weight={2}
            value={content?.custadres2}
            onChange={(value) => setContent({ custadres2: value })}
          />
        </LabelBox>

        <LabelBox
          title="(갑) 상호"
          weight={1.2}
        >
          <TextBox
            textAlign="left"
            value={content?.spjangnm}
            onChange={(v) => setContent({ spjangnm: v })}
          />
        </LabelBox>

        <LabelBox
          title="성명(대표이사)"
          weight={0.8}
        >
          <TextBox
            textAlign="center"
            value={content?.prenm}
            onChange={(v) => setContent({ prenm: v })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox
          title="[하수급사업자] 주소"
          weight={4}
          style={Global.STYLE_COLOR_RED}
          footer={<FlexLayout>
            <AddressButton
              publicStore={scope.props.publicStore}
              modalStore={scope.props.modalStore}
              onChange={(address) => setContent({
                xzipcd: address.zonecode,
                xaddress: address.address,
                xaddress2: address.jibunAddress,
              })}
            >
              주소검색
            </AddressButton>
          </FlexLayout>}
        >
          <TextBox
            textAlign="center"
            value={content?.xzipcd}
            readonly={true}
          />

          <TextBox
            weight={2}
            value={content?.xaddress}
            onChange={(value) => setContent({ xaddress: value })}
          />

          <TextBox
            weight={2}
            value={content?.xaddress2}
            onChange={(value) => setContent({ xaddress2: value })}
          />
        </LabelBox>

        <LabelBox title="(을) 상호">
          <TextBox
            textAlign="left"
            value={content?.xspjangnm}
            onChange={(v) => setContent({ xspjangnm: v })}
          />
        </LabelBox>

        <LabelBox
          title="계약일"
          footer={
            <FlexLayout style={content?.attcnt === '0' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}>
              {content?.attcnt === '0' ? '서명없음' : '서명있음'}
            </FlexLayout>
          }
        >
          <DateTextBox
            textAlign="center"
            format="####-##-##"
            value={content?.contdate}
            onChange={(v) => setContent({ contdate: v })}
          />
        </LabelBox>
      </FlexLayout>
    </FlexLayout>
  );
};
