import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import ContractDefaultModel from '../models/ContractDefaultModel';
import { Contract } from '../Contract';

/**
 * 화면 > 탭 - 기본정보 > 아이디 > 팝업
 * @window w_tb_xusers_share
 * @category 외주설치자등록
 */
export const ContractIDModalGridHeader: GLHF<ContractDefaultModel, Contract> = () => ([
  {
    id: 'pernm',
    text: '성명',
    width: 20,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'hpnum',
    text: '핸드폰번호',
    width: 40,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.hpnum}
      </FlexLayout>
    ),
  },
  {
    id: 'email',
    text: '이메일주소',
    width: 40,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.email}
      </FlexLayout>
    ),
  },
]);
