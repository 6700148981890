/**
 * 모델
 * @window w_tb_KaKao_report
 * @category 카카오톡송신내역
 */

export class KakaoTalkModel {
  /**
   * 생성
   *
   * **data type** int
   */
  readonly idx: string;

  /**
   * 받는이연락처
   *
   * **data type** varchar(20)
   */
  readonly hp: string;

  /**
   * 전송내역
   *
   * **data type** varchar(255)
   */
  readonly msg: string;

  /**
   * 전송여부
   *
   * **data type** varchar(1)
   */
  readonly resultcd: string;

  /**
   * 전송일자
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 전송시간
   *
   * **data type** varchar(4)
   */
  readonly intime: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.new = isNew ? '1' : '0';
    this.idx = data.idx || '';
    this.hp = data.hp || '';
    this.msg = data.msg || '';
    this.resultcd = data.resultcd || '';
    this.indate = data.indate || '';
    this.intime = data.intime || '';
  }
}
