import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { FiArrowRightCircle } from 'react-icons/fi';
import { IoIosMail } from 'react-icons/all';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Year } from './Year';
import { SearchBinding } from '../../../../models/common';
import { Date8 } from '../../../../utils/time';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';


const yearly_flags = [
  { value: '0', remark: '연차' },
  { value: '1', remark: '급여' },
];

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

/**
 * 화면
 * @window w_pb209
 * @category 연차생성
 */
export const YearTemplate: React.FC<TemplateProps<Year>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <SearchLayout>
            <FlexLayout>
              <span>년도</span>
              <DateTextBox
                value={scope.state.searchQuery}
                textAlign="center"
                format="####"
                onChange={(value) => setData({ searchQuery: value })}
                onEnter={(value) => setData({ searchQuery: value }, () => scope.onRetrieveEvent())}
                head={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.searchQuery}-01-01`);
                      date.setFullYear(date.getFullYear() - 1);
                      setData({ searchQuery: date.getFullYear().toString() },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>
                )}
                trail={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.searchQuery}-01-01`);
                      date.setFullYear(date.getFullYear() + 1);
                      setData({ searchQuery: date.getFullYear().toString() },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>
                )}
                isDisabledTrackingStateChange={true}
              />

            </FlexLayout>
            <FlexLayout>
              <span style={{ color: 'var(--color-red)' }}>부서</span>
              <TextBox
                value={scope.state.divicd}
                textAlign="center"
                onChange={(value) => setData({ divicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                    scope.onRetrieveEvent();
                  },
                )}
              />
              <TextBox
                value={scope.state.divinm}
                onChange={(value) => setData({ divinm: value })}
                readonly={true}
              />
            </FlexLayout>
            <FlexLayout>
              <span style={{ color: 'var(--color-red)' }}>사용자</span>
              <TextBox
                value={scope.state.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                    scope.onRetrieveEvent();
                  },
                )}
              />
              <TextBox
                value={scope.state.pernm}
                onChange={(value) => setData({ pernm: value })}
                readonly={true}
              />
            </FlexLayout>

            <FlexLayout>
              <span>재직구분</span>
              <ComboBox
                value={scope.state.rtclafi}
                textAlign="center"
                data={[
                  { value: '%', remark: '전체' },
                  { value: '001', remark: '재직자' },
                  { value: '002', remark: '퇴직자' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => setData({ rtclafi: option.value },
                  () => scope.onRetrieveEvent())}
              />
            </FlexLayout>

            <FlexLayout>
              <span>연차생성기준</span>
              <ComboBox
                value={scope.state.holiflag}
                textAlign="center"
                data={[
                  { value: '0', remark: '입사일' },
                  { value: '1', remark: '회계년도' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => setData({ holiflag: option.value })}
              />
            </FlexLayout>
              <Button onClick={() => scope.refresh()}>새로고침</Button>
          </SearchLayout>
        </FlexLayout>
      </FlexLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'nperid',
            text: '사원코드',
            width: 5,
          },
          {
            id: 'pernm',
            text: '사원명',
            width: 5,
          },
          {
            id: 'rspnm',
            text: '직위',
            width: 5,
          },
          {
            id: 'divinm',
            text: '부서명',
            width: 8,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.divinm}
              </FlexLayout>
            ),
          },
          {
            id: 'entdate',
            text: '입사일',
            width: 7,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={Number(x.yearterm) < 2 ? { color: 'var(--color-red)' } : ''}
              >
                {Date8.withDash(x.entdate)}
              </FlexLayout>
            ),
          },
          {
            id: 'yearly_flag',
            text: '연차급여',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {yearly_flags.filter((y) => y.value === x.yearly_flag)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'detail',
            text: (
              <FlexLayout
                style={{ color: 'var(--color-red)' }}
              >
                상세
              </FlexLayout>
            ),
            width: 2,
            render: (x) => (
              <FlexLayout>
                <Button
                  onClick={() => scope.onDetail(true, x)}
                  isIcon={true}
                  theme={BUTTON_THEMES.BLUE}
                >
                  <FiArrowRightCircle size={25}/>
                </Button>
              </FlexLayout>
            ),
          },
          {
            id: 'iwolnum',
            text: '이월일수',
            width: 5,
            render: (x, _, ref) => (
              <TextBox
                textAlign="right"
                ref={ref}
                value={x.iwolnum}
              />
            ),
          },
          {
            id: 'holinum_basic',
            text: '기본연차',
            width: 5,
            render: (x, _, ref) => (
              <TextBox
                textAlign="right"
                ref={ref}
                value={x.holinum_basic}
              />
            ),
          },
          {
            id: 'iwolnum_comp',
            text: '이월확정일수',
            width: 5,
            render: (x, rowUpdate, ref) => (
              <FlexLayout
                style={{ backgroundColor: '#FAFA80' }}
              >
                <TextBox
                  textAlign="right"
                  ref={ref}
                  value={x.iwolnum_comp}
                  onChange={(value) => rowUpdate({ iwolnum_comp: value })}
                  onBlur={(value) => { scope.itemchanged(x, value, 'iwolnum_comp', rowUpdate); }}
                />
              </FlexLayout>
            ),
          },
          {
            id: 'holinum',
            text: '연차생성',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {x.holinum}
              </FlexLayout>
            ),
          },
          {
            id: 'daynum',
            text: (
              <FlexLayout
                style={{ color: 'var(--color-red)' }}
              >
                연차사용일수
              </FlexLayout>
            ),
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
                onDoubleClick={() => scope.onDayNumDoubleClick(true, x)}
              >
                {x.daynum}
              </FlexLayout>
            ),
          },
          {
            id: 'restnum',
            text: '연차잔여일수',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#4C4C4C', backgroundColor: '#B2EBF4' }}
              >
                {x.restnum}
              </FlexLayout>
            ),
          },
          {
            id: 'worknum',
            text: '대체근무일수',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#4C4C4C' }}
              >
                {x.worknum}
              </FlexLayout>
            ),
          },
          {
            id: 'workdaynum',
            text: '대체사용일수',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {x.workdaynum}
              </FlexLayout>
            ),
          },
          {
            id: 'workrestnum',
            text: '대체잔여일수',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ backgroundColor: '#B2EBF4', color: '#662500' }}
              >
                {x.workrestnum}
              </FlexLayout>
            ),
          },
          {
            id: 'totnum',
            text: '연차+대체일수',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {x.totnum}
              </FlexLayout>
            ),
          },
          {
            id: 'totdaynum',
            text: '총사용일수',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {x.totdaynum}
              </FlexLayout>
            ),
          },
          {
            id: 'totrestnum',
            text: '총잔여일수',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ backgroundColor: '#B2EBF4', color: '#662500' }}
              >
                {x.totrestnum}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      />

      <ModalView
        width={1300}
        height={800}
        isVisible={scope.state.detailModal}
        onClose={() => scope.onDetailModalClose()}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 10 }}
        >
          <LayoutTitle>[팝업]연차생성-상세</LayoutTitle>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout
              size={200}
              justify="center"
              align="center"
            >
              <span>년도</span>

              <DateTextBox
                value={scope.state.detailPopupYear}
                textAlign="center"
                format="####"
                onChange={(value) => scope.SS({ detailPopupYear: value })}
                head={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.detailPopupYear}-01-01`);
                      date.setFullYear(date.getFullYear() - 1);
                      scope.SS({ detailPopupYear: date.getFullYear().toString() });
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>
                )}
                trail={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.detailPopupYear}-01-01`);
                      date.setFullYear(date.getFullYear() + 1);
                      scope.SS({ detailPopupYear: date.getFullYear().toString() });
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>
                )}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={300}>
              <span style={{ color: 'var(--color-red)' }}>부서</span>

              <TextBox
                textAlign="center"
                value={scope.state.detailPopupDivicd}
                onChange={(value) => scope.SS({ detailPopupDivicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    scope.setState({
                      detailPopupDivicd: item.cd,
                      detailPopupDivinm: item.cdnm,
                    });
                  },
                )}
              >
              </TextBox>
              <TextBox
                value={scope.state.detailPopupDivinm}
                readonly={true}
                textAlign="center"
                onChange={(value) => scope.SS({ detailPopupDivinm: value })}
              >
              </TextBox>
            </FlexLayout>

            <FlexLayout size={300}>
              <span style={{ color: 'var(--color-red)' }}>사용자</span>

              <TextBox
                value={scope.state.detailPopupPerid}
                textAlign="center"
                onChange={(value) => scope.setState({ detailPopupPerid: value })}
                onEnter={() => scope.onDetailModalRetrieve()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    scope.setState({
                      detailPopupPerid: item.cd,
                      detailPopupPernm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={scope.state.detailPopupPernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </FlexLayout>

            <FlexLayout size={160}>
              <span>재직구분</span>
              <ComboBox
                value={scope.state.detailPopupRtclafi}
                textAlign="center"
                data={[
                  { value: '%', remark: '전체' },
                  { value: '001', remark: '재직자' },
                  { value: '002', remark: '퇴직자' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.SS({ detailPopupRtclafi: option.value })}
              />
            </FlexLayout>

            <Button
              onClick={() => scope.onDetailPopupRetrieve()}
              width={120}>
              조회
            </Button>
          </SearchLayout>

          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'perid',
                  text: '사원코드',
                  width: 60,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.perid}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '사원명',
                  width: 60,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'entdate',
                  text: '입사일',
                  width: 80,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.entdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'dayterm',
                  text: '근무개월',
                  width: 80,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={
                        x.dayterm.substr(1, 1) === '년'
                          && Number(x.dayterm.substr(0, 1)) + Number(x.dayterm.substr(2, 1)) < 2
                          ? { color: 'var(--color-red)' } : ''
                      }
                    >
                      {x.dayterm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'holidate',
                  text: (
                    <FlexLayout isVertical={true}>
                      <FlexLayout>기본연차</FlexLayout>
                      <FlexLayout>생성일</FlexLayout>
                    </FlexLayout>
                  ),
                  width: 80,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.holidate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'holinum_default',
                  text: '기본연차',
                  width: 40,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ backgroundColor: '#B2EBF4' }}
                    >
                      {x.holinum_default}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'holinum_add',
                  text: (
                    <FlexLayout isVertical={true}>
                      <FlexLayout>개근연차(근무개월 1년미만)</FlexLayout>

                      <FlexLayout>
                        <FlexLayout>총</FlexLayout>
                        <FlexLayout>(작년)</FlexLayout>
                        <FlexLayout>(금년)</FlexLayout>
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  width: 100,
                  render: (x) => (
                    <FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {x.holinum_add}
                      </FlexLayout>

                      <FlexLayout>
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          (
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {x.holinum_add_lastyear}
                        </FlexLayout>
                      </FlexLayout>

                      <FlexLayout style={{ backgroundColor: '#B2EBF4' }}>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ marginLeft: 30 }}
                        >
                          {x.holinum_add_thisyear}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          )
                        </FlexLayout>
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'holinum_last',
                  text: (
                    <FlexLayout isVertical={true}>
                      <FlexLayout>작년연차</FlexLayout>

                      <FlexLayout>
                        <FlexLayout>작년연차</FlexLayout>
                        <FlexLayout>연차사용</FlexLayout>
                        <FlexLayout>남은연차</FlexLayout>
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  width: 100,
                  render: (x) => (
                    <FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {x.holinum_last}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {x.daynum_last}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {x.iwolnum}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'iwolnum_comp',
                  text: '이월확정',
                  width: 40,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ backgroundColor: '#B2EBF4' }}
                    >
                      {x.iwolnum_comp}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'restnum',
                  text: (
                    <FlexLayout isVertical={true}>
                      <FlexLayout>금년연차</FlexLayout>

                      <FlexLayout>
                        <FlexLayout>작년연차</FlexLayout>
                        <FlexLayout>연차사용</FlexLayout>
                        <FlexLayout>남은연차</FlexLayout>
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  width: 100,
                  render: (x) => (
                    <FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {x.holinum}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {x.daynum}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ fontWeight: 1000 }}
                      >
                        {x.restnum}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.detailPopupData}
            />
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={1000}
        height={800}
        isVisible={scope.state.daynumModal}
        onClose={() => scope.onDaynumModalClose()}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>[팝업]연차생성-연차사용</LayoutTitle>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout
              size={200}
              justify="center"
              align="center"
            >
              <span>년도</span>

              <DateTextBox
                value={scope.state.daynumPopupYear}
                textAlign="center"
                format="####"
                onChange={(value) => scope.SS({ daynumPopupYear: value })}
                head={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.daynumPopupYear}-01-01`);
                      date.setFullYear(date.getFullYear() - 1);
                      scope.SS({ daynumPopupYear: date.getFullYear().toString() });
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>
                )}
                trail={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.daynumPopupYear}-01-01`);
                      date.setFullYear(date.getFullYear() + 1);
                      scope.SS({ daynumPopupYear: date.getFullYear().toString() });
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>
                )}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={300}>
              <span style={{ color: 'var(--color-red)' }}>사원명</span>

              <TextBox
                value={scope.state.daynumPopupPerid}
                textAlign="center"
                onChange={(value) => scope.setState({ daynumPopupPerid: value })}
                onEnter={() => scope.onDaynumPopupRetrieve()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    scope.setState({
                      daynumPopupPerid: item.cd,
                      daynumPopupPernm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={scope.state.daynumPopupPernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </FlexLayout>

            <Button
              onClick={() => scope.onDaynumPopupRetrieve()}
              width={120}>
              조회
            </Button>
          </SearchLayout>

          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'reqdate',
                  text: '작성일자',
                  width: 7,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.reqdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'vafrdate',
                  text: '시작일',
                  width: 7,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.vafrdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'vatodate',
                  text: '종료일',
                  width: 7,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.vatodate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'usenum',
                  text: '휴가일',
                  width: 4,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {x.usenum}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'daynum2',
                  text: (
                    <FlexLayout
                      align="start"
                      justify="center"
                      isVertical={true}
                    >
                      <FlexLayout>
                        연차
                      </FlexLayout>

                      <FlexLayout>
                        사용일수
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  width: 4,
                  render: (x, ref) => (
                    <FlexLayout
                      ref={ref}
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-green)' }}
                    >
                      {x.daynum === '0' ? '' : x.daynum}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'workdaynum2',
                  text: (
                    <FlexLayout
                      align="start"
                      justify="center"
                      isVertical={true}
                    >
                      <FlexLayout>
                        대체
                      </FlexLayout>

                      <FlexLayout>
                        사용일수
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  width: 4,
                  render: (x, ref) => (
                    <FlexLayout
                      ref={ref}
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {x.workdaynum2 === '0' ? '' : parseInt(x.workdaynum2, 10).toFixed(1)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'atdcd',
                  text: '신청구분',
                  width: 5,
                  render: (x) => (
                    <FlexLayout
                      align='center'
                      justify='center'
                    >
                      {scope.state.atdcds.filter((y) => y.atdcd === x.atdcd)[0]?.atdnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'gowhere',
                  text: '제목',
                  width: 20,
                  render: (x, ref) => (
                    <FlexLayout
                      ref={ref}
                      justify="left"
                      align="center"
                    >
                      {x.gowhere}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'appgubun',
                  text: '상태',
                  width: 7,
                  render: (x) => (
                    <FlexLayout>
                      <FlexLayout
                        weight={2}
                        align='center'
                        justify='center'
                        style={{
                          marginLeft: x.appremark?.length > 0 ? 27 : 0,
                          color: x.appgubun === '101'
                            ? 'var(--color-blue)'
                            : x.appgubun === '131'
                              ? 'var(--color-red)'
                              : '',
                        }}
                      >
                        {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                      </FlexLayout>
                      { x.appremark.length > 0
                        ? <FlexLayout
                          align='center'
                          justify='right'
                          style={{ color: 'var(--color-red)' }}
                        >
                          <IoIosMail size={25}/>
                        </FlexLayout>
                        : ''
                      }
                    </FlexLayout>
                  ),
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.daynumPopupData}
            >
            </GridLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
