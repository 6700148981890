import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca640_pa105_02w
 * @category 월교통비/통행료내역
 */
export class TransportationFeeModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 비용항목
   *
   * **data type** varchar(4)
   */
  artcd: string;

  /**
   * 항목명
   *
   * **data type** varchar(50)
   */
  artnm: string;

  /**
   * 5개월전
   *
   * **data type** varchar(6)
   */
  bemon05: string;

  /**
   * 4개월전
   *
   * **data type** varchar(6)
   */
  bemon04: string;

  /**
   * 3개월전
   *
   * **data type** varchar(6)
   */
  bemon03: string;

  /**
   * 2개월전
   *
   * **data type** varchar(6)
   */
  bemon02: string;

  /**
   * 1개월전
   *
   * **data type** varchar(6)
   */
  bemon01: string;

  /**
   * 해당월
   *
   * **data type** varchar(6)
   */
  mon: string;

  /**
   * 5개월전금액
   *
   * **data type** number
   */
  besamt05: string;

  /**
   * 4개월전금액
   *
   * **data type** number
   */
  besamt04: string;

  /**
   * 3개월전금액
   *
   * **data type** number
   */
  besamt03: string;

  /**
   * 2개월전금액
   *
   * **data type** number
   */
  besamt02: string;

  /**
   * 1개월전금액
   *
   * **data type** number
   */
  besamt01: string;

  /**
   * 해당월금액
   *
   * **data type** number
   */
  samt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.divinm = data.divinm || '';
    this.artcd = data.artcd || '';
    this.artnm = data.artnm || '';
    this.pernm = data.pernm || '';
    this.bemon05 = data.bemon05 || '';
    this.bemon04 = data.bemon04 || '';
    this.bemon03 = data.bemon03 || '';
    this.bemon02 = data.bemon02 || '';
    this.bemon01 = data.bemon01 || '';
    this.mon = data.mon || '';
    this.besamt05 = data.besamt05 || '';
    this.besamt04 = data.besamt04 || '';
    this.besamt03 = data.besamt03 || '';
    this.besamt02 = data.besamt02 || '';
    this.besamt01 = data.besamt01 || '';
    this.samt = data.samt || '';
    this.custcd = data.custcd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
