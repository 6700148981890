import * as React from 'react';
import Lottie from 'react-lottie';
import { RefObject } from 'react';
import style from './RadioBox.module.scss';
import { JoinClassName } from '../../../utils/string';
import radio from '../../../../assets/icons/svg/radio.json';

interface RadioBoxActions {
  onChange?: (value: boolean) => any;
}

interface RadioBoxProps extends RadioBoxActions {
  value: boolean;
  className?: any;
  style?: any;
}

export class RadioBox extends React.Component<RadioBoxProps> {
  lottie: RefObject<Lottie>;

  constructor(props: RadioBoxProps) {
    super(props);
    this.lottie = React.createRef();
  }

  componentDidMount() {
    const { value } = this.props;
    const segments = value ? [29, 30] : [0, 1];

    // @ts-ignore
    this.lottie.current?.anim.playSegments(segments, true);

    // @ts-ignore
    this.lottie.current?.anim.pause();
  }

  shouldComponentUpdate(nextProps: Readonly<RadioBoxProps>): boolean {
    return nextProps.value !== this.props.value;
  }

  componentDidUpdate() {
    const { value } = this.props;
    const segments = value ? [0, 30] : [30, 0];

    // @ts-ignore
    this.lottie.current?.anim.playSegments(segments, true);
  }

  render() {
    const { value, onChange } = this.props;

    return (
      <div
        className={JoinClassName.make([
          style.container,
          this.props.className,
          value ? style.active : false,
        ])}
        style={this.props.style}
        onClick={() => onChange && onChange(!value)}
        role="check-box"
      >
        <Lottie
          ref={this.lottie}
          options={{
            loop: false,
            autoplay: false,
            animationData: radio,
          }}
          speed={2.5}
          isClickToPauseDisabled={true}
          height={18}
          width={18}
        />
      </div>
    );
  }
}
