import * as React from 'react';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Public } from './Public';
import { Global, TemplateProps } from '../../../../constants';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';


/**
 * 화면
 * @window w_tb_ca510
 * @category 공통코드등록
 */
export const PublicTemplate: React.FC<TemplateProps<Public>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="대분류, 분류항목으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'com_cls',
              text: '대분류코드',
              width: 25,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="center"
                  readonly={x.new !== '1'}
                  transparent={true}
                  ref={ref}
                  value={x.com_cls}
                  onChange={(value) => {
                    rowUpdate({ com_cls: value });
                    scope.updateFocusedRow({ com_cls: value });
                  }}/>
              ),
            },
            {
              id: 'com_cnam',
              text: '대분류명칭',
              width: 50,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.com_cnam}
                  onChange={(value) => {
                    rowUpdate({ com_cnam: value });
                    scope.updateFocusedRow({ com_cnam: value });
                  }}
                />
              ),
            },
            {
              id: 'com_rem1',
              text: '분류항목',
              width: 25,
              render: (x, rowUpdate) => (
                x.com_rem1 === '' || x.com_rem1 === null
                  ? <ComboBox
                    value={x.com_rem1}
                    data={[
                      { value: '000', remark: ' ' },
                      { value: '001', remark: '정기' },
                      { value: '002', remark: '정밀' },
                      { value: '003', remark: '자체' },
                      { value: '004', remark: '설치' },
                      { value: '005', remark: '완성' },
                      { value: '006', remark: '수시' },
                      { value: '007', remark: '검사' },
                      { value: '008', remark: '0' },
                    ].map((y) => new ComboBoxModel(y.value, y.remark))}
                    onSelect={(option) => {
                      rowUpdate({ com_rem1: option.value });
                      scope.updateFocusedRow({ com_rem1: option.value });
                    }}
                  />
                  : <ComboBox
                    value={x.com_rem1}
                    data={[
                      { value: '001', remark: '정기' },
                      { value: '002', remark: '정밀' },
                      { value: '003', remark: '자체' },
                      { value: '004', remark: '설치' },
                      { value: '005', remark: '완성' },
                      { value: '006', remark: '수시' },
                      { value: '007', remark: '검사' },
                      { value: '008', remark: '0' },
                    ].map((y) => new ComboBoxModel(y.value, y.remark))}
                    onSelect={(option) => {
                      rowUpdate({ com_rem1: option.value });
                      scope.updateFocusedRow({ com_rem1: option.value });
                    }}
                  />
              ),
            },
          ]}
          data={scope.state.publicList}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
        />

        <FlexLayout isVertical={true}>
          <FlexLayout justify={'right'} size={Global.LAYOUT_BUTTON_HEIGHT_1}>
            <Button style={{ width: '80px' }} onClick={() => scope.onNewEvent2()}>추가</Button>
            <Button style={{ width: '80px' }} theme={BUTTON_THEMES.RED} onClick={() => scope.dw_2_delete()}>삭제</Button>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <TableLayout
              ref={scope.tableDetail}
              header={[
                {
                  id: 'com_code',
                  text: '코드',
                  width: 25,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      textAlign="left"
                      ref={ref}
                      value={x.com_code}
                      onChange={(value) => rowUpdate({ com_code: value })}/>
                  ),
                },
                {
                  id: 'com_cnam',
                  text: '코드명칭',
                  width: 37.5,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      textAlign="left"
                      ref={ref}
                      value={x.com_cnam}
                      onChange={(value) => rowUpdate({ com_cnam: value })}/>
                  ),
                },
                {
                  id: 'com_rem1',
                  text: '내용',
                  width: 37.5,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      textAlign="left"
                      ref={ref}
                      value={x.com_rem1}
                      onChange={(value) => rowUpdate({ com_rem1: value })}/>
                  ),
                },
              ]}
              data={scope.state.publicDetailList}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity2}
              onChange={(rows2, updatedRows2) => {
                scope.onUpdatedRows2(rows2, updatedRows2);
              }}
              onRowFocusEvent={(item) => scope.onDetailRowFocusEvent(item)}
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
