import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  FlexLayout,
  TextBox,
  GridLayout,
  SearchLayout,
  DateTextBox,
  ComboBox,
  ComboBoxModel,
  Button,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { ElectronCheck } from './ElectronCheck';
import { SearchBinding } from '../../../../models';
import { Date6Calculator, Date8, Time4 } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_e035_gosi
 * @category 전자점검연동내역
 */
export const ElectronCheckTemplate: React.FC<TemplateProps<ElectronCheck>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={150}>
          <span>결과</span>
          <ComboBox
            value={scope.state.result}
            textAlign="center"
            style={{ marginLeft: 12 }}
            data={[
              { value: '%', remark: '전체' },
              { value: '00', remark: '성공(정상)' },
              { value: '-99', remark: '시스템오류' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => scope.setState({ result: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stmon}
            textAlign="center"
            format="####-##"
            onChange={(value) => setData({ stmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={280}>
          <span style={{ color: 'var(--color-red)' }}>사용자</span>
          <TextBox
            weight={0.9}
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => setData({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ perid: item.cd, pernm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.pernm}
            textAlign="center"
            readonly={true}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={360}>
          <span>현장</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
          />
        </FlexLayout>

        <FlexLayout size={160}>
          <span>차이일</span>
          <TextBox
            value={scope.state.gigan}
            onChange={(value) => setData({ gigan: value })}
            onEnter={() => scope.onRetrieveEvent()}
          />
        </FlexLayout>

        <FlexLayout>
        </FlexLayout>

          <Button onClick={() => scope.onClickWarp()}>
            정보센터확인
          </Button>

      </SearchLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'RESULT_CODE',
              text: '전송결과',
              width: 80,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x) => {
                let color = '';
                let resultCode = '';

                switch (x.result_code) {
                  case '00':
                    color = 'var(--color-blue)';
                    break;

                  case '0':
                    color = 'var(--color-violet)';
                    break;

                  case '-99':
                    color = 'var(--color-red)';
                    break;

                  default:
                    break;
                }

                switch (x.result_code) {
                  case '00':
                    resultCode = '성공(정상)';
                    break;

                  case '0':
                    resultCode = '성공(연동)';
                    break;

                  case '-99':
                    resultCode = '시스템오류';
                    break;

                  case '':
                    resultCode = '';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color }}
                  >
                    {resultCode}
                  </FlexLayout>
                );
              },
            },
            {
              id: 'wkactcd',
              text: '회사',
              width: 50,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.total}건
                </FlexLayout>
              ),
              render: (x) => (
               <FlexLayout
                 justify="center"
                 align="center"
                 style={x.wkactcd !== '001' ? { color: 'var(--color-violet)' } : ''}
               >
                 {scope.state.wkactcds.filter((y) => y.wkactcd === x.wkactcd)[0]?.wkactnm || '-'}
               </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 200,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                 전송실패 {scope.state.sendcnt1_tot}건
                </FlexLayout>
              ),
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.actnm}
                />
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  전송 {scope.state.sendcnt2_tot}건
                </FlexLayout>
              ),
              render: (x) => (
                <TextBox
                  textAlign="center"
                  value={x.equpnm}
                />
              ),
            },
            {
              id: 'elno',
              text: '승강기번호',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {x.elno?.substr(0, 4)}-{x.elno?.substr(4, 3)}
                </FlexLayout>
              ),
            },
            {
              id: 'kcpernm',
              text: '점검자',
              width: 70,
              render: (x) => (
                <TextBox
                  textAlign="center"
                  value={x.kcpernm}
                />
              ),
            },
            {
              id: 'plandate',
              text: '계획일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {Date8.withDash(x.plandate)}
                </FlexLayout>
              ),
            },
            {
              id: 'rptdate',
              text: '점검일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {Date8.withDash(x.rptdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'senddate',
              text: '전송일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {Date8.withDash(x.senddate)}
                </FlexLayout>
              ),
            },
            {
              id: 'sendtime',
              text: '전송시간',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                 { x.sendtime === '' ? '' : Time4.withDash(x.sendtime)}
                </FlexLayout>
              ),
            },
            {
              id: 'gigan',
              text: '차이일',
              width: 50,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="right"
                  style={Number(x.gigan) > 4 ? { color: 'var(--color-red)' } : ''}
                >
                  {x.gigan}일
                </FlexLayout>
              ),
            },
            {
              id: 'error_message',
              text: '전송실패내용',
              width: 300,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.error_message}
                />
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
          data={scope.state.data}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
