import { TLHF } from '../../../../../constants';
import { CheckBox, FlexLayout, FormatTextBox } from '../../../../../components';
import { Plan } from '../Plan';
import PlanYetModel from '../models/PlanYetModel';

/**
 * 화면 > 미계획 > 테이블 레이아웃
 * @window w_tb_e035
 * @category 점검계획등록
 */
export const PlanYetTableHeader: TLHF<PlanYetModel, Plan> = (scope) => ([
  {
    id: 'chk',
    text: (
      <FlexLayout
        justify="center"
        align="center"
      >
        <CheckBox
          noMargin
          value={scope.state.pushTotalCheck}
          onChange={async (value) => scope
            .updateCheckAllToggle(value)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    ),
    width: 10,
    render: (x, rowUpdate) => (
      <CheckBox
        noMargin
        value={x.chk === '1'}
        onChange={(value) => {
          rowUpdate({ chk: value ? '1' : '0' });
        }}
      />
    ),
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 10,
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpcd',
    text: '호기코드',
    width: 10,
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 10,
  },
  {
    id: 'custcd',
    text: '점검일자',
    width: 10,
    render: (x, rowUpdate, ref) => (
      <FormatTextBox
        ref={ref}
        format="## 일"
        textAlign="right"
        value={x.kcdate}
        onChange={(v) => rowUpdate({ kcdate: v })}
      />
    ),
  },
]);
