import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { BreakCustomerTemplate } from './BreakCustomer.template';
import { InfinityRetrieve } from '../../../../models/common';
import { BreakCustomerModel } from './BreakCustomer.model';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { GridLayoutAdditionalHeader } from '../../../../components/layout/GridLayoutAdditionalHeader';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';
import { TableLayout } from '../../../../components';
import { Update } from '../../../../utils/array';

interface BreakCustomerState {

  // 검색조건
  perid: string;
  pernm: string;
  divicd: string;
  divinm: string;
  searchQuery: string;
  rcvflag: string;

  // 문자, 카톡 전송
  kakaoyn: string;

  stdate: string;
  enddate: string;
  stmon: string;
  endmon: string;
  stday: string;
  endday: string;

  atdcds: Array<any>;

  BreakCustomerList: Array<BreakCustomerModel>;
  focusedBreakCustomer: BreakCustomerModel;

  // 통계
  todayplan_tot: string;
  plancomp_tot: string;
  notplancomp_tot: string;
  todaycompplan_tot: string;
  misplan_tot: string;
  planyul_tot: string;
  plancontcnt_tot: string;
  plancont001cnt_tot: string;
  plancont002cnt_tot: string;
  planmiscnt_tot: string;
  planmisamt_tot: string;
  rececompcnt_tot: string;
  rececnt_tot: string;
  wadcnt_tot: string;
  notkccnt_tot: string;
  kccnt_tot: string;
  kc001cnt_tot: string;
  kc002cnt_tot: string;
  contcnt_tot: string;
  cont001cnt_tot: string;
  cont002cnt_tot: string;
  compcnt_tot: string;
  costcnt_tot: string;
  costcompcnt_tot: string;
  miscnt_tot: string;
  misamt_tot: string;

  total: string;

  // 상세보기 팝업창 Text
  detailText: string;
  detailModal: boolean;
  detailModalTitle: string;
}

/**
 * 컨트롤러
 * @window w_tb_e401w_recvscore
 * @category 고장처리고객만족도
 */
export class BreakCustomer extends PageComponent<PageProps, BreakCustomerState>
  implements PageToolEvents {
  updatedRows?: Array<BreakCustomerModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  additionalSubTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {

      // 조회 조건
      perid: '',
      pernm: '',
      divicd: '',
      divinm: '',
      searchQuery: '',
      rcvflag: '1',

      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      stmon: `${month}`,
      endmon: `${month}`,
      stday: `${date}`,
      endday: `${date}`,

      // 문자, 카톡
      kakaoyn: '',

      atdcds: [],

      data: new BreakCustomerModel(),
      BreakCustomerList: [],

      todayplan_tot: '',
      plancomp_tot: '',
      notplancomp_tot: '',
      todaycompplan_tot: '',
      misplan_tot: '',
      planyul_tot: '',
      plancontcnt_tot: '',
      plancont001cnt_tot: '',
      plancont002cnt_tot: '',
      planmiscnt_tot: '',
      planmisamt_tot: '',
      rececompcnt_tot: '',
      rececnt_tot: '',
      wadcnt_tot: '',
      notkccnt_tot: '',
      kccnt_tot: '',
      kc001cnt_tot: '',
      kc002cnt_tot: '',
      contcnt_tot: '',
      cont001cnt_tot: '',
      cont002cnt_tot: '',
      compcnt_tot: '',
      costcnt_tot: '',
      costcompcnt_tot: '',
      miscnt_tot: '',
      misamt_tot: '',

      total: '',

      // 상세보기 팝업창 Text
      detailText: '',
      detailModal: false,
      detailModalTitle: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const openData = await api.fxExec('open');

    await this.setState({
      kakaoyn: openData?.alim_kakao_useyn,
    });

    const data = await api.dropdown('wf_dd_pb002_03');

    if (!data) return;

    this.setState({
      atdcds: data?.items,
    });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        rcvflag: this.state.rcvflag,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          BreakCustomerList: items,
        }, next);
      },
      async () => {
        await this.SS({
          BreakCustomerList: [],
        });
        await this.infinity?.retrieveAll();

        if (this.state.rcvflag === '1') {
          this.grid.current?.setFocus(0);
        } else {
          this.table.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      BreakCustomerList: [],
    });

    const data = await this.infinity?.retrieveAll();

    await this.setState({
      todayplan_tot: data?.todayplan_tot || '0',
      plancomp_tot: data?.plancomp_tot || '0',
      notplancomp_tot: data?.notplancomp_tot || '0',
      todaycompplan_tot: data?.todaycompplan_tot || '0',
      misplan_tot: data?.misplan_tot || '0',
      planyul_tot: data?.planyul_tot || '0',
      plancontcnt_tot: data?.plancontcnt_tot || '0',
      plancont001cnt_tot: data?.plancont001cnt_tot || '0',
      plancont002cnt_tot: data?.plancont002cnt_tot || '0',
      planmiscnt_tot: data?.planmiscnt_tot || '0',
      planmisamt_tot: data?.planmisamt_tot || '0',
      rececompcnt_tot: data?.rececompcnt_tot || '0',
      rececnt_tot: data?.rececnt_tot || '0',
      wadcnt_tot: data?.wadcnt_tot || '0',
      notkccnt_tot: data?.notkccnt_tot || '0',
      kccnt_tot: data?.kccnt_tot || '0',
      kc001cnt_tot: data?.kc001cnt_tot || '0',
      kc002cnt_tot: data?.kc002cnt_tot || '0',
      contcnt_tot: data?.contcnt_tot || '0',
      cont001cnt_tot: data?.cont001cnt_tot || '0',
      cont002cnt_tot: data?.cont002cnt_tot || '0',
      compcnt_tot: data?.compcnt_tot || '0',
      costcnt_tot: data?.costcnt_tot || '0',
      costcompcnt_tot: data?.costcompcnt_tot || '0',
      miscnt_tot: data?.miscnt_tot || '0',
      misamt_tot: data?.misamt_tot || '0',
      total: data?.total || '0',
    });

    if (this.state.rcvflag === '1') {
      await this.grid.current?.setFocus(0);
    } else {
      await this.table.current?.setFocus(0);
      await this.table.current?.update(true);
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.BreakCustomerList.length > 0, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.BreakCustomerList.length > 0, '오류', '엑셀 변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
      rcvflag: this.state.rcvflag,
    });
  }

  @action
  onRowFocusEvent(item:BreakCustomerModel) {
    this.setState({ focusedBreakCustomer: item });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ BreakCustomerList: rows });
  }

  @action
  async onCheckList(type: string) {
    let noChk = true;

    // 전송할 내역 있는지 찾기
    this.state.BreakCustomerList.forEach((x) => {
      if (x.chk === '1') {
        noChk = false;
      }
    });

    // chk 하나도 안되있을 때 경고창
    if (noChk) {
      ConfirmWarning.show('경고', '전송할 내역을 하나이상 선택해주세요.');
      return;
    }

    ConfirmWarning.show('경고', '연락처가 없거나 핸드폰번호가 아닌 번호는 \n 자동 전송하지 않습니다\n 선택이 해제됩니다');

    // 체크한것중에서 신고자 번호가없거나 번호가 010이 아닌 리스트는 체크풀어줌
    for (let i = 0; i < this.state.BreakCustomerList.length; i += 1) {
      const x = this.state.BreakCustomerList[i];

      if (x.chk === '1' && !x.hp?.startsWith('010')) {
        // eslint-disable-next-line no-await-in-loop
        await Update.byIndex(this, 'BreakCustomerList', i, 'chk', '0');
      }
    }

    await this.table.current?.update(false);

    // kakao버튼이면 kakao로
    // sms버튼이면 sms로
    if (type === 'kakao') {
      await this.onSendKakao();
    } else if (type === 'sms') {
      await this.onSendSms();
    }
  }

  @action
  async onSendSms() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_sms', {
      items: this.state.BreakCustomerList?.filter((x) => x.chk === '1').map((x) => ({
        chk: x.chk,
        recedate: x.recedate,
        recenum: x.recenum,
        actnm: x.actnm,
        hp: x.hp,
      })),
    });

    if (data?.titlebox === '성공') {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onSendKakao() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_kakaosend', {
      items: this.state.BreakCustomerList?.filter((x) => x.chk === '1').map((x) => ({
        chk: x.chk,
        recedate: x.recedate,
        recenum: x.recenum,
        actnm: x.actnm,
        hp: x.hp,
      })),
    });

    if (data?.titlebox === '성공') {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDetailText(isOpen: boolean, text: string, title: string) {
    await this.SS({
      detailText: text,
      detailModal: isOpen,
      detailModalTitle: title,
    });
  }

  @action
  async onCloseDetailModal() {
    await this.SS({
      detailText: '',
      detailModal: false,
      detailModalTitle: '',
    });
  }

  render() {
    return (
      <BreakCustomerTemplate scope={this} />
    );
  }
}
