import * as React from 'react';
import { Button, FlexLayout, TableLayout } from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Regist } from '../Regist';
import { RegistTabTableTelHeader } from '../headers';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';

/**
 * 화면 > 상세 > 탭 - 담당연락처
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabTel: React.FC<TemplateProps<Regist>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout
      size={Global.LAYOUT_BUTTON_HEIGHT_1}
      justify="end"
    >
      <Button width={85} onClick={() => scope.onClickTelNew()}>추가</Button>
      <Button width={85} theme={BUTTON_THEMES.RED} onClick={() => scope.onClickTelRemove()}>삭제</Button>
    </FlexLayout>

    <TableLayout
      ref={scope.tableTel}
      header={RegistTabTableTelHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.tabDataTel}
      onChange={(rows, updates) => scope.onTabTableTelChange(rows, updates)}
      onRowFocusEvent={(_, index) => scope.onTabTableTelRowFocused(index)}
    />
  </FlexLayout>
);
