

export class CheckModalModel {
  /**
   * 부서명
   *
   */
  readonly divinm: string

  /**
   * 담당자
   *
   */
  readonly pernm: string

  /**
   * 진행
   *
   */
  readonly state: string

  /**
   * 만료일자
   *
   */
  readonly enddate: string

  /**
   * 검사일자
   *
   */
  readonly plandate: string

  /**
   * 결과
   *
   */
  readonly result: string

  /**
   * 현장코드
   *
   */
  readonly actcd: string

  /**
   * 현장명
   *
   */
  readonly actnm: string

  /**
   * 호기명
   *
   */
  readonly equpnm: string

  /**
   * 호기코드
   *
   */
  readonly equpcd: string

  /**
   * 구분
   *
   */
  readonly gubun: string

  /**
   * 검사일자
   *
   */
  readonly kcdate: string

  /**
   * 검사자
   *
   */
  readonly kcpernm: string

  /**
   * 검사기관
   *
   */
  readonly kcspnm: string

  /**
   * 부서코드
   *
   */
  readonly divicd: string

  /**
   * 회사코드
   *
   */
  readonly custcd: string

  /**
   * 승강기번호
   *
   */
  readonly elno: string

  constructor(data: any = {}) {
    this.divinm = data.divinm || '';
    this.divicd = data.divicd || '';
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.elno = data.elno || '';
    this.pernm = data.pernm || '';
    this.state = data.state || '';
    this.enddate = data.enddate || '';
    this.plandate = data.plandate || '';
    this.result = data.result || '';
    this.actnm = data.actnm || '';
    this.actcd = data.actcd || '';
    this.equpnm = data.equpnm || '';
    this.equpcd = data.equpcd || '';
    this.gubun = data.gubun || '';
    this.kcdate = data.kcdate || '';
    this.kcpernm = data.kcpernm || '';
    this.kcspnm = data.kcspnm || '';
  }
}
