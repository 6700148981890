import { GLHF } from '../../../../../constants';
import { ChargeReceivable } from '../ChargeReceivable';
import { FlexLayout } from '../../../../../components';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

export const WeekendReceivablesTabGridHeader: GLHF<any, ChargeReceivable> = (scope) => ([
  {
    id: 'actcd',
    text: '현장코드',
    width: 3,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.actcd}
      </FlexLayout>
    ),
    sum: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        소 계
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumActnm = Sum.groupLength(data, 'actnm', x, ['wkactcd', 'pernm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {sumActnm} 건
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope.state.total}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자별그룹',
    isHidden: true,
    group: true,
    value: (x) => x.pernm,
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 3,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 3,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'beamt',
    text: '전월미수금',
    width: 3,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.beamt)}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumBeamt = Sum.byGroup(data, 'beamt', x, ['pernm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumBeamt)}
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.beamt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt5',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          당월 미수금
        </FlexLayout>
      </FlexLayout>
    ),
    width: 3,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.iamt5)}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumIamt5 = Sum.byGroup(data, 'iamt5', x, ['pernm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumIamt5)}
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(scope.state.iamt5_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '수금액',
    width: 3,
    render: (x) => {
      const rcv = parseInt(x.rcvamt, 10) > 0
        ? <FlexLayout justify="right" align="center">{Format.number(x.rcvamt)}</FlexLayout>
        : <FlexLayout/>;
      return (
        rcv
      );
    },
    sum: (x, data) => {
      const sumRcvamt = Sum.byGroup(data, 'rcvamt', x, ['pernm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumRcvamt)}
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.rcvamt_tot}
      </FlexLayout>
    ),
  },
]);
