import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Balance } from './Balance';
import { SearchBinding } from '../../../../models/common';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';

const gubuns = [
  { value: 'x', remark: '재고조정' },
  { value: 'i', remark: '입고' },
  { value: 'c', remark: '출고' },
  { value: 'b', remark: '반납' },
];

/**
 * 화면
 * @window w_tb_ca602w_01
 * @category 현재고현황
 */

export const BalanceTemplate: React.FC<TemplateProps<Balance>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <FlexLayout
        style={{
          width: 1150,
          paddingBottom: 6,
        }}
        size={18}
        justify='center'
        align='center'
      >
        <span
          style={{ color: 'var(--color-blue)' }}>
          * 더블클릭 시 재고이력이 나옵니다.
        </span>
      </FlexLayout>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={200}>
          <span>년도</span>
          <DateTextBox
            value={scope.state.year}
            textAlign="center"
            format="####"
            onChange={(value) => scope.setState({ year: value })}
            onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  scope.setState({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() + 1);
                  scope.setState({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>창고</span>
          <TextBox
            value={scope.state.store}
            textAlign="center"
            onChange={(value) => scope.setState({ store: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_CA504',
              {},
              true,
              (item) => {
                scope.setState({
                  store: item.cd,
                  storenm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.storenm}
            weight={2}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout weight={1}>
          <span style={{ marginRight: 20 }}>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="제품, 제품한글명, 규격, 조정사유로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={200}>
          <span>재고유무</span>
          <ComboBox
            value={scope.state.qtyflag}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '재고있음' },
              { value: '0', remark: '재고없음' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => scope.setState({ qtyflag: option.value },
              () => {
                scope.onRetrieveEvent();
              })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
      <TableLayout
        ref={scope.table}
        isPaging={true}
        header={[
          {
            id: 'pcode',
            text: '품목코드',
            width: 150,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합 계
              </FlexLayout>
            ),
            render: (x, _, ref) => (
              <TextBox
                ref={ref}
                value={x.pcode}
                textAlign="center"
              />
            ),
          },
          {
            id: 'pname',
            text: '품목명',
            width: 250,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.total} 건
              </FlexLayout>
            ),
            render: (x, _, ref) => (
              <FlexLayout
                onDoubleClick={() => scope.loadDetailModal(true)}
              >
                <TextBox
                  ref={ref}
                  value={x.pname}
                  textAlign="left"
                />
              </FlexLayout>
            ),
          },
          {
            id: 'psize',
            text: '규격',
            width: 200,
            render: (x, _, ref) => (
              <TextBox
                ref={ref}
                value={x.psize}
                textAlign="left"
              />
            ),
          },
          {
            id: 'punit',
            text: '단위',
            width: 50,
            render: (x, _, ref) => (
              <TextBox
                ref={ref}
                value={x.punit}
                textAlign="center"
              />
            ),
          },
          {
            id: 'qty',
            text: '현재고수량',
            width: 75,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.qty_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-black)' }}
                justify='center'
                align='center'
              >
                {x.qty}
              </FlexLayout>
            ),
          },
          {
            id: 'xqty',
            text: '실사(조정)수량',
            width: 75,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.xqty_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-green)' }}
              >
                {Format.number(x.xqty)}
              </FlexLayout>
            ),
          },
          {
            id: 'iqty',
            text: '입고수량',
            width: 75,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.iqty_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-blue)' }}
                justify='center'
                align='center'
              >
                {x.iqty}
              </FlexLayout>
            ),
          },
          {
            id: 'cqty',
            text: '출고수량',
            width: 75,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.cqty_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-red)' }}
                justify='center'
                align='center'
              >
                {x.cqty}
              </FlexLayout>
            ),
          },
          {
            id: 'bqty',
            text: '반납수량',
            width: 75,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.bqty_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-blue)' }}
                justify='center'
                align='center'
              >
                {x.bqty}
              </FlexLayout>
            ),
          },
          {
            id: 'remark',
            text: '조정사유',
            width: 200,
            render: (x, _, ref) => (
              <TextBox
                ref={ref}
                value={x.remark}
                textAlign="left"
              />
            ),
          },
        ]}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.balanceList}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
        onDoubleClick={() => scope.loadDetailModal(true)}
      />

      <ModalView
        isVisible={scope.state.loadDetailModal}
        onClose={() => scope.loadDetailModal(false)}
        width={1100}
        height={600}
        zIndex={-1}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <LayoutTitle>상세내역</LayoutTitle>
          <FlexLayout
            isVertical={true}
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
          >
            <FlexLayout>
              <FlexLayout weight={3}>
                <LayoutTitle size={70}>
                  창고명
                </LayoutTitle>
                <LabelBox>
                  <TextBox
                    value={scope.state.storenm}
                    textAlign="left"
                  />
                </LabelBox>
              </FlexLayout>
              <FlexLayout weight={3}>
                <LayoutTitle size={70}>
                  품목코드
                </LayoutTitle>
                <LabelBox>
                  <TextBox
                    value={scope.state.focusedBalance?.pcode}
                    textAlign="left"
                  />
                </LabelBox>
              </FlexLayout>
              <FlexLayout weight={4}>
                <LayoutTitle size={70}>
                  품목명
                </LayoutTitle>
                <LabelBox>
                  <TextBox
                    value={scope.state.focusedBalance?.pname}
                    textAlign="left"
                  />
                </LabelBox>
              </FlexLayout>
              <FlexLayout weight={4}>
                <LayoutTitle size={70}>
                  규격
                </LayoutTitle>
                <LabelBox>
                  <TextBox
                    value={scope.state.focusedBalance?.psize}
                    textAlign="left"
                  />
                </LabelBox>
              </FlexLayout>
              <FlexLayout weight={2}>
                <LayoutTitle size={70}>
                  단위
                </LayoutTitle>
                <LabelBox>
                  <TextBox
                    value={scope.state.popuoPunit}
                    textAlign="left"
                  />
                </LabelBox>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
          <GridLayout
            ref={scope.popupGrid}
            header={[
              {
                id: 'seq',
                text: '순서',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.seq}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'idate',
                text: '일자',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.idate}
                  />
                ),
              },
              {
                id: 'inum',
                text: '번호',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.inum}
                  </FlexLayout>
                ),
              },
              {
                id: 'qty',
                text: '수량',
                width: 100,
                trail: (data) => {
                  const sumQty = Sum.all(data, 'qty');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Format.number(String(sumQty))}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.qty}
                  </FlexLayout>
                ),
              },
              {
                id: 'uamt',
                text: '단가',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.uamt}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '등록자',
                width: 150,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '납품장소',
                width: 300,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupList}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.modalRowFocusEvent(item)}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.loadDetailModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
);
