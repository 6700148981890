import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da026_import
 * @category 지로자동이체
 */
export class JiroAutoDetail2Model {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** char(4)
   */
  custcd: string;

  /**
   * 청구일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 청구금액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 입금일자
   *
   * **data type** varchar(8)
   */
  rcvdate: string;

  /**
   * 입금구분
   *
   * **data type** char(1)
   */
  chk: string;

  /**
   * 입금액
   *
   * **data type** varchar(8)
   */
  rcvamt: string;

  /**
   * 미수잔액
   *
   * **data type** number
   */
  misuamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.misdate = data.misdate || '';
    this.misamt = data.misamt || '';
    this.rcvdate = data.rcvdate || '';
    this.chk = data.chk || '';
    this.rcvamt = data.rcvamt || '';
    this.misuamt = data.misuamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
