/**
 * 모델
 * @window w_popup_tb_e601w_sulchi_09
 * @category 설치계약달성현황
 */

export class CompPeridsPopupModel {
  /**
   * 건수
   *
   * **data type** varchar(8)
   */
  readonly cnt: string;

  /**
   * 대수
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 공급가액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 부가세액
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 합계금액
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 건수 년합계
   *
   * **data type** varchar(8)
   */
  readonly cnttot: string;

  /**
   * 건수 01월
   *
   * **data type** varchar(8)
   */
  readonly cnt01: string;

  /**
   * 건수 02월
   *
   * **data type** varchar(8)
   */
  readonly cnt02: string;

  /**
   * 건수 03월
   *
   * **data type** varchar(8)
   */
  readonly cnt03: string;

  /**
   * 건수 04월
   *
   * **data type** varchar(8)
   */
  readonly cnt04: string;

  /**
   * 건수 05월
   *
   * **data type** varchar(8)
   */
  readonly cnt05: string;

  /**
   * 건수 06월
   *
   * **data type** varchar(8)
   */
  readonly cnt06: string;

  /**
   * 건수 07월
   *
   * **data type** varchar(8)
   */
  readonly cnt07: string;

  /**
   * 건수 08월
   *
   * **data type** varchar(8)
   */
  readonly cnt08: string;

  /**
   * 건수 09월
   *
   * **data type** varchar(8)
   */
  readonly cnt09: string;

  /**
   * 건수 10월
   *
   * **data type** varchar(8)
   */
  readonly cnt10: string;

  /**
   * 건수 11월
   *
   * **data type** varchar(8)
   */
  readonly cnt11: string;

  /**
   * 건수 12월
   *
   * **data type** varchar(8)
   */
  readonly cnt12: string;

  /**
   * 대수 년합계
   *
   * **data type** varchar(8)
   */
  readonly qtytot: string;

  /**
   * 대수 01월
   *
   * **data type** varchar(8)
   */
  readonly qty01: string;

  /**
   * 대수 02월
   *
   * **data type** varchar(8)
   */
  readonly qty02: string;

  /**
   * 대수 03월
   *
   * **data type** varchar(8)
   */
  readonly qty03: string;

  /**
   * 대수 04월
   *
   * **data type** varchar(8)
   */
  readonly qty04: string;

  /**
   * 대수 05월
   *
   * **data type** varchar(8)
   */
  readonly qty05: string;

  /**
   * 대수 06월
   *
   * **data type** varchar(8)
   */
  readonly qty06: string;

  /**
   * 대수 07월
   *
   * **data type** varchar(8)
   */
  readonly qty07: string;

  /**
   * 대수 08월
   *
   * **data type** varchar(8)
   */
  readonly qty08: string;

  /**
   * 대수 09월
   *
   * **data type** varchar(8)
   */
  readonly qty09: string;

  /**
   * 대수 10월
   *
   * **data type** varchar(8)
   */
  readonly qty10: string;

  /**
   * 대수 11월
   *
   * **data type** varchar(8)
   */
  readonly qty11: string;

  /**
   * 대수 12월
   *
   * **data type** varchar(8)
   */
  readonly qty12: string;

  /**
   * 총금액 년합계
   *
   * **data type** varchar(8)
   */
  readonly mamttot: string;

  /**
   * 총금액 01월
   *
   * **data type** varchar(8)
   */
  readonly mamt01: string;

  /**
   * 총금액 02월
   *
   * **data type** varchar(8)
   */
  readonly mamt02: string;

  /**
   * 총금액 03월
   *
   * **data type** varchar(8)
   */
  readonly mamt03: string;

  /**
   * 총금액 04월
   *
   * **data type** varchar(8)
   */
  readonly mamt04: string;

  /**
   * 총금액 05월
   *
   * **data type** varchar(8)
   */
  readonly mamt05: string;

  /**
   * 총금액 06월
   *
   * **data type** varchar(8)
   */
  readonly mamt06: string;

  /**
   * 총금액 07월
   *
   * **data type** varchar(8)
   */
  readonly mamt07: string;

  /**
   * 총금액 08월
   *
   * **data type** varchar(8)
   */
  readonly mamt08: string;

  /**
   * 총금액 09월
   *
   * **data type** varchar(8)
   */
  readonly mamt09: string;

  /**
   * 총금액 10월
   *
   * **data type** varchar(8)
   */
  readonly mamt10: string;

  /**
   * 총금액 11월
   *
   * **data type** varchar(8)
   */
  readonly mamt11: string;

  /**
   * 총금액 12월
   *
   * **data type** varchar(8)
   */
  readonly mamt12: string;

  constructor(data: any = {}) {
    this.cnt = data.cnt || '';
    this.qty = data.qty || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.pernm = data.pernm || '';
    this.cnttot = data.cnttot || '';
    this.cnt01 = data.cnt01 || '';
    this.cnt02 = data.cnt02 || '';
    this.cnt03 = data.cnt03 || '';
    this.cnt04 = data.cnt04 || '';
    this.cnt05 = data.cnt05 || '';
    this.cnt06 = data.cnt06 || '';
    this.cnt07 = data.cnt07 || '';
    this.cnt08 = data.cnt08 || '';
    this.cnt09 = data.cnt09 || '';
    this.cnt10 = data.cnt10 || '';
    this.cnt11 = data.cnt11 || '';
    this.cnt12 = data.cnt12 || '';
    this.qtytot = data.qtytot || '';
    this.qty01 = data.qty01 || '';
    this.qty02 = data.qty02 || '';
    this.qty03 = data.qty03 || '';
    this.qty04 = data.qty04 || '';
    this.qty05 = data.qty05 || '';
    this.qty06 = data.qty06 || '';
    this.qty07 = data.qty07 || '';
    this.qty08 = data.qty08 || '';
    this.qty09 = data.qty09 || '';
    this.qty10 = data.qty10 || '';
    this.qty11 = data.qty11 || '';
    this.qty12 = data.qty12 || '';
    this.mamttot = data.mamttot || '';
    this.mamt01 = data.mamt01 || '';
    this.mamt02 = data.mamt02 || '';
    this.mamt03 = data.mamt03 || '';
    this.mamt04 = data.mamt04 || '';
    this.mamt05 = data.mamt05 || '';
    this.mamt06 = data.mamt06 || '';
    this.mamt07 = data.mamt07 || '';
    this.mamt08 = data.mamt08 || '';
    this.mamt09 = data.mamt09 || '';
    this.mamt10 = data.mamt10 || '';
    this.mamt11 = data.mamt11 || '';
    this.mamt12 = data.mamt12 || '';
  }
}
