import { MdKeyboardArrowRight } from 'react-icons/md';
import {
  Global,
  TemplateProps,
} from '../../../../../constants';
import { PurchasingStatistics } from '../PurchasingStatistics';
import {
  FlexLayout,
  GridLayout,
  OptionBox,
  SearchLayout,
  TextBox,
} from '../../../../../components';
import { PurchasingStatisticsTabActcdGridHeader } from '../headers/PurchasingStatisticsTabActcdGridHeader';

export const PurchasingStatisticsActcdTemplate: React.FC<TemplateProps<PurchasingStatistics>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={400}>
        <span>찾기</span>
        <TextBox
          value={scope.state.tab3Search}
          onChange={(value) => scope.SS({ tab3Search: value })}
          onEnter={() => scope.tab3SearchFocus()}
        >
        </TextBox>
        <button onClick={() => scope.tab3SearchFocus()}>
          <MdKeyboardArrowRight size={24}/>
        </button>
      </FlexLayout>

      <FlexLayout size={400}>
        <span>지급기준</span>
        <OptionBox
          value={scope.state.dateflag}
          data={[
            { value: '0', remark: '매입일자' },
            { value: '1', remark: '지급일자' },
          ]}
          onChange={(item) => scope.SS({ dateflag: item.value })}
        />
      </FlexLayout>
    </SearchLayout>
    <GridLayout
      ref={scope.gridActcd}
      header={PurchasingStatisticsTabActcdGridHeader(scope)}
      headerHeight={60}
      rowHeight={48}
      data={scope.state.dataActcd}
    />
  </FlexLayout>
);
