export default class AttachmentInfo {
  /**
   * 윈도우명
   *
   * **data type** varchar(8)
   */
  readonly sub: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 문서 번호
   *
   * **data type** varchar(13)
   */
  readonly appnum: string;

  /**
   * 파일 번호
   *
   * **data type** float
   */
  readonly fileseq: string;

  /**
   * 파일 번호2
   *
   * **data type** float
   */
  readonly seq: string;

  /**
   * 문서 이름
   *
   * **data type** varchar(100)
   */
  readonly appnm: string;

  /**
   * 파일 제목(출력시 표기됨)
   *
   * **data type** varchar(255)
   */
  readonly title: string;

  /**
   * 파일명
   *
   * **data type** varchar(255)
   */
  readonly filename: string;

  /**
   * 파일 확장자
   *
   * **data type** varchar(5)
   */
  readonly extension: string;

  /**
   * 파일 크기
   *
   * **data type** float
   */
  readonly size: number;

  /**
   * 삭제
   * - no DB
   *
   * **data type** bool
   */
  readonly delete: boolean;


  constructor(data: any = {}) {
    const filename = data?.filename || data?.tempfile?.filename || '';

    this.sub = data?.sub || '';
    this.custcd = data?.custcd || '';
    this.spjangcd = data?.spjangcd || '';
    this.appnum = data?.appnum || '';
    this.fileseq = data?.fileseq || '';
    this.seq = data?.seq || '';
    this.appnm = data?.appnm || '';
    this.title = data?.title || '';
    this.filename = filename.startsWith('.') ? `이름없음${filename}` : filename;
    this.extension = data?.fileext
      || data?.tempfile?.filename?.substr(data?.tempfile?.filename?.lastIndexOf('.') + 1) || '';
    this.size = data?.filesize || data?.tempfile?.size || '';
    this.delete = false;
  }
}
