import * as React from 'react';
import {
  Button,
  CheckBox,
  FlexLayout,
  TableLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  Failure,
  FailureTabDataStateNames,
  FailureTabFocusedStateNames,
  FailureTabId,
  FailureTabUpdatesStateNames,
} from './Failure';
import { Global, TemplateProps } from '../../../../constants';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';


/**
 * 화면
 * @window w_tb_e010
 * @category 고장관리코드
 */
export const FailureTemplate: React.FC<TemplateProps<Failure>> = ({
  update,
  scope,
  requestRowFocusEvent,
}) => {
  const setData = update!;
  const request = requestRowFocusEvent!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <TabView
          tabs={scope.tabs}
          focused={scope.state.focusedTab}
          onChange={(tab) => scope.onTabChange(tab)}
        />
      </FlexLayout>
      <FlexLayout isVertical={true}>
        <TableLayout
          ref={scope.table}
          header={scope.tabHeaders[scope.tabIndex]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          // @ts-ignore
          data={scope.state[FailureTabDataStateNames[scope.tabIndex]]}
          onRowFocusEvent={(item) => {
            setData({
              [FailureTabFocusedStateNames[scope.tabIndex]]: item,
            }, () => {
              if (scope.tabIndex === FailureTabId.Part) {
                request();
              }
            });
          }}
          onChange={(rows, updatedRows) => setData({
            [FailureTabDataStateNames[scope.tabIndex]]: rows,
            [FailureTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
          })}
        />
        {scope.tabIndex === FailureTabId.Part && <FlexLayout isVertical={true}>
          <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
            <FlexLayout justify="right" weight={3}>
                <Button
                  style={{ height: '25px', width: '60px' }}
                  onClick={() => scope.onNewEvent2()}
                >
                  추가
                </Button>
                <Button
                  style={{ height: '25px', width: '60px' }}
                  theme={BUTTON_THEMES.RED}
                  onClick={() => scope.onDeleteEvent2()}
                >
                  삭제
                </Button>
            </FlexLayout>
        </FlexLayout>
        <TableLayout
          ref={scope.tableDetail}
          header={[
            {
              id: 'regicd',
              text: '코드',
              width: 50,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.regicd}
                  onChange={(value) => rowUpdate({ regicd: value })}
                  readonly={x?.new !== '1'}
                  transparent={true}
                  textAlign="center"
                />
              ),
            },
            {
              id: 'reginm',
              text: '고장상세부위명',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.reginm}
                  onChange={(value) => rowUpdate({ reginm: value })}
                />
              ),
            },
            {
              id: 'useyn',
              text: '사용유무',
              width: 30,
              render: (x, rowUpdate) => (
                <CheckBox
                  value={x.useyn === '1'}
                  onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
                />
              ),
            },
            {
              id: 'remark',
              text: '비고',
              width: 300,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.remark}
                  onChange={(value) => rowUpdate({ remark: value })}
                />
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          data={scope.state.partsDetail}
          onChange={(rows2, updatedRows2) => {
            scope.onUpdatedRows2(rows2, updatedRows2);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
        />
        </FlexLayout>}
      </FlexLayout>
    </FlexLayout>
  );
};
