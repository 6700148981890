import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { PublicTemplate } from './Public.template';
import { PublicModel } from './models/Public.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ConfirmDelete, ConfirmSuccess, ConfirmWarning } from '../../../../utils/confirm';
import { InfinityRetrieve } from '../../../../models/common';
import { PublicModel2 } from './models/Public.model2';
import { PageComponent } from '../../../../utils';

interface PublicState {
  searchQuery: string;
  total: string;
  com_cnam: string;
  publicList: Array<PublicModel>;
  publicDetailList: Array<PublicModel2>;
  focusedPublic: PublicModel;
  focused: PublicModel2;
}

/**
 * 컨트롤러
 * @window w_tb_ca510
 * @category 공통코드등록
 */
export class Public extends PageComponent<PageProps, PublicState>
  implements PageToolEvents {
  updatedRows?: Array<PublicModel>;

  updatedRows2?: Array<PublicModel2>;

  table: React.RefObject<TableLayout> = React.createRef();

  tableDetail: React.RefObject<TableLayout> = React.createRef();

  tableFocus?: PublicModel;

  tableFocusIndex: number = 0;

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  // newchk?: string;

  // newRowCount: number;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      total: '',
      publicDetailList: [],
      publicList: [],
      com_cnam: '',
    };
    // this.newchk = '0';
    // this.newRowCount = 0;
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          publicList: [...this.state.publicList, ...items.map((item) => new PublicModel(item))],
        });
      },
      async () => {
        await this.SS({
          publicList: [],
        });
        await this.infinity?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      publicList: [],
      publicDetailList: [],
    }, async () => {
      const data = await this.infinity?.retrieveAll();
      this.setState({
        total: data.total,
      });
      // let next = this.tableFocusIndex;
      //
      // if (this.state.publicList.length - 1 < next) {
      //   next = this.tableFocusIndex === 0 ? 0 : this.tableFocusIndex - 1;
      //   this.table.current?.setFocus(next);
      // }
      // await this.table.current?.update();
      // await this.table.current?.setFocus(next);
      // this.newchk = '0';
      // this.newRowCount = 0;

      this.table.current?.update(true);
      if (this.state.publicList.length > 0) {
        this.table.current?.setFocus(0);
      }
    });
  }

  @action
  onRowFocusEvent(item: PublicModel | undefined, index: number) {
    // this.tableFocus = item;
    this.tableFocusIndex = index;

    if (item?.isNew) {
      return;
    }
    if (!item) {
      return;
    }

    const { actionStore: api } = this.props;
    this.updatedRows2 = [];

    this.setState({
      focusedPublic: item,
      publicDetailList: [],
    },
    async () => {
      await this.tableDetail.current?.update(true);

      // 무한 스크롤바 헬퍼 초기화
      this.infinity2 = new InfinityRetrieve(
        this.state.focusedPublic,
        (params) => api.fxExec('dw_1_RowFocuschanged', params),
        (items) => {
          this.setState({
            publicDetailList: [...this.state.publicDetailList, ...items.map((x: any) => new PublicModel2(x))],
          }, async () => {
            await this.tableDetail.current?.update(true);
          });
        },
        async () => {
          await this.SS({
            publicDetailList: [],
          });
          await this.infinity2?.retrieveAll();
        },
      );

      await this.infinity2.retrieveAll();
      await this.tableDetail.current?.update(true);
      await this.tableDetail.current?.setFocus(0);
    });
  }

  @action
  async onDetailRowFocusEvent(item: PublicModel2) {
    if (item?.new === '1') return;
    this.setState({ focused: item });
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    if (this.state.focusedPublic.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.new();

    // this.newchk = '1';
    // this.newRowCount += 1;

    if (data) {
      // data.new = '1';
      // data.com_rem1 = '';
      data && this.setState({
        publicDetailList: [],
        publicList: [...this.state.publicList, new PublicModel(data, true)],
      }, async () => {
        await this.table.current?.update(true);
        await this.tableDetail.current?.update(true);
        await this.table.current?.setFocus(this.state.publicList.length - 1, 1);
        this.setState({ focusedPublic: new PublicModel(data, true) });
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      com_cls: this.state.focusedPublic?.com_cls,
      com_code: this.state.focusedPublic?.com_code,
      com_cnam: this.state.focusedPublic?.com_cnam,
      com_rem1: this.state.focusedPublic?.com_rem1,
      items: this.updatedRows2,
    }, this.state.focusedPublic?.isNew)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
    // this.newRowCount = 0;
    await this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `대분류코드: ${this.state.focusedPublic?.com_cls} 코드명칭: ${this.state.focusedPublic?.com_cnam}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '하단 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      await api.delete(text2, this.state.focusedPublic) && this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_2_new',
      {
        com_cls: this.state.focusedPublic?.com_cls,
      },
    );

    data && this.setState({
      publicDetailList: [...this.state.publicDetailList,
        new PublicModel2(data, true)],
    }, async () => {
      await this.tableDetail.current?.update(true);
      this.tableDetail.current?.setFocus(this.state.publicDetailList.length - 1, 1);
    });
  }

  @action
  async dw_2_delete() {
    const focusedItem = this.state.focused;

    if (focusedItem) {
      const text = `공통코드: ${this.state.focused?.com_code}, 코드명칭: ${this.state.focused?.com_cnam}`;

      if (await ConfirmDelete.show(text)) {
        const { actionStore: api } = this.props;

        await api.fxExec(
          'dw_2_delete',
          {
            com_cls: this.state.focused?.com_cls,
            com_code: this.state.focused?.com_code,
          },
        );
        ConfirmSuccess.show('삭제', `${focusedItem.com_rem2}를 삭제하였습니다.`);
        this.onRetrieveEvent();
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  @action
  onUpdatedRows(rows: Array<PublicModel>, updatedRows: Array<PublicModel>) {
    this.updatedRows = updatedRows;
    this.setState({ publicList: rows });
  }

  /**
   * 행 변경 이벤트
   * @param rows2      전체 행 (변경 행 반영된 상태)
   * @param updatedRows2 변경 행들만
   */
  @action
  onUpdatedRows2(rows2: Array<PublicModel2>, updatedRows2: Array<PublicModel2>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ publicDetailList: rows2 });
  }

  updateFocusedRow(data: any) {
    this.SS({
      focusedPublic: new PublicModel({
        ...this.state.focusedPublic,
        ...data,
      }, this.state.focusedPublic.isNew),
    });
  }

  render() {
    return (
      <PublicTemplate
        scope={this}
        update={(change) => {
          this.setState({
            ...this.state.publicList,
            ...this.state.publicDetailList,
            ...this.state.focusedPublic,
            ...change,
          });
        }}
      />
    );
  }
}
