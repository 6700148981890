import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  Button,
  CalendarLayout,
  DateTextBox,
  DefaultLayout,
  FlexLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader, TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Plan } from './Plan';
import { CalendarLayoutDataModel, SearchBinding } from '../../../../models';
import PlanModel from './models/PlanModel';
import { Date6Calculator, Date8 } from '../../../../utils/time';
import { PlanActTableHeader } from './headers/PlanActTableHeader';
import { PlanEqupTableHeader } from './headers/PlanEqupTableHeader';
import { PlanPerTableHeader } from './headers/PlanPerTableHeader';

/**
 * 화면
 * @window w_tb_e470
 * @category 공사계획등록
 */
export const PlanTemplate: React.FC<TemplateProps<Plan>> = ({
  scope,
}) => {
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={220}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stmon}
            textAlign="center"
            format="####-##"
            onChange={(value) => setData({ stmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span style={Global.STYLE_COLOR_RED}>사용자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                  searchQuery: item.cd,
                });
                scope.onRetrieveEvent();
              },
            )}
          />

          <TextBox
            weight={1.4}
            value={scope.state.pernm}
            textAlign="center"
            onChange={(value) => setData({ pernm: value })}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout size={500}>
          <span style={Global.STYLE_COLOR_RED}>현장코드</span>
          <TextBox
            textAlign="center"
            value={scope.state.actcd}
            onChange={(value) => setData({ actcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E601_1',
              {},
              true,
              (item) => {
                setData({
                  actcd: item.cd,
                  actnm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={2.4}
            value={scope.state.actnm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout size={340}>
          <span style={Global.STYLE_COLOR_RED}>호기코드</span>
          <TextBox
            textAlign="center"
            value={scope.state.equpcd}
            onChange={(value) => setData({ equpcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E611',
              { uv_arg1: scope.state.actcd },
              true,
              (item) => {
                setData({
                  equpcd: item.cd,
                  equpnm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={1.4}
            value={scope.state.equpnm}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <CalendarLayout
          month={scope.state.stmon}
          data={scope.state.data}
          render={(c, items: CalendarLayoutDataModel<PlanModel>[]) => (
            <DefaultLayout style={{ cursor: 'pointer' }}>
              <div>
                {scope.dayRemarks[c.data]?.g12 > 0
                  && <span style={{ color: 'var(--color-blue)' }}>공사 {scope.dayRemarks[c.data]?.g12 || 0} </span>}
                {scope.dayRemarks[c.data]?.g13 > 0
                  && <span style={{ color: 'var(--color-green)' }}>부품 {scope.dayRemarks[c.data]?.g13 || 0} </span>}
                {scope.dayRemarks[c.data]?.g15 > 0
                  && <span style={{ color: 'var(--color-red)' }}>리모 {scope.dayRemarks[c.data]?.g15 || 0} </span>}
              </div>

              {items.slice(0, 6).map((x, i) => (
                <div key={i}>
                <span style={{ color: scope.getItemColor(x.data) }}>
                  [{scope.getItemPrefix(x.data)}]
                </span>
                  {x.data.actnm}
                </div>
              ))}
            </DefaultLayout>
          )}
          onDateClick={(date) => {
            // CalendarItem의 data는 여유 공간인데 CalendarLayout에서는 8자리 날짜가 들어있습니다
            scope.openModal(date.data);
          }}
        />
      </FlexLayout>

      <ModalView
        width={1200}
        isVisible={scope.state.isVisibleModal}
        onClose={() => scope.SS({ isVisibleModal: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <LayoutTitle size={Global.LAYOUT_TITLE_HEIGHT_1}>
            {Date8.withDash(scope.state.modalDate)} 공사계획등록
          </LayoutTitle>

          <FlexLayout>
            <FlexLayout isVertical={true}>
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  size={Global.LAYOUT_TITLE_HEIGHT_1}
                >
                  <SubHeader>
                    <div>
                      <span>현장</span>
                    </div>

                    <FlexLayout justify="end">
                      <Button onClick={() => scope.onModalActAdd()}>추가</Button>
                      <Button onClick={() => scope.onModalActRemove()}>삭제</Button>
                    </FlexLayout>
                  </SubHeader>
                </FlexLayout>

                <FlexLayout>
                  <TableLayout
                    ref={scope.refAct}
                    header={PlanActTableHeader(scope)}
                    rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                    data={scope.state.dataAct}
                    infinityHandler={scope.infinityAct}
                    onRowFocusEvent={(row, index) => scope.onActRowFocusEvent(row, index)}
                    onChange={(rows) => scope.SS({ dataAct: rows })}
                  />
                </FlexLayout>
              </FlexLayout>

              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  size={Global.LAYOUT_TITLE_HEIGHT_1}
                >
                  <SubHeader>
                    <div>
                      <span>공사자</span>
                    </div>

                    <FlexLayout justify="end">
                      <Button onClick={() => scope.onModalPerAdd()}>추가</Button>
                      <Button onClick={() => scope.onModalPerRemove()}>삭제</Button>
                    </FlexLayout>
                  </SubHeader>
                </FlexLayout>

                <FlexLayout>
                  <TableLayout
                    ref={scope.refPer}
                    header={PlanPerTableHeader(scope)}
                    rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                    data={scope.state.dataPer}
                    onRowFocusEvent={(row, index) => scope.onPerRowFocusEvent(row, index)}
                    onChange={(rows) => scope.SS({ dataPer: rows })}
                  />
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout
              weight={2}
              isVertical={true}
            >
              <FlexLayout
                isVertical={true}
                size={Global.LAYOUT_TITLE_HEIGHT_1}
              >
                <SubHeader>
                  <div>
                    <span>호기</span>
                  </div>

                  <FlexLayout justify="end">
                    <Button onClick={() => scope.onModalEqupAdd()}>추가</Button>
                    <Button onClick={() => scope.onModalEqupRemove()}>삭제</Button>
                  </FlexLayout>
                </SubHeader>
              </FlexLayout>

              <FlexLayout>
                <TableLayout
                  ref={scope.refEqup}
                  header={PlanEqupTableHeader(scope)}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  data={scope.state.dataEqup}
                  onRowFocusEvent={(row, index) => scope.onEqupRowFocusEvent(row, index)}
                  onChange={(rows) => scope.SS({ dataEqup: rows })}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout
            size={Global.LAYOUT_TITLE_HEIGHT_1}
            justify="end"
          >
            <Button
              isFull={true}
              onClick={() => scope.onModalSave()}
            >
              저장
            </Button>

            <Button
              isFull={true}
              onClick={() => scope.SS({ isVisibleModal: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
