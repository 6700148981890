import * as React from 'react';
import { action, computed } from 'mobx';
import {
  MdFilter1,
  MdFilter2,
} from 'react-icons/md';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  TableLayoutHeader,
} from '../../../../constants';
import { RemodelTemplate } from './Remodel.template';
import {
  ElectricModel,
  MachineModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { Confirm, ConfirmWarning } from '../../../../utils/confirm';
import { TextBox } from '../../../../components/forms/TextBox';
import { CheckBox } from '../../../../components/forms/CheckBox';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';
import { FormatNumberTextBox } from '../../../../components';


export enum RemodelTabId {
  Electric,
  Machine,
}

export const RemodelTabTitles = [
  '전기자재내역',
  '기계자재내역',
];

export const RemodelTabModels = [
  ElectricModel,
  MachineModel,
];

export const RemodelTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const RemodelTabDataStateNames = [
  'electrics',
  'machines',
];

export const RemodelTabUpdatesStateNames = [
  'electricUpdates',
  'machineUpdates',
];

export const RemodelTabFocusedStateNames = [
  'electricsFocused',
  'machinesFocused',
];

export interface RemodelState {
  // 전기자재내역
  electrics?: Array<ElectricModel>;
  electricUpdates?: Array<ElectricModel>;
  electricsFocused?: ElectricModel;

  // 기계자재내역
  machines?: Array<MachineModel>;
  machineUpdates?: Array<MachineModel>;
  machinesFocused?: MachineModel;

  focusedTab?: TabModel;
}

/**
 * 컨트롤러
 * @window w_tb_e450_bom_15
 * @category 견적리모델링제품등록
 */
export class Remodel extends PageComponent<PageProps, RemodelState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<TableLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = RemodelTabId;
    const titles = RemodelTabTitles;

    this.tabs = [
      new TabModel(id.Electric.toString(), MdFilter1, titles[id.Electric]),
      new TabModel(id.Machine.toString(), MdFilter2, titles[id.Machine]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };

    this.tabHeaders = [
      // 전기자재내역
      [
        {
          id: 'seq',
          text: '순번',
          width: 20,
        },
        {
          id: 'pcode',
          text: '품명코드',
          width: 60,
          color: 'var(--color-red)',
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pcode}
              textAlign="center"
              onChange={(value) => rowUpdate({ pcode: value })}
              onDoubleClick={async () => {
                const result = await this.props.modalStore.openProductSelector(x.pcode);
                rowUpdate({
                  pcode: result.phm_pcod,
                  pname: result.phm_pnam,
                  psize: result.phm_size,
                  punit: result.phm_unit,
                  uamt: result.phm_uamt || result.phm_samt,
                });
              }}
            />
          ),
        },
        {
          id: 'pname',
          text: '품명',
          width: 60,
          render: (x) => (
            <FlexLayout align="center" justify="left">
              {x.pname}
            </FlexLayout>
          ),
        },
        {
          id: 'psize',
          text: '규격',
          width: 80,
          render: (x) => (
            <FlexLayout align="center" justify="left">
              {x.psize}
            </FlexLayout>
          ),
        },
        {
          id: 'punit',
          text: '단위',
          width: 40,
        },
        {
          id: 'uamt',
          text: '단가',
          width: 60,
          render: (x, rowUpdate, ref) => (
            <FormatNumberTextBox
              textAlign="right"
              ref={ref}
              value={x.uamt}
              onChange={(value) => rowUpdate({ uamt: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 20,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 130,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 기계자재내역
      [
        {
          id: 'seq',
          text: '순번',
          width: 20,
        },
        {
          id: 'pcode',
          text: '품명코드',
          width: 60,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="center"
              value={x.pcode}
              onChange={(value) => rowUpdate({ pcode: value })}
              onDoubleClick={async () => {
                const result = await this.props.modalStore.openProductSelector(x.pcode);
                rowUpdate({
                  pcode: result.phm_pcod,
                  pname: result.phm_pnam,
                  psize: result.phm_size,
                  punit: result.phm_unit,
                  uamt: result.phm_uamt || result.phm_samt,
                });
              }}
            />
          ),
        },
        {
          id: 'pname',
          text: '품명',
          width: 60,
          render: (x) => (
            <FlexLayout align="center" justify="left">
              {x.pname}
            </FlexLayout>
          ),
        },
        {
          id: 'psize',
          text: '규격',
          width: 80,
          render: (x) => (
            <FlexLayout align="center" justify="left">
              {x.psize}
            </FlexLayout>
          ),
        },
        {
          id: 'punit',
          text: '단위',
          width: 40,
        },
        {
          id: 'uamt',
          text: '단가',
          width: 60,
          render: (x, rowUpdate, ref) => (
            <FormatNumberTextBox
              textAlign="right"
              ref={ref}
              value={x.uamt}
              onChange={(value) => rowUpdate({ uamt: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 20,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 130,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],
    ];
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[RemodelTabId.Electric]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    if (i === RemodelTabId.Electric && (this.state.machines?.length || 0) === 0) {
      await this.doRetrieve(RemodelTabId.Machine);
    }

    await this.doRetrieve(i);
    this.state.machines?.length && this.table.current?.setFocus(0);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    const data = await api.fxExec(
      `${RemodelTabFunctionNames[i]}_new`,
    );

    // @ts-ignore
    data && this.setState({
      [RemodelTabDataStateNames[i]]: [
        new RemodelTabModels[i](data, true),
        // @ts-ignore
        ...this.state[RemodelTabDataStateNames[i]],
      ],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(0, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    await api.fxSave(
      `${RemodelTabFunctionNames[i]}_save`,
      {
        // @ts-ignore
        items: this.state[RemodelTabUpdatesStateNames[i]],
      },
      true,
    );
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    const item = this.state[RemodelTabFocusedStateNames[i]];

    if (!item) {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
      return;
    }

    let text = '';
    switch (i) {
      case RemodelTabId.Electric:
        text = `${item.contcd} - ${item.contnm}`;
        break;

      case RemodelTabId.Machine:
        text = `${item.remocd} - ${item.remonm}`;
        break;

      default:
        text = '선택된 행을 삭제하시겠습니까?';
    }

    await api.fxDelete(
      `${RemodelTabFunctionNames[i]}_delete`,
      text,
      // @ts-ignore
      this.state[RemodelTabFocusedStateNames[i]],
    );

    this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[RemodelTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxPrint(
      `${RemodelTabFunctionNames[i]}_print`,
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[RemodelTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }

    await api.fxExcel(
      `${RemodelTabFunctionNames[i]}_excel`,
    );
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    const i = this.tabIndex;
    if (this.state.focusedTab
      // @ts-ignore
      && (this.state[RemodelTabUpdatesStateNames[i]]?.length || 0) > 0) {
      const text = '저장하지 않은 데이터가 있습니다.\n탭을 넘어가면 저장하지 않은 데이터는 사라집니다.\n넘어가시겠습니까?';
      if (await Confirm.show('경고', text, ConfirmType.WARNING)) {
        this.setState({
          [RemodelTabUpdatesStateNames[i]]: [],
          [RemodelTabFocusedStateNames[i]]: undefined,
        });
      } else {
        return;
      }
    }

    this.setState({ focusedTab }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async doRetrieve(i: RemodelTabId): Promise<void> {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {},
      (params) => api.fxExec(
        `${RemodelTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items) => {
        if (items) {
          this.setState({
            [RemodelTabDataStateNames[i]]:
            // @ts-ignore
              [...this.state[RemodelTabDataStateNames[i]],
                ...items.map((x: any) => new RemodelTabModels[i](x))],
          }, () => this.table.current?.update(false));
        }
      },
      async () => {
        await this.SS({
          [RemodelTabDataStateNames[i]]: [],
        });

        await this.infinity?.retrieve();
        this.table.current?.update(true);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.SS({
      [RemodelTabDataStateNames[i]]: [],
      [RemodelTabUpdatesStateNames[i]]: [],
      [RemodelTabFocusedStateNames[i]]: undefined,
    });
    await this.infinity?.retrieve();
    this.table.current?.update(true);
  }

  @computed
  get tabIndex(): RemodelTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [RemodelTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [RemodelTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  render() {
    return (
      <RemodelTemplate
        scope={this}
        update={(state) => this.setState(state)}
      />
    );
  }
}
