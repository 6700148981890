import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  ComboBox, ComboBoxModel, DateTextBox, FlexLayout, IFrame, SearchLayout, TabLayout,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Effort } from './Effort';
import { EffortTab } from './Effort.tab';

/**
 * 화면
 * @window w_tb_e601w_sulchi_06
 * @category 월공수현황
 */
export const EffortTemplate: React.FC<TemplateProps<Effort>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.year}
            textAlign="center"
            format="####"
            onChange={(value) => setData({ year: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const year = `${parseInt(scope.state.year, 10) - 1}`;
                scope.setState({ year }, () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const year = `${parseInt(scope.state.year, 10) + 1}`;
                scope.setState({ year }, () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span>사업장</span>
          <ComboBox
            value={scope.state.spjangcd}
            data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
            onSelect={(option) => setData({ spjangcd: option.value }, () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span>직영구분</span>
          <ComboBox
            value={scope.state.mpclafi}
            data={[
              { value: '%', remark: '전체' },
              { value: '0', remark: '직영' },
              { value: '1', remark: '외주' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ mpclafi: option.value }, () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>


      <FlexLayout isVertical={true}>
        <FlexLayout weight={2}>
          <TabLayout
            scope={scope}
            tabs={EffortTab}
            onChange={(_, index) => scope.onTabChange(index)}
          />
        </FlexLayout>

        <FlexLayout>
          <IFrame src={scope.state.graphUrl} />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
