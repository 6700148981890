import * as React from 'react';
import { observer } from 'mobx-react';
import ReactJson from 'react-json-view';
import style from '../AuthorizedScreen.module.scss';
import {
  ModalView,
  FlexLayout,
  SearchLayout,
  GridLayout,
  Button,
  TextBox,
  LayoutTitle,
} from '../../components';
import { ModalStore } from '../../stores';
import { Global } from '../../constants';
import { Date8, Time4 } from '../../utils/time';
import { SearchBinding } from '../../models';

interface ModalRollbackProps {
  modalStore: ModalStore;
}

@observer
export class ModalReproduce extends React.Component<ModalRollbackProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={1300}
        height={800}
        isVisible={modalStore.isVisibleReproduce}
        onClose={() => modalStore.closeReproduceSelector()}
        zIndex={-1}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout size={250}>
              <span style={{ color: 'var(--color-red)' }}>사용자</span>
              <TextBox
                weight={1.5}
                value={modalStore.reproducePerid}
                textAlign="center"
                onChange={(value) => modalStore.reproduceUpdatePerid(value)}
                bindSearchModal={new SearchBinding(
                  modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    modalStore.reproduceUpdate(item.cd, item.cdnm);
                  },
                )}
              />

              <TextBox
                value={modalStore.reproducePernm}
                textAlign="center"
                weight={1.5}
                readonly={true}
              />
            </FlexLayout>
          </SearchLayout>

          <FlexLayout>
            <FlexLayout size={800}>
              <GridLayout
                header={[
                  {
                    id: 'indate',
                    text: '날짜',
                    width: 12,
                    render: (x: any) => {
                      const date = new Date(x.created);
                      return (
                        <FlexLayout justify="center" align="center">
                          {Date8.withDash(date)}
                        </FlexLayout>
                      );
                    },
                  },
                  {
                    id: 'intime',
                    text: '시간',
                    width: 10,
                    render: (x: any) => {
                      const date = new Date(x.created);
                      return (
                        <FlexLayout justify="center" align="center">
                          {Time4.withDash(date)}
                        </FlexLayout>
                      );
                    },
                  },
                  {
                    id: 'type',
                    text: '구분',
                    width: 10,
                    render: (x: any) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{
                          color: x.type === 'function' ? 'var(--color-green)' : 'var(--color-blue)',
                        }}
                      >
                        {x.type}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'action_name',
                    text: 'ActionName',
                    width: 13,
                    render: (x: any) => (
                      <FlexLayout justify="start" align="center">
                        {x.action_name}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'function_name',
                    text: 'FunctionName',
                    width: 25,
                    render: (x: any) => (
                      <FlexLayout justify="start" align="center">
                        {x.function_name}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'ipaddr',
                    text: 'IP',
                    width: 15,
                  },
                  {
                    id: 'perid',
                    text: '사원번호',
                    width: 15,
                  },
                ]}
                infinityHandler={modalStore.reproduceSelectorInfinity}
                data={modalStore.reproduceSelectorData || []}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                onRowClick={(_: any, index: number) => modalStore.onReproduceSelectorRowFocusEvent(index)}
              />
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <LayoutTitle>Payload</LayoutTitle>
              <FlexLayout
                size={350}
                isScroll={true}
              >
                <ReactJson src={modalStore.reproduceJson} />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => modalStore.closeReproduceSelector()}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
