import { FlexLayout, FormatNumberTextBox, TextBox } from '../../../../../components';
import { TLHF } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { SearchBinding } from '../../../../../models';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 탭 > 테이블 - 견적항목
 * @window w_tb_da006
 * @category 수주서등록
 */
export const EnrollmentTabItemsTableHeader: TLHF<any, Enrollment> = (scope) => ([
  {
    id: 'costcd',
    text: '견적항목',
    width: 10,
    color: 'var(--color-red)',
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.costcd}
        textAlign="center"
        onChange={(value) => rowUpdate({ costcd: value })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E450',
          {},
          true,
          (item) => {
            rowUpdate({
              costcd: item.cd,
              costnm: item.cdnm,
              unit: item.uv_arg1,
              qty: item.uv_arg2,
              remark: item.uv_arg4,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'costnm',
    text: '견적명',
    width: 15,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.costnm}
        onChange={(value) => rowUpdate({ costnm: value })}
      />
    ),
  },
  {
    id: 'size',
    text: '규격',
    width: 15,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.psize}
        onChange={(value) => rowUpdate({ psize: value })}
      />
    ),
  },
  {
    id: 'unit',
    text: '단위',
    width: 5,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.punit}
        onChange={(value) => rowUpdate({ punit: value })}
      />
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 5,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="center"
        value={x.qty}
        onChange={(value) => scope.tabAutoCalc({ ...x, qty: value }, rowUpdate)}
      />
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 10,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.uamt}
        onChange={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate)}
      />
    ),
  },
  {
    id: 'samt',
    text: '금액',
    width: 10,
    trail: (data) => {
      const sumSamt = Sum.all(data, 'samt');
      return (
        <FlexLayout
          justify="end"
          align="center"
        >
          {Format.number(sumSamt)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.samt}
        onChange={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, true)}
      />
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 10,
    trail: (data) => {
      const sumTamt = Sum.all(data, 'tamt');
      return (
        <FlexLayout
          justify="end"
          align="center"
        >
          {Format.number(sumTamt)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.tamt}
        onChange={(value) => rowUpdate({ tamt: value })}
      />
    ),
  },
  {
    id: 'mamt',
    text: '견적금액',
    width: 10,
    trail: (data) => {
      const sumMamt = Sum.all(data, 'mamt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumMamt)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.mamt}
        onChange={(value) => rowUpdate({ mamt: value })}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 10,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(value) => rowUpdate({ remark: value })}
      />
    ),
  },
]);
