/**
 * 모델
 * @window w_tb_ca642w_12
 * @category 월시재현황
 */
import { computed } from 'mobx';

export class MonthMoneyModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 월
   *
   * **data type** varchar(8)
   */
  mon: string;

  /**
   * 전월잔액
   *
   * **data type** number
   */
  beamt: string;

  /**
   * 입금금액
   *
   * **data type** number
   */
  rcvamt: string;

  /**
   * 지급금액
   *
   * **data type** number
   */
  sndamt: string;

  /**
   * 잔액
   *
   * **data type** long
   */
  resuamt: string;

  /**
   * 사업장코드
   *
   * **data type** char(2)
   */
  spjangcd: string;

  /**
   * 사업장명
   *
   * **data type** char(40)
   */
  spjangnm: string;

  /**
   * 입금금액01
   *
   * **data type** number
   */
  rcvamt01: string;

  /**
   * 입금금액02
   *
   * **data type** number
   */
  rcvamt02: string;

  /**
   * 입금금액03
   *
   * **data type** number
   */
  rcvamt03: string;

  /**
   * 입금금액04
   *
   * **data type** number
   */
  rcvamt04: string;

  /**
   * 입금금액05
   *
   * **data type** number
   */
  rcvamt05: string;

  /**
   * 입금금액06
   *
   * **data type** number
   */
  rcvamt06: string;

  /**
   * 입금금액07
   *
   * **data type** number
   */
  rcvamt07: string;

  /**
   * 입금금액08
   *
   * **data type** number
   */
  rcvamt08: string;

  /**
   * 입금금액09
   *
   * **data type** number
   */
  rcvamt09: string;

  /**
   * 입금금액10
   *
   * **data type** number
   */
  rcvamt10: string;

  /**
   * 입금금액11
   *
   * **data type** number
   */
  rcvamt11: string;

  /**
   * 입금금액12
   *
   * **data type** number
   */
  rcvamt12: string;

  /**
   * 지급금액01
   *
   * **data type** number
   */
  sndamt01: string;

  /**
   * 지급금액02
   *
   * **data type** number
   */
  sndamt02: string;

  /**
   * 지급금액03
   *
   * **data type** number
   */
  sndamt03: string;

  /**
   * 지급금액04
   *
   * **data type** number
   */
  sndamt04: string;

  /**
   * 지급금액05
   *
   * **data type** number
   */
  sndamt05: string;

  /**
   * 지급금액06
   *
   * **data type** number
   */
  sndamt06: string;

  /**
   * 지급금액07
   *
   * **data type** number
   */
  sndamt07: string;

  /**
   * 지급금액08
   *
   * **data type** number
   */
  sndamt08: string;

  /**
   * 지급금액09
   *
   * **data type** number
   */
  sndamt09: string;

  /**
   * 지급금액10
   *
   * **data type** number
   */
  sndamt10: string;

  /**
   * 지급금액11
   *
   * **data type** number
   */
  sndamt11: string;

  /**
   * 지급금액12
   *
   * **data type** number
   */
  sndamt12: string;

  /**
   * 흑자
   *
   * **data type** number
   */
  addamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.mon = data.mon || '';
    this.addamt = data.addamt || '';
    this.beamt = data.beamt || '';
    this.rcvamt = data.rcvamt || '';
    this.sndamt = data.sndamt || '';
    this.resuamt = data.resuamt || '';
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.rcvamt01 = data.rcvamt01 || '';
    this.rcvamt02 = data.rcvamt02 || '';
    this.rcvamt03 = data.rcvamt03 || '';
    this.rcvamt04 = data.rcvamt04 || '';
    this.rcvamt05 = data.rcvamt05 || '';
    this.rcvamt06 = data.rcvamt06 || '';
    this.rcvamt07 = data.rcvamt07 || '';
    this.rcvamt08 = data.rcvamt08 || '';
    this.rcvamt09 = data.rcvamt09 || '';
    this.rcvamt10 = data.rcvamt10 || '';
    this.rcvamt11 = data.rcvamt11 || '';
    this.rcvamt12 = data.rcvamt12 || '';
    this.sndamt01 = data.sndamt01 || '';
    this.sndamt02 = data.sndamt02 || '';
    this.sndamt03 = data.sndamt03 || '';
    this.sndamt04 = data.sndamt04 || '';
    this.sndamt05 = data.sndamt05 || '';
    this.sndamt06 = data.sndamt06 || '';
    this.sndamt07 = data.sndamt07 || '';
    this.sndamt08 = data.sndamt08 || '';
    this.sndamt09 = data.sndamt09 || '';
    this.sndamt10 = data.sndamt10 || '';
    this.sndamt11 = data.sndamt11 || '';
    this.sndamt12 = data.sndamt12 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
