import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  DateTextBox,
  FlexLayout,
  ModalView,
  SearchLayout,
} from '../../../../components';
import { TemplateProps } from '../../../../constants';
import { Month } from './Month';
import { Date6Calculator } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_monend
 * @category 월마감현황
 */
export const MonthTemplate: React.FC<TemplateProps<Month>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <ModalView
      width={370}
      height={70}
      isVisible={scope.state.textDetailModal}
      onClose={() => scope.textModal(false)}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <SearchLayout>
          <FlexLayout size={200}>
            <span>월</span>
            <DateTextBox
              value={scope.state.yearmon}
              textAlign="center"
              format="####년 ##월"
              onChange={(value) => scope.setState({ yearmon: value })}
              head={(<button
                onClick={() => {
                  scope.setState({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() });
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  scope.setState({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() });
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <Button onClick={() => scope.onPageMove()}>
            확인
          </Button>
        </SearchLayout>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);
