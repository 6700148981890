import React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import { OrderCollection } from './OrderCollection';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { Sum } from '../../../../utils/array';


/**
 * 화면
 * @window w_tb_e601w_sulchi_10
 * @category 설치수주/수금현황
 */
export const OrderCollectionTemplate: React.FC<TemplateProps<OrderCollection>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stmon}
          textAlign="center"
          format="####년 ##월"
          onChange={(value) => scope.setState({ stmon: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              scope.setState({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              scope.setState({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={180}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.endmon}
          textAlign="center"
          format="####년 ##월"
          onChange={(value) => scope.setState({ endmon: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              scope.setState({ endmon: new Date6Calculator(scope.state.endmon).add(-1).toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              scope.setState({ endmon: new Date6Calculator(scope.state.endmon).add().toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={500}>
        <span>검색어</span>
        <TextBox
          value={scope.state.searchQuery}
          onChange={(value) => scope.SS({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        >
        </TextBox>
      </FlexLayout>

      <FlexLayout size={200}>
        <span>수금여부</span>
        <ComboBox
          value={scope.state.misflag}
          textAlign="center"
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '미수금' },
            { value: '2', remark: '수금' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.SS({ misflag: option.value })}
          isDisabledTrackingStateChange={true}
        >
        </ComboBox>
      </FlexLayout>

      <FlexLayout size={220}>
        <span>수금예정구분</span>
        <ComboBox
          value={scope.state.rcvexflag}
          textAlign="center"
          data={[
            { value: '%', remark: '전체' },
            { value: '0', remark: '예정' },
            { value: '1', remark: '예정지연' },
            { value: '2', remark: '수금' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ rcvexflag: option.value }, () => scope.onItemChanged('rcvexflag', option.value))}
          isDisabledTrackingStateChange={true}
        >
        </ComboBox>
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <FlexLayout size={200}>
        <GridLayout
          ref={scope.leftGrid}
          header={[
            {
              id: 'actnm',
              text: '월',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout>
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'notamt',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout>
                    미수금
                  </FlexLayout>
                  <FlexLayout>
                    (수주-수금)
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.notamt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.notmisamt_tot}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.leftData}
          onRowClick={(item) => scope.onLeftRowFocusEvent(item)}
          onRowDoubleClick={() => scope.onDoubleClicked()}
        >
        </GridLayout>
      </FlexLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'mongroup',
              text: '월',
              isHidden: true,
              group: true,
              value: (x) => x.compdate?.substr(0, 6),
            },
            {
              id: 'actcd',
              text: '현장코드',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actcd}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout>
                  합 계
                </FlexLayout>
              ),
              sum: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDashMon(x.compdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '설치현장명',
              width: 300,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout>
                  {scope.state.cnt_tot}
                </FlexLayout>
              ),
              sum: (x, data) => {
                const sumActnm = Sum.groupLength(data, 'actnm', x, ['mongroup']);
                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Format.number(sumActnm)}
                  </FlexLayout>
                );
              },
            },
            {
              id: 'qty',
              text: '대수',
              width: 60,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.qty}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout>
                  {scope.state.qty_tot}
                </FlexLayout>
              ),
              sum: (x, data) => {
                const sumQty = Sum.byGroup(data, 'qty', x, ['mongroup']);
                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Format.number(sumQty)}
                  </FlexLayout>
                );
              },
            },
            {
              id: 'gubun',
              text: '구분',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{
                    color: x.gubun === '1' ? 'var(--color-green)'
                      : x.gubun === '2' ? 'var(--color-violet)' : 'var(--color-black)',
                  }}
                >
                  {
                    x.gubun === '1' ? '선급금-계약'
                      : x.gubun === '2' ? '중도금-자재출하' : '잔금-검사예정'
                  }
                </FlexLayout>
              ),
            },
            {
              id: 'compdate',
              text: '구분일자',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{
                    color: x.gubun === '1' ? 'var(--color-green)'
                      : x.gubun === '2' ? 'var(--color-violet)' : 'var(--color-black)',
                  }}
                >
                  {Date8.withDash(x.compdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'compamt',
              text: '수주금액',
              width: 120,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.compamt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout justify="right">
                  {scope.state.compamt_tot}
                </FlexLayout>
              ),
              sum: (x, data) => {
                const sumComamt = Sum.byGroup(data, 'compamt', x, ['mongroup']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumComamt)}
                  </FlexLayout>
                );
              },
            },
            {
              id: 'rcvamt',
              text: '수금액',
              width: 120,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Format.number(x.rcvamt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  style={{ color: '#00E7B8' }}
                >
                  {scope.state.rcvamt_tot}
                </FlexLayout>
              ),
              sum: (x, data) => {
                const sumRcvamt = Sum.byGroup(data, 'rcvamt', x, ['mongroup']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(sumRcvamt)}
                  </FlexLayout>
                );
              },
            },
            {
              id: 'notamt',
              text: '미수금',
              width: 120,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.notamt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  style={{ color: '#FF2A9E' }}
                >
                  {scope.state.notamt_tot}
                </FlexLayout>
              ),
              sum: (x, data) => {
                const sumNotAmt = Sum.byGroup(data, 'notamt', x, ['mongroup']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(sumNotAmt)}
                  </FlexLayout>
                );
              },
            },
            {
              id: 'misflag',
              text: '진행',
              width: 60,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{
                    color: x.misflag === '1' ? 'var(--color-black)'
                      : x.misflag === '2' ? 'var(--color-blue)' : 'var(--color-red)',
                  }}
                >
                  {
                    x.misflag === '1' ? '매출'
                      : x.misflag === '2' ? '수금' : '수주'
                  }
                </FlexLayout>
              ),
            },
            {
              id: 'contdate',
              text: '계약일',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.contdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'enddate',
              text: '납기일',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.enddate)}
                </FlexLayout>
              ),
            },
            {
              id: 'ppernm',
              text: '계약자',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.ppernm}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvexdatetime',
              text: '수금예정일',
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.rcvexdatetime.substr(0, 10)}
                  </FlexLayout>

                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.rcvexdatetime.substr(10, 10)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvexflag',
              text: '수금예정구분',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={
                  x.rcvexflag === '0' ? { color: 'var(--color-black)' }
                    : x.rcvexflag === '1' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
                >
                  {
                    x.rcvexflag === '0' ? '예정'
                      : x.rcvexflag === '1' ? '예정지연'
                        : x.rcvexflag === '2' ? '수금' : ''
                  }
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.orderCollectionList}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        >
        </GridLayout>
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
);
