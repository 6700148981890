import * as React from 'react';
import { action, computed } from 'mobx';
import {
  RiLayout4Line,
  RiBuilding4Line,
  RiFolderLine,
  RiAppsLine,
  RiSlackLine,
  RiArticleLine,
  RiLayoutBottomLine,
  RiLayoutColumnLine,
  RiOrderPlayLine,
} from 'react-icons/ri';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  TableLayoutHeader,
} from '../../../../constants';
import { ElevatorManagementCodeTemplate } from './ElevatorManagementCode.template';
import {
  ElevatorCategoryModel,
  ElevatorDoorModel,
  ElevatorFormModel,
  ElevatorManufacturerModel,
  ElevatorModelModel,
  ElevatorPurposeModel,
  ElevatorSpeedModel,
  ElevatorTypeCodeModel,
  ElevatorTypeModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { Confirm, ConfirmWarning } from '../../../../utils/confirm';
import { TextBox } from '../../../../components/forms/TextBox';
import { CheckBox } from '../../../../components/forms/CheckBox';
import { ComboBox, ComboBoxModel } from '../../../../components/forms/ComboBox';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';


export enum ElevatorManagementCodeTabId {
  TYPE_CODE,
  MANUFACTURER,
  TYPE,
  PURPOSE,
  CATEGORIE,
  FORM,
  MODEL,
  DOOR,
  SPEED,
}

export const ElevatorManagementCodeTabTitles = [
  '분류코드',
  '제작사',
  '엘리베이터구분',
  '엘리베이터용도',
  '엘리베이터종류',
  '승강기형식',
  '모델명',
  '도어방식',
  '정격속도',
];

export const ElevatorManagementCodeTabModels = [
  ElevatorTypeCodeModel,
  ElevatorManufacturerModel,
  ElevatorTypeModel,
  ElevatorPurposeModel,
  ElevatorCategoryModel,
  ElevatorFormModel,
  ElevatorModelModel,
  ElevatorDoorModel,
  ElevatorSpeedModel,
];

export const ElevatorManagementCodeTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
  'tab_5',
  'tab_6',
  'tab_7',
  'tab_8',
  'tab_9',
];

export const ElevatorManagementCodeTabDataStateNames = [
  'typeCodes',
  'manufacturers',
  'types',
  'purposes',
  'categories',
  'forms',
  'models',
  'doors',
  'speeds',
];

export const ElevatorManagementCodeTabUpdatesStateNames = [
  'typeCodeUpdates',
  'manufacturerUpdates',
  'typeUpdates',
  'purposeUpdates',
  'categorieUpdates',
  'formUpdates',
  'modelUpdates',
  'doorUpdates',
  'speedUpdates',
];

export const ElevatorManagementCodeTabFocusedStateNames = [
  'typeCodeFocused',
  'manufacturersFocused',
  'typesFocused',
  'purposesFocused',
  'categoriesFocused',
  'formsFocused',
  'modelsFocused',
  'doorsFocused',
  'speedsFocused',
];

export interface ElevatorManagementCodeState {
  // 분류코드
  typeCodes?: Array<ElevatorTypeCodeModel>;
  typeCodeUpdates?: Array<ElevatorTypeCodeModel>;
  typeCodeFocused?: ElevatorTypeCodeModel;

  // 제작사
  manufacturers?: Array<ElevatorManufacturerModel>;
  manufacturerUpdates?: Array<ElevatorManufacturerModel>;
  manufacturersFocused?: ElevatorManufacturerModel;

  // 엘리베이터구분
  types?: Array<ElevatorTypeModel>;
  typeUpdates?: Array<ElevatorTypeModel>;
  typesFocused?: ElevatorTypeModel;

  // 엘리베이터용도
  purposes?: Array<ElevatorPurposeModel>;
  purposeUpdates?: Array<ElevatorPurposeModel>;
  purposesFocused?: ElevatorPurposeModel;

  // 엘리베이터종류
  categories?: Array<ElevatorCategoryModel>;
  categorieUpdates?: Array<ElevatorCategoryModel>;
  categoriesFocused?: ElevatorCategoryModel;

  // 승강기형식
  forms?: Array<ElevatorFormModel>;
  formUpdates?: Array<ElevatorFormModel>;
  formsFocused?: ElevatorFormModel;

  // 모델명
  models?: Array<ElevatorModelModel>;
  modelUpdates?: Array<ElevatorModelModel>;
  modelsFocused?: ElevatorModelModel;

  // 도어방식
  doors?: Array<ElevatorDoorModel>;
  doorUpdates?: Array<ElevatorDoorModel>;
  doorsFocused?: ElevatorDoorModel;

  // 도어방식
  speeds?: Array<ElevatorSpeedModel>;
  speedUpdates?: Array<ElevatorSpeedModel>;
  speedsFocused?: ElevatorSpeedModel;

  focusedTab?: TabModel;
}

/**
 * 컨트롤러
 * @window w_tb_e001
 * @category 승강기관리코드
 */
export class ElevatorManagementCode extends PageComponent<PageProps, ElevatorManagementCodeState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<TableLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = ElevatorManagementCodeTabId;
    const titles = ElevatorManagementCodeTabTitles;

    this.tabs = [
      new TabModel(id.TYPE_CODE.toString(), RiLayout4Line, titles[id.TYPE_CODE]),
      new TabModel(id.MANUFACTURER.toString(), RiBuilding4Line, titles[id.MANUFACTURER]),
      new TabModel(id.TYPE.toString(), RiFolderLine, titles[id.TYPE]),
      new TabModel(id.PURPOSE.toString(), RiAppsLine, titles[id.PURPOSE]),
      new TabModel(id.CATEGORIE.toString(), RiSlackLine, titles[id.CATEGORIE]),
      new TabModel(id.FORM.toString(), RiArticleLine, titles[id.FORM]),
      new TabModel(id.MODEL.toString(), RiLayoutBottomLine, titles[id.MODEL]),
      new TabModel(id.DOOR.toString(), RiLayoutColumnLine, titles[id.DOOR]),
      new TabModel(id.SPEED.toString(), RiOrderPlayLine, titles[id.SPEED]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };

    this.tabHeaders = [
      // 분류코드
      [
        {
          id: 'classcd',
          text: '코드',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.classcd}
              onChange={(value) => rowUpdate({ classcd: value })}
              readonly={true}
              transparent={true}
              textAlign="center"
            />
          ),
        },
        {
          id: 'classnm',
          text: '분류명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.classnm}
              onChange={(value) => rowUpdate({ classnm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 제작사
      [
        {
          id: 'manucd',
          text: '코드',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.manucd}
              onChange={(value) => rowUpdate({ manucd: value })}
              readonly={!x?.isNew}
              transparent={true}
              textAlign="center"
            />
          ),
        },
        {
          id: 'manunm',
          text: '제작사명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.manunm}
              onChange={(value) => rowUpdate({ manunm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 엘리베이터구분
      [
        {
          id: 'eligcd',
          text: '코드',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.eligcd}
              onChange={(value) => rowUpdate({ eligcd: value })}
              readonly={!x?.isNew}
              transparent={true}
              textAlign="center"
            />
          ),
        },
        {
          id: 'elignm',
          text: '엘리베이터구분명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.elignm}
              onChange={(value) => rowUpdate({ elignm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 엘리베이터용도
      [
        {
          id: 'eliycd',
          text: '코드',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.eliycd}
              onChange={(value) => rowUpdate({ eliycd: value })}
              readonly={!x?.isNew}
              transparent={true}
              textAlign="center"
            />
          ),
        },
        {
          id: 'eliynm',
          text: '엘리베이터용도명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.eliynm}
              onChange={(value) => rowUpdate({ eliynm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 엘리베이터종류
      [
        {
          id: 'elijcd',
          text: '코드',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.elijcd}
              onChange={(value) => rowUpdate({ elijcd: value })}
              readonly={!x?.isNew}
              transparent={true}
              textAlign="center"
            />
          ),
        },
        {
          id: 'elijnm',
          text: '엘리베이터용도명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.elijnm}
              onChange={(value) => rowUpdate({ elijnm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 승강기형식
      [
        {
          id: 'elifcd',
          text: '코드',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.elifcd}
              onChange={(value) => rowUpdate({ elifcd: value })}
              readonly={!x?.isNew}
              transparent={true}
              textAlign="center"
            />
          ),
        },
        {
          id: 'elifnm',
          text: '승강기형식명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.elifnm}
              onChange={(value) => rowUpdate({ elifnm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 모델명
      [
        {
          id: 'mdcd',
          text: '코드',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.mdcd}
              onChange={(value) => rowUpdate({ mdcd: value })}
              readonly={!x?.isNew}
              transparent={true}
              textAlign="center"
            />
          ),
        },
        {
          id: 'mdnm',
          text: '모델명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.mdnm}
              onChange={(value) => rowUpdate({ mdnm: value })}
            />
          ),
        },
        {
          id: 'manucd',
          text: '제작사',
          width: 150,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.manucd}
              data={this.state.manufacturers?.map((y) => new ComboBoxModel(y.manucd, y.manunm))}
              onSelect={(option) => rowUpdate({ manucd: option.value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 도어방식
      [
        {
          id: 'invercd',
          text: '코드',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.invercd}
              onChange={(value) => rowUpdate({ invercd: value })}
              readonly={!x?.isNew}
              transparent={true}
              textAlign="center"
            />
          ),
        },
        {
          id: 'invernm',
          text: '도어방식명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.invernm}
              onChange={(value) => rowUpdate({ invernm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 정격속도
      [
        {
          id: 'prospcd',
          text: '코드',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.prospcd}
              onChange={(value) => rowUpdate({ prospcd: value })}
              readonly={!x?.isNew}
              transparent={true}
              textAlign="center"
            />
          ),
        },
        {
          id: 'prospnm',
          text: '정격속도명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.prospnm}
              onChange={(value) => rowUpdate({ prospnm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],
    ];
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[ElevatorManagementCodeTabId.TYPE_CODE]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    if (i === ElevatorManagementCodeTabId.MODEL && (this.state.manufacturers?.length || 0) === 0) {
      await this.doRetrieve(ElevatorManagementCodeTabId.MANUFACTURER);
    }

    await this.doRetrieve(i);
  }


  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    const data = await api.fxExec(
      `${ElevatorManagementCodeTabFunctionNames[i]}_new`,
    );

    // @ts-ignore
    data && this.setState({
      [ElevatorManagementCodeTabDataStateNames[i]]: [
        new ElevatorManagementCodeTabModels[i](data, true),
        // @ts-ignore
        ...this.state[ElevatorManagementCodeTabDataStateNames[i]],
      ],
    }, async () => {
      await this.table.current?.update(false);
      // @ts-ignore
      this.table.current?.setFocus(0, 0);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    await api.fxSave(
      `${ElevatorManagementCodeTabFunctionNames[i]}_save`,
      {
        // @ts-ignore
        items: this.state[ElevatorManagementCodeTabUpdatesStateNames[i]],
      },
      true,
    );
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    const item = this.state[ElevatorManagementCodeTabFocusedStateNames[i]];

    if (!item) {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
      return;
    }

    let text = '';
    switch (i) {
      case ElevatorManagementCodeTabId.TYPE_CODE:
        text = `${item.classcd} - ${item.classnm}`;
        break;

      case ElevatorManagementCodeTabId.MANUFACTURER:
        text = `${item.manucd} - ${item.manunm}`;
        break;

      case ElevatorManagementCodeTabId.TYPE:
        text = `${item.eligcd} - ${item.elignm}`;
        break;

      case ElevatorManagementCodeTabId.PURPOSE:
        text = `${item.eliycd} - ${item.eliynm}`;
        break;

      case ElevatorManagementCodeTabId.CATEGORIE:
        text = `${item.elijcd} - ${item.elijnm}`;
        break;

      case ElevatorManagementCodeTabId.FORM:
        text = `${item.elifcd} - ${item.elifnm}`;
        break;

      case ElevatorManagementCodeTabId.MODEL:
        text = `${item.mdcd} - ${item.mdnm}`;
        break;

      case ElevatorManagementCodeTabId.DOOR:
        text = `${item.invercd} - ${item.invernm}`;
        break;

      case ElevatorManagementCodeTabId.SPEED:
        text = `${item.prospcd} - ${item.prospnm}`;
        break;

      default:
        text = '선택된 행을 삭제하시겠습니까?';
    }

    await api.fxDelete(
      `${ElevatorManagementCodeTabFunctionNames[i]}_delete`,
      text,
      // @ts-ignore
      this.state[ElevatorManagementCodeTabFocusedStateNames[i]],
    );

    await this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[ElevatorManagementCodeTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxPrint(
      `${ElevatorManagementCodeTabFunctionNames[i]}_print`,
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[ElevatorManagementCodeTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }

    await api.fxExcel(
      `${ElevatorManagementCodeTabFunctionNames[i]}_excel`,
    );
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    const i = this.tabIndex;
    if (this.state.focusedTab
      // @ts-ignore
      && (this.state[ElevatorManagementCodeTabUpdatesStateNames[i]]?.length || 0) > 0) {
      const text = '저장하지 않은 데이터가 있습니다.\n탭을 넘어가면 저장하지 않은 데이터는 사라집니다.\n넘어가시겠습니까?';
      if (await Confirm.show('경고', text, ConfirmType.WARNING)) {
        this.setState({
          [ElevatorManagementCodeTabUpdatesStateNames[i]]: [],
          [ElevatorManagementCodeTabFocusedStateNames[i]]: undefined,
        });
      } else {
        return;
      }
    }

    this.setState({ focusedTab }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async doRetrieve(i: ElevatorManagementCodeTabId) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {},
      (params) => api.fxExec(
        `${ElevatorManagementCodeTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items) => {
        if (items) {
          this.setState({
            [ElevatorManagementCodeTabDataStateNames[i]]:
            // @ts-ignore
              [...this.state[ElevatorManagementCodeTabDataStateNames[i]],
                ...items.map((x: any) => new ElevatorManagementCodeTabModels[i](x))],
          }, () => this.table.current?.update(false));
        }
      },
      async () => {
        await this.SS({
          [ElevatorManagementCodeTabDataStateNames[i]]: [],
        });

        await this.infinity?.retrieve();
        this.table.current?.update(true);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      [ElevatorManagementCodeTabDataStateNames[i]]: [],
      [ElevatorManagementCodeTabUpdatesStateNames[i]]: [],
      [ElevatorManagementCodeTabFocusedStateNames[i]]: undefined,
    }, async () => {
      await this.infinity?.retrieve();
      this.table.current?.update(true);
    });
  }

  @computed
  get tabIndex(): ElevatorManagementCodeTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [ElevatorManagementCodeTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [ElevatorManagementCodeTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  render() {
    return (
      <ElevatorManagementCodeTemplate
        scope={this}
        update={(state) => this.setState(state)}
      />
    );
  }
}
