import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/all';
import {
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  FormatTextBox,
  TableLayout,
  TextBox,
  DateTextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { System } from './System';
import { SearchBinding } from '../../../../models/common';
import { Date8 } from '../../../../utils/time';

const butons = [
  { value: '%', remark: '전체' },
  { value: 'STR', remark: '접속' },
  { value: 'END', remark: '접속종료' },
  { value: 'OPEN', remark: '화면오픈' },
  { value: 'NEW', remark: '신규' },
  { value: 'SECH', remark: '조회' },
  { value: 'SAVE', remark: '저장' },
  { value: 'DEL', remark: '삭제' },
  { value: 'ART', remark: '알림' },
  { value: 'PRT', remark: '출력' },
  { value: 'FAX', remark: '팩스' },
  { value: 'EXL', remark: '엑셀' },
  { value: 'SMS', remark: '문자' },
  { value: 'MAIL', remark: '이메일' },
  { value: 'GPS', remark: '위치' },
  { value: 'GOSI', remark: '승강기정보센터' },
];

/**
 * 화면
 * @window w_tb_xlogin
 * @category 사용자접속현황
 */
export const SystemTemplate: React.FC<TemplateProps<System>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1} style={{ width: 950 }}>
        <FlexLayout>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => setData({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                setData({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                setData({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => setData({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                setData({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                setData({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            value={scope.state.divicd}
            textAlign="center"
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                  searchQuery: item.cd,
                });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.divinm}
            onChange={(value) => setData({ divinm: value })}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>사용자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                  searchQuery: item.cd,
                });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.pernm}
            onChange={(value) => setData({ pernm: value })}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1} style={{ width: 950 }}>
        <FlexLayout>
          <span>화면명</span>
          <TextBox
            value={scope.state.winid}
            onChange={(value) => setData({ winid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>사용내역</span>
          <ComboBox
            value={scope.state.buton}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={butons.map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ buton: option.value }) }
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>플랫폼</span>
          <ComboBox
            value={scope.state.log}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={[
              { value: '%', remark: '전체' },
              { value: 'ERP', remark: 'ERP' },
              { value: 'APP', remark: '어플' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ log: option.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout isVertical={true}>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'londtdate',
              text: '일자',
              width: 10,
              render: (x, rowUpdate, ref) => (
                <FormatTextBox
                  textAlign="center"
                  format="####-##-##"
                  ref={ref}
                  value={x.londtdate}
                  onChange={(value) => rowUpdate({ londtdate: value })}
                  readonly={true}
                  transparent={true}
                />
              ),
            },
            {
              id: 'londttime',
              text: '시간',
              width: 5,
              render: (x, rowUpdate, ref) => (
                <FormatTextBox
                  textAlign="center"
                  format="##:##"
                  ref={ref}
                  value={x.londttime}
                  onChange={(value) => rowUpdate({ londttime: value })}
                  readonly={true}
                  transparent={true}
                />
              ),
            },
            {
              id: 'perid',
              text: '접속자ID',
              width: 5,
            },
            {
              id: 'pernm',
              text: '접속자',
              width: 5,
            },
            {
              id: 'winnm',
              text: '사용한화면',
              width: 15,
              render: (x) => {
                let color = '';

                switch (x.winnm) {
                  case '로그인':
                    color = 'var(--color-blue)';
                    break;

                  case '로그오프':
                    color = 'var(--color-red)';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style= {{ color }}
                  >
                    {x.winnm}
                  </FlexLayout>
                );
              },
            },
            {
              id: 'buton',
              text: '사용내역',
              width: 15,
              render: (x) => {
                let color = '';

                switch (x.buton) {
                  case 'STR':
                    color = 'var(--color-blue)';
                    break;

                  case 'END':
                    color = 'var(--color-red)';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style= {{ color }}
                  >
                    {butons.filter((y) => y.value === x.buton)[0]?.remark}
                  </FlexLayout>
                );
              },
            },
            {
              id: 'logname',
              text: '접속플랫폼',
              width: 15,
            },
            {
              id: 'logip',
              text: '접속IP',
              width: 15,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.logip}
                  onChange={(value) => rowUpdate({ logip: value })}
                  readonly={true}
                  transparent={true}
                />
              ),
            },
            {
              id: 'lognum',
              text: '사용순번',
              width: 15,
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
