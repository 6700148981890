import * as React from 'react';
import {
  FiArrowRightCircle,
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  Report,
  ReportItemChangeTypes,
} from './Report';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Date8,
} from '../../../../utils/time';
import {
  AttachmentBox,
  Badge,
  Button,
  ComboBox,
  ComboBoxModel,
  CustomAttachmentBox,
  FormatTextBox,
  ModalView,
  OptionBox,
  LabelBox,
  TextBox,
  DateTextBox,
  GridLayout,
  FlexLayout,
  SearchLayout,
} from '../../../../components';
import { SearchBinding } from '../../../../models';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/*
* 화면
* @window w_tb_e311
* @category 민원처리보고서
*/

export const States = [
  { key: '0', value: '접수' },
  { key: '1', value: '배정' },
  { key: '2', value: '완료' },
];

export const Appgubuns = [
  { key: '101', value: '결재' },
  { key: '121', value: '참조' },
  { key: '001', value: '대기' },
  { key: '131', value: '반려' },
  { key: '111', value: '결재중' },
  { key: '301', value: '전결' },
  { key: '401', value: '대결' },
];


export const ReportTemplate: React.FC<TemplateProps<Report>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.focusedDetailReport;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <SearchLayout>
            <FlexLayout size={200}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.stdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ stdate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={175}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ enddate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={150}>
              <span>결과</span>
              <ComboBox
                value={scope.state.state}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '1', remark: '배정' },
                  { value: '2', remark: '완료' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.setState({ state: option.value },
                  () => scope.onRetrieveEvent())}
              />
            </FlexLayout>

            <FlexLayout>
              <span>검색어</span>
              <TextBox
                placeholderColor="#CACACA"
                placeholderAlign="left"
                placeholder="현장으로 검색하세요"
                value={scope.state.searchQuery}
                onChange={(value) => scope.setState({ searchQuery: value })}
                onEnter={() => scope.onRetrieveEvent()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={300}>
              <span>담당자</span>
              <TextBox
                value={scope.state.perid}
                textAlign="center"
                onChange={(value) => scope.setState({ pernm: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    scope.setState({ perid: item.cd, pernm: item.cdnm });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                weight={1.6}
                value={scope.state.pernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </FlexLayout>

            <Button onClick={() => scope.onReport()}>
              <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
            </Button>
          </SearchLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout isVertical={true}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'state',
                text: '상태',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                    style={x.state === '2' && '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
                  >
                    {States.filter((y) => y.key === x.state)[0]?.value}
                  </FlexLayout>
                ),
              },
              {
                id: 'resultnm',
                text: '처리결과',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.resultnm === '처리완료' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
                  >
                    {x.resultnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'compdate',
                text: '민원일자',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.compdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.recedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'recenum',
                text: '번호',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.recenum}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 40,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'compdate',
                text: '완료일자',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.compdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'appgubun',
                text: '결재상태',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.state === '101' || x.state === '301' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
                  >
                    {Appgubuns.filter((y) => y.key === x.appgubun)[0]?.value}
                    {x?.appremark && <Badge>
                      <IoIosMail color="var(--color-red)" size={25} />
                    </Badge>}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.ReportList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>
        <FlexLayout
          weight={1.7}
          isVertical={true}
          disabled={!scope.state.ReportList.length && !scope.state.focusedDetailReport.isNew}
          disabledText="신규로 추가하거나 현장을 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="민원접수일자"
              isNeed={true}
            >
              <DateTextBox
                readonly={model?.new === '0'}
                value={model?.recedate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ recedate: value })}
              />
              <TextBox
                weight={0.5}
                textAlign="center"
                value={model?.recenum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="접수시간">
              <FormatTextBox
                format="##:##"
                value={model?.recetime}
                textAlign="center"
                onChange={(value) => setData({ recetime: value })}
              />
            </LabelBox>
            <LabelBox title="접수자"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.reperid}
                textAlign="center"
                onChange={(value) => setData({ reperid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      reperid: item.cd,
                      repernm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.repernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="민원처리일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.compdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ compdate: value })}
              />
            </LabelBox>
            <LabelBox
              title="민원처리일시"
              isNeed={true}
            >
              <FormatTextBox
                value={model?.sttime}
                textAlign="center"
                format="##:##"
                onChange={(value) => setData({ sttime: value })}
              />
              <span style={{ marginLeft: 8, marginRight: 8, marginTop: 8 }}>~</span>
              <FormatTextBox
                value={model?.endtime}
                textAlign="center"
                format="##:##"
                onChange={(value) => setData({ endtime: value })}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <TextBox
                textAlign="center"
                value={model?.appnum}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="고객명(현장/민원신고자)"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.actcd}
                textAlign="center"
                onChange={(value) => setData({ actcd: value }, () => scope.itemChanged(ReportItemChangeTypes.ACTCD))}
                onBlur={() => scope.itemChanged(ReportItemChangeTypes.ACTCD)}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.actnm}
                weight={1.7}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="민원구분"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.civilcd}
                textAlign="center"
                onChange={(value) => setData({ civilcd: value }, () => scope.itemChanged(ReportItemChangeTypes.ACTCD))}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E300',
                  {},
                  true,
                  (item) => {
                    setData({
                      civilcd: item.cd,
                      civilnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.civilnm}
                weight={1.7}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="신고자연락처"
            >
              <TextBox
                value={model?.acttelnum}
                textAlign="center"
                onChange={(value) => setData({ acttelnum: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="처리구분">
              <OptionBox
                value={model!?.okflag}
                data={[
                  { value: '1', remark: '미처리' },
                  { value: '2', remark: '처리완료' },
                ]}
                onChange={(v) => setData({ okflag: v.value })}
              />
            </LabelBox>
            <LabelBox title="처리결과"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.resultcd}
                textAlign="center"
                onChange={(value) => setData({ resultcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E015',
                  {},
                  true,
                  (item) => {
                    setData({
                      resultcd: item.cd,
                      resultnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={1.7}
                value={model?.resultnm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="처리자"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value }, () => scope.itemChanged(ReportItemChangeTypes.ACTCD))}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={1.7}
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부(사진)"
              size={150}
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.photoModal(true)}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                textAlign="center"
                value={model?.attcnt2 === '1' ? 'O' : 'X'}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="고객상세내용">
              <TextBox
                value={model?.remark}
                isMultiline={true}
                textAlign="left"
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
            <LabelBox title="민원처리결과내용">
              <TextBox
                value={model?.compremark}
                isMultiline={true}
                textAlign="left"
                onChange={(value) => setData({ compremark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={200}>
            <LabelBox title="특이사항">
              <TextBox
                value={model?.bigo}
                isMultiline={true}
                textAlign="left"
                onChange={(value) => setData({ bigo: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    onClick={() => scope.openReferSelector()}
                    style={{ marginRight: 5 }}
                  >
                    <FiArrowRightCircle size={17}/>
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                textAlign='left'
                value={model?.refer}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.fileModal(true)}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                textAlign="center"
                value={model?.attcnt === '1' ? 'O' : 'X'}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <ModalView
            width={800}
            isVisible={scope.state.filePhotoModal}
            onClose={() => scope.photoModal(false)}
          >
            <FlexLayout
              style={{
                padding: 8,
              }}
            >
              <CustomAttachmentBox
                isEnabledPreview={true}
                window="w_popup_e311_img"
                params={{
                  recedate: model?.recedate,
                  recenum: model?.recenum,
                }}
              />
            </FlexLayout>
          </ModalView>

          <ModalView
            width={1300}
            isVisible={scope.state.fileDetailModal}
            onClose={() => scope.fileModal(false)}
          >
            <FlexLayout
              style={{
                padding: 8,
              }}
            >
              <AttachmentBox
                appnum={scope.state.focusedDetailReport?.appnum || ''}
                appnm={'민원처리보고서'}
                isEnabledPreview={true}
              />
            </FlexLayout>
          </ModalView>

        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
