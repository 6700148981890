import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox, TelnumTextBox, SearchLayout,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchBinding } from '../../../../models/common';
import { Mobile } from './Mobile';


/**
 * 화면
 * @window w_tb_xappnum
 * @category 어플사용자등록
 */
export const MobileTemplate: React.FC<TemplateProps<Mobile>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1} style={{ width: 800 }}>
    <FlexLayout >
      <span>검색어</span>
      <TextBox
        placeholderColor="#CACACA"
        placeholderAlign="left"
        placeholder="사원, 핸드폰번호로 검색하세요"
        value={scope.state.searchQuery}
        onChange={(value) => scope.setState({ searchQuery: value })}
        onEnter={() => scope.onRetrieveEvent()}
        isDisabledTrackingStateChange={true}
      />
    </FlexLayout>
    </SearchLayout>
    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'perid',
          text: '사원코드',
          width: 9,
          color: 'var(--color-red)',
          render: (x, rowUpdate, refs) => (
            <TextBox
              refs={refs}
              value={x.perid}
              textAlign="center"
              onChange={(value) => rowUpdate({ perid: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  rowUpdate({ perid: item.cd, pernm: item.cdnm, handphone: item.uv_arg4 });
                },
              )}
            />
          ),
        },
        {
          id: 'pernm',
          text: '사원명',
          width: 9,
        },
        {
          id: 'handphone',
          text: '핸드폰번호',
          width: 15,
          render: (x, rowUpdate, refs) => (
            <TelnumTextBox
              refs={refs}
              value={x.handphone}
              textAlign="center"
              onChange={(value) => rowUpdate({ handphone: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용',
          width: 5,
          render: (x, rowUpdate) => (
            <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'battery_app',
          text: '어플 배터리 최적화 모드',
          width: 32.5,
          render: (x) => (
            <FlexLayout align="center">
              <span style={{ width: 60, textAlign: 'center' }}>
                <b style={{ color: x.battery_app === '0' ? 'var(--color-blue)' : 'var(--color-red)' }}>
                  {x.battery_app === '1' ? '동작중' : '꺼짐'}
                </b>
              </span>
              {x.battery_app === '1' ? '최적화 동작중에 있어 현위치가 불정확할 수 있습니다.' : ''}
            </FlexLayout>
          ),
        },
        {
          id: 'battery_device',
          text: '휴대폰 기기 절전 모드',
          width: 32.5,
          render: (x) => (
            <FlexLayout align="center">
              <span style={{ width: 60, textAlign: 'center' }}>
                <b style={{ color: x.battery_device === '0' ? 'var(--color-blue)' : 'var(--color-red)' }}>
                  {x.battery_device === '1' ? '동작중' : '꺼짐'}
                </b>
              </span>
              {x.battery_device === '1' ? '절전모드 동작중에 있어 현위치가 불정확할 수 있습니다.' : ''}
            </FlexLayout>
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(x) => scope.onRowFocusEvent(x)}
      data={scope.state.data}
    />
  </FlexLayout>
);
