import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { BillBundleTemplate } from './BillBundle.template';
import { BillBundleModel } from './BillBundle.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

interface BillBundleState {
  // search
  searchQuery: string;
  yearmon: string;
  endYearmon: string;

  // data
  data: Array<BillBundleModel>;
  focused?: BillBundleModel;
  pushTotalCheck: boolean;
  pushData: Array<BillBundleModel>;

  // trail
  prtcnt_tot: string;
  notcnt_tot: string;
  amt_tot: string;
  addamt_tot: string;
  misamt_tot: string;
  total: string;
}

/**
 * 컨트롤러
 * @window w_tb_e050w_04
 * @category 청구서일괄발행
 */
export class BillBundle extends PageComponent<PageProps, BillBundleState>
  implements PageToolEvents {
  updatedRows?: Array<BillBundleModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      // search
      yearmon: Today.yearMon(),
      endYearmon: Today.yearMon(),
      searchQuery: '',
      data: [],

      // trail
      prtcnt_tot: '0',
      notcnt_tot: '0',
      amt_tot: '0',
      total: '0',
      addamt_tot: '0',
      misamt_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onDateEvent() {
    this.setState({ endYearmon: this.state.yearmon }, async () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stmon: this.state.yearmon,
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new BillBundleModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update(true);
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      pushTotalCheck: false,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['cltnm', 'misnum'],
        [this.state.focused?.cltnm, this.state.focused?.misnum], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.table.current?.update(true);
        this.table.current?.setFocus(index, 0);
      }
      // 전체 체크
      await this.SS({
        prtcnt_tot: this.infinity?.box?.prtcnt_tot || '0',
        notcnt_tot: this.infinity?.box?.notcnt_tot || '0',
        total: this.infinity?.box?.total || '0',
        amt_tot: this.infinity?.box?.amt_tot || '0',
        addamt_tot: this.infinity?.box?.addamt_tot || '0',
        misamt_tot: this.infinity?.box?.misamt_updateCheckAllToggletot || '0',
      });
      await this.table.current?.update(true);
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    let chkData: BillBundleModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new BillBundleModel({
          ...x,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
    } else if (chkData.length >= 50) {
      ConfirmWarning.show('경고', '한번에 최대 50건까지 인쇄가 가능합니다!');
    } else {
      await api.printWithElmanManager({
        as_nm: this.state.searchQuery,
        stmon: this.state.yearmon,
        items: chkData,
      });
      chkData = [];
    }
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      as_nm: this.state.searchQuery,
      stmon: this.state.yearmon,
    });
  }

  @action
  onRowFocusEvent(item: BillBundleModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<BillBundleModel>, updatedRows: Array<BillBundleModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.data?.forEach((x: any) => {
      checkData.push(new BillBundleModel({
        ...x,
        chk: checked === true ? '1' : '0',
      }));
    });

    this.setState({
      pushTotalCheck: checked,
      data: checkData,
    }, async () => {
      this.table.current?.update(false);
    });
  }

  @action
  onClickOpenBalEnrollment() {
    this.props.publicStore.go(
      '/w_tb_da023',
      {
        projno: this.state.focused?.projno,
        misdate: this.state.focused?.misdate,
      },
    );
  }

  render() {
    return (
      <BillBundleTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
