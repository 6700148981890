/**
 * 모델
 * @window w_tb_sms_report
 * @category 문자송신내역
 */
import { computed } from 'mobx';

export class TextTransmitModel {
  /**
   * x
   *
   * **data type** int
   */
  MSG_ID: string;

  /**
   * x
   *
   * **data type** varchar(20)
   */
  USER_ID: string;

  /**
   * x
   *
   * **data type** int
   */
  JOB_ID: string;

  /**
   * x
   *
   * **data type** int
   */
  MSG_SUBTYPE: string;

  /**
   * x
   *
   * **data type** int
   */
  SCHEDULE_TYPE: string;

  /**
   * x
   *
   * **data type** int
   */
  DEST_TYPE: string;

  /**
   * x
   *
   * **data type** int
   */
  MENT_TYPE: string;

  /**
   * x
   *
   * **data type** int
   */
  VOICE_TYPE: string;

  /**
   * 제목
   *
   * **data type** varchar(50)
   */
  SUBJECT: string;

  /**
   * x
   *
   * **data type** varchar(20)
   */
  NOW_DATE: string;

  /**
   * 전송일자
   *
   * **data type** varchar(20)
   */
  SEND_DATE: string;

  /**
   * 보낸이연락처
   *
   * **data type** varchar(20)
   */
  CALLBACK: string;

  /**
   * x
   *
   * **data type** int
   */
  REPLY_TYPE: string;

  /**
   * x
   *
   * **data type** int
   */
  REPLY_COUNT: string;

  /**
   * x
   *
   * **data type** int
   */
  COUNSELOR_DTMF: string;

  /**
   * x
   *
   * **data type** varchar(20)
   */
  COUNSELOR_NUMBER: string;

  /**
   * x
   *
   * **data type** int
   */
  RELISTEN_COUNT: string;

  /**
   * x
   *
   * **data type** varchar(20)
   */
  KT_OFFICE_CODE: string;

  /**
   * x
   *
   * **data type** varchar(20)
   */
  CDR_ID: string;

  /**
   * x
   *
   * **data type** varchar(100)
   */
  SMSPLUS_MSG: string;

  /**
   * x
   *
   * **data type** varchar(150)
   */
  HEADER_MSG: string;

  /**
   * x
   *
   * **data type** text
   */
  TAIL_MSG: string;

  /**
   * x
   *
   * **data type** int
   */
  TTS_MSG: string;

  /**
   * x
   *
   * **data type** text
   */
  DEST_INFO: string;

  /**
   * x
   *
   * **data type** text
   */
  ATTACH_FILE: string;

  /**
   * 보낸이
   *
   * **data type** varchar(50)
   */
  RESERVED1: string;

  /**
   * 받는이
   *
   * **data type** varchar(50)
   */
  RESERVED2: string;

  /**
   * x
   *
   * **data type** varchar(50)
   */
  RESERVED3: string;

  /**
   * x
   *
   * **data type** varchar(50)
   */
  RESERVED4: string;

  /**
   * x
   *
   * **data type** varchar(50)
   */
  RESERVED5: string;

  /**
   * x
   *
   * **data type** varchar(50)
   */
  RESERVED6: string;

  /**
   * x
   *
   * **data type** varchar(50)
   */
  RESERVED7: string;

  /**
   * x
   *
   * **data type** varchar(50)
   */
  RESERVED8: string;

  /**
   * x
   *
   * **data type** varchar(50)
   */
  RESERVED9: string;

  /**
   * 전송여부
   *
   * **data type** int
   */
  SUCC_COUNT: string;

  /**
   * x
   *
   * **data type** int
   */
  FAIL_COUNT: string;

  /**
   * x
   *
   * **data type** int
   */
  CANCEL_STATUS: string;

  /**
   * x
   *
   * **data type** int
   */
  CANCEL_COUNT: string;

  /**
   * x
   *
   * **data type** int
   */
  CANCEL_REQ_DATE: string;

  /**
   * x
   *
   * **data type** int
   */
  CANCEL_RESULT: string;

  /**
   * x
   *
   * **data type** int
   */
  STD_ID: string;

  /**
   * 그룹코드
   *
   * **data type** varchar(50)
   */
  groupcd: string;

  /**
   * 전화구분
   * **data type** varchar(10)
   */
  telcd: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.MSG_ID = data.MSG_ID || '';
    this.USER_ID = data.USER_ID || '';
    this.JOB_ID = data.JOB_ID || '';
    this.MSG_SUBTYPE = data.MSG_SUBTYPE || '';
    this.SCHEDULE_TYPE = data.SCHEDULE_TYPE || '';
    this.DEST_TYPE = data.DEST_TYPE || '';
    this.MENT_TYPE = data.MENT_TYPE || '';
    this.VOICE_TYPE = data.VOICE_TYPE || '';
    this.SUBJECT = data.SUBJECT || '';
    this.NOW_DATE = data.NOW_DATE || '';
    this.SEND_DATE = data.SEND_DATE || '';
    this.CALLBACK = data.CALLBACK || '';
    this.REPLY_TYPE = data.REPLY_TYPE || '';
    this.REPLY_COUNT = data.REPLY_COUNT || '';
    this.COUNSELOR_DTMF = data.COUNSELOR_DTMF || '';
    this.COUNSELOR_NUMBER = data.COUNSELOR_NUMBER || '';
    this.RELISTEN_COUNT = data.RELISTEN_COUNT || '';
    this.KT_OFFICE_CODE = data.KT_OFFICE_CODE || '';
    this.CDR_ID = data.CDR_ID || '';
    this.SMSPLUS_MSG = data.SMSPLUS_MSG || '';
    this.HEADER_MSG = data.HEADER_MSG || '';
    this.TAIL_MSG = data.TAIL_MSG || '';
    this.TTS_MSG = data.TTS_MSG || '';
    this.DEST_INFO = data.DEST_INFO || '';
    this.ATTACH_FILE = data.ATTACH_FILE || '';
    this.RESERVED1 = data.RESERVED1 || '';
    this.RESERVED2 = data.RESERVED2 || '';
    this.RESERVED3 = data.RESERVED3 || '';
    this.RESERVED4 = data.RESERVED4 || '';
    this.RESERVED5 = data.RESERVED5 || '';
    this.RESERVED6 = data.RESERVED6 || '';
    this.RESERVED7 = data.RESERVED7 || '';
    this.RESERVED8 = data.RESERVED8 || '';
    this.RESERVED9 = data.RESERVED9 || '';
    this.SUCC_COUNT = data.SUCC_COUNT || '';
    this.FAIL_COUNT = data.FAIL_COUNT || '';
    this.CANCEL_STATUS = data.CANCEL_STATUS || '';
    this.CANCEL_COUNT = data.CANCEL_COUNT || '';
    this.CANCEL_REQ_DATE = data.CANCEL_REQ_DATE || '';
    this.CANCEL_RESULT = data.CANCEL_RESULT || '';
    this.STD_ID = data.STD_ID || '';
    this.groupcd = data.groupcd || '';
    this.telcd = data.telcd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
