import * as React from 'react';
import { action } from 'mobx';
import update from 'react-addons-update';
import {
  AskType,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import EnrollmentModel from './models/EnrollmentModel';
import { EnrollmentTemplate } from './Enrollment.template';
import {
  Date8,
  DateStabilizer,
  Today,
} from '../../../../utils/time';
import {
  Confirm,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { PageComponent } from '../../../../utils';
import EnrollmentPullModel from '../../../maintenance/construction/Enrollment/models/EnrollmentPullModel';
import { Format } from '../../../../utils/string';

export const Gubuns = [
  { value: '%', remark: '전체' },
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '기타수입' },
  { value: '18', remark: '기타환불' },
];

interface SampleState {
  // Search
  searchQuery: string;
  stdate: string;
  enddate: string;

  // 예전날짜 및 번호
  beorddate: string;
  beordnum: string;

  // Header
  data: Array<EnrollmentModel>;
  content: EnrollmentModel;
  lastSaveData: EnrollmentModel;

  // Tab - Materials
  dataMaterials: Array<any>;

  // Tab - Materials
  dataItems: Array<any>;

  // Tab - Materials
  dataConditions: Array<any>;

  isModifyDate: boolean;

  isVisiblePull: boolean;
  dataPull: Array<EnrollmentPullModel>;
}

/**
 * 컨트롤러
 * @window w_tb_da006
 * @category 수주서등록
 */
export class Enrollment extends PageComponent<PageProps, SampleState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  tabIndex: number = 0;

  tableMaterials: React.RefObject<TableLayout> = React.createRef();

  rowsUpdatedMaterials: Array<any> = [];

  rowFocusIndexMaterials: number = 0;

  tableItems: React.RefObject<TableLayout> = React.createRef();

  rowsUpdatedItems: Array<any> = [];

  rowFocusIndexItems: number = 0;

  tableConditions: React.RefObject<TableLayout> = React.createRef();

  rowsUpdatedConditions: Array<any> = [];

  rowFocusIndexConditions: number = 0;

  infinityPull?: InfinityRetrieve;

  gridPullFocusIndex: number = 0;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    this.state = props.state || {
      // Search
      searchQuery: pageParams?.searchQuery || '',
      stdate: pageParams?.stdate || `${Date8.make().substr(0, 6)}01`,
      enddate: pageParams?.enddate || Date8.make(),

      // Header
      data: [],
      content: new EnrollmentModel(),

      // Data
      dataMaterials: [],
      dataItems: [],
      dataConditions: [],

      isModifyDate: false,
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        searchQuery: pageParams?.searchQuery || this.state.searchQuery,
        stdate: pageParams?.stdate || this.state.stdate,
        enddate: pageParams?.enddate || this.state.enddate,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    if (this.state.content.isNew
      && await Confirm.ask('내용 유실 주의', '현재 신규 작성중입니다\n다른 항목으로 이동시 작성중인 내용은 사라집니다\n이동하시겠습니까?', '예', '취소')
    ) {
      return;
    }

    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new EnrollmentModel(item)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          content: new EnrollmentModel(),
          data: [],
        });

        await this.infinity?.retrieveAll();
        this.state.data.length > 0 && this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.content;
    this.setState({
      content: new EnrollmentModel(),
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(
        ['orddate', 'ordnum'],
        [lastSelected?.orddate, lastSelected?.ordnum],
        type,
        true,
      ) || 0;

      if (!autoLoad) return;
      this.state.data.length > index && this.grid.current?.setFocus(index);
    });
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '' || this.state.enddate < Today.date()) {
      await this.SS({
        searchQuery: '',
        stdate: `${Date8.make().substr(0, 6)}01`,
        enddate: Date8.make(),
      });
      await this.onRetrieveEvent(RetrieveFocusType.FIRST, false);
    }

    if (this.state.content.isNew) {
      ConfirmWarning.show('오류', '이미 작성중이신 신규 수주서가 있습니다\n저장후 이용해주세요.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.new({ orddate: Date8.make() });
    if (data) {
      const one = new EnrollmentModel(data, true);

      this.setState({
        data: [one, ...this.state.data],
        content: one,
        lastSaveData: one,
        dataItems: data.items,
      }, () => this.grid.current?.setFocus(0));
    }
  }

  @action
  async onSaveEvent() {
    if (!this.state.dataMaterials.length) {
      ConfirmWarning.show('오류', '수주할 품목이 없습니다');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.save(
      {
        ...this.state.content,
        items: this.rowsUpdatedMaterials,
        items2: this.rowsUpdatedItems,
        items3: this.rowsUpdatedConditions,
      },
      this.state.content.isNew,
    );
    if (data && this.state.content.notice === '1') {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.content.orddate);
      this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
        content: new EnrollmentModel({ ...data, ordnum: data.ordnum }),
      }, () => this.onRetrieveEvent());
    } else if (this.state.content.notice === '0') {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.content.orddate);
      if (!await Confirm.show('수주통보',
        '수주통보내역이 없는 수주입니다..\n수주를 담당자들에게 통보하시겠습니까?', ConfirmType.QUESTION)) {
        this.setState({
          stdate: futureSearchRange.stdate,
          enddate: futureSearchRange.enddate,
          content: new EnrollmentModel({ ...data, ordnum: data.ordnum }),
        }, () => this.onRetrieveEvent(RetrieveFocusType.DEFAULT));
        return;
      }
      await this.sendNotice();
      this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
        content: new EnrollmentModel({ ...data, ordnum: data.ordnum }),
      }, () => this.onRetrieveEvent(RetrieveFocusType.DEFAULT));
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.content?.actnm} - ${this.state.content?.title}`;

    if (await api.delete(text, this.state.content)) {
      this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onMessageEvent(topic: string, message: string) {
    const { publicStore } = this.props;
    if (topic === `/elman/web/${publicStore.user.custcd}/${publicStore.user.perid}`) {
      const json = JSON.parse(JSON.parse(message));
      if (json.key === 'ALERT-ANSWER'
        && AskType.YES === await Confirm.ask(json.title, json.message, '예', '아니요')
      ) {
        this.openPull();
      }
    }
  }

  @action
  async onRowFocusEvent(item: EnrollmentModel) {
    if (this.state.content.isNew
      && !(this.state.content.orddate === item.orddate && this.state.content.ordnum === item.ordnum)
      && await Confirm.ask('내용 유실 주의', '현재 신규 작성중입니다\n다른 항목으로 이동시 작성중인 내용은 사라집니다\n이동하시겠습니까?', '예', '취소')) {
      this.setState({
        content: this.state.lastSaveData,
      });
      return;
    }

    this.rowsUpdatedMaterials = [];
    this.rowsUpdatedItems = this.state.content.isNew ? this.state.dataItems : [];
    this.rowsUpdatedConditions = [];

    this.setState({
      content: item,
      isModifyDate: false,
      dataMaterials: [],
      dataItems: this.state.content.isNew ? this.state.dataItems : [],
      dataConditions: [],
    }, () => {
      this.tableMaterials.current?.update(true);
      this.tableItems.current?.update(true);
      this.tableConditions.current?.update(true);
    });

    if (this.state.content.isNew) {
      this.setState({
        content: this.state.lastSaveData,
      });
      return;
    }
    await this.loadHeader(item);
  }

  @action
  async loadHeader(item: EnrollmentModel) {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_1_RowFocuschanged', item);

    if (data) {
      this.setState({
        content: new EnrollmentModel(data, this.state.content.isNew),
        dataMaterials: data?.items || [],
        dataItems: data?.items2 || [],
        dataConditions: data?.items3 || [],
      }, () => {
        this.tableMaterials.current?.update(true);
        this.tableItems.current?.update(true);
        this.tableConditions.current?.update(true);
      });
    }
  }

  @action
  async headerItemChanged(key: string, value: string) {
    const { actionStore: api } = this.props;
    const { content } = this.state;
    if (this.state.isModifyDate) {
      await this.changeDate();
      return;
    }

    let result: { items: any[]; };

    if (key === 'perid') {
      result = await api.fxExec('dw_2_itemchanged', {
        itemname: key,
        data: value,
      });
      return;
    }
    result = await api.fxExec('dw_2_itemchanged', {
      ...content,
      itemname: key,
      data: value,
      orddate: content.orddate,
      ordnum: content.ordnum,
      items3: this.state.dataMaterials.length,
      items4: this.state.dataItems.length,
      items5: this.state.dataConditions.length,
      new: content.isNew ? '1' : '0',
    });



    const one = new EnrollmentModel({ ...content, ...result }, content.isNew);
    const newTabData: Array<any> = [];
    this.state.dataMaterials.forEach((x: any, i: number) => newTabData.push({
      ...x,
      ...result.items[i],
    }));
    this.setState({ content: one, dataMaterials: newTabData });
    await this.tableMaterials.current?.update(true);
  }

  @action
  sendNotice() {
    const { actionStore: api } = this.props;
    api.fxExec('wb_notice', this.state.content);
  }

  @action
  changeDate() {
    const { actionStore: api } = this.props;
    api.fxExec('dw_2_itemchanged',
      {
        itemname: 'b_orddate',
        data: this.state.content.orddate,
        beorddate: this.state.beorddate,
        beordnum: this.state.beordnum,
        newdate: this.state.content.orddate,
        projno: this.state.content.projno,
        actcd: this.state.content.actcd,
      });
  }


  // Tab
  onTabChange(index: number) {
    this.tabIndex = index;
  }

  @action
  async tabButtonAdd() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(`tab_${this.tabIndex + 1}_dw_3_new`, {
      orddate: this.state.content.orddate,
      ordnum: this.state.content.ordnum,
      cltcd: this.state.content.cltcd,
      actcd: this.state.content.actcd,
      gubun: this.state.content.gubun,
    });

    if (data) {
      let lastSeq = '000';
      let seq = '00';

      switch (this.tabIndex) {
        case 0:
          lastSeq = this.state.dataMaterials[this.state.dataMaterials.length - 1]?.seq || lastSeq;
          seq = (`00${(parseInt((this.state.dataMaterials.length > 0
            ? lastSeq
            : seq), 10) + 1).toString()}`).substr(-2);

          this.setState({
            dataMaterials: [...this.state.dataMaterials, { ...data, new: '1' }],
          }, async () => {
            await this.tableMaterials.current?.update(true);
            this.tableMaterials.current?.setFocus(this.state.dataMaterials.length - 1);
          });
          break;

        case 1:
          this.setState({
            dataItems: [...this.state.dataItems, { ...data, seq, new: '1' }],
          }, async () => {
            await this.tableItems.current?.update(true);
            this.tableItems.current?.setFocus(this.state.dataItems.length - 1);
          });
          break;

        case 2:
          lastSeq = this.state.dataConditions[this.state.dataConditions.length - 1]?.seq || lastSeq;
          seq = (parseInt((this.state.dataConditions.length > 0
            ? lastSeq
            : seq), 10) + 1).toString();

          this.setState({
            dataConditions: [...this.state.dataConditions, { ...data, seq, new: '1' }],
          }, async () => {
            await this.tableConditions.current?.update(true);
            this.tableConditions.current?.setFocus(this.state.dataConditions.length - 1);
          });
          break;
      }
    }
  }

  getRowFocusItemOnActiveTab(): any {
    switch (this.tabIndex) {
      case 0:
        return this.state.dataMaterials[this.rowFocusIndexMaterials];

      case 1:
        return this.state.dataItems[this.rowFocusIndexItems];

      case 2:
        return this.state.dataConditions[this.rowFocusIndexConditions];

      default:
        return {};
    }
  }

  @action
  async tabButtonRemove() {
    const { actionStore: api } = this.props;
    let data = false;
    const item = this.getRowFocusItemOnActiveTab();

    if (item.new === '1') {
      data = true;
    } else {
      data = await api.fxExec(`tab_${this.tabIndex + 1}_dw_3_delete`, item);
    }

    if (data) {
      switch (this.tabIndex) {
        case 0:
          this.setState({
            dataMaterials: update(this.state.dataMaterials, {
              $splice: [[this.rowFocusIndexMaterials, 1]],
            }),
          });

          // Focus next row
          await this.tableMaterials.current?.update(false);
          if (this.state.dataMaterials.length) {
            const next = this.rowFocusIndexMaterials === 0 ? 0 : this.rowFocusIndexMaterials - 1;
            this.rowFocusIndexMaterials = next;
            this.tableMaterials.current?.setFocus(next);
          }
          break;

        case 1:
          this.setState({
            dataItems: update(this.state.dataItems, {
              $splice: [[this.rowFocusIndexItems, 1]],
            }),
          });

          // Focus next row
          await this.tableItems.current?.update(false);
          if (this.state.dataItems.length) {
            const next = this.rowFocusIndexItems === 0 ? 0 : this.rowFocusIndexItems - 1;
            this.rowFocusIndexItems = next;
            this.tableItems.current?.setFocus(next);
          }
          break;

        case 2:
          this.setState({
            dataConditions: update(this.state.dataConditions, {
              $splice: [[this.rowFocusIndexConditions, 1]],
            }),
          });

          // Focus next row
          await this.tableConditions.current?.update(false);
          if (this.state.dataConditions.length) {
            const next = this.rowFocusIndexConditions === 0 ? 0 : this.rowFocusIndexConditions - 1;
            this.rowFocusIndexConditions = next;
            this.tableConditions.current?.setFocus(next);
          }
          break;
      }
    }
  }


  // Tab - Materials
  // @action
  // async tabMaterialsItemChanged(rowUpdate: RowUpdate, key: string, value: string) {
  //   const { actionStore: api } = this.props;
  //   const result = await api.fxExec('dw_3_itemchanged', {
  //     ...this.getRowFocusItemOnActiveTab(),
  //     [key]: value,
  //     itemname: key,
  //     data: value,
  //   }, this.getRowFocusItemOnActiveTab().new === '1');
  //
  //   if (result) {
  //     rowUpdate(result);
  //   }
  // }

  @action
  async tabAutoCalc(item: any, rowUpdate: RowUpdate, isSamt: boolean = false) {
    const isTax = this.state.content.taxcls === '1';
    const qty = Format.toNumber(item.qty);
    const uamt = Format.toNumber(item.uamt);

    let samt = Format.toNumber(item.samt);

    if (!isSamt) {
      samt = Math.round((qty * uamt) / (isTax ? 1.1 : 1));
    } else {
      samt = isTax ? Math.round(samt / 1.1) : samt;
    }

    const tamt = Math.round(samt * 0.1);

    rowUpdate({
      ...item,
      samt: samt.toString(),
      tamt: tamt.toString(),
      mamt: (samt + tamt).toString(),
    });
  }

  @action
  async tabSamtChange(rowUpdate: RowUpdate, _: string, value: string) {
    const currentData = value.replace(',', '');
    if (this.state.content.taxcls === '1') {
      const samtResult = Math.round((Number(currentData)
        * Number(this.state.dataMaterials[this.rowFocusIndexMaterials]?.qty)) / 1.1);
      const tamtResult = Number(currentData)
        * Number(this.state.dataMaterials[this.rowFocusIndexMaterials]?.qty)
        - Math.round((Number(currentData)
          * Number(this.state.dataMaterials[this.rowFocusIndexMaterials]?.qty)) / 1.1);
      const mamtResult = samtResult + tamtResult;
      const newTabData: Array<any> = [];
      // eslint-disable-next-line no-new-object
      this.state.dataMaterials.forEach((x: any) => newTabData.push(new Object({
        ...x,
        ...this.state.dataMaterials,
        samt: String(samtResult),
        tamt: String(tamtResult),
        mamt: String(mamtResult),
      })));
      this.setState({
        dataMaterials: newTabData,
      });
      await this.tableMaterials.current?.update(true);
    }
    if (this.state.content.taxcls === '0') {
      const samtResult = Math.round(Number(currentData)
        * Number(this.state.dataMaterials[this.rowFocusIndexMaterials]?.qty));
      const tamtResult = Math.round(Number(currentData)
        * Number(this.state.dataMaterials[this.rowFocusIndexMaterials]?.qty)) / 10;
      const mamtResult = samtResult + tamtResult;
      const newTabData: Array<any> = [];
      // eslint-disable-next-line no-new-object
      this.state.dataMaterials.forEach((x: any) => newTabData.push(new Object({
        ...x,
        samt: String(samtResult),
        tamt: String(tamtResult),
        mamt: String(mamtResult),
      })));
      this.setState({
        dataMaterials: newTabData,
      });
      await this.tableMaterials.current?.update(true);
      if (tamtResult) {
        rowUpdate(tamtResult);
        rowUpdate(samtResult);
      }
    }
  }

  @action
  onChangeTableMaterials(rows: Array<any>, updatedRows: Array<any>) {
    this.rowsUpdatedMaterials = updatedRows;
    this.setState({
      dataMaterials: rows,
    });
  }

  @action
  onRowFocusMaterials(_: any, index: number) {
    this.rowFocusIndexMaterials = index;
  }


  // Tab - Items
  @action
  async tabItemsItemChanged(rowUpdate: RowUpdate, key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_4_itemchanged', {
      ...this.getRowFocusItemOnActiveTab(),
      [key]: value,
      itemname: key,
      data: value,
    }, this.getRowFocusItemOnActiveTab().new === '1');

    if (result) {
      rowUpdate(result);
    }
  }

  @action
  onChangeTableItems(rows: Array<any>, updatedRows: Array<any>) {
    this.rowsUpdatedItems = updatedRows;
    this.setState({
      dataItems: rows,
    });
  }

  @action
  onRowFocusItems(_: any, index: number) {
    this.rowFocusIndexItems = index;
  }


  // Tab - Conditions
  @action
  async tabConditionsItemChanged(rowUpdate: RowUpdate, key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_5_itemchanged', {
      ...this.getRowFocusItemOnActiveTab(),
      [key]: value,
      itemname: key,
      data: value,
    }, this.getRowFocusItemOnActiveTab().new === '1');

    if (result) {
      rowUpdate(result);
    }
  }

  @action
  onChangeTableConditions(rows: Array<any>, updatedRows: Array<any>) {
    this.rowsUpdatedConditions = updatedRows;
    this.setState({
      dataConditions: rows,
    });
  }

  @action
  onRowFocusConditions(_: any, index: number) {
    this.rowFocusIndexConditions = index;
  }

  // Pull
  @action
  async openPull() {
    const { actionStore: api } = this.props;
    this.infinityPull = new InfinityRetrieve(
      {
        sub: 'w_popup_da451',
        actcd: this.state.content?.actcd,
        saleflag: this.state.content?.saleflag,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.SS({
          dataPull: [
            ...this.state.dataPull,
            ...items.map((item) => new EnrollmentPullModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          dataPull: [],
          isVisiblePull: true,
        });
        this.infinityPull?.retrieve();
      },
    );

    await this.SS({
      dataPull: [],
      isVisiblePull: true,
    });
    this.infinityPull?.retrieve();
  }

  @action
  async onChargeEnterEvent() {
    this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }

  @action
  async donePull() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_2_itemchanged', {
      ...this.state.dataPull[this.gridPullFocusIndex],
      actcd_after: this.state.content?.actcd,
      itemname: 'actcd_after',
      data: 'actcd_after',
      compdate: this.state.content?.compdate,
    }, this.state.content.isNew);

    ConfirmSuccess.showPbMessage(data);

    this.SS({
      isVisiblePull: false,
    });

    this.onRetrieveEvent();
  }

  onPullRowFocusEvent(index: number) {
    this.gridPullFocusIndex = index;
  }


  render() {
    return <EnrollmentTemplate
      scope={this}
    />;
  }
}
