import * as React from 'react';
import { RefObject } from 'react';
import { action } from 'mobx';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  BreakCompleteDetailModel,
  BreakCompleteModel,
} from './models';
import {
  Category,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { BreakCompleteTemplate } from './BreakComplete.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import {
  DateStabilizer,
  Today,
} from '../../../../utils/time';
import {
  Confirm,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';


export enum BreakCompleteButtonClickedTypes {
  cancle,
  cancle1,
  cancle2,
  cancle3
}

export enum BreakCompleteButtonClickedTypeNames{
  b_cancle,
  b_cancle1,
  b_cancle2,
  b_cancle3
}

export enum BreakCompleteItemChangeTypes {
  COMPDATE,
  ARRIVTIME,
  COMPTIME,
  PERID,
  PERID2,
  TROUBLE,
  EQUPCD,
}

export enum BreakCompleteItemChangeTypeNames {
  compdate,
  arrivtime,
  comptime,
  perid,
  perid2,
  trouble,
  equpcd
}

interface BreakCompleteState {

  // 검색조건
  gubun: string; // 구분
  stdate: string; // 기간
  enddate: string; // 기간
  searchQuery: string; // 검색어
  perid: string; // 담당자 사번
  pernm: string; // 담당자 이름
  trouble: string; // 사람갇힘

  // 총계
  total: string;

  // 데이터 객체

  // 포커싱 데이터
  focusedBreakComplete?: BreakCompleteModel;
  focusedDetailBreakComplete: BreakCompleteDetailModel;
  lastSaveData: BreakCompleteDetailModel;

  // 하단 테이블 데이터
  data: BreakCompleteDetailModel;

  // 테이블 데이터 리스트
  breakCompleteList: Array<BreakCompleteModel>;

  // 구분종류
  wkcds: Array<any>;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isReported: boolean; // 결재상신 여부

  // 문자 팝업
  isVisibleSMS: boolean;
  recetelnum: string;
  acttelnum: string;
  smsTo: { tel: string }[];
  text: string;
  url: string;
  tel: string;

  kakaoyn: string;
}

/*
* 컨트롤러
* @window w_tb_e411_01
* @category 고장처리결과등록
*/

export class BreakComplete extends PageComponent<PageProps, BreakCompleteState>
  implements PageToolEvents {
  updatedRows?: Array<BreakCompleteModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  detailGrid: React.RefObject<GridLayout> = React.createRef();

  popupGrid: React.RefObject<GridLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  smsTableTo: RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);

    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: Today.date(),
      gubun: '0',
      searchQuery: '',
      perid: '%',
      trouble: '0',
      total: '',
      data: new BreakCompleteDetailModel(),
      breakResisterList: [],
      breakFieldList: [],

      // 문자 팝업
      isVisibleSMS: false,
      recetelnum: '',
      acttelnum: '',
      smsTo: [],
      text: '',
      url: '',
      tel: '',


      wkcds: [],
      isReported: false,

      kakaoyn: '0',
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const openData = await api.fxExec('open');
    await this.setState({
      kakaoyn: openData?.alim_kakao_useyn,
    });

    const data = await api.dropdown('wf_dd_e021');
    data && this.setState({ wkcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        gubun: this.state.gubun,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: '%',
        trouble: this.state.trouble,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          breakCompleteList: [...this.state.breakCompleteList, ...items],
          focusedBreakComplete: new BreakCompleteModel(items),
        }, next);
      },
      async () => {
        await this.SS({
          // focusedDetailBreakComplete: new BreakCompleteDetailModel(), // 조회 후 신규 시 행 예외에 걸려서 추가
          // focusedBreakComplete: new BreakCompleteModel(), // 조회 후 신규 시 행 예외에 걸려서 추가
          breakCompleteList: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.data) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.focusedDetailBreakComplete;
    this.setState({
      focusedDetailBreakComplete: new BreakCompleteDetailModel({}, false), // 조회 후 신규 시 행 예외에 걸려서 추가
      data: new BreakCompleteDetailModel([], false), // 조회 후 신규 시 행 예외에 걸려서 추가
      focusedBreakComplete: new BreakCompleteModel(), // 조회 후 신규 시 행 예외에 걸려서 추가
      breakCompleteList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(
        ['recedate', 'recenum'], [lastSelected?.recedate, lastSelected?.recenum], type,
        true,
      ) || 0;
      const data = this.infinity?.box;
      this.setState({
        total: data?.total || '0',
      });
      if (this.state.breakCompleteList && this.state.breakCompleteList?.length > index) {
        this.grid.current?.setFocus(index);
      }
    });
  }

  @action
  onRowFocusEvent(item: BreakCompleteDetailModel) {
    if (item?.new === '1') {
      this.setState({
        isReported: false,
        focusedDetailBreakComplete: this.state.lastSaveData,
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      { focusedDetailBreakComplete: item },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            gubun: this.state.gubun,
            recedate: this.state.focusedDetailBreakComplete?.recedate,
            recenum: this.state.focusedDetailBreakComplete?.recenum,
            cltcd: this.state.focusedDetailBreakComplete?.cltcd,
            actcd: this.state.focusedDetailBreakComplete?.actcd,
            actnm: this.state.focusedDetailBreakComplete?.actnm,
            equpcd: this.state.focusedDetailBreakComplete?.equpcd,
            equpnm: this.state.focusedDetailBreakComplete?.equpnm,
            dong: '',
            ho: '',
            recetime: this.state.focusedDetailBreakComplete?.recetime,
            trouble: this.state.focusedDetailBreakComplete?.trouble,
            troubledate: this.state.focusedDetailBreakComplete?.troubledate,
            troubletime: this.state.focusedDetailBreakComplete?.troubletime,
            troublesu: this.state.focusedDetailBreakComplete?.troublesu,
            divicd: this.state.focusedDetailBreakComplete?.divicd,
            divinm: this.state.focusedDetailBreakComplete?.divinm,
            actperid: this.state.focusedDetailBreakComplete?.actperid,
            actpernm: this.state.focusedDetailBreakComplete?.actpernm,
            contnm: this.state.focusedDetailBreakComplete?.contnm,
            compdate: this.state.focusedDetailBreakComplete?.compdate,
            compnum: this.state.focusedDetailBreakComplete?.compnum,
            contents: this.state.focusedDetailBreakComplete?.contents,
            wadcnt: this.state.focusedDetailBreakComplete?.wadcnt,
            lastcnt: this.state.focusedDetailBreakComplete?.lastcnt,
            elno: this.state.focusedDetailBreakComplete?.elno,
          });

        await data && this.setState({
          focusedDetailBreakComplete: new BreakCompleteDetailModel(data),
        });

        if (data?.items) {
          this.detailGrid.current?.setFocus(0);
        }
      },
    );
  }

  @action
  fileModal(isOpen: boolean) {
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedDetailBreakComplete!); }
      });
  }

  @action
  async onButtonClicked(type: any) {
    const { actionStore: api } = this.props;

    const params = {
      itemname: BreakCompleteButtonClickedTypeNames[type],
      data: '',
      recedate: this.state.data.recedate,
      new: this.state.data.new,
    };

    if (type === BreakCompleteButtonClickedTypes.cancle) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          perid: this.state.data.perid,
          actnm: this.state.data.actnm,
          equpnm: this.state.data.equpnm,
        },
      );
    }

    if (type === BreakCompleteButtonClickedTypes.cancle1) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          actnm: this.state.focusedDetailBreakComplete?.actnm,
          equpnm: this.state.focusedDetailBreakComplete?.equpnm,
        },
      );
    }

    if (type === BreakCompleteButtonClickedTypes.cancle2) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          actnm: this.state.focusedDetailBreakComplete?.actnm,
          equpnm: this.state.focusedDetailBreakComplete?.equpnm,
        },
      );
    }

    if (type === BreakCompleteButtonClickedTypes.cancle3) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          actnm: this.state.focusedDetailBreakComplete?.actnm,
          equpnm: this.state.focusedDetailBreakComplete?.equpnm,
        },
      );
    }
  }

  @action
  async allgitaButton() {
    const { actionStore: api } = this.props;

    // 모두 기타 클릭 시 고장내용 기타로 자동입력
    await Confirm.ask('확인', '처리결과, 고장원인상세등 입력한 내용이 있다면 \n전부 기타로 엎어지게 됩니다. \n"전부(일괄) 기타로 선택하시겠습니까?"', '예', '아니오');

    const data = await api.fxExec('dw_2_buttonclicked', {
      itemname: 'b_allgita',
      actcd: this.state.focusedDetailBreakComplete?.actcd,
      equpcd: this.state.focusedDetailBreakComplete?.equpcd,
      recedate: this.state.focusedDetailBreakComplete?.recedate,
      recenum: this.state.focusedDetailBreakComplete?.recenum,
    });

    data && this.setState({
      focusedDetailBreakComplete: new BreakCompleteDetailModel({
        ...this.state.focusedDetailBreakComplete,
        ...data,
      }, this.state.focusedDetailBreakComplete?.isNew),
    });

    await this.detailGrid.current?.forceRepaint();
  }

  @action
  async itemChanged(type: number) {
    const { actionStore: api } = this.props;

    let data:any = {
      new: undefined,
    };

    const params = {
      itemname: BreakCompleteItemChangeTypeNames[type],
      compdate: this.state.focusedDetailBreakComplete?.compdate,
      compnum: this.state.focusedDetailBreakComplete?.compnum,
      new: this.state.focusedDetailBreakComplete?.new,
    };

    if (type === BreakCompleteItemChangeTypeNames.compdate) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakComplete?.compdate,
          recedate: this.state.focusedDetailBreakComplete?.recedate,
        },
      );

      data && this.setState({
        focusedDetailBreakComplete: new BreakCompleteDetailModel({
          ...this.state.focusedDetailBreakComplete, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakCompleteItemChangeTypeNames.arrivtime) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakComplete?.arrivtime,
          recedate: this.state.focusedDetailBreakComplete?.recedate,
          recetime: this.state.focusedDetailBreakComplete?.recetime,
          comptime: this.state.focusedDetailBreakComplete?.comptime,
          arrivdate: this.state.focusedDetailBreakComplete?.arrivdate,
        },
      );

      data && this.setState({
        focusedDetailBreakComplete: new BreakCompleteDetailModel({
          ...this.state.focusedDetailBreakComplete, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakCompleteItemChangeTypeNames.comptime) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakComplete?.comptime,
          recedate: this.state.focusedDetailBreakComplete?.recedate,
          recetime: this.state.focusedDetailBreakComplete?.recetime,
          arrivtime: this.state.focusedDetailBreakComplete?.arrivtime,
          arrivdate: this.state.focusedDetailBreakComplete?.arrivdate,
        },
      );

      data && this.setState({
        focusedDetailBreakComplete: new BreakCompleteDetailModel({
          ...this.state.focusedDetailBreakComplete, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakCompleteItemChangeTypeNames.perid) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakComplete?.perid,
        },
      );

      data && this.setState({
        focusedDetailBreakComplete: new BreakCompleteDetailModel({
          ...this.state.focusedDetailBreakComplete, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakCompleteItemChangeTypeNames.perid2) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakComplete?.perid2,
        },
      );

      data && this.setState({
        focusedDetailBreakComplete: new BreakCompleteDetailModel({
          ...this.state.focusedDetailBreakComplete, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakCompleteItemChangeTypeNames.trouble) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakComplete?.trouble,
        },
      );

      data && this.setState({
        focusedDetailBreakComplete: new BreakCompleteDetailModel({
          ...this.state.focusedDetailBreakComplete, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakCompleteItemChangeTypeNames.equpcd) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          actcd: this.state.focusedDetailBreakComplete?.actcd,
          recedate: this.state.focusedDetailBreakComplete?.recedate,
        },
      );

      data && this.setState({
        focusedDetailBreakComplete: new BreakCompleteDetailModel({
          ...this.state.focusedDetailBreakComplete, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (this.state.focusedDetailBreakComplete?.compdate === '00000000') {
      ConfirmWarning.show('경고', '완료일자를 선택해주세요');
      return;
    }

    // eslint-disable-next-line max-len
    const receLastDate = new Date(Number(this.state.focusedDetailBreakComplete?.recedate.substr(0, 4)), Number(this.state.focusedDetailBreakComplete?.recedate.substr(4, 2)), 0).getDate();
    // eslint-disable-next-line max-len
    const bereceLastDate = new Date(Number(this.state.focusedDetailBreakComplete?.berecedate.substr(0, 4)), Number(this.state.focusedDetailBreakComplete?.berecedate.substr(4, 2)), 0).getDate();
    // eslint-disable-next-line max-len
    const compLastDate = new Date(Number(this.state.focusedDetailBreakComplete?.compdate.substr(0, 4)), Number(this.state.focusedDetailBreakComplete?.compdate.substr(4, 2)), 0).getDate();

    if (receLastDate < Number(this.state.focusedDetailBreakComplete?.recedate.substr(6, 2))) {
      ConfirmWarning.show('경고', '접수일자가 올바르지 않습니다.');
      return;
    }

    if (bereceLastDate < Number(this.state.focusedDetailBreakComplete?.berecedate.substr(6, 2))) {
      ConfirmWarning.show('경고', '이전처리일자가 올바르지 않습니다.');
      return;
    }

    if (compLastDate < Number(this.state.focusedDetailBreakComplete?.compdate.substr(6, 2))) {
      ConfirmWarning.show('경고', '완료일자가 올바르지 않습니다.');
      return;
    }

    // 미래날짜 못하게
    if (this.state.focusedDetailBreakComplete?.arrivdate > Today.date()) {
      const cancel = await Confirm.ask('확인', '도착일자가 금일 이후입니다 맞습니까?', '예', '아니오');
      if (cancel) {
        return;
      }
    }

    // 미래 날짜 못하게
    if (this.state.focusedDetailBreakComplete?.compdate > Today.date()) {
      const cancel = await Confirm.ask('확인', '완료일자가 금일 이후입니다 맞습니까?', '예', '아니오');

      if (cancel) {
        return;
      }
    }

    if (await api.save({
      new: this.state.gubun === '0' ? '1' : this.state.focusedDetailBreakComplete?.new,
      custcd: this.state.focusedDetailBreakComplete?.custcd,
      spjangcd: this.state.focusedDetailBreakComplete?.spjangcd,
      compdate: this.state.focusedDetailBreakComplete?.compdate,
      compnum: this.state.focusedDetailBreakComplete?.compnum,
      recedate: this.state.focusedDetailBreakComplete?.recedate,
      recenum: this.state.focusedDetailBreakComplete?.recenum,
      seq: this.state.focusedDetailBreakComplete?.seq,
      berecedate: this.state.focusedDetailBreakComplete?.berecedate,
      berecetime: this.state.focusedDetailBreakComplete?.berecetime,
      result: this.state.focusedDetailBreakComplete?.result,
      cltcd: this.state.focusedDetailBreakComplete?.cltcd,
      mpclafi: this.state.focusedDetailBreakComplete?.mpclafi,
      perid: this.state.focusedDetailBreakComplete?.perid,
      perid2: this.state.focusedDetailBreakComplete?.perid2,
      actperid: this.state.focusedDetailBreakComplete?.actperid,
      divicd: this.state.focusedDetailBreakComplete?.divicd,
      divicd2: this.state.focusedDetailBreakComplete?.divicd2,
      actcd: this.state.focusedDetailBreakComplete?.actcd,
      actnm: this.state.focusedDetailBreakComplete?.actnm,
      equpcd: this.state.focusedDetailBreakComplete?.equpcd,
      equpnm: this.state.focusedDetailBreakComplete?.equpnm,
      contcd: this.state.focusedDetailBreakComplete?.contcd,
      receequpcd: this.state.focusedDetailBreakComplete?.receequpcd,
      contremark: this.state.focusedDetailBreakComplete?.contremark,
      gregicd: this.state.focusedDetailBreakComplete?.gregicd,
      regicd: this.state.focusedDetailBreakComplete?.regicd,
      remocd: this.state.focusedDetailBreakComplete?.remocd,
      remoremark: this.state.focusedDetailBreakComplete?.remoremark,
      resucd: this.state.focusedDetailBreakComplete?.resucd,
      resuremark: this.state.focusedDetailBreakComplete?.resuremark,
      resultcd: this.state.focusedDetailBreakComplete?.resultcd,
      faccd: this.state.focusedDetailBreakComplete?.faccd,
      resutime: this.state.focusedDetailBreakComplete?.resutime,
      resulttime: this.state.focusedDetailBreakComplete?.resulttime,
      recetime: this.state.focusedDetailBreakComplete?.recetime,
      arrivdate: this.state.focusedDetailBreakComplete?.arrivdate,
      arrivtime: this.state.focusedDetailBreakComplete?.arrivtime,
      comptime: this.state.focusedDetailBreakComplete?.comptime,
      resuname: '',
      remark: this.state.focusedDetailBreakComplete?.remark,
      store: '',
      storenm: '',
      gubun: this.state.focusedDetailBreakComplete?.gubun,
      evigubun: '',
      addgubun: '',
      inputdate: '',
      inperid: this.state.focusedDetailBreakComplete?.inperid,
      indate: this.state.focusedDetailBreakComplete?.indate,
      customer: this.state.focusedDetailBreakComplete?.customer,
      trouble: this.state.focusedDetailBreakComplete?.trouble,
      troubledate: this.state.focusedDetailBreakComplete?.troubledate,
      troubletime: this.state.focusedDetailBreakComplete?.troubletime,
      resque: this.state.focusedDetailBreakComplete?.resque,
      resquedate: this.state.focusedDetailBreakComplete?.resquedate,
      rescuetime: this.state.focusedDetailBreakComplete?.rescuetime,
      rescuenm: this.state.focusedDetailBreakComplete?.rescuenm,
      errcd: this.state.focusedDetailBreakComplete?.errcd,
      errnm: this.state.focusedDetailBreakComplete?.errnm,
      errseq: this.state.focusedDetailBreakComplete?.errseq,
      actppernm: this.state.focusedDetailBreakComplete?.actppernm,
      actpernm: this.state.focusedDetailBreakComplete?.actpernm,
      email: this.state.focusedDetailBreakComplete?.email,
      troublesms_hd: '',
      samt: this.state.focusedDetailBreakComplete?.samt,
      addamt: '',
      sucetime: '',
      sucehour: '',
      troublesu: this.state.focusedDetailBreakComplete?.troublesu,
      wkactcd: '',
      pernm: this.state.focusedDetailBreakComplete?.pernm,
      contnm: this.state.focusedDetailBreakComplete?.contnm,
      contents: this.state.focusedDetailBreakComplete?.contents,
      greginm: this.state.focusedDetailBreakComplete?.greginm,
      remonm: this.state.focusedDetailBreakComplete?.remonm,
      facnm: this.state.focusedDetailBreakComplete?.facnm,
      resunm: this.state.focusedDetailBreakComplete?.resunm,

    }, this.state.gubun === '0' || this.state.data.new === '1')) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.focusedDetailBreakComplete?.recedate);
      this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
        focusedDetailBreakComplete: new BreakCompleteDetailModel(),
      });
      await this.onRetrieveEvent();
    }
  }


  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    if (this.state.gubun === '0') {
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const newModel = new BreakCompleteDetailModel(data, true);

      this.setState({
        data: newModel,
        lastSaveData: newModel,
        breakCompleteList: [
          newModel,
          ...this.state.breakCompleteList,
        ],
        focusedDetailBreakComplete: newModel,
      }, async () => {
        await this.grid.current?.setFocus(0);
        await this.detailGrid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `접수일자: ${this.state.focusedDetailBreakComplete?.recedate}
    접수번호: ${this.state.focusedDetailBreakComplete?.recenum} 를 삭제하시겠습니까?`;
    await api.delete(text, {
      recedate: this.state.focusedDetailBreakComplete?.recedate,
      recenum: this.state.focusedDetailBreakComplete?.recenum,
      compdate: this.state.focusedDetailBreakComplete?.compdate,
      compnum: this.state.focusedDetailBreakComplete?.compnum,
      gubun: this.state.gubun,
      seq: this.state.focusedDetailBreakComplete?.seq,
      actcd: this.state.focusedDetailBreakComplete?.actcd,
      equpcd: this.state.focusedDetailBreakComplete?.equpcd,
    }) && await this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }

  @action
  async onFaxEvent() {
    const { actionStore: api } = this.props;
    const { publicStore } = this.props;

    if (!ConfirmWarning.assert(this.state.breakCompleteList !== undefined, '오류', '전송할 내역이 없습니다')) {
      return;
    }
    if (!ConfirmWarning.assert(this.state.breakCompleteList.length > 0, '오류', '전송할 내역이 없습니다')) {
      return;
    }
    if (!ConfirmWarning.assert(this.state.focusedDetailBreakComplete.resultcd === '001', '오류', '처리결과가 완료가 아닙니다.')) {
      return;
    }
    await api.fxFax(
      `${this.state.focusedDetailBreakComplete?.faxnum}`,
      `고장일지 - ${publicStore.user.spjangnm}`,
      {
        compdate: this.state.focusedDetailBreakComplete?.compdate,
        compnum: this.state.focusedDetailBreakComplete?.compnum,
        wkactcd: this.state.focusedDetailBreakComplete?.actcd,
        attcnt: this.state.focusedDetailBreakComplete?.attcnt || '0',
      },
    );
  }

  @action
  onEmailEvent() {
    const { actionStore: api } = this.props;
    const { publicStore } = this.props;
    if (!ConfirmWarning.assert(this.state.breakCompleteList !== undefined, '오류', '전송할 내역이 없습니다')) {
      return;
    }
    if (!ConfirmWarning.assert(this.state.breakCompleteList.length > 0, '오류', '전송할 내역이 없습니다')) {
      return;
    }
    if (!ConfirmWarning.assert(this.state.focusedDetailBreakComplete.resultcd === '001', '오류', '처리결과가 완료가 아닙니다.')) {
      return;
    }

    if (this.state.focusedDetailBreakComplete?.email === '') {
      ConfirmWarning.show('경고', '현장메일을 입력해주세요');
      return;
    }

    let date = `${this.state.focusedDetailBreakComplete?.arrivdate.substr(0, 4)}년`;
    date = `${date}${this.state.focusedDetailBreakComplete?.arrivdate.substr(4, 2)}월`;
    date = `${date}${this.state.focusedDetailBreakComplete?.arrivdate.substr(6, 2)}일`;
    api.fxEmail(
      this.state.focusedDetailBreakComplete?.email,
      this.state.focusedDetailBreakComplete?.actnm,
      '',
      this.state.focusedDetailBreakComplete?.tel,
      `고장일지 - ${publicStore.user.spjangnm}`,
      // eslint-disable-next-line max-len
      `${this.state.focusedDetailBreakComplete?.actnm}의 무궁한 발전을 기원합니다.\n${date} 고장처리가 완료되어 수리내역을 보냅니다.`,
      {
        compdate: this.state.focusedDetailBreakComplete?.compdate,
        compnum: this.state.focusedDetailBreakComplete?.compnum,
        wkactcd: this.state.focusedDetailBreakComplete?.actcd,
        attcnt: this.state.focusedDetailBreakComplete?.attcnt,
      },
    );
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.gubun !== '0', '오류', '구분이 미처리인 경우 인쇄할 수 없습니다..')) {
      return;
    }
    await api.printWithElmanManager({
      compdate: this.state.focusedDetailBreakComplete?.compdate,
      compnum: this.state.focusedDetailBreakComplete?.compnum,
      wkactcd: this.state.focusedDetailBreakComplete?.actcd,
      attcnt: this.state.focusedDetailBreakComplete?.attcnt,
    });
  }

  @action
  async onKakaoButtonClicked() {
    const { actionStore: api } = this.props;

    await api.fxExec('wb_kakaosend', {
      gubun: this.state.gubun,
      actcd: this.state.focusedDetailBreakComplete?.actcd,
      actnm: this.state.focusedDetailBreakComplete?.actnm,
      equpnm: this.state.focusedDetailBreakComplete?.equpnm,
      compdate: this.state.focusedDetailBreakComplete?.compdate,
      compnum: this.state.focusedDetailBreakComplete?.compnum,
    });
  }

  @action
  async onSMSEvent() {
    if (this.state.gubun === '0') {
      ConfirmWarning.show('오류', '먼저 처리등록을 하세요!');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.exec(Category.MAINTENANCE, 'wb_sms', {
      spjangcd: this.state.focusedDetailBreakComplete?.spjangcd,
      perid: this.state.focusedDetailBreakComplete?.perid,
      pernm: this.state.focusedDetailBreakComplete?.pernm,
      actcd: this.state.focusedDetailBreakComplete?.actcd,
      actnm: this.state.focusedDetailBreakComplete?.actnm,
      compdate: this.state.focusedDetailBreakComplete?.compdate,
      comptime: this.state.focusedDetailBreakComplete?.comptime,
      contnm: this.state.focusedDetailBreakComplete?.contnm,
      greginm: this.state.focusedDetailBreakComplete?.greginm,
      remonm: this.state.focusedDetailBreakComplete?.remonm,
      facnm: this.state.focusedDetailBreakComplete?.facnm,
      resunm: this.state.focusedDetailBreakComplete?.resunm,
      recedate: this.state.focusedDetailBreakComplete?.recedate,
      recenum: this.state.focusedDetailBreakComplete?.recenum,
    });

    const textVersion = data.text.replace(/\\n/gi, '\n');
    const urlVersion = textVersion.replace(/\n/gi, '|');

    this.setState({
      url: `https://api.elmansoft.com/etc/fixCard.php?&tel2=${data.tel}&pernm=${data.pernm}
      &perid=${data.perid}&custcd=${data.custcd}&spjangcd=${data.spjangcd}&txt=${urlVersion}`,
      isVisibleSMS: true,
      text: textVersion,
      tel: data.tel,
    });
  }

  @action
  async sendSMS(): Promise<boolean> {
    if (!this.state.text) {
      ConfirmWarning.show('오류', '본문을 입력해주세요!');
      return false;
    }

    if (this.state.smsTo.length === 0) {
      ConfirmWarning.show('오류', '받는 사람을 최소 한명 추가해주세요!');
      return false;
    }

    if (this.state.text) {
      const {
        actionStore: api,
        publicStore,
      } = this.props;
      const { user } = publicStore;
      await api.smsSend({
        items: this.state.smsTo.map((x) => x.tel.replace(/-/ig, '')),
        caller: this.state.tel || user.tel2,
        callcc: user.tel,
        pernm: user.pernm,
        title: '',
        message: this.state.text,
      });
    }

    ConfirmSuccess.show('전송 요청됨', '문자 전송 요청이 완료되었습니다.\n순차적으로 전송될 예정입니다.');

    return true;
  }

  @action
  closeSMS(): void {
    this.setState({
      isVisibleSMS: false,
      smsTo: [],
    });
    this.smsTableTo.current?.update(true);
  }

  @action
  async addReceive() {
    this.setState({
      smsTo: [...this.state.smsTo, { tel: '' }],
    });
    await this.smsTableTo.current?.update(false);
    this.smsTableTo.current?.setFocus(this.state.smsTo.length - 1, 1);
  }

  @action
  async breakTelnum() {
    if (!this.state.recetelnum) {
      ConfirmWarning.show('오류', '먼저 고장신고자전화번호를 입력해주세요!');
      return;
    }
    this.setState({
      smsTo: [...this.state.smsTo, { tel: this.state.recetelnum }],
    });
    await this.smsTableTo.current?.update(false);
    this.smsTableTo.current?.setFocus(this.state.smsTo.length - 1, 1);
  }

  @action
  async actTelnum() {
    if (!this.state.acttelnum) {
      ConfirmWarning.show('오류', '먼저 현장관리자핸드폰번호를 입력해주세요!');
      return;
    }
    this.setState({
      smsTo: [...this.state.smsTo, { tel: this.state.acttelnum }],
    });
    await this.smsTableTo.current?.update(false);
    this.smsTableTo.current?.setFocus(this.state.smsTo.length - 1, 1);
  }

  @action
  async wadnotCheck(value: boolean) {
    const { actionStore: api } = this.props;

    let result;

    // 고장아님 체크 시 고장내용 기타로 자동입력
    if (value) {
      result = await Confirm.ask('확인', '처리결과, 고장원인상세등 입력한 내용이 있다면 \n전부 기타로 엎어지게 됩니다. \n"고장아님을 선택하시겠습니까?"', '예', '아니오');
    }

    const data = await api.fxExec('dw_2_itemchanged', {
      itemname: 'wadnot',
      data: value ? '1' : '0',
      actcd: this.state.focusedDetailBreakComplete?.actcd,
      equpcd: this.state.focusedDetailBreakComplete?.equpcd,
      recedate: this.state.focusedDetailBreakComplete?.recedate,
      recenum: this.state.focusedDetailBreakComplete?.recenum,
      net: result ? '0' : '1',
    });

    data && this.setState({
      focusedDetailBreakComplete: new BreakCompleteDetailModel({
        ...this.state.focusedDetailBreakComplete,
        ...data,
      }, this.state.focusedDetailBreakComplete?.isNew),
    });

    await this.detailGrid.current?.forceRepaint();
  }


  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */

  @action
  onUpdatedRows(rows: Array<BreakCompleteModel>, updatedRows: Array<BreakCompleteModel>) {
    this.updatedRows = updatedRows;
    this.setState({ breakCompleteList: rows });
  }

  @action
  readOnly() {
    let readonly = false;
    // 처리, 처리중일땐 readonly true
    // 처리이면서 신규이면 readonly true 해제
    if (this.state.gubun === '2') {
      readonly = true;
    }
    if (this.state.gubun === '1') {
      if (this.state.focusedDetailBreakComplete?.isNew) {
        readonly = false;
      } else {
        readonly = true;
      }
    }
    return readonly;
  }

  render() {
    return (
      <BreakCompleteTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            focusedDetailBreakComplete: new BreakCompleteDetailModel({
              ...this.state.focusedDetailBreakComplete,
              ...change,
            }, this.state.focusedDetailBreakComplete?.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
