import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e471
 * @category 공사등록
 */
export default class EnrollmentDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 코드
   *
   * **data type** varchar(8)
   */
  readonly costcd: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly addamt: string;

  /**
   * 공사금액
   *
   * **data type** varchar(8)
   */
  readonly compamt: string;

  /**
   * 공사일
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 공사번호
   *
   * **data type** varchar(8)
   */
  readonly compnum: string;

  /**
   * 공사명
   *
   * **data type** varchar(8)
   */
  readonly costnm: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 수량
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 단가
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 순서
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 사업장번호
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly uamt: string;

  /**
   * 단위
   *
   * **data type** varchar(8)
   */
  readonly unit: string;


  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.addamt = data.addamt || '';
    this.compamt = data.compamt || '';
    this.compdate = data.compdate || '';
    this.compnum = data.compnum || '';
    this.costcd = data.costcd || '';
    this.costnm = data.costnm || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.qty = data.qty || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.seq = data.seq || '';
    this.spjangcd = data.spjangcd || '';
    this.uamt = data.uamt || '';
    this.unit = data.unit || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
