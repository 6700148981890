import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_pb300_04
 * @category 급여명세서
 */
export class PayslipModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 직책코드
   *
   * **data type** varchar(2)
   */
  rspcd: string;

  /**
   * 직책
   *
   * **data type** varchar(20)
   */
  rspnm: string;

  /**
   * 급여월
   *
   * **data type** varchar(6)
   */
  mon: string;

  /**
   * 급여총액
   *
   * **data type** number
   */
  tot_samt: string;

  /**
   * 공제금액
   *
   * **data type** number
   */
  tot_tamt: string;

  /**
   * 실수령금액
   *
   * **data type** number
   */
  tot_mamt: string;

  /**
   * 사업장
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 순번
   *
   * **data type** varchar(4)
   */
  seq: string;

  /**
   * 부서
   *
   * **data type** varchar(10)
   */
  divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 입사일자
   *
   * **data type** varchar(8)
   */
  entdate: string;

  /**
   * 퇴사일자
   *
   * **data type** varchar(8)
   */
  rtdate: string;

  /**
   * 고용보험
   *
   * **data type** number
   */
  insu_unem: string;

  /**
   * 건강보험
   *
   * **data type** number
   */
  insu_health: string;

  /**
   * 장기요양보험
   *
   * **data type** number
   */
  insu_longterm: string;

  /**
   * 국민연금
   *
   * **data type** number
   */
  insu_national: string;

  /**
   * 식비수당
   *
   * **data type** number
   */
  pay_food: string;

  /**
   * 직무수당
   *
   * **data type** number
   */
  pay_SerAllow: string;

  /**
   * 야근수당
   *
   * **data type** number
   */
  pay_older: string;

  /**
   * 고정야간근로수당
   *
   * **data type** number
   */
  pay_night: string;

  /**
   * 고정휴일근로수당
   *
   * **data type** number
   */
  pay_holiday: string;

  /**
   * 연차수당
   *
   * **data type** number
   */
  pay_yearly: string;

  /**
   * 인센티브1
   *
   * **data type** number
   */
  pay_leader: string;

  /**
   * 인센티브2
   *
   * **data type** number
   */
  pay_incentive: string;

  /**
   * 기타(통신)수당
   *
   * **data type** number
   */
  pay_other: string;

  /**
   * pay_other_random
   *
   * **data type** number
   */
  pay_other_random: string;

  /**
   * 추가연장근로수당
   *
   * **data type** number
   */
  over_older_pay: string;

  /**
   * 추가휴일근로수당
   *
   * **data type** number
   */
  over_holi_pay: string;

  /**
   * 연차수당
   *
   * **data type** number
   */
  rest_pay: string;

  /**
   * 소득세
   *
   * **data type** number
   */
  tax_income: string;

  /**
   * 건강보험료연말정산
   *
   * **data type** number
   */
  tax_health: string;

  /**
   * 연말정산건강보험료
   *
   * **data type** number
   */
  tax_national: string;

  /**
   * 가불금
   *
   * **data type** number
   */
  tax_suspense: string;

  /**
   * 기타공제
   *
   * **data type** number
   */
  tax_other: string;

  /**
   * 비고
   *
   * **data type** varchar(32764)
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 상조회비
   *
   * **data type** number
   */
  insu_maf: string;

  /**
   * 주민세
   *
   * **data type** number
   */
  payamt_tax2: string;

  /**
   * 연수령금액안내
   *
   * **data type** number
   */
  year_tot_samt: string;

  /**
   * 공제금액
   *
   * **data type** number
   */
  year_tot_tamt: string;

  /**
   * 실수령
   *
   * **data type** number
   */
  year_tot_mamt: string;

  /**
   * 연차일수
   *
   * **data type** decimal(1)
   */
  holinum: string;

  /**
   * 특근이수
   *
   * **data type** decimal(1)
   */
  worknum: string;

  /**
   * -
   *
   * **data type** decimal(1)
   */
  wkactcd: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.wkactcd = data.wkactcd || '';
    this.pernm = data.pernm || '';
    this.rspcd = data.rspcd || '';
    this.rspnm = data.rspnm || '';
    this.mon = data.mon || '';
    this.tot_samt = data.tot_samt || '';
    this.tot_tamt = data.tot_tamt || '';
    this.tot_mamt = data.tot_mamt || '';
    this.spjangnm = data.spjangnm || '';
    this.seq = data.seq || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.entdate = data.entdate || '';
    this.rtdate = data.rtdate || '';
    this.insu_unem = data.insu_unem || '';
    this.insu_health = data.insu_health || '';
    this.insu_longterm = data.insu_longterm || '';
    this.insu_national = data.insu_national || '';
    this.pay_food = data.pay_food || '';
    this.pay_SerAllow = data.pay_SerAllow || '';
    this.pay_older = data.pay_older || '';
    this.pay_night = data.pay_night || '';
    this.pay_holiday = data.pay_holiday || '';
    this.pay_yearly = data.pay_yearly || '';
    this.pay_leader = data.pay_leader || '';
    this.pay_incentive = data.pay_incentive || '';
    this.pay_other = data.pay_other || '';
    this.pay_other_random = data.pay_other_random || '';
    this.over_older_pay = data.over_older_pay || '';
    this.over_holi_pay = data.over_holi_pay || '';
    this.rest_pay = data.rest_pay || '';
    this.tax_income = data.tax_income || '';
    this.tax_health = data.tax_health || '';
    this.tax_national = data.tax_national || '';
    this.tax_suspense = data.tax_suspense || '';
    this.tax_other = data.tax_other || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.insu_maf = data.insu_maf || '';
    this.payamt_tax2 = data.payamt_tax2 || '';
    this.year_tot_samt = data.year_tot_samt || '';
    this.year_tot_tamt = data.year_tot_tamt || '';
    this.year_tot_mamt = data.year_tot_mamt || '';
    this.holinum = data.holinum || '';
    this.worknum = data.worknum || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
