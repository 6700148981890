import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { Button } from '../../components/forms/Button';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { SubHeader } from '../../components/layout/SubHeader';
import { TextBox } from '../../components/forms/TextBox';
import { FormatTextBox } from '../../components/forms/FormatTextBox';
import { CheckBox } from '../../components/forms/CheckBox';
import { TableLayout } from '../../components/layout/TableLayout';
import { LabelBox } from '../../components/forms/LabelBox';

interface ModalHistoryProps {
  modalStore: ModalStore;
}

@observer
export class ModalHistory extends React.Component<ModalHistoryProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={1000}
        zIndex={-2}
        isVisible={modalStore.isVisibleHistorySelector}
        onClose={() => modalStore.closeHistorySelector()}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 10 }}
        >
          <LayoutTitle>계약내용기록</LayoutTitle>

          <TableLayout
            ref={modalStore.historyTableUsers}
            header={[
              {
                id: 'seq',
                text: '순서',
                width: 8,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.seq}
                  </FlexLayout>
                ),
              },
              {
                id: 'perid',
                text: '작성자',
                width: 8,
                color: 'var(--color-red)',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    onDoubleClick={() => modalStore.openWriterSelector()}
                  >
                    {x.perid}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '작성자명',
                width: 8,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '기록일자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <FormatTextBox
                      textAlign="center"
                      format="####-##-##"
                      value={x.hdate}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'wakedate',
                text: '알림만료일',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <FormatTextBox
                      textAlign="center"
                      format="####-##-##"
                      value={x.wakedate}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '제목',
                width: 40,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    <TextBox
                      value={x.title}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ title: value })}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'okflag',
                text: '답변확인',
                width: 6,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.okflag === '1'}
                      onChange={(value) => {
                        rowUpdate({ okflag: value ? '1' : '0' });
                      }}
                    />
                  </FlexLayout>
                ),
              },
            ]}
            data={modalStore?.historyData || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowFocusEvent={(item) => modalStore.onHistoryRowFocusEvent(item)}
            onChange={(rows, updatedRows) => {
              modalStore.onHistoryUpdatedRows(rows, updatedRows);
            }}
          />

          <FlexLayout isVertical={true}>
            <LabelBox title="계약내용">
              <TextBox
                value={modalStore.remark}
                onChange={(value) => { modalStore.remark = value; }}
                isMultiline={true}
              />
            </LabelBox>

            <SubHeader>
              <FlexLayout justify="end">
                <Button onClick={() => modalStore.onNewHistoryEvent()}>신규</Button>
                <Button onClick={() => modalStore.onHistorySaveEvent()}>저장</Button>
                <Button onClick={() => modalStore.historyDelete()}>삭제</Button>
                <Button onClick={() => modalStore.closeHistorySelector()}>닫기</Button>
              </FlexLayout>
            </SubHeader>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
