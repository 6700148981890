import * as React from 'react';
import { action, computed } from 'mobx';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';
import {
  BreakResisterModel,
  BreakResisterDetailModel,
  BreakFieldModel,
  PopupModel,
} from './models';
import {
  AskType,
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { BreakResisterTemplate } from './BreakResister.template';
import {
  Date8,
  DateStabilizer,
  Time4,
  Today,
} from '../../../../utils/time';
import { Confirm, ConfirmSuccess, ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';
import { Fix } from '../../../../utils/string';

export enum BreakResisterButtonClickedTypes {
  cancle,
  cancle1,
  cancle2,
  cancle3
}

export enum BreakResisterButtonClickedTypeNames{
  b_cancle,
  b_cancle1,
  b_cancle2,
  b_cancle3
}

export enum BreakResisterItemChangeTypes {
  TROUBLE,
  RECEDATE,
  RECETIME,
  OKFLAG,
  OKFLAG_COMP,
  OKFLAG_OKPUSH,
  ACTCD,
  EQUPCD,
  CONTCD,
  PERID,
}

export enum BreakResisterItemChangeTypeNames {
  trouble,
  recedate,
  recetime,
  okflag,
  okflag_comp,
  okflag_okpush,
  actcd,
  equpcd,
  contcd,
  perid,
}

interface BreakResisterState {

  // 검색조건
  stdate: string; // 기간
  enddate: string; // 기간
  searchQuery: string; // 검색어
  perid: string; // 사용자 아이디
  resultck: string; // 결과
  okflag: string; // 배정

  // 하단 팝업

  popupDetailModal: boolean;

  focusedPopupData?:PopupModel;
  popupData: Array<PopupModel>;

  // 문자유형
  gubun: string;
  gubunCheck: boolean;

  // 데이터 객체

  // 포커싱 데이터
  focusedBreakResister?: BreakResisterModel;
  focusedDetailBreakResister: BreakResisterDetailModel;
  lastSaveData: BreakResisterDetailModel;

  // 하단 테이블 데이터
  data: BreakResisterDetailModel;

  // 테이블 데이터 리스트
  breakResisterList: Array<BreakResisterModel>;
  breakFieldList: Array<BreakFieldModel>;

  // 구분종류
  wkcds: Array<any>;

  // 체크 될 사람들 비교
  checkedPerson: Array<any>;

  pushCheck: string;

  // 모달
  fileDetailModal: boolean; // 첨부파일
  smsModal: boolean; // 문자전송
  smsPushModal: boolean; // 문자전송 (그냥문자)
  alarmModal: boolean; // 알림전송

  isReported: boolean; // 결재상신 여부
}

/*
* 컨트롤러
* @window w_tb_e401
* @category 고장접수등록
*/

export class BreakResister extends PageComponent<PageProps, BreakResisterState>
  implements PageToolEvents {
  updatedRows?: Array<BreakResisterModel>;

  updatedRows2?: Array<BreakFieldModel>;

  updatedPopupRows?: Array<PopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  detailGrid: React.RefObject<GridLayout> = React.createRef();

  popupGrid: React.RefObject<GridLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  gridFocus?: BreakResisterModel;

  gridFocusIndex: number = 0;

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  infinityPopup?: InfinityRetrieve;

  lastSavedPerid?: string;

  lastSavedRecedate?: string;

  alarmItem?: any;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      stdate: Today.date(),
      enddate: Today.date(),
      gubun: '1',
      gubunCheck: true,
      searchQuery: '',
      perid: '%',
      resultck: '%',
      okflag: '%',
      data: new BreakResisterDetailModel(),
      breakResisterList: [],
      breakFieldList: [],
      pushCheck: '0',

      wkcds: [],
      isReported: false,
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_e021');
    data && this.setState({ wkcds: data?.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: '%',
        resultck: this.state.resultck,
        okflag: this.state.okflag,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          breakResisterList: [...this.state.breakResisterList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          focusedBreakResister: new BreakResisterModel(), // 조회 후 신규 시 행 예외에 걸려서 추가
          breakResisterList: [],
        });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      focusedDetailBreakResister: new BreakResisterDetailModel(), // 조회 후 신규 시 행 예외에 걸려서 추가
      focusedBreakResister: new BreakResisterModel(), // 조회 후 신규 시 행 예외에 걸려서 추가
      breakResisterList: [],
      breakFieldList: [],
      data: new BreakResisterDetailModel(),
    }, async () => {
      await this.infinity?.retrieveAll();
      if (this.gridFocusIndex >= this.state.breakResisterList.length) {
        this.gridFocusIndex = 0;
      }

      // await this.grid.current?.setFocus(await this.lastSavedPerIndex());

      this.grid.current?.setFocus(this.gridFocusIndex);
      this.lastSavedPerid = undefined;
      this.lastSavedRecedate = undefined;
    });
  }

  @action
  async onRowFocusEvent(item: BreakResisterDetailModel, index: number) {
    if (item?.new === '1') {
      this.setState({
        isReported: false,
        data: this.state.lastSaveData,
        breakFieldList: [],
      });
      this.detailGrid.current?.setFocus(0);
      return;
    }

    if (!item) {
      this.setState({
        focusedBreakResister: new BreakResisterModel(),
        focusedDetailBreakResister: new BreakResisterDetailModel(),
      });
      this.gridFocus = item;
      this.gridFocusIndex = 0;
      return;
    }

    this.gridFocusIndex = index;

    const { actionStore: api } = this.props;
    this.setState(
      {
        focusedBreakResister: item,
        focusedDetailBreakResister: item,
      },
      async () => {
        this.gridFocus = item;
        const data = await api.exec(Category.MAINTENANCE, 'dw_1_RowFocuschanged',
          {
            recedate: this.state.focusedDetailBreakResister?.recedate,
            recenum: this.state.focusedDetailBreakResister?.recenum,
            enddate: this.state.enddate,
          });

        await data && this.setState({
          data: new BreakResisterDetailModel(data),
          breakFieldList: data?.items,
        });

        if (data.items) {
          this.detailGrid.current?.setFocus(0);
        }
      },
    );
  }

  @action
  async onButtonClicked(type: any) {
    const { actionStore: api } = this.props;

    const params = {
      itemname: BreakResisterButtonClickedTypeNames[type],
      data: '',
      recedate: this.state.data.recedate,
      recenum: this.state.data.recenum,
      new: this.state.data.new,
    };

    if (type === BreakResisterButtonClickedTypes.cancle) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          perid: this.state.data.perid,
          actnm: this.state.data.actnm,
          equpnm: this.state.data.equpnm,
        },
      );
    }

    if (type === BreakResisterButtonClickedTypes.cancle1) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          nearperid1: this.state.focusedDetailBreakResister?.nearperid1,
          nearpernm1: this.state.focusedDetailBreakResister?.nearpernm1,
          actnm: this.state.focusedDetailBreakResister?.actnm,
          equpnm: this.state.focusedDetailBreakResister?.equpnm,
        },
      );
    }

    if (type === BreakResisterButtonClickedTypes.cancle2) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          nearperid2: this.state.focusedDetailBreakResister?.nearperid2,
          nearpernm2: this.state.focusedDetailBreakResister?.nearpernm2,
          actnm: this.state.focusedDetailBreakResister?.actnm,
          equpnm: this.state.focusedDetailBreakResister?.equpnm,
        },
      );
    }

    if (type === BreakResisterButtonClickedTypes.cancle3) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          nearperid3: this.state.focusedDetailBreakResister?.nearperid3,
          nearpernm3: this.state.focusedDetailBreakResister?.nearpernm3,
          actnm: this.state.focusedDetailBreakResister?.actnm,
          equpnm: this.state.focusedDetailBreakResister?.equpnm,
        },
      );
    }
  }

  @action
  async itemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;

    if (type === BreakResisterItemChangeTypes.RECEDATE) {
      const today = Today.date();
      if (today < item) {
        const result = await Confirm.ask('확인', '고장일자가 금일 이후 입니다.\n맞습니까?', '예', '아니오');
        if (result !== AskType.YES) {
          await this.SS({
            data: new BreakResisterDetailModel({
              ...this.state.data,
              recedate: today,
            }, this.state.data.isNew),
          });
          this.itemChanged(BreakResisterItemChangeTypes.RECEDATE, today);
          return;
        }
      }
    }

    let data: any = {
      new: undefined,
      items: [],
    };

    const params = {
      itemname: BreakResisterItemChangeTypeNames[type],
      data: item,
      recedate: this.state.focusedDetailBreakResister?.recedate,
      recenum: this.state.focusedDetailBreakResister?.recenum,
      new: this.state.data.new,
    };

    if (type === BreakResisterItemChangeTypeNames.trouble) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          trouble: this.state.focusedDetailBreakResister?.trouble,
        },
      );

      data && this.setState({
        focusedDetailBreakResister: new BreakResisterDetailModel({
          ...this.state.focusedDetailBreakResister, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakResisterItemChangeTypeNames.recedate) {
      if (this.state.focusedDetailBreakResister?.actcd) {
        return;
      }
      data = await api.fxExec(
        'dw_2_itemchanged', {
          itemname: BreakResisterItemChangeTypeNames[type],
          data: this.state.data?.recedate,
          recenum: this.state.data?.recenum,
          new: this.state.data.new,
          recedate: this.state.data?.recedate,
        },
      );

      data && this.setState({
        focusedDetailBreakResister: new BreakResisterDetailModel({
          ...data,
          ...this.state.focusedDetailBreakResister, // new = 0
          // new = 1
        }, data.new === '1'),
        data: new BreakResisterDetailModel({
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakResisterItemChangeTypeNames.recetime) {
      if (this.state.focusedDetailBreakResister?.actcd) {
        return;
      }
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          recetime: this.state.focusedDetailBreakResister?.recetime,
        },
      );

      data && this.setState({
        focusedDetailBreakResister: new BreakResisterDetailModel({
          ...this.state.focusedDetailBreakResister, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakResisterItemChangeTypeNames.actcd) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          actcd: this.state.data?.actcd,
          actnm: this.state.data?.actnm,
          recetime: this.state.focusedDetailBreakResister?.recetime,
        },
      );

      data && this.setState({
        focusedDetailBreakResister: new BreakResisterDetailModel({
          ...this.state.focusedDetailBreakResister, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
        data: new BreakResisterDetailModel({
          ...this.state.data, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakResisterItemChangeTypeNames.equpcd) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          itemname: BreakResisterItemChangeTypeNames[type],
          data: this.state.data?.equpcd,
          recenum: this.state.data?.recenum,
          recedate: this.state.data?.recedate,
          new: this.state.data.new,
          actcd: this.state.data?.actcd,
          equpcd: this.state.data?.equpcd,
          equpnm: this.state.data?.equpnm,
        },
      );

      data && this.setState({
        focusedDetailBreakResister: new BreakResisterDetailModel({
          ...this.state.focusedDetailBreakResister, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
        data: new BreakResisterDetailModel({
          ...this.state.data, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakResisterItemChangeTypeNames.contcd) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          itemname: 'contcd',
          data: this.state.data?.contcd,
          recedate: this.state.data?.recedate,
          recenum: this.state.data?.recenum,
          new: this.state.data.new,
        },
      );

      data && this.setState({
        focusedDetailBreakResister: new BreakResisterDetailModel({
          ...this.state.focusedDetailBreakResister, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
        data: new BreakResisterDetailModel({
          ...this.state.data, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakResisterItemChangeTypeNames.okflag) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          itemname: 'okflag',
          data: this.state.data?.okflag,
          recedate: this.state.data?.recedate,
          recenum: this.state.data?.recenum,
          new: this.state.data.new,
          perid: this.state.data?.perid,
          actcd: this.state.data?.actcd,
        },
      );

      data && this.setState({
        focusedDetailBreakResister: new BreakResisterDetailModel({
          ...this.state.focusedDetailBreakResister, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });

      if (data.messagebox === 'message-open') {
        const text = `${this.state.data?.pernm}기사 배정내역을 전직원에게 전송하시겠습니까?`;
        if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
          data = await api.fxExec(
            'dw_2_itemchanged', {
              itemname: 'okflag_comp',
              data: '1',
              recedate: this.state.focusedDetailBreakResister?.recedate,
              recenum: this.state.focusedDetailBreakResister?.recenum,
              new: this.state.data.new,
              perid: this.state.data?.perid,
              actcd: this.state.data?.actcd,
            },
          );

          data && this.setState({
            focusedDetailBreakResister: new BreakResisterDetailModel({
              ...this.state.focusedDetailBreakResister, // new = 0
              ...data, // new = 1
            }, data.new === '1'),
          });
          return;
        }
        data = await api.fxExec(
          'dw_2_itemchanged', {
            itemname: this.state.data?.okflag_comp,
            data: '1',
            recedate: this.state.focusedDetailBreakResister?.recedate,
            recenum: this.state.focusedDetailBreakResister?.recenum,
            new: this.state.data.new,
            perid: this.state.data?.perid,
            actcd: this.state.data?.actcd,
          },
        );

        data && this.setState({
          focusedDetailBreakResister: new BreakResisterDetailModel({
            ...this.state.focusedDetailBreakResister, // new = 0
            ...data, // new = 1
          }, data.new === '1'),
        });

        data = await api.fxExec(
          'wb_okpush',
          {
            actcd: this.state.data?.actcd,
            perid: this.state.data?.perid,
            equpcd: this.state.data?.equpcd,
            contcd: this.state.data?.contcd,
            recedate: this.state.data?.recedate,
            recenum: this.state.data?.recenum,
            pernm: this.state.data?.pernm,
          },
        );
        if (data) {
          ConfirmSuccess.show(data.titlebox, data.messagebox);
        }
      }
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.fxSave(
      'save',
      {
        new: this.state.data?.new,
        custcd: this.state.data?.custcd,
        spjangcd: this.state.data?.spjangcd,
        recedate: this.state.data?.recedate,
        recenum: this.state.data?.recenum,
        hitchdate: this.state.data?.hitchdate,
        hitchhour: this.state.data?.hitchhour,
        reperid: this.state.data?.reperid,
        repernm: this.state.data?.repernm,
        divicd: this.state.data?.divicd,
        mpclafi: this.state.data?.mpclafi,
        perid: this.state.data?.perid,
        cltcd: this.state.data?.cltcd,
        actcd: this.state.data?.actcd,
        actnm: this.state.data?.actnm,
        dong: this.state.data?.dong,
        ho: this.state.data?.ho,
        equpcd: this.state.data?.equpcd,
        equpnm: this.state.data?.equpnm,
        contcd: this.state.data?.contcd,
        contents: this.state.data?.contents,
        remark: this.state.data?.remark,
        recetime: this.state.data?.recetime,
        trouble: this.state.data?.trouble,
        troubledate: this.state.data?.troubledate,
        troubletime: this.state.data?.troubletime,
        troublesms: this.state.data?.troublesms,
        resultck: this.state.data?.resultck,
        catchdate: this.state.data?.catchdate,
        catchtime: this.state.data?.catchtime,
        okflag: this.state.data?.okflag,
        pushflag: this.state.data?.pushflag,
        pushokflag: this.state.data?.pushokflag,
        wkactcd: this.state.data?.wkactcd,
        urltoken: this.state.data?.urltoken,
        recetelnum: this.state.data?.recetelnum,
        recetelflag: this.state.data?.recetelflag,
        nearperid1: this.state.data?.nearperid1,
        nearperid2: this.state.data?.nearperid2,
        nearperid3: this.state.data?.nearperid3,
        nearpernm1: this.state.data?.nearpernm1,
        nearpernm2: this.state.data?.nearpernm2,
        nearpernm3: this.state.data?.nearpernm3,
        rDistance1: this.state.data?.rDistance1,
        rDistance2: this.state.data?.rDistance2,
        rDistance3: this.state.data?.rDistance3,
        pay1: this.state.data?.pay1,
        pay2: this.state.data?.pay2,
        pay3: this.state.data?.pay3,
        nearlat1: this.state.data?.nearlat1,
        nearlng1: this.state.data?.nearlng1,
        nearlat2: this.state.data?.nearlat2,
        nearlng2: this.state.data?.nearlng2,
        nearlat3: this.state.data?.nearlat3,
        nearlng3: this.state.data?.nearlng3,
        incentive: this.state.data?.incentive,
        troublesu: this.state.data?.troublesu,
        wadnot: this.state.data?.wadnot,
      },
      this.state.data.new === '1',
    )) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.focusedDetailBreakResister?.recedate);
      this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
        focusedDetailBreakResister: new BreakResisterDetailModel(),
      });
      this.alarmCheck();
    }
  }

  @computed
  get currentItem(): BreakResisterModel {
    return this.state.breakResisterList[this.gridFocusIndex];
  }

  @action
  async alarmCheck() {
    await ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');

    if (this.state.data?.pushflag !== '1'
      && this.state.pushCheck !== '1'
      && AskType.YES === await Confirm.ask(
        '확인',
        '알림전송이 없는 고장접수내역입니다. 알림을 전송하시겠습니까?',
        '예',
        '아니요',
      )
    ) {
      await this.onAlarmClicked();
    }
    await this.onRetrieveEvent();
  }

  // @action
  // async onSaveEvent() {
  //   // 결재 상신된 청구서
  //   if (this.state.isReported) {
  //     ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
  //     return;
  //   }
  //
  //   const { actionStore: api } = this.props;
  //
  //   if (await api.save({
  //     new: this.state.data?.new,
  //     custcd: this.state.data?.custcd,
  //     spjangcd: this.state.data?.spjangcd,
  //     recedate: this.state.data?.recedate,
  //     recenum: this.state.data?.recenum,
  //     hitchdate: this.state.data?.hitchdate,
  //     hitchhour: this.state.data?.hitchhour,
  //     reperid: this.state.data?.reperid,
  //     repernm: this.state.data?.repernm,
  //     divicd: this.state.data?.divicd,
  //     mpclafi: this.state.data?.mpclafi,
  //     perid: this.state.data?.perid,
  //     cltcd: this.state.data?.cltcd,
  //     actcd: this.state.data?.actcd,
  //     actnm: this.state.data?.actnm,
  //     dong: this.state.data?.dong,
  //     ho: this.state.data?.ho,
  //     equpcd: this.state.data?.equpcd,
  //     equpnm: this.state.data?.equpnm,
  //     contcd: this.state.data?.contcd,
  //     contents: this.state.data?.contents,
  //     remark: this.state.data?.remark,
  //     recetime: this.state.data?.recetime,
  //     trouble: this.state.data?.trouble,
  //     troubledate: this.state.data?.troubledate,
  //     troubletime: this.state.data?.troubletime,
  //     troublesms: this.state.data?.troublesms,
  //     resultck: this.state.data?.resultck,
  //     catchdate: this.state.data?.catchdate,
  //     catchtime: this.state.data?.catchtime,
  //     okflag: this.state.data?.okflag,
  //     pushflag: this.state.data?.pushflag,
  //     pushokflag: this.state.data?.pushokflag,
  //     wkactcd: this.state.data?.wkactcd,
  //     urltoken: this.state.data?.urltoken,
  //     recetelnum: this.state.data?.recetelnum,
  //     recetelflag: this.state.data?.recetelflag,
  //     nearperid1: this.state.data?.nearperid1,
  //     nearperid2: this.state.data?.nearperid2,
  //     nearperid3: this.state.data?.nearperid3,
  //     nearpernm1: this.state.data?.nearpernm1,
  //     nearpernm2: this.state.data?.nearpernm2,
  //     nearpernm3: this.state.data?.nearpernm3,
  //     rDistance1: this.state.data?.rDistance1,
  //     rDistance2: this.state.data?.rDistance2,
  //     rDistance3: this.state.data?.rDistance3,
  //     pay1: this.state.data?.pay1,
  //     pay2: this.state.data?.pay2,
  //     pay3: this.state.data?.pay3,
  //     nearlat1: this.state.data?.nearlat1,
  //     nearlng1: this.state.data?.nearlng1,
  //     nearlat2: this.state.data?.nearlat2,
  //     nearlng2: this.state.data?.nearlng2,
  //     nearlat3: this.state.data?.nearlat3,
  //     nearlng3: this.state.data?.nearlng3,
  //     incentive: this.state.data?.incentive,
  //     troublesu: this.state.data?.troublesu,
  //
  //   }, this.state.data.new === '1')) {
  //     if (this.state.data?.pushflag !== '1' && this.state.pushCheck !== '1') {
  //       if (!await Confirm.show('확인', '알림전송이 없는 고장접수내역입니다. 알림을 전송하시겠습니까?', ConfirmType.QUESTION)) {
  //         return;
  //         await this.onRetrieveEvent();
  //       }
  //       await this.onAlarmClicked();
  //     }
  //   }
  //   this.state.data.pushflag = '1';
  //   this.setState({ pushCheck: '1' });
  //   this.lastSavedPerid = this.state.focusedBreakResister?.recenum;
  //   this.lastSavedRecedate = this.state.focusedBreakResister?.recedate;
  //   await this.onRetrieveEvent();
  // }

  @action
  async onAlarmClicked() {
    const { modalStore } = this.props;
    const { actionStore: api } = this.props;

    this.alarmItem = await api.exec(Category.MAINTENANCE, 'wb_push', {
      actcd: this.state.data?.actcd,
      lat: this.state.data?.lat,
      lng: this.state.data?.lng,
      equpcd: this.state.data?.equpcd,
      contcd: this.state.data?.contcd,
      contents: this.state.data?.contents,
      divicd: this.state.data?.divicd,
      recetime: this.state.data?.recetime,
      address: this.state.data?.address,
      recedate: this.state.data?.recedate,
      recenum: this.state.data?.recenum,
      spjangcd: this.state.data?.spjangcd,
      actperid: this.state.data?.actperid,
    });

    const items = await modalStore.openPush(
      this.alarmItem.title,
      this.alarmItem.remark,
      this.alarmItem.recedate,
      this.alarmItem.recenum,
      this.alarmItem.items,
    );

    await api.save({
      sub: 'w_popup_push_perid',
      title: this.alarmItem.title,
      remark: this.alarmItem.remark,
      recedate: this.alarmItem.recedate,
      recenum: this.alarmItem.recenum,
      items,
    }, true);
  }

  @action
  async PopupModal(isOpen: boolean) {
    await this.popupRetrieve();
    this.setState({ popupDetailModal: isOpen });
  }

  @action
  async popupRetrieve() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinityPopup = new InfinityRetrieve(
      {
        sub: 'w_popup_e401_cancle',
        recedate: this.state.data?.recedate,
        recenum: this.state.data?.recenum,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.setState({
          popupData: items?.map((x: any) => new PopupModel(x)),
        });
      },
      async () => {
        await this.SS({
          popupData: [],
        });
        await this.infinityPopup?.retrieve();
        await this.popupTable.current?.update();
        if (this.state.popupData.length) {
          this.popupTable.current?.setFocus(0);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      popupData: [],
    }, async () => {
      await this.infinityPopup?.retrieve();
      await this.popupTable.current?.update();
      if (this.state.popupData.length) {
        this.popupTable.current?.setFocus(0);
      }
    });
  }

  @action
  async popupSave() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix

    if (this.state.popupData?.length! < 1) {
      await this.setState({ popupDetailModal: false });
      return;
    }

    const data = await api.fxExec('save',
      {
        sub: 'w_popup_e401_cancle',
        items: this.state.popupData,
      }, false);

    if (data) {
      ConfirmSuccess.show(data.titlebox, data.messagebox);
      await this.popupRetrieve();
    }
  }

  @action
  async popupNew() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      sub: 'w_popup_e401_cancle',
      recedate: this.state.data?.recedate,
      recenum: this.state.data?.recenum,
      perid: this.state.data?.perid,
      pernm: this.state.data?.pernm,
      reperid: this.state.data?.reperid,
      repernm: this.state.data?.repernm,
    });

    const newModel = new PopupModel(data, true);

    if (data) {
      this.setState({
        popupData: [...this.state.popupData, new PopupModel(data, true)],
        focusedPopupData: newModel,
      });

      await this.popupTable.current?.update(false);
      this.popupTable.current?.setFocus(this.state.popupData.length - 1, 1);
    }
  }

  @action
  async popupDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_e401_cancle',
      recedate: this.state.data?.recedate,
      recenum: this.state.data?.recenum,
      seq: this.state.focusedPopupData?.seq,
    }) && await this.popupRetrieve();
  }

  @action
  onPopupRowFocusEvent(item: PopupModel) {
    this.setState({ focusedPopupData: item });
  }

  @action
  async onCustomerSMS() {
    const { actionStore: api } = this.props;

    const data = await api.exec(Category.MAINTENANCE, 'wb_safesms', {
      recedate: this.state.data?.recedate,
      recenum: this.state.data?.recenum,
      recetelnum: this.state.data?.recetelnum,
      perid: this.state.data?.perid,
      pernm: this.state.data?.pernm,
      actnm: this.state.data?.actnm,
      address: this.state.data?.address,
      address2: this.state.data?.address2,
    });

    if (data) {
      await ConfirmSuccess.show(data.titlebox, data.messagebox);
    }
  }

  @action
  async onClickSMS() {
    console.log(this.state.data);
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state?.breakResisterList.length, '오류', '문자전송할 고장내역이 없습니다')) {
      return;
    }
    await this.smsSendModal(false);

    if (this.state.gubun === '1' && this.state.gubunCheck) {
      const text = `${this.state.data?.pernm}님에게 음성문자를 전송하시겠습니까?`;
      if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
        return;
      }
      await this.smsSendModal(false);
      ConfirmSuccess.showPbMessage(await api.fxExec('wb_sms', {
        gubun: this.state.gubun,
        recedate: this.state.data?.recedate,
        recenum: this.state.data?.recenum,
        perid: this.state.data?.perid,
        actcd: this.state.data?.actcd,
        actnm: this.state.data?.actnm,
        contcd: this.state.data?.contcd,
        recetime: this.state.data?.recetime,
        equpcd: this.state.data?.equpcd,
        equpnm: this.state.data?.equpnm,
        contents: this.state.data?.contents,
        trouble: this.state.data?.trouble,
        recetelnum: this.state.data?.recetelnum,
      }));
    } else if (this.state.gubun === '0' && this.state.gubunCheck) {
      const { actionStore: api } = this.props;
      const { modalStore } = this.props;
      const data = await api.exec(Category.MAINTENANCE, 'wb_sms', {
        gubun: this.state.gubun,
        recedate: this.state.data?.recedate,
        recenum: this.state.data?.recenum,
        perid: this.state.data?.perid,
        actcd: this.state.data?.actcd,
        actnm: this.state.data?.actnm,
        contcd: this.state.data?.contcd,
        recetime: this.state.data?.recetime,
        equpcd: this.state.data?.equpcd,
        equpnm: this.state.data?.equpnm,
        contents: this.state.data?.contents,
        trouble: this.state.data?.trouble,
        recetelnum: this.state.data?.recetelnum,
      });
      const hpData = [{ tel: data?.hp }];

      // 사람갇힘이면 대표님 번호 추가
      if (data?.preperid !== '') {
        hpData.push({ tel: data?.prehandphone });
      }
      modalStore.openSMS(hpData, Fix.newline(data?.text));
    }
  }


  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const newModel = new BreakResisterDetailModel({
        ...data,
        recedate: Date8.make(),
        recetime: Time4.make(),
        hitchdate: Date8.make(),
      }, true);

      this.setState({
        // @ts-ignore
        data: newModel,
        lastSaveData: newModel,
        breakResisterList: [
          newModel,
          ...this.state.breakResisterList,
        ],
        focusedBreakResister: newModel,
        focusedDetailBreakResister: newModel,
        breakFieldList: [],
      }, async () => {
        this.grid.current?.setFocus(0);
        this.detailGrid.current?.setFocus(0);
      });
    }
  }

  @action
  async smsSendModal(isOpen: boolean) {
    if (!ConfirmWarning.assert(this.state?.breakResisterList.length, '오류', '문자전송할 고장내역이 없습니다')) {
      return;
    }
    this.setState({ smsModal: isOpen });
  }

  @action
  alarmSendModal(isOpen: boolean) {
    this.setState({ alarmModal: isOpen });
  }

  @action
  smsSendModalSave(isOpen: boolean) {
    this.setState({ gubunCheck: isOpen });
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line max-len
    const text = `접수일자: ${this.state.data?.recedate} 번호: ${this.state.data?.recenum}를 삭제하시겠습니까?`;
    await api.delete(text, {
      resultck: this.state.data?.resultck,
      okflag: this.state.data?.okflag,
      recedate: this.state.data?.recedate,
      recenum: this.state.data?.recenum,
      actcd: this.state.data?.actcd,
      equpcd: this.state.data?.equpcd,
    }) && await this.onRetrieveEvent();
  }

  @action
  wadnotItemcanged(value: boolean) {
    const { actionStore: api } = this.props;
    const data = api.fxExec('dw_2_itemchanged', {
      data: value ? '1' : '0',
      itemname: 'wadnot',
      actcd: this.state.data?.actcd,
      actnm: this.state.data?.equpcd,
    });

    data && this.setState({
      data: new BreakResisterDetailModel({
        ...this.state.data,
        ...data,
      }, this.state.data?.isNew),
    });
  }

  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */

  @action
  onUpdatedRows(rows: Array<BreakResisterModel>, updatedRows: Array<BreakResisterModel>) {
    this.updatedRows = updatedRows;
    this.setState({ breakResisterList: rows });
  }

  @action
  onUpdatedRows2(rows: Array<BreakFieldModel>, updatedRows: Array<BreakFieldModel>) {
    this.updatedRows2 = updatedRows;
    this.setState({ breakFieldList: rows });
  }

  @action
  onPopupUpdateRows(rows: Array<PopupModel>, updatedRows: Array<PopupModel>) {
    this.updatedPopupRows = updatedRows;
    this.setState({ popupData: rows });
  }

  render() {
    return (
      <BreakResisterTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new BreakResisterDetailModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
