import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e451
 * @category 견적서등록
 */
export default class EnrollmentConferenceModel {
  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 협의날짜
   *
   * **data type** varchar(8)
   */
  readonly hisdate: string;

  /**
   * 현장협의사항
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.seq = data.seq || '';
    this.hisdate = data.hisdate || '';
    this.remark = data.remark || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
