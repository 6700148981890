import Date6 from './Date6';

export default class Date6Calculator {
  date: Date;

  constructor(date?: Date | string) {
    this.date = typeof date === 'string' ? new Date(`${Date6.withDash(date)}-01`) : date || new Date();
  }

  public add(month?: number): Date6Calculator {
    this.date.setMonth(this.date.getMonth() + (month || 1));
    return this;
  }

  public addYear(year?: number): Date6Calculator {
    this.date.setFullYear(this.date.getFullYear() + (year || 1));
    return this;
  }

  public toString(): string {
    return Date6.make(this.date);
  }
}
