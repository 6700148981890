import {
  FaQuoteLeft,
  FaQuoteRight,
}
  from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { PaySales } from './PaySales';
import { TabModel } from '../../../../models';
import { TabMonthTemplate } from './tabs/TabMonth.template';
import { TabFiveYearTemplate } from './tabs/TabFiveYear.template';

/**
 * 화면 > 탭
 * @window w_tb_e101w_06
 * @category 보수수주매출통계
 */
export const PaySalesTab: Array<TabLayoutDataItem<PaySales>> = [
  {
    tab: new TabModel('Five', FaQuoteLeft, '10년간 수주매출내역'),
    template: (scope) => <TabFiveYearTemplate scope={scope} />,
  },
  {
    tab: new TabModel('Month', FaQuoteRight, '월별 수주매출내역'),
    template: (scope) => <TabMonthTemplate scope={scope} />,
  },
];
