import { TLHF } from '../../../../../constants';
import { TextBox } from '../../../../../components';
import { Plan } from '../Plan';
import { SearchBinding } from '../../../../../models';
import PlanPerModel from '../models/PlanPerModel';

/**
 * 화면 > 팝업 - 공사자 테이블 레이아웃
 * @window w_tb_e470
 * @category 공사계획등록
 */
export const PlanPerTableHeader: TLHF<PlanPerModel, Plan> = (scope) => ([
  {
    id: 'perid',
    text: '공사자코드',
    width: 30,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.perid}
        textAlign="center"
        onChange={(v) => rowUpdate({ perid: v })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_JA001',
          {},
          true,
          (item) => {
            rowUpdate({
              perid: item.cd,
              pernm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'pernm',
    text: '공사자명',
    width: 70,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.pernm}
        onChange={(v) => rowUpdate({ pernm: v })}
      />
    ),
  },
]);
