/**
 * 모델
 * @window w_tb_e601w_sulchi_09
 * @category 설치계약달성현황
 */

export class MisPopupModel {
  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  readonly misdate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly misnum: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 매출구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 매출금액
   *
   * **data type** varchar(8)
   */
  readonly misamt2: string;

  /**
   * 입금액
   *
   * **data type** varchar(8)
   */
  readonly rcvamt2: string

  /**
   * 미수금액
   *
   * **data type** varchar(8)
   */
  readonly iamt2: string;

  /**
   * 계약일자
   *
   * **data type** varchar(8)
   */
  readonly contdate: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  readonly projnm: string;

  /**
   * 진행현황
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 답변작성일
   *
   * **data type** varchar(8)
   */
  readonly hdate: string;

  constructor(data: any = {}) {
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.pernm = data.pernm || '';
    this.gubun = data.gubun || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.misamt2 = data.misamt2 || '';
    this.rcvamt2 = data.rcvamt2 || '';
    this.iamt2 = data.iamt2 || '';
    this.contdate = data.contdate || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.remark = data.remark || '';
    this.hdate = data.hdate || '';
  }
}
