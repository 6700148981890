import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  IFrame,
  SearchLayout,
  TableLayout,
} from '../../../../components';
import { SalesPurchase } from './SalesPurchase';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_ca642w_13
 * @category 월매출매입현황
 */
export const SalesPurchaseTemplate: React.FC<TemplateProps<SalesPurchase>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>년도</span>
            <DateTextBox
              value={scope.state.year}
              textAlign="center"
              format="####"
              onChange={(value) => setData({ year: value })}
              onEnter={() => {
                scope.onYearChange();
              }}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() - 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onYearChange());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() + 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
            />
          </FlexLayout>

          <FlexLayout size={230}>
            <span>매출세금계산서</span>
            <ComboBox
              value={scope.state.billkind}
              data={[
                { value: '%', remark: '전체' },
                { value: '1', remark: '발행' },
                { value: '0', remark: '미발행' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ billkind: option.value },
                () => scope.onRetrieveEvent())}
            />
          </FlexLayout>

          <FlexLayout size={230}>
            <span>매입세금계산서</span>
            <ComboBox
              value={scope.state.searchGubun}
              style={{
                padding: '0 0 0 6px',
                fontSize: 12,
              }}
              data={scope.state.gubun?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
              onSelect={(option) => setData({ searchGubun: option.value },
                () => scope.onRetrieveEvent())}
            />
          </FlexLayout>
        </SearchLayout>
    </FlexLayout>

      <FlexLayout>
        <FlexLayout size={200}>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'cnam',
                text: '매출구분',
                width: 80,
              },
              {
                id: 'chk',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={scope.state.pushTotalCheck}
                      onChange={async (value) => scope
                        .updateCheckAllToggle(value)}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
                width: 20,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.chk === '1'}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            data={scope.state.openChk}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
          />
        </FlexLayout>

        <FlexLayout weight={3}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'mon',
                text: '월',
                width: 16,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##"
                    value={x.mon}
                  />
                ),
              },
              {
                id: 'misamt',
                text: '매출금액',
                width: 28,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.misamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.misamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijamt',
                text: '매입금액',
                width: 28,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mijamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.mijamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'resuamt',
                text: '차액',
                width: 28,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.resuamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: x.resuamt > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                  >
                    {Format.number(x.resuamt)}
                  </FlexLayout>
                ),
              },
            ]}
             data={scope.state.data}
             infinityHandler={scope.infinity2}
             rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
             onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>

        <FlexLayout weight={4.5}>
          <IFrame src={scope.graph} />
        </FlexLayout>
      </FlexLayout>

      <FlexLayout weight={1.1}>
        <FlexLayout size={200}>
          <TableLayout
            ref={scope.table2}
            header={[
              {
                id: 'cnam',
                text: '매입항목',
                width: 80,
              },
              {
                id: 'chk',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={scope.state.pushTotalCheck2}
                      onChange={async (value) => scope
                        .updateCheckAllToggle2(value)}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
                width: 20,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.chk === '1'}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity3}
            data={scope.state.openChk2}
            onChange={(rows2, updatedRows2) => {
              scope.onUpdatedRows2(rows2, updatedRows2);
            }}
          />
        </FlexLayout>

        <FlexLayout weight={7.4}>
          <GridLayout
            ref={scope.grid2}
            headerHeight={60}
            header={[
              {
                id: 'spjangnm',
                text: '사업장명',
                width: 11,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt01',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      01월
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 6.5,
                trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.misamt01_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.mijamt01_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.resuamt01_tot}
                      </FlexLayout>
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt01)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt01)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt01)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt02',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      02월
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 6.5,
                trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.misamt02_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.mijamt02_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.resuamt02_tot}
                      </FlexLayout>
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt02)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt02)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt02)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt03',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      03월
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 6.5,
                trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.misamt03_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.mijamt03_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.resuamt03_tot}
                      </FlexLayout>
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt03)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt03)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt03)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt04',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      04월
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 6.5,
                trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.misamt04_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.mijamt04_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.resuamt04_tot}
                      </FlexLayout>
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt04)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt04)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt04)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt05',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      05월
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 6.5,
                trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.misamt05_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.mijamt05_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.resuamt05_tot}
                      </FlexLayout>
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt05)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt05)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt05)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt06',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      06월
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 6.5,
                trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.misamt06_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.mijamt06_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.resuamt06_tot}
                      </FlexLayout>
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt06)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt06)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt06)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt07',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      07월
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 6.5,
                trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.misamt07_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.mijamt07_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.resuamt07_tot}
                      </FlexLayout>
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt07)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt07)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt07)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt08',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      08월
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 6.5,
                trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.misamt08_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.mijamt08_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.resuamt08_tot}
                      </FlexLayout>
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt08)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt08)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt08)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt09',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      09월
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 6.5,
                trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.misamt09_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.mijamt09_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.resuamt09_tot}
                      </FlexLayout>
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt09)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt09)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt09)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt10',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      10월
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 6.5,
                trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.misamt10_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.mijamt10_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.resuamt10_tot}
                      </FlexLayout>
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt10)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt10)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt10)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt11',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      11월
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 6.5,
                trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.misamt11_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.mijamt11_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.resuamt11_tot}
                      </FlexLayout>
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt11)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt11)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt11)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt12',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      12월
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 6.5,
                trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.misamt12_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.mijamt12_tot}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.resuamt12_tot}
                      </FlexLayout>
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt12)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt12)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt12)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvamttot',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      합계
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      매출금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-red)' }}
                    >
                      매입금액
                    </FlexLayout>
                    <FlexLayout align="end">
                      차액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 11,
                trail: (data) => {
                  const sumMisamttot = Sum.all(data, 'misamttot');
                  const sumMijamttot = Sum.all(data, 'mijamttot');
                  const sumResuamttot = Sum.all(data, 'resuamttot');
                  return (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(sumMisamttot)}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {Format.number(sumMijamttot)}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumResuamttot)}
                      </FlexLayout>
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    style={{ backgroundColor: '#B2EBF4' }}
                  >
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamttot)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamttot)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamttot)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.dataDetail}
            infinityHandler={scope.infinity4}
            rowHeight={50}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
