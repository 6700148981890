import { computed } from 'mobx';

/**
 * 모델
 * 미수내역
 * @window w_tb_e601_new
 * @category 보수현장등록
 */
export class EnrollmentReceivableModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(2)
   */
  cltnm: string;

  /**
   * 발생일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 번호
   *
   * **data type** varchar(10)
   */
  misnum: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  remark: string;

  /**
   * 청구금액
   *
   * **data type** varchar(10)
   */
  misamt: string;

  /**
   * 입금액
   *
   * **data type** varchar(10)
   */
  rcvamt: string;

  /**
   * 미수잔액
   *
   * **data type** varchar(10)
   */
  resuamt: string;

  /**
   * 장기미수구분
   *
   * **data type** varchar(10)
   */
  longmisnm: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.cltnm = data.cltnm || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.remark = data.remark || '';
    this.misamt = data.misamt || '';
    this.rcvamt = data.rcvamt || '';
    this.resuamt = data.resuamt || '';
    this.longmisnm = data.longmisnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
