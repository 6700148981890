import { CheckBox, FlexLayout } from '../../../../../components';
import { TLHF } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 탭 > 테이블 - 견적항목
 * @window w_tb_da006
 * @category 수주서등록
 */
export const EnrollmentModalOrderMaterialsTableHeader: TLHF<any, Enrollment> = () => ([
  {
    id: 'chk',
    text: '선택',
    width: 60,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.chk === '1'}
        onChange={(v) => rowUpdate({ chk: v ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'pcode',
    text: '품번',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pcode}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품명',
    width: 200,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.psize}
      </FlexLayout>
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.punit}
      </FlexLayout>
    ),
  },
  {
    id: 'equpcd',
    text: '동호기',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.equpcd}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '동호기명',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'myn',
    text: '유무상',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.myn === '1' ? '무상' : '유상'}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Format.number(x.qty)}
      </FlexLayout>
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.uamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
  {
    id: 'addamt',
    text: '부가세',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.addamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'costamt',
    text: '견적금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.costamt)}
      </FlexLayout>
    ),
  },
]);
