/**
 * 모델
 * @window w_tb_e470
 * @category 공사계획등록
 */
export default class PlanModel {
  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 계약구분
   *
   * **data type** varchar(8)
   */
  readonly contg: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 구분명
   *
   * **data type** varchar(8)
   */
  readonly gubunnm: string;

  /**
   * 계획일자
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;


  constructor(data: any = {}) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.contg = data.contg || '';
    this.gubun = data.gubun || '';
    this.gubunnm = data.gubunnm || '';
    this.plandate = data.plandate || '';
    this.state = data.state || '';
  }
}
