import { action } from 'mobx';
import * as React from 'react';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { DutyTemplate } from './Duty.template';
import { DutyModel } from './Duty.model';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { GridLayout } from '../../../../components/layout/GridLayout';

interface DutyState {
  yearmon: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;

  // data
  data: Array<DutyModel>;
  focused?: DutyModel;

  // trail
  plantot_tot: string;
  tot_tot: string;
  planteltot_tot: string;
  teldaytot_tot: string;
  plantotweeksat_tot: string;
  totweeksat_tot: string;
  plantotweeksun_tot: string;
  totweeksun_tot: string;
  plantotweek_tot: string;
  totweek_tot: string;
  plantot21_tot: string;
  tot21_tot: string;
  plantot25_tot: string;
  tot25_tot: string;
  plantot6_tot: string;
  tot6_tot: string;
  totplantot_tot: string;
  totcomptot_tot: string;
  totplantottime_tot: string;
  totcomptottime_tot: string;
  date1_tot: string;
  date2_tot: string;
  date3_tot: string;
  date4_tot: string;
  date5_tot: string;
  date6_tot: string;
  date7_tot: string;
  date8_tot: string;
  date9_tot: string;
  date10_tot: string;
  date11_tot: string;
  date12_tot: string;
  date13_tot: string;
  date14_tot: string;
  date15_tot: string;
  date16_tot: string;
  date17_tot: string;
  date18_tot: string;
  date19_tot: string;
  date20_tot: string;
  date21_tot: string;
  date22_tot: string;
  date23_tot: string;
  date24_tot: string;
  date25_tot: string;
  date26_tot: string;
  date27_tot: string;
  date28_tot: string;
  date29_tot: string;
  date30_tot: string;
  date31_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e512w_01
 * @category 당직현황
 */
export class Duty extends PageComponent<PageProps, DutyState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      yearmon: Today.yearMon(),
      divicd: '',
      perid: '',
      data: [],

      // trail
      plantot_tot: '0',
      tot_tot: '0',
      planteltot_tot: '0',
      teldaytot_tot: '0',
      plantotweeksat_tot: '0',
      totweeksat_tot: '0',
      plantotweeksun_tot: '0',
      totweeksun_tot: '0',
      plantotweek_tot: '0',
      totweek_tot: '0',
      plantot21_tot: '0',
      tot21_tot: '0',
      plantot25_tot: '0',
      tot25_tot: '0',
      plantot6_tot: '0',
      tot6_tot: '0',
      totplantot_tot: '0',
      totcomptot_tot: '0',
      totplantottime_tot: '0',
      totcomptottime_tot: '0',
      date1_tot: '0',
      date2_tot: '0',
      date3_tot: '0',
      date4_tot: '0',
      date5_tot: '0',
      date6_tot: '0',
      date7_tot: '0',
      date8_tot: '0',
      date9_tot: '0',
      date10_tot: '0',
      date11_tot: '0',
      date12_tot: '0',
      date13_tot: '0',
      date14_tot: '0',
      date15_tot: '0',
      date16_tot: '0',
      date17_tot: '0',
      date18_tot: '0',
      date19_tot: '0',
      date20_tot: '0',
      date21_tot: '0',
      date22_tot: '0',
      date23_tot: '0',
      date24_tot: '0',
      date25_tot: '0',
      date26_tot: '0',
      date27_tot: '0',
      date28_tot: '0',
      date29_tot: '0',
      date30_tot: '0',
      date31_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new DutyModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      await this.grid.current?.setFocus(0);

      this.SS({
        plantot_tot: this.infinity?.box?.plantot_tot || '0',
        tot_tot: this.infinity?.box?.tot_tot || '0',
        planteltot_tot: this.infinity?.box?.planteltot_tot || '0',
        teldaytot_tot: this.infinity?.box?.teldaytot_tot || '0',
        plantotweeksat_tot: this.infinity?.box?.plantotweeksat_tot || '0',
        totweeksat_tot: this.infinity?.box?.totweeksat_tot || '0',
        plantotweeksun_tot: this.infinity?.box?.plantotweeksun_tot || '0',
        totweeksun_tot: this.infinity?.box?.totweeksun_tot || '0',
        plantotweek_tot: this.infinity?.box?.plantotweek_tot || '0',
        totweek_tot: this.infinity?.box?.totweek_tot || '0',
        plantot21_tot: this.infinity?.box?.plantot21_tot || '0',
        tot21_tot: this.infinity?.box?.tot21_tot || '0',
        plantot25_tot: this.infinity?.box?.plantot25_tot || '0',
        tot25_tot: this.infinity?.box?.tot25_tot || '0',
        plantot6_tot: this.infinity?.box?.plantot6_tot || '0',
        tot6_tot: this.infinity?.box?.tot6_tot || '0',
        totplantot_tot: this.infinity?.box?.totplantot_tot || '0',
        totcomptot_tot: this.infinity?.box?.totcomptot_tot || '0',
        totplantottime_tot: this.infinity?.box?.totplantottime_tot || '0',
        totcomptottime_tot: this.infinity?.box?.totcomptottime_tot || '0',
        date1_tot: this.infinity?.box?.date1_tot || '0',
        date2_tot: this.infinity?.box?.date2_tot || '0',
        date3_tot: this.infinity?.box?.date3_tot || '0',
        date4_tot: this.infinity?.box?.date4_tot || '0',
        date5_tot: this.infinity?.box?.date5_tot || '0',
        date6_tot: this.infinity?.box?.date6_tot || '0',
        date7_tot: this.infinity?.box?.date7_tot || '0',
        date8_tot: this.infinity?.box?.date8_tot || '0',
        date9_tot: this.infinity?.box?.date9_tot || '0',
        date10_tot: this.infinity?.box?.date10_tot || '0',
        date11_tot: this.infinity?.box?.date11_tot || '0',
        date12_tot: this.infinity?.box?.date12_tot || '0',
        date13_tot: this.infinity?.box?.date13_tot || '0',
        date14_tot: this.infinity?.box?.date14_tot || '0',
        date15_tot: this.infinity?.box?.date15_tot || '0',
        date16_tot: this.infinity?.box?.date16_tot || '0',
        date17_tot: this.infinity?.box?.date17_tot || '0',
        date18_tot: this.infinity?.box?.date18_tot || '0',
        date19_tot: this.infinity?.box?.date19_tot || '0',
        date20_tot: this.infinity?.box?.date20_tot || '0',
        date21_tot: this.infinity?.box?.date21_tot || '0',
        date22_tot: this.infinity?.box?.date22_tot || '0',
        date23_tot: this.infinity?.box?.date23_tot || '0',
        date24_tot: this.infinity?.box?.date24_tot || '0',
        date25_tot: this.infinity?.box?.date25_tot || '0',
        date26_tot: this.infinity?.box?.date26_tot || '0',
        date27_tot: this.infinity?.box?.date27_tot || '0',
        date28_tot: this.infinity?.box?.date28_tot || '0',
        date29_tot: this.infinity?.box?.date29_tot || '0',
        date30_tot: this.infinity?.box?.date30_tot || '0',
        date31_tot: this.infinity?.box?.date31_tot || '0',
      });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stmon: this.state.yearmon,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stmon: this.state.yearmon,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  render() {
    return (
      <DutyTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
