import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ae001
 * @category 정기지출항목등록
 */
export class ExpenseModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 비용코드
   *
   * **data type** varchar(4)
   */
  artcd: string;

  /**
   * 번호
   *
   * **data type** varchar(3)
   */
  outcd: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 증빙구분
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 거래처
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 미지급거래처
   *
   * **data type** varchar(13)
   */
  mijcltcd: string;

  /**
   * 자산구분
   *
   * **data type** varchar(3)
   */
  jsflag: string;

  /**
   * 발행구분
   *
   * **data type** varchar(3)
   */
  bhflag: string;

  /**
   * 세액공제
   *
   * **data type** varchar(2)
   */
  taxreclafi: string;

  /**
   * 세액공제2
   *
   * **data type** varchar(2)
   */
  taxrenm: string;

  /**
   * 영세율구분
   *
   * **data type** varchar(3)
   */
  osflag: string;

  /**
   * 내수구분
   *
   * **data type** varchar(3)
   */
  cdflag: string;

  /**
   * 귀속부서
   *
   * **data type** varchar(13)
   */
  divicd: string;

  /**
   * 귀속부서2
   *
   * **data type** varchar(13)
   */
  divinm: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(3)
   */
  billkind: string;

  /**
   * 고정금액
   *
   * **data type** float
   */
  samt: string;

  /**
   * 귀속년월
   *
   * **data type** varchar(6)
   */
  yyyymm: string;

  /**
   * 지급예정일
   *
   * **data type** varchar(8)
   */
  schdate: string;

  /**
   * 지급유형
   *
   * **data type** varchar(5)
   */
  mijchk: string;

  /**
   * 지급주기
   *
   * **data type** varchar(2)
   */
  mijjugi: string;

  /**
   * 카드코드
   *
   * **data type** varchar(10)
   */
  cardco: string;

  /**
   * 카드2
   *
   * **data type** varchar(40)
   */
  cardnm: string;

  /**
   * 카드3
   *
   * **data type** varchar(20)
   */
  cardnum: string;

  /**
   * 지급은행
   *
   * **data type** varchar(8)
   */
  bankcd: string;

  /**
   * 지급은행1
   *
   * **data type** varchar(8)
   */
  bankcd_1: string;

  /**
   * 지급은행2
   *
   * **data type** varchar(8)
   */
  bankcd_2: string;

  /**
   * 카드1
   *
   * **data type** varchar(20)
   */
  bankno: string;

  /**
   * 참고(비고)
   *
   * **data type** varchar(255)
   */
  bigo: string;

  /**
   * 1월
   *
   * **data type** varchar(2)
   */
  mon1: string;

  /**
   * 2월
   *
   * **data type** varchar(2)
   */
  mon2: string;

  /**
   * 3월
   *
   * **data type** varchar(2)
   */
  mon3: string;

  /**
   * 4월
   *
   * **data type** varchar(2)
   */
  mon4: string;

  /**
   * 5월
   *
   * **data type** varchar(2)
   */
  mon5: string;

  /**
   * 6월
   *
   * **data type** varchar(2)
   */
  mon6: string;

  /**
   * 7월
   *
   * **data type** varchar(2)
   */
  mon7: string;

  /**
   * 8월
   *
   * **data type** varchar(2)
   */
  mon8: string;

  /**
   * 9월
   *
   * **data type** varchar(2)
   */
  mon9: string;

  /**
   * 10월
   *
   * **data type** varchar(2)
   */
  mon10: string;

  /**
   * 11월
   *
   * **data type** varchar(2)
   */
  mon11: string;

  /**
   * 12월
   *
   * **data type** varchar(2)
   */
  mon12: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 회계여부
   *
   * **data type** varchar(1)
   */
  accyn: string;

  /**
   * 지급예정일 체크
   *
   * **data type** varchar(1)
   */
  schchk: string;

  /**
   * 거래처구분
   *
   * **data type** varchar(2)
   */
  cltflag: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  title: string;

  /**
   * 거래처
   *
   * **data type** varchar(255)
   */
  cltnm: string;

  /**
   * 미지급거래처
   *
   * **data type** varchar(255)
   */
  mijcltnm: string;

  /**
   * 저장시 지급처리
   *
   * **data type** varchar(1)
   */
  etflag: string;

  /**
   * 정기유무
   *
   * **data type** varchar(1)
   */
  jflag: string;

  /**
   * 시작일
   *
   * **data type** varchar(8)
   */
  stdate: string;

  /**
   * 종료일
   *
   * **data type** varchar(8)
   */
  enddate: string;

  /**
   * 지급처리
   *
   * **data type** varchar(1)
   */
  cancleflag: string;

  /**
   * 비용항목명
   *
   * **data type** varchar(255)
   */
  artnm: string;

  /**
   * 프로젝트
   *
   * **data type** varchar(10)
   */
  projno: string;

  /**
   * 프로젝트2
   *
   * **data type** varchar(255)
   */
  projnm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(13)
   */
  actcd: string;

  /**
   * 비용계정명
   *
   * **data type** varchar(255)
   */
  actnm: string

  /**
   * 경비증빙여부
   *
   * **data type** varchar(1)
   */
  proof: string

  /**
   * 지급시작일
   *
   * **data type** datetime
   */
  datetime: string

  /**
   * 지급종료일
   *
   * **data type** datetime
   */
  datetime2: string

  /**
   * 은행코드
   *
   * **data type** varchar
   */
  banknm: string

  /**
   * 계좌번호
   *
   * **data type** varchar
   */
  accnum: string

  /**
   * 카드은행번호
   *
   * **data type** varchar
   */
  uv_arg1: string

  /**
   * 카드번호
   *
   * **data type** varchar
   */
  cd: string

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.artcd = data.artcd || '';
    this.outcd = data.outcd || '';
    this.remark = data.remark || '';
    this.gubun = data.gubun || '';
    this.cltcd = data.cltcd || '';
    this.mijcltcd = data.mijcltcd || '';
    this.jsflag = data.jsflag || '';
    this.bhflag = data.bhflag || '';
    this.taxreclafi = data.taxreclafi || '';
    this.taxrenm = data.taxrenm || '';
    this.osflag = data.osflag || '';
    this.cdflag = data.cdflag || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.billkind = data.billkind || '';
    this.samt = data.samt || '';
    this.yyyymm = data.yyyymm || '';
    this.schdate = data.schdate || '';
    this.mijchk = data.mijchk || '';
    this.mijjugi = data.mijjugi || '';
    this.cardco = data.cardco || '';
    this.cardnm = data.cardnm || '';
    this.cardnum = data.cardnum || '';
    this.bankcd = data.bankcd || '';
    this.bankcd_1 = data.bankcd_1 || '';
    this.bankcd_2 = data.bankcd_2 || '';
    this.bankno = data.bankno || '';
    this.bigo = data.bigo || '';
    this.mon1 = data.mon1 || '';
    this.mon2 = data.mon2 || '';
    this.mon3 = data.mon3 || '';
    this.mon4 = data.mon4 || '';
    this.mon5 = data.mon5 || '';
    this.mon6 = data.mon6 || '';
    this.mon7 = data.mon7 || '';
    this.mon8 = data.mon8 || '';
    this.mon9 = data.mon9 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.accyn = data.accyn || '';
    this.schchk = data.schchk || '';
    this.cltflag = data.cltflag || '';
    this.title = data.title || '';
    this.cltnm = data.cltnm || '';
    this.mijcltnm = data.mijcltnm || '';
    this.etflag = data.etflag || '';
    this.jflag = data.jflag || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.cancleflag = data.cancleflag || '';
    this.artnm = data.artnm || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.proof = data.proof || '';
    this.datetime = data.datetime || '';
    this.datetime2 = data.datetime2 || '';
    this.banknm = data.banknm || '';
    this.accnum = data.accnum || '';
    this.uv_arg1 = data.uv_arg1 || '';
    this.cd = data.cd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
