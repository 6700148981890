import { FlexLayout } from '../../../../../components';
import { GLHF } from '../../../../../constants';
import EnrollmentModel from '../models/EnrollmentModel';
import { Enrollment } from '../Enrollment';
import { Date8 } from '../../../../../utils/time';

const Gubun = {
  14: '설치공사',
  15: '리모델링',
};

/**
 * 화면 - 리스트
 * @window w_tb_da006
 * @category 수주서등록
 */
export const EnrollmentMainGridHeader: GLHF<EnrollmentModel, Enrollment> = () => ([
  {
    id: 'orddate',
    text: '현장코드',
    width: 20,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.orddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'ordnum',
    text: '번호',
    width: 10,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.ordnum}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '수주종류',
    width: 20,
    render: (x) => {
      // @ts-ignore
      const gubun = Gubun[x.gubun];
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          {gubun}
        </FlexLayout>
      );
    },
  },
]);
