import * as React from 'react';
import mStyle from './LayoutTitle.module.scss';
import { JoinClassName } from '../../../utils/string';
import { FlexLayoutProps, Global } from '../../../constants';
import { FlexLayout } from '../../layout';

export const LayoutTitle: React.FC<FlexLayoutProps> = ({
  className,
  style,
  children,
  weight,
  size,
  align,
  justify,
  highlight,
}) => (
  <FlexLayout
    className={JoinClassName.make([
      mStyle.container,
      highlight ? mStyle.highlight : false,
      className,
    ])}
    weight={weight}
    size={size || Global.LAYOUT_TITLE_HEIGHT_1}
    align={align || 'center'}
    justify={justify || 'center'}
    style={{
      padding: '4px 8px',
      ...style,
    }}
  >
    <span>{children}</span>
  </FlexLayout>
);
