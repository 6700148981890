export default class UserModel {
  readonly custcd: string;

  readonly spjangcd: string;

  readonly perid: string;

  readonly kukcd: string;

  readonly pernm: string;

  readonly divicd: string;

  readonly divinm: string;

  readonly rspnm: string;

  readonly spjangnm: string;

  readonly tel: string;

  readonly tel2: string;

  readonly spjangcd_lat: string;

  readonly spjangcd_lng: string;

  readonly weather: string;

  readonly chk: string;

  readonly noticeflag: string;

  readonly totusers: string;

  readonly userid: string;

  readonly handphone: string;

  readonly quality: string;

  readonly lat: string;

  readonly lng: string;

  readonly commute: string;

  readonly call_type: string;

  readonly pushtye: string;

  readonly emailadres: string;

  readonly groupid: string;

  readonly grouppw: string;

  readonly token: string;

  readonly theme: string;

  readonly contrast: string;

  readonly emailuseyn: string;

  readonly papernum: string;

  readonly contflag: string;

  ipaddr: string;

  constructor(data?: any) {
    this.custcd = data?.custcd || 'dmyong_g';
    this.spjangcd = data?.spjangcd || '10';
    this.perid = data?.perid || '';
    this.kukcd = data?.kukcd || '';
    this.pernm = data?.pernm || '';
    this.divicd = data?.divicd || '';
    this.divinm = data?.divinm || '';
    this.rspnm = data?.rspnm || '';
    this.spjangnm = data?.spjangnm || '';
    this.tel = data?.tel || '';
    this.tel2 = data?.tel2 || '';
    this.spjangcd_lat = data?.spjangcd_lat || '';
    this.spjangcd_lng = data?.spjangcd_lng || '';
    this.weather = data?.weather || '';
    this.chk = data?.chk || '';
    this.noticeflag = data?.noticeflag || '';
    this.totusers = data?.totusers || '';
    this.userid = data?.userid || '';
    this.handphone = data?.handphone || '';
    this.quality = data?.quality || '';
    this.lat = data?.lat || '';
    this.lng = data?.lng || '';
    this.commute = data?.commute || '';
    this.call_type = data?.call_type || '';
    this.pushtye = data?.pushtye || '';
    this.emailadres = data?.emailadres || '';
    this.groupid = data?.groupid || '';
    this.grouppw = data?.grouppw || '';
    this.token = data?.token || '';
    this.theme = data?.theme || '';
    this.contrast = data?.contrast || '0';
    this.emailuseyn = data?.emailuseyn || '0';
    this.papernum = data?.papernum || '0';
    this.contflag = data?.contflag || '0';
    this.ipaddr = '';
  }
}
