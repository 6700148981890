import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { TripTemplate } from './Trip.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TripDetailModel,
  TripModel,
} from './models';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { TripPopupModel } from './models/TripPopup.model';
import { Format } from '../../../../utils/string';
import { PageComponent } from '../../../../utils';
import { DateStabilizer } from '../../../../utils/time';

export enum TripItemChangeTypes {
  OUTDATE,
  STDATE,
  ENDDATE,
  WEEKFLAG,
  TITLE,
  SAMT,
  UAMT,
  QTY,
}

export enum TripItemChangeTypeNames {
  outdate,
  stdate,
  enddate,
  weekflag,
  title,
  samt,
  uamt,
  qty,
}

export enum TripButtonClickedTypes {
  PIC,
  REFER,
  PERID,
  PERID_OK
}

export enum TripButtonClickedTypeNames{
  b_pic,
  b_refer,
  b_perid,
  b_perid_ok
}

interface TripState {

  // 검색 조건
  searchQuery: string;
  stdate: string;
  enddate: string;
  perid: string;
  pernm: string;

  // 데이터 객체
  focusedTrip?: TripModel;
  focusedTripDetail?: TripDetailModel;
  focusedUpdate?: TripDetailModel;
  data: TripModel;
  lastNewData: TripModel;
  tripList: Array<TripModel>;
  tripDetailList: Array<TripDetailModel>;
  popupList: Array<TripPopupModel>;
  popupFocused?: TripPopupModel;
  gubuns: Array<any>;

  // 모달
  fileDetailModal: boolean; // 첨부파일
  loadTriperModal: boolean // 출장자 모달

  isReported: boolean; // 결재상신 여부
  isCopy: boolean;

  qty_tot: string;
  samt_tot: string;

  focusIndex: number;
  chk: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb401
 * @category 출장보고서
 */
export class Trip extends PageComponent<PageProps, TripState>
  implements PageToolEvents {
  updatedRows?: Array<TripDetailModel>;

  updatedRows2?: Array<TripPopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      stdate: `${year}0101`,
      enddate: `${year}${month}${date}`,
      data: new TripModel({
        outdate: `${year}${month}${date}`,
        stdate: `${year}${month}${date}`,
        enddate: `${year}${month}${date}`,
      }),
      tripList: [],
      tripDetailList: [],
      popupList: [],
      isCopy: false,
      isReported: false,

      qty_tot: '',
      samt_tot: '',

      focusIndex: 0,
      chk: '0',
    };
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          ...this.state.data,
        }, false,
      );

      data && this.setState({
        data: new TripModel({
          ...this.state.data,
          ...data,
        }, data.new),
      });

      // await this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;

    // 팝업 구분
    const data = await api.dropdown('wf_dd_pb401_gubun');
    if (!data) return;
    this.setState({
      gubuns: data.items,
      perid: user.perid,
      pernm: user.pernm,
    });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          tripList: [...this.state.tripList, ...items],
        }, next);
      },
      async () => {
        await this.SS({ tripList: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      tripList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['outdate', 'outnum'],
        [lastSelected?.outdate, lastSelected?.outnum], type, true) || 0;
      if (this.state.chk === '1') {
        this.grid.current?.setFocus(index);
        this.setState({ chk: '0' });
      } else {
        this.grid.current?.setFocus(0);
      }
    });
  }

  @action
  async onRowFocusEvent(items: TripModel, index: number) {
    if (this.state.isCopy) {
      this.setState({
        focusedTripDetail: undefined,
        isCopy: false,
        isReported: false,
      });
      return;
    }
    if (items?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        focusedTripDetail: undefined,
        tripDetailList: [],
        isReported: false,
        data: lastNewData,
      });
      this.table.current?.update(false);
      return;
    }

    const { actionStore: api } = this.props;
    this.updatedRows2 = [];

    this.setState(
      {
        focusedTrip: items,
        focusIndex: index,
      },
    );

    this.infinity2 = new InfinityRetrieve(
      {
        outdate: items?.outdate,
        outnum: items?.outnum,
      },
      (params) => api.fxExec('dw_1_RowFocuschanged', params),
      (items) => {
        this.setState({
          tripDetailList: items.map((x: any, index: number) => new TripDetailModel({
            // 순번 생성
            ...x,
            // eslint-disable-next-line no-nested-ternary
            seq: index < 9 ? `00${index + 1}` : index < 99 ? `0${index + 1}` : (index + 1).toString(),
          })),
        }, () => this.table.current?.update());

        // 결재상신 여부확인
        if (this.state.focusedTrip?.appgubun === null
          || this.state.focusedTrip?.appgubun === ''
          || this.state.focusedTrip?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
      },
      async () => {
        await this.SS({ tripDetailList: [] });
        await this.infinity2?.retrieve();
        await this.table.current?.update();
        this.table.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      tripDetailList: [],
    }, async () => {
      const datas = await this.infinity2?.retrieve();
      this.setState({
        data: new TripModel(datas),
        qty_tot: datas?.qty_tot || '0',
        samt_tot: datas?.samt_tot || '0',
      });

      await this.table.current?.update();
      this.table.current?.setFocus(0, 1);
    });
  }

  @action
  async tabAutoCalc(item: any, rowUpdate: RowUpdate, name: string) {
    const qty = Format.toNumber(item.qty);
    const uamt = Format.toNumber(item.uamt);
    let samt = Format.toNumber(item.samt);

    if (qty === 0 && name !== 'qty') {
      samt = Math.round(uamt);
    } else {
      samt = Math.round((qty * uamt));
    }

    rowUpdate({
      ...item,
      qty: qty.toString(),
      uamt: uamt.toString(),
      samt: samt.toString(),
    });
  }

  @action
  onDetailRowFocusEvent(item:TripDetailModel) {
    this.setState({ focusedTripDetail: item });
  }

  @action
  onPopupRowFocusEvent(item:TripPopupModel) {
    this.setState({ popupFocused: item });
  }

  @action
  async loadTriperModal(isOpen: boolean) {
    this.setState({ loadTriperModal: isOpen });
    isOpen && await this.modalRetrieve();
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;

    this.infinity3 = new InfinityRetrieve(
      {
        sub: 'w_popup_pb401_perid',
        outdate: this.state.data.outdate,
        outnum: this.state.data.outnum,
        page: '',
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          popupList: [...this.state.popupList, ...items],
        }, () => this.popupTable.current?.update(false));
      },
      async () => {
        await this.SS({ popupList: [] });
        await this.infinity3?.retrieveAll();
        this.popupTable.current?.setFocus(0, 0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      popupList: [],
    }, async () => {
      await this.infinity3?.retrieveAll();
      this.popupTable.current?.setFocus(0, 0);
    });
  }

  @action
  async modalDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_pb401_perid',
      outdate: this.state.popupFocused?.outdate,
      outnum: this.state.popupFocused?.outnum,
      seq: this.state.popupFocused?.seq,
    }) && await this.modalRetrieve();

    this.updatedRows2 = [];
  }

  @action
  async modalSave() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('save',
      {
        sub: 'w_popup_pb401_perid',
        items: this.state.popupList,
      });

    if (data) {
      await this.loadTriperModal(false);
      await this.onRetrieveEvent();
      this.updatedRows2 = [];
    }
  }

  @action
  async modalNew() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('new',
      {
        sub: 'w_popup_pb401_perid',
        outdate: this.state.data.outdate,
        outnum: this.state.data.outnum,
      });

    if (data) {
      const newModel = new TripPopupModel(data, true);

      this.setState({
        popupList: [
          newModel,
          ...this.state.popupList,
        ],
        popupFocused: newModel,
      }, async () => {
        await this.popupTable.current?.update(false);
        this.popupTable.current?.setFocus(0, 0);
      });
    }
  }

  @action
  async itemChanged(type: number, _?: any) {
    const { actionStore: api } = this.props;

    let data:any = {
      new: undefined,
      items: [],
    };

    const params = {
      itemname: TripItemChangeTypeNames[type],
      data: this.state.data.outdate,
      new: this.state.data.new,
      outdate: this.state.data.outdate,
      outnum: this.state.data.outnum,
      stdate: this.state.data.stdate,
      enddate: this.state.data.enddate,
      title: this.state.data.title,
      weekflag: this.state.data.weekflag,
    };

    if (type === TripItemChangeTypeNames.outdate) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          outnum: '',
        },
      );

      data && this.setState({
        data: new TripModel({
          ...this.state.data, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === TripItemChangeTypeNames.stdate
      || TripItemChangeTypeNames.enddate
      || TripItemChangeTypeNames.weekflag
      || TripItemChangeTypeNames.title
    ) {
      if (this.state.data.stdate !== ''
        && this.state.data.enddate !== ''
        && this.state.data.title !== ''
        && this.state.data.weekflag !== '') {
        data = await api.fxExec('dw_2_itemchanged', params);

        if (this.state.data.new === '1') {
          if (data?.items) {
            this.setState({
              tripDetailList: [...this.state.tripDetailList,
                ...data?.items.map((x: any, index: number) => (
                  new TripDetailModel({ ...x, seq: String(index + 1) })
                ))],
            }, () => this.table.current?.update(false));
          }
        } else if (data?.items) {
          this.setState({
            tripDetailList:
                this.state.tripDetailList.map((x: any, index: number) => (
                  // eslint-disable-next-line radix
                  (index + 1) === parseInt(data?.items[0]?.seq)
                    ? new TripDetailModel({ ...x, ...data?.items[0] })
                    : new TripDetailModel(x)
                )),
          }, () => this.table.current?.update(false));
        }
      }
    }
  }

  @action
  async detailItemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;

    // eslint-disable-next-line max-len
    const focusedDetailIndex = this.state.tripDetailList.findIndex((x) => x.seq === item.seq);
    let data = { new: undefined };
    const params = {
      itemname: TripItemChangeTypeNames[type],
      data: '',
      new: this.state.focusedTripDetail?.new,
    };

    if (type === TripItemChangeTypeNames.uamt
    || type === TripItemChangeTypeNames.qty) {
      data = await api.fxExec(
        'dw_3_itemchanged',
        {
          ...params,
          uamt: item.uamt,
          qty: item.qty,
        },
      );
    }

    // 디테일 테이블에서 수정되어야하는 로우를 찾아 값 변경
    data && this.setState({
      focusedTripDetail: new TripDetailModel({
        ...this.state.focusedTripDetail,
      }, false),
      // eslint-disable-next-line max-len
      tripDetailList: this.state.tripDetailList.map((x: any, index: number) => (
        index === focusedDetailIndex
          ? new TripDetailModel({ ...x, ...data }, data.new === '1') : new TripDetailModel(x)
      )),
    }, () => this.table.current?.update(false));
  }


  @action
  async onButtonClicked(type: any) {
    const { actionStore: api } = this.props;
    let data = {
      new: undefined,
      messagebox: '',
    };

    const params = {
      itemname: TripButtonClickedTypeNames[type],
      data: '',
      outdate: this.state.data.outdate,
      outnum: this.state.data.outnum,
      appnum: this.state.data.appnum,
      new: this.state.data.new,
    };

    if (type === TripButtonClickedTypes.PIC) {
      data = await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          appnm: '출장보고서',
          spjangcd: this.state.data.spjangcd,
          subject: this.state.data.title,
        },
      );
      this.fileModal(true);
    }

    if (type === TripButtonClickedTypes.REFER) {
      data = await api.fxExec('dw_2_buttonclicked', params);
      this.openReferSelector();
    }

    if (type === TripButtonClickedTypes.PERID) {
      data = await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          title: this.state.data.title,
          stdate: this.state.data.stdate,
          enddate: this.state.data.enddate,
          appgubun: this.state.data.appgubun,
        },
      );
      if (data?.messagebox === '') {
        data && this.setState({
          data: new TripModel({
            ...this.state.data, // new = 0
            ...data, // new = 1
          }, data.new === '1'),
        }, () => this.loadTriperModal(true));
      }
    }

    if (type === TripButtonClickedTypes.PERID_OK) {
      data = await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          title: this.state.data.title,
          stdate: this.state.data.stdate,
          enddate: this.state.data.enddate,
          appgubun: this.state.data.appgubun,
        },
      );
      if (data?.messagebox === '') this.modalSave();
    }
  }

  @action
  fileModal(isOpen: boolean) {
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedTrip!, this.state.focusIndex); }
      });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(
      PAPERCD.BUSINESSTRIP,
      this.state.data?.appnum,
    )) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedTrip!, this.state.focusIndex);
  }

  // 청구서 복사
  @action
  async onCopy() {
    const text = `청구일자: ${this.state.focusedTrip?.outdate}, 청구번호: ${this.state.focusedTrip?.outnum}`;
    if (!await Confirm.show('복사하시겠습니까?', text, ConfirmType.QUESTION)) {
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.exec(Category.EMAIL, 'wb_copy', {
      ...this.state.data,
      outdate: '',
      outnum: '',
      appnum: '',
      items: this.state.tripDetailList,
    });

    if (data) {
      const newModel = new TripModel(data, true);

      this.setState({
        data: newModel,
        tripList: [newModel, ...this.state.tripList],
        focusedTrip: newModel,
        isCopy: true,
      }, async () => {
        await this.table.current?.update(false);
      });
      this.grid.current?.setFocus(0);
    }
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.BUSINESSTRIP);
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.BUSINESSTRIP.toString(),
      ...this.state.data,
      appflag,
    });
    data && this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }
    if (this.state.tripDetailList.length < 1) {
      ConfirmWarning.show('경고', '지출내역이 없습니다.');
      return;
    }

    const checkData: any = [];
    this.state.tripDetailList?.forEach((x: any, n) => {
      checkData.push({
        ...x,
        seq: `00${String(n + 1)}`,
        new: '1',
      });
    });

    const { actionStore: api } = this.props;
    if (await api.save({
      ...this.state.data,
      subject: '',
      items: checkData,
    }, this.state.data.new === '1')) {
      this.updatedRows2 = [];
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.outdate);
      await this.setState({
        chk: '1',
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new({
      gubun: this.state.focusedTrip?.gubun,
      kmflag: this.state.focusedTrip?.kmflag,
      title: this.state.focusedTrip?.title,
    });
    if (data) {
      const newModel = new TripModel({ ...data, outnum: '' }, true);

      this.setState({
        data: newModel,
        lastNewData: newModel,
        tripList: [
          newModel,
          ...this.state.tripList,
        ],
        tripDetailList: [],
        focusedTrip: newModel,
        focusIndex: 0,
      }, async () => {
        await this.table.current?.update(false);
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `청구일자: ${this.state.focusedTrip?.outdate}, 청구번호: ${this.state.focusedTrip?.outnum}`;
    await api.delete(text, this.state.focusedTrip) && await this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_3_new',
      {
        outdate: this.state.data.outdate,
        outnum: this.state.data.outnum,
        seq: this.state.focusedTripDetail?.seq,
        title: this.state.data.title,
        stdate: this.state.data.stdate,
        enddate: this.state.data.enddate,
      },
    );

    const seq = this.state.tripDetailList.length;
    data && this.setState({
      tripDetailList: [...this.state.tripDetailList,
        // eslint-disable-next-line no-nested-ternary,max-len
        new TripDetailModel({ ...data, seq: seq < 9 ? `00${seq + 1}` : seq < 99 ? `0${seq + 1}` : (seq + 1).toString() }, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.tripDetailList.length - 1, 0);
    });
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    if (this.state.tripDetailList.length === 0) {
      ConfirmWarning.show('경고', '삭제할 내용이 없습니다.');
      return;
    }

    const text = `No: ${this.state.focusedTripDetail?.seq}`;
    await api.fxDelete(
      'dw_3_delete',
      text,
      {
        outdate: this.state.data.outdate,
        outnum: this.state.data.outnum,
        seq: this.state.focusedTripDetail?.seq,
        title: this.state.data.title,
        stdate: this.state.data.stdate,
        enddate: this.state.data.enddate,
        appgubun: this.state.data.appgubun,
      },
    ) && await this.onRetrieveEvent();
    await this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.focusedTripDetail?.custcd, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      outdate: this.state.data.outdate,
      outnum: this.state.data.outnum,
      seq: this.state.focusedTripDetail?.seq,
      title: this.state.data.title,
      stdate: this.state.data.stdate,
      enddate: this.state.data.enddate,
      appgubun: this.state.data.appgubun,
    });
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  /**
   * 행 변경 이벤트
   * @param rows
   * @param updatedRows
   */
  @action
  onUpdatedRows(rows: Array<TripDetailModel>, updatedRows: Array<TripDetailModel>) {
    this.updatedRows = updatedRows;
    this.setState({ tripDetailList: rows });
  }

  @action
  onUpdatedRows2(rows2: Array<TripPopupModel>, updatedRows2: Array<TripPopupModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ popupList: rows2 });
  }

  render() {
    return (
      <TripTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new TripModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
