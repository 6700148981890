import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  OptionBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { PaymentCalculate } from './PaymentCalculate';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_tb_ca644
 * @category 가지급정산등록
 */
export const PaymentCalculateTemplate: React.FC<TemplateProps<PaymentCalculate>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ enddate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={400}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="사용자, 지급사유로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => scope.setState({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={176}>
            <span>구분</span>
            <ComboBox
              value={scope.state.gubun}
              textAlign="center"
              style={{ marginLeft: 12 }}
              data={[
                { value: '%', remark: '전체' },
                { value: '0', remark: '미정산' },
                { value: '1', remark: '정산' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => scope.setState({ gubun: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'compflag',
            text: '구분',
            width: 5,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합계
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.compflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.compflag === '1' ? '정산' : '미정산'}
              </FlexLayout>
            ),
          },
          {
            id: 'snddate',
            text: '지급일자',
            width: 8,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.snddate)}
              </FlexLayout>
            ),
          },
          {
            id: 'sndnum',
            text: '번호',
            width: 5,
          },
          {
            id: 'pernm',
            text: '사용자',
            width: 6,
          },
          {
            id: 'remark',
            text: '지급사유',
            width: 11,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'samt',
            text: '지급금액',
            width: 8,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {scope.state.samt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {Format.number(x.samt)}
              </FlexLayout>
            ),
          },
          {
            id: 'tamt',
            text: '잔액(환불액)',
            width: 8,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#7F7A2C' }}
              >
                {scope.state.tamt_tot}
              </FlexLayout>
            ),
            render: (x, rowUpdate, ref) => (
              x.compflag === '1'
                ? <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                   {Format.number(x.tamt)}
                  </FlexLayout>
                : <FormatNumberTextBox
                    color={'#7F7A2C'}
                    ref={ref}
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={x.tamt}
                    onChange={(value) => scope.tabAutoCalc({ ...x, tamt: value }, rowUpdate)}
                  />
            ),
          },
          {
            id: 'mamt',
            text: '정산금액',
            width: 8,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {scope.state.mamt_tot}
              </FlexLayout>
            ),
            render: (x, rowUpdate, ref) => (
              x.compflag === '1'
                ? <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.mamt}
                </FlexLayout>
                : <FormatNumberTextBox
                  color={'var(--color-blue)'}
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.mamt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, mamt: value }, rowUpdate)}
                />
            ),
          },
          {
            id: 'iamt',
            text: '미정산금액',
            width: 8,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#ED709B' }}
              >
                {scope.state.iamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#ED709B' }}
              >
                {Format.number(x.iamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'bankcd_2',
            text: '정산',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <Button
                  isMini={true}
                  theme={x.compflag === '1' ? BUTTON_THEMES.RED : BUTTON_THEMES.BLUE}
                  onClick={() => (x.compflag === '1' ? scope.onBtnDelEvent() : scope.onBtnSaveEvent())}
                >
                  {x.compflag === '1' ? '취소' : '정산'}
                </Button>
              </FlexLayout>
            ),
          },
          {
            id: 'amtflag',
            text: '지급유형',
            width: 8,
            render: (x) => (
              <OptionBox
                value={x.amtflag}
                data={[
                  { value: 'H', remark: '현금' },
                  { value: 'B', remark: '예금' },
                ]}
                onChange={() => {}}
              />
            ),
          },
          {
            id: 'banknm',
            text: '지급은행',
            width: 8,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.banknm}
              </FlexLayout>
            ),
          },
          {
            id: 'accnum',
            text: '계좌번호',
            width: 10,
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
      />
    </FlexLayout>
);
