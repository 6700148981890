import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { ManageStatus } from './ManageStatus';
import { TabModel } from '../../../../models';
import {
  ManageStatusHeadTabContractTemplate,
  ManageStatusHeadTabArrivalTemplate,
  ManageStatusHeadTabPreinspectTemplate,
  ManageStatusHeadTabInspectTemplate,
  ManageStatusHeadTabDeliveryTemplate, ManageStatusHeadTabRealCheckDateTemplate, ManageStatusHeadTabDesignDateTemplate,
} from './tabs';

/**
 * 화면 > 탭 - 상단
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusHeadTabTemplate: Array<TabLayoutDataItem<ManageStatus>> = [
  {
    tab: new TabModel('CONTRACT', FaCodeBranch, '계약일자별'),
    template: (scope) => <ManageStatusHeadTabContractTemplate scope={scope} />,
  },
  {
    tab: new TabModel('REALCHECKDATE', FaCodeBranch, '실측일자별'),
    template: (scope) => <ManageStatusHeadTabRealCheckDateTemplate scope={scope} />,
  },
  {
    tab: new TabModel('DESIGNDATE', FaCodeBranch, '설계일자별'),
    template: (scope) => <ManageStatusHeadTabDesignDateTemplate scope={scope} />,
  },
  {
    tab: new TabModel('ARRIVAL', FaCodeBranch, '자재현장도착일자별'),
    template: (scope) => <ManageStatusHeadTabArrivalTemplate scope={scope} />,
  },
  {
    tab: new TabModel('PREINSPECT', FaCodeBranch, '검사예정일자별'),
    template: (scope) => <ManageStatusHeadTabPreinspectTemplate scope={scope} />,
  },
  {
    tab: new TabModel('INSPECT', FaCodeBranch, '검사일자별'),
    template: (scope) => <ManageStatusHeadTabInspectTemplate scope={scope} />,
  },
  {
    tab: new TabModel('DELIVERY', FaCodeBranch, '납기일자별'),
    template: (scope) => <ManageStatusHeadTabDeliveryTemplate scope={scope} />,
  },
];
