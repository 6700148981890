import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da052w_01
 * @category 목표대비실적[개인별]
 */
export class GoalResultModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 월
   *
   * **data type** number
   */
  planmon: string;

  /**
   * 매출목표
   *
   * **data type** number
   */
  planamt: string;

  /**
   * 매출실적
   *
   * **data type** number
   */
  totamt: string;

  /**
   * 유지보수
   *
   * **data type** number
   */
  amt11: string;

  /**
   * 수리공사
   *
   * **data type** number
   */
  amt12: string;

  /**
   * 부품교체
   *
   * **data type** number
   */
  amt13: string;

  /**
   * 리모델링
   *
   * **data type** number
   */
  amt14: string;

  /**
   * 가공매출(기타)
   *
   * **data type** number
   */
  amtelse: string;

  /**
   * 대수
   *
   * **data type** number
   */
  qty: string;

  /**
   * 월실적
   *
   * **data type** number
   */
  yul: string;

  /**
   * 상단 유지보수
   *
   * **data type** number
   */
  planamt11: string;

  /**
   * 상단 수리공사
   *
   * **data type** number
   */
  planamt12: string;

  /**
   * 상단 부품교체
   *
   * **data type** number
   */
  planamt13: string;

  /**
   * 상단 리모델링
   *
   * **data type** number
   */
  planamt14: string;

  /**
   * 월
   *
   * **data type** char(8)
   */
  planmon2: string

  /**
   * 상단
   *
   * **data type** number
   */
  planamtqty: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.planmon = data.planmon || '';
    this.planmon2 = data.planmon2 || '';
    this.planamtqty = data.planamtqty || '';
    this.planamt = data.planamt || '';
    this.totamt = data.totamt || '';
    this.amt11 = data.amt11 || '';
    this.amt12 = data.amt12 || '';
    this.amt13 = data.amt13 || '';
    this.amt14 = data.amt14 || '';
    this.planamt11 = data.planamt11 || '';
    this.planamt12 = data.planamt12 || '';
    this.planamt13 = data.planamt13 || '';
    this.planamt14 = data.planamt14 || '';
    this.amtelse = data.amtelse || '';
    this.qty = data.qty || '';
    this.yul = data.yul || '';
    this.spjangnm = data.spjangnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
