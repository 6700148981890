import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import { OvertimeTemplate } from './Overtime.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  OvertimeDetailModel,
  OvertimeModel,
} from './models';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';
import { DateStabilizer } from '../../../../utils/time';

export enum OvertimeItemChangeTypes {
  // dw_2_itemchanged
  OVERDATE,

  // dw_3_itemchanged
  STDATE,
  ENDDATE,
  STTIME,
  ENDTIME,
  PERID
}

export enum OvertimeItemChangeTypeNames {
  // dw_2_itemchanged
  overdate,

  // dw_3_itemchanged
  stdate,
  enddate,
  sttime,
  endtime,
  perid,
}

interface OvertimeState {

  // 검색 조건
  searchQuery: string;
  stdate: string;
  enddate: string;
  perid: string;
  pernm: string;

  // 데이터 객체
  focusedOvertime?: OvertimeModel;
  focusedOvertimeDetail?: OvertimeDetailModel;
  data: OvertimeModel;
  lastNewData: OvertimeModel;
  overtimeList: Array<OvertimeModel>;
  overtimeDetailList: Array<OvertimeDetailModel>;
  gubuns: Array<any>;

  // 모달
  fileDetailModal: boolean; // 첨부파일
  loadOvertimeerModal: boolean // 출장자 모달

  isReported: boolean; // 결재상신 여부
  isCopy: boolean;

  focusIndex: number;
  chk: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb420
 * @category 시간외근무보고서
 */
export class Overtime extends PageComponent<PageProps, OvertimeState>
  implements PageToolEvents {
  updatedRows?: Array<OvertimeDetailModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      data: new OvertimeModel(),
      overtimeList: [],
      overtimeDetailList: [],
      popupList: [],
      isCopy: false,
      isReported: false,
      focusIndex: 0,
      chk: '0',
    };
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          ...this.state.data,
        }, false,
      );

      data && this.setState({
        data: new OvertimeModel({
          ...this.state.data,
          ...data,
        }, data.new),
      });

      await this.onRetrieveEvent();
    }
  }


  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          overtimeList: [...this.state.overtimeList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          overtimeList: [],
          overtimeDetailList: [],
        });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      overtimeList: [],
      overtimeDetailList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['overdate', 'overnum'],
        [lastSelected?.overdate, lastSelected?.overnum], type, true) || 0;
      this.state.overtimeList.length > index && this.grid.current?.setFocus(index);
    });
  }

  @action
  onRowFocusEvent(item: OvertimeModel, index: number) {
    if (this.state.isCopy) {
      this.setState({
        focusedOvertimeDetail: undefined,
        isCopy: false,
        isReported: false,
      });
      return;
    }
    if (item?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        focusedOvertimeDetail: undefined,
        overtimeDetailList: [],
        isReported: false,
        data: lastNewData,
      });
      this.table.current?.update(false);
      return;
    }

    const { actionStore: api } = this.props;
    this.updatedRows = [];

    this.setState(
      {
        focusedOvertime: item,
        focusIndex: index,
      },
      async () => {
        this.infinity2 = new InfinityRetrieve({
          overdate: this.state.focusedOvertime?.overdate,
          overnum: this.state.focusedOvertime?.overnum,
        },
        (params) => api.fxExec('dw_1_RowFocuschanged', params),
        (items) => {
          this.setState({
            overtimeDetailList: items.map((x: any) => new OvertimeDetailModel(x)),
          }, () => this.table.current?.update());

          // 결재상신 여부확인
          if (this.state.focusedOvertime?.appgubun === null
            || this.state.focusedOvertime?.appgubun === ''
            || this.state.focusedOvertime?.appgubun === '131') {
            this.setState({ isReported: false });
          } else {
            this.setState({ isReported: true });
          }
          this.table.current?.update(false);
        },
        async () => {
          await this.SS({ overtimeDetailList: [] });
          await this.infinity2?.retrieveAll();
          await this.table.current?.update();
          this.table.current?.setFocus(0, 0);
        });

        await this.infinity2?.retrieveAll();
        this.setState({ data: new OvertimeModel(this.infinity2.box) });

        if (!this.infinity2.count) {
          this.setState({ overtimeDetailList: [] }, () => this.table.current?.update());
        }
        await this.table.current?.update();
        this.table.current?.setFocus(0, 0);
      },
    );
  }

  @action
  onDetailRowFocusEvent(item:OvertimeDetailModel) {
    // if (item.new === '1') return;
    this.setState({ focusedOvertimeDetail: item });
  }

  @action
  async itemChanged(type: number, _?: any) {
    const { actionStore: api } = this.props;

    let data:any = { new: undefined, items: [] };

    const params = {
      itemname: OvertimeItemChangeTypeNames[type],
      data: this.state.data.title,
      new: this.state.data.new,
    };

    if (type === OvertimeItemChangeTypeNames.overdate) {
      data = await api.fxExec('dw_2_itemchanged', params);
    }

    data && this.setState({
      data: new OvertimeModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  @action
  async detailItemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;

    // eslint-disable-next-line max-len
    const focusedDetailIndex = this.state.overtimeDetailList.findIndex((x) => x.seq === item.seq);
    let data:any;
    const params = {
      itemname: OvertimeItemChangeTypeNames[type],
      data: '',
      new: item?.new,
    };

    if (type === OvertimeItemChangeTypeNames.stdate
      || type === OvertimeItemChangeTypeNames.enddate
      || type === OvertimeItemChangeTypeNames.sttime
      || type === OvertimeItemChangeTypeNames.endtime) {
      data = await api.fxExec('dw_3_itemchanged', {
        ...params,
        stdate: item?.stdate,
        enddate: item?.enddate,
        sttime: item?.sttime,
        endtime: item?.endtime,
      });

      // 디테일 테이블에서 수정되어야하는 로우를 찾아 값 변경
      data && this.setState({
        focusedOvertimeDetail: new OvertimeDetailModel(this.state.focusedOvertimeDetail, this.state.focusedOvertimeDetail?.isNew),
        overtimeDetailList: this.state.overtimeDetailList.map((x: any, index: number) => (
          index === focusedDetailIndex
            ? new OvertimeDetailModel({ ...x, ...data }, x.isNew)
            : new OvertimeDetailModel(x, x.isNew)
        )),
      }, () => this.table.current?.update(true));
    }

    if (type === OvertimeItemChangeTypeNames.perid) {
      data = await api.fxExec('dw_3_itemchanged', {
        ...params,
        perid: item?.overperid,
      });

      // 디테일 테이블에서 수정되어야하는 로우를 찾아 값 변경
      data && this.setState({
        focusedOvertimeDetail: new OvertimeDetailModel(this.state.focusedOvertimeDetail, this.state.focusedOvertimeDetail?.isNew),
        overtimeDetailList: this.state.overtimeDetailList.map((x: any, index: number) => (
          index === focusedDetailIndex
            ? new OvertimeDetailModel({ ...x, ...data, divinm: data?.overdivinm }, x.isNew)
            : new OvertimeDetailModel(x, x.isNew)
        )),
      }, () => this.table.current?.update(true));
    }
  }

  @action
  fileModal(isOpen: boolean) {
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedOvertime!, this.state.focusIndex); }
      });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(
      PAPERCD.OVERTIME,
      this.state.data?.appnum,
    )) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedOvertime!, this.state.focusIndex);
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.OVERTIME);
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.OVERTIME.toString(),
      appnum: this.state.data.appnum,
      appgubun: this.state.data.appgubun,
      overdate: this.state.data.overdate,
      overnum: this.state.data.overnum,
      title: this.state.data.title,
      perid: this.state.data.perid,
      refer: this.state.data.refer,
      appflag,
    });
    data && this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }
    if (this.state.overtimeDetailList.length < 1) {
      ConfirmWarning.show('경고', '시간외근무 내역이 없습니다.');
      return;
    }
    const { actionStore: api, publicStore } = this.props;
    if (await api.save({
      custcd: publicStore.user.custcd,
      spjangcd: publicStore.user.spjangcd,
      overdate: this.state.data.overdate,
      overnum: this.state.data.overnum,
      title: this.state.data.title,
      perid: this.state.data.perid,
      pernm: this.state.data.pernm,
      remark: this.state.data.remark,
      appdate: this.state.data.appdate,
      apptime: this.state.data.apptime,
      appgubun: this.state.data.appgubun,
      appperid: this.state.data.appperid,
      appremark: this.state.data.appremark,
      appnum: this.state.data.appnum,
      picflag: this.state.data.picflag,
      refer: this.state.data.refer,
      attcnt: this.state.data.attcnt,
      items: this.state.overtimeDetailList,
    }, this.state.data.new === '1')) {
      this.updatedRows = [];
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.overdate);
      await this.setState({
        chk: '1',
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new({});
    if (data) {
      const newModel = new OvertimeModel(data, true);

      this.setState({
        data: newModel,
        lastNewData: newModel,
        overtimeList: [
          newModel,
          ...this.state.overtimeList,
        ],
        overtimeDetailList: [],
        focusedOvertime: newModel,
        focusIndex: 0,
      }, async () => {
        await this.table.current?.update(true);
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    if (this.state.isReported && this.state.data.appgubun !== '001') {
      await api.fxExec('delete',
        {
          ...this.state.data,
        }, false) && this.onRetrieveEvent();
    } else {
      const text = `작성일자: ${this.state.focusedOvertime?.overdate}, 작성번호: ${this.state.focusedOvertime?.overnum}`;
      await api.delete(text, this.state.data) && await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }


  @action
  async onCreate() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_3_create',
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        sttime: this.state.focusedOvertimeDetail?.sttime,
        endtime: this.state.focusedOvertimeDetail?.endtime,
        remark: this.state.focusedOvertimeDetail?.remark,
        row: this.state.overtimeDetailList.length,
      },
    );

    data?.items && this.setState({
      // eslint-disable-next-line max-len
      overtimeDetailList: this.state.overtimeDetailList.map((x: any, index: number) => new OvertimeDetailModel({ ...x, ...data?.items[index] })),
    }, () => this.table.current?.update(false));
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_3_new',
      {
        seq: this.state.focusedOvertimeDetail?.seq,
        title: this.state.data.title,
        overdate: this.state.data.overdate,
        overnum: this.state.data.overnum,
      },
    );

    const seq = this.state.overtimeDetailList.length;
    data && this.setState({
      overtimeDetailList: [...this.state.overtimeDetailList,
        new OvertimeDetailModel({
          // eslint-disable-next-line no-nested-ternary
          ...data, seq: seq < 9 ? `00${seq + 1}` : seq < 99 ? `0${seq + 1}` : (seq + 1).toString(),
        }, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.overtimeDetailList.length - 1, 0);
    });
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    if (this.state.overtimeDetailList.length === 0) {
      ConfirmWarning.show('경고', '삭제할 내용이 없습니다.');
      return;
    }
    const text = `${this.state.focusedOvertimeDetail?.seq}`;
    await api.fxDelete(
      'dw_3_delete',
      text,
      {
        seq: this.state.focusedOvertimeDetail?.seq,
        title: this.state.data.title,
        overdate: this.state.data.overdate,
        overnum: this.state.data.overnum,
        appgubun: this.state.data.appgubun,
      },
    ) && this.onRowFocusEvent(this.state.focusedOvertime!, this.state.focusIndex);
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.focusedOvertime?.overnum, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      overdate: this.state.focusedOvertime?.overdate,
      overnum: this.state.focusedOvertime?.overnum,
      appgubun: this.state.focusedOvertime?.appgubun,
      net: '',
    });
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  /**
   * 행 변경 이벤트
   * @param rows
   * @param updatedRows
   */
  @action
  onUpdatedRows(rows: Array<OvertimeDetailModel>, updatedRows: Array<OvertimeDetailModel>) {
    this.updatedRows = updatedRows;
    this.setState({ overtimeDetailList: rows });
  }

  render() {
    return (
      <OvertimeTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new OvertimeModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
