import * as React from 'react';
import { action } from 'mobx';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { RegularBuyTemplate } from './RegularBuy.template';
import { RegularBuyModel } from './RegularBuy.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';
import Confirm from '../../../../utils/confirm/Confirm';

interface RegularBuyState {
  // search
  yearmon: string;
  acccds?: Array<any>;

  // data
  focusIndex: number;
  pushTotalCheck: boolean;
  data: Array<RegularBuyModel>;
  focused?: RegularBuyModel;

  // trail
  samt_tot: string;
  tamt_tot: string;
  mijamt_tot: string;
  total: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca640_all
 * @category 정기매입자료생성
 */
export class RegularBuy extends PageComponent<PageProps, RegularBuyState>
  implements PageToolEvents {
  updatedRows?: Array<RegularBuyModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      // search
      yearmon: Today.yearMon(),

      // data
      data: [],
      focusIndex: 0,

      // trail
      samt_tot: '0',
      tamt_tot: '0',
      mijamt_tot: '0',
      total: '0',
    };
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const json = JSON.parse(JSON.parse(message));

    if (json?.key === 'wb_miscreate_lazytask') {
      ConfirmWarning.show('확인', json?.response.messagebox);
      await this.onRetrieveEvent();
    }

    if (json?.key === 'delete_lazytask') {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 계정과목
    const data2 = await api.dropdown('wf_dd_ac001');
    if (!data2) return;
    this.setState({ acccds: data2.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new RegularBuyModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update();
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      pushTotalCheck: false,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['cltcd', 'mijnum'],
        [this.state.focused?.cltcd, this.state.focused?.mijnum], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.table.current?.update();
        this.table.current?.setFocus(index, 0);
        this.onRowFocusEvent(this.state.data[0], 0);
      }
    });
    this.SS({
      samt_tot: this.infinity?.box?.samt_tot || '0',
      tamt_tot: this.infinity?.box?.tamt_tot || '0',
      mijamt_tot: this.infinity?.box?.mijamt_tot || '0',
      total: this.infinity?.box?.total || '0',
    });
    await this.table.current?.update();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const chkData: RegularBuyModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new RegularBuyModel({
          chk: x.chk,
          cltcd: x.cltcd,
          mijdate: x.mijdate,
          mijnum: x.mijnum,
        }));
      }
    });

    if (chkData?.length === 0) {
      ConfirmWarning.show('경고', '삭제 선택된것이 없습니다.');
    } else {
      const warning = await Confirm.show('확인', '매입이 삭제됩니다. 그래도 삭제하시겠습니까?', ConfirmType.QUESTION);
      if (warning === false) return;

      await api.fxExec(
        'delete_lazytask',
        { items: chkData },
      );
      await this.onRetrieveEvent();
    }
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stmon: this.state.yearmon,
    });
  }

  @action
  async onBtnEvent() {
    ConfirmWarning.show('확인', '처리중입니다. 완료메시지가 뜰때까지 기다려주세요');
    const { actionStore: api } = this.props;
    api.fxExec('wb_mijcreate_lazytask', { stmon: this.state.yearmon });
  }

  @action
  onRowFocusEvent(item: RegularBuyModel, index: number) {
    this.setState({
      focused: item,
      focusIndex: index,
    });
  }

  @action
  onUpdatedRows(rows: Array<RegularBuyModel>, updatedRows: Array<RegularBuyModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.data?.forEach((x: any) => {
      checkData.push(new RegularBuyModel({
        ...x,
        chk: checked === true ? '1' : '0',
      }));
    });

    this.setState({
      pushTotalCheck: checked,
      data: checkData,
    }, async () => {
      this.table.current?.update(false);
    });
  }

  render() {
    return (
      <RegularBuyTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
