import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Format } from '../../../../../utils/string';
import { AccountModel } from '../models';
import { Receivable } from '../Receivable';

// 거래처별
const Account: GridLayoutHeader<AccountModel, Receivable>[] = [
  {
    id: 'cltcd',
    text: '거래처코드',
    width: 10,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '거래처명',
    width: 40,
    trail: (_, scope) => (
        <FlexLayout
          justify="left"
          align="center"
        >
          건수: {scope?.state.total}
        </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'beamt',
    text: '전일미수',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.beamt)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.beamt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '매출액',
    width: 10,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.misamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '입금액',
    width: 10,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.rcvamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.rcvamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'resuamt',
    text: '미수잔액',
    width: 10,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.resuamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.resuamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {x.remark}
      </FlexLayout>
    ),
  },
];

export default Account;
