import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e101_history
 * @category 계약기록
 */

export class RecordPopupModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly seq: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly hdate: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly title: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly wakeflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly wakedate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly okflag: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly r_color: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly gs_color: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.seq = data.seq || '';
    this.cltcd = data.cltcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.hdate = data.hdate || '';
    this.title = data.title || '';
    this.remark = data.remark || '';
    this.wakeflag = data.wakeflag || '';
    this.wakedate = data.wakedate || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.okflag = data.okflag || '';
    this.r_color = data.r_color || '';
    this.gs_color = data.gs_color || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
