import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { ElmanData } from './ElmanData';
import {
  Date8,
} from '../../../../utils/time';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_e050w_elman
 * @category 엘맨자료
 */
export const ElmanDataTemplate: React.FC<TemplateProps<ElmanData>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ enddate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={400}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="계정과목, 적요로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'date',
              text: '작성일자',
              width: 8,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.date)}
                </FlexLayout>
              ),
            },
            {
              id: 'num',
              text: '번호',
              width: 5,
            },
            {
              id: 'seq',
              text: '순번',
              width: 5,
            },
            {
              id: 'accnm',
              text: '계정과목',
              width: 8,
            },
            {
              id: 'remark',
              text: '적요',
              width: 52,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'iamt',
              text: '입금',
              width: 10,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.iamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'camt',
              text: '출금',
              width: 12,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.camt)}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
