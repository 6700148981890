import * as React from 'react';
import { action } from 'mobx';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { SelfCheckTemplate } from './SelfCheck.template';
import {
  PageProps, PageToolEvents, RetrieveFocusType,
} from '../../../../constants';
import SelfCheckModel from './SelfCheck.model';
import { ChargePopupModel } from '../../../electronic/preparation/Journal/models';
import { Today } from '../../../../utils/time';
import { GridLayoutAdditionalHeader } from '../../../../components';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

// eslint-disable-next-line no-shadow
export enum SelfCheckButtonClickedTypes {
  ADD,
  MINUS,
}

// eslint-disable-next-line no-shadow
export enum SelfCheckButtonClickedTypeNames{
  b_add,
  b_minus,
}

interface SelfCheckState {

  // 검색조건
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  divicd: string; // 부서
  divinm: string; // 부서명
  perid: string; // 담당
  pernm: string; // 담당자명
  wkactcd: string; // 회사구분
  wkactnm: string; // 회사구분명
  actcd: string; // 현장코드
  actnm: string; // 현장명
  equpcd: string; // 호기명
  equpnm: string; // 호기명
  gubun: string; // 결과
  contg: string; // 계약구분
  kcflag: string; //

  // 회사구분리스트

  wkactcds: Array<any>;

  // trail
  total: string;
  cnt_tot: string;
  comp_tot: string;
  jcomp_tot: string;
  plan_not_tot: string;
  rece_tot: string;
  remote_tot: string;
  success_tot: string;
  totcomp_tot: string;



  // 담당자 합계 모달
  isChargeModalVisible: boolean;
  popupChargeList: Array<ChargePopupModel>;

  // 담당자 합계 조회 조건
  popupStmon: string;

  isGraphVisible: boolean;

  // 데이터 객체
  focusedSelfCheck?: SelfCheckModel;
  SelfCheckList: Array<SelfCheckModel>;
  data: SelfCheckModel;

  // 테이블 포커싱 데이터
  focusedCharge?: ChargePopupModel;

}

/*
 컨트롤러
 * @window w_tb_e033w
 * @category 자체점검현황
*/

export class SelfCheck extends PageComponent<PageProps, SelfCheckState>
  implements PageToolEvents {
  updatedRows?: Array<SelfCheckModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  popupChargeGrid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  graph?: any;

  tabIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    // state 기본값 정의
    this.state = props.state || {
      popupStmon: Today.yearMon(),
      stdate: `${year}${month}01`, // 기간(시작)
      enddate: date === '30' ? `${year}${month}30` : `${year}${month}31`,
      papercd: '%', // 문서구분
      searchQuery: '',
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      divicd: bosuDivicd || '',
      divinm: bosuDivinm || '',
      wkactcd: '',
      actcd: '',
      actnm: '',
      contg: '1',
      gubun: '%',
      kcflag: '%',
      data: new SelfCheckModel(),
      SelfCheckList: [],
      remark: '',
      attcnt: 0,
      isAttcnt: false,
      total: '0',
      cnt_tot: '0',
      comp_tot: '0',
      jcomp_tot: '0',
      plan_not_tot: '0',
      rece_tot: '0',
      remote_tot: '0',
      success_tot: '0',
      totcomp_tot: '0',

      // 담당별 합계 팝업
      popupChargeList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 회사구분 리스트
    const data = await api.dropdown('wf_dd_e018_1');
    if (!data) return;
    this.setState({ wkactcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        divicd: this.state.divicd,
        perid: this.state.perid,
        wkactcd: this.state.wkactcd,
        actcd: this.state.actcd,
        equpcd: this.state.equpcd,
        gubun: this.state.gubun,
        contg: this.state.contg,
        kcflag: this.state.kcflag,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          SelfCheckList: [...this.state.SelfCheckList, ...items.map((x: any) => new SelfCheckModel(x))],
        }, next);
      },
      async () => {
        await this.SS({
          SelfCheckList: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.SelfCheckList.length) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      SelfCheckList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['rptdate', 'actnm'],
        [this.state.focusedSelfCheck?.rptdate, this.state.focusedSelfCheck?.actnm], type, true) || 0;
      this.setState({
        total: this.infinity?.box?.total || '0',
        cnt_tot: this.infinity?.box?.cnt_tot || '0',
        comp_tot: this.infinity?.box?.comp_tot || '0',
        jcomp_tot: this.infinity?.box?.jcomp_tot || '0',
        plan_not_tot: this.infinity?.box?.plan_not_tot || '0',
        rece_tot: this.infinity?.box?.rece_tot || '0',
        remote_tot: this.infinity?.box?.remote_tot || '0',
        success_tot: this.infinity?.box?.success_tot || '0',
        totcomp_tot: this.infinity?.box?.totcomp_tot || '0',
      });
      if (this.state.SelfCheckList.length) {
        this.grid.current?.setFocus(index);
      }
    });
  }

  // @action
  // async onRetrieveEvent() {
  //   const { actionStore: api } = this.props;
  //   this.updatedRows = [];
  //
  //   const apiParams = {
  //     stdate: this.state.stdate,
  //     enddate: this.state.enddate,
  //     divicd: this.state.divicd,
  //     perid: this.state.perid,
  //     wkactcd: this.state.wkactcd,
  //     actcd: this.state.actcd,
  //     equpcd: this.state.equpcd,
  //     gubun: this.state.gubun,
  //     contg: this.state.contg,
  //     kcflag: this.state.kcflag,
  //   };
  //
  //   const data = await api.retrieve(apiParams);
  //
  //   // 무한 스크롤바 헬퍼 초기화
  //   this.infinity = new InfinityRetrieve(
  //     apiParams,
  //     (params) => api.retrieve(params),
  //     () => {
  //       this.setState({
  //         data: new SelfCheckModel(data),
  //         SelfCheckList: data?.items,
  //       });
  //     },
  //   );
  //
  //   // 상단 조회 버튼을 누를때는 기존 배열 초기화
  //   this.setState({
  //     SelfCheckList: [],
  //   }, async () => {
  //     const data = await this.infinity?.retrieve();
  //     this.setState({
  //       cnt_tot: data?.cnt_tot || '0',
  //       comp_tot: data?.cnt_tot || '0',
  //       jcomp_tot: data?.cnt_tot || '0',
  //       plan_not_tot: data?.cnt_tot || '0',
  //       rece_tot: data?.cnt_tot || '0',
  //       remote_tot: data?.cnt_tot || '0',
  //       success_tot: data?.cnt_tot || '0',
  //       totcomp_tot: data?.cnt_tot || '0',
  //     });
  //     await this.grid.current?.setFocus(0);
  //   });
  // }

  @action
  onRowFocusEvent(item: SelfCheckModel) {
    this.setState({ focusedSelfCheck: item });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.SelfCheckList.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      actcd: this.state.actcd,
      equpcd: this.state.equpcd,
      divicd: this.state.divicd,
      perid: this.state.perid,
      gubun: this.state.gubun,
      contg: this.state.contg,
      wkactcd: this.state.wkactcd,
      kcflag: this.state.kcflag,
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.SelfCheckList.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      actcd: this.state.actcd,
      equpcd: this.state.equpcd,
      divicd: this.state.divicd,
      perid: this.state.perid,
      gubun: this.state.gubun,
      contg: this.state.contg,
      wkactcd: this.state.wkactcd,
      kcflag: this.state.kcflag,
    });
  }

  @action
  async onButtonClicked(type:any, item:any) {
    const { actionStore: api } = this.props;
    let data = {
      new: undefined,
      items: [],
    };
    const params = {
      sub: 'w_popup_e033w',
      itemname: SelfCheckButtonClickedTypeNames[type],
      data: '',
    };

    if (type === SelfCheckButtonClickedTypes.ADD) {
      data = await api.fxExec(
        'dw_list_buttonclikced',
        {
          ...params,
          divicd: item.divicd,
          stmon: this.state.popupStmon,
        },
      );

      if (data?.items) {
        const focusedIndex = this.state.popupChargeList.findIndex((x) => x.perid === item.perid);

        this.setState({
          focusedCharge: new ChargePopupModel(this.state.focusedCharge, true),
          popupChargeList: [
            ...this.state.popupChargeList.slice(0, focusedIndex),
            new ChargePopupModel(this.state.focusedCharge, true),
            ...data?.items.map((x: any) => new ChargePopupModel(x)),
            ...this.state.popupChargeList.slice(focusedIndex + 1),
          ],
        });
      }
    }

    if (type === SelfCheckButtonClickedTypes.MINUS) {
      // 버튼을 누른 행의 인덱스 추출
      const focusedIndex:number = this.state.popupChargeList.findIndex((x) => x.perid === item.perid);

      // 버튼을 누른 행 다음에 있는 첫번째 부서장 모델 추출
      const model = this.state.popupChargeList.slice(focusedIndex + 1).find((x) => x.chk === '0');

      // 버튼을 누른 행의 다음 부서장 인덱스 추출
      const deleteIndex = this.state.popupChargeList.findIndex((x) => x.divicd === model?.divicd);
      if (deleteIndex > -1) {
        this.setState({
          focusedCharge: new ChargePopupModel(this.state.focusedCharge, false),
          popupChargeList: [
            ...this.state.popupChargeList.slice(0, focusedIndex),
            new ChargePopupModel(this.state.focusedCharge, false),
            ...this.state.popupChargeList.slice(deleteIndex),
          ],
        });
      } else {
        this.setState({
          focusedCharge: new ChargePopupModel(this.state.focusedCharge, false),
          popupChargeList: [
            ...this.state.popupChargeList.slice(0, focusedIndex),
            new ChargePopupModel(this.state.focusedCharge, false),
          ],
        });
      }
    }
  }

  @action
  onChargeRowFocusEvent(item: ChargePopupModel) {
    this.setState({ focusedCharge: item });
  }

  @action
  async openChargeModal() {
    this.setState({
      isChargeModalVisible: true,
    }, () => this.modalChargeRetrieve());
  }

  @action
  async closeChargeModal() {
    this.setState({
      popupChargeList: [],
      isGraphVisible: false,
      isChargeModalVisible: false,
    });
  }

  @action
  async modalChargeRetrieve() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    this.graph = `https://api.elmansoft.com/chart/stick2.php?database=weberp&window=w_popup_e033w&type=0&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_mon=${this.state.popupStmon}`;

    const data = await api.retrieve(
      {
        sub: 'w_popup_e033w',
        stmon: this.state.popupStmon,
      },
    );

    if (data?.items) {
      this.setState({
        isGraphVisible: true,
        popupChargeList: data.items?.map((x: any) => new ChargePopupModel({ open: '0', ...x })),
      });
    } else {
      this.setState({
        isGraphVisible: true,
        popupChargeList: [],
      });
    }
  }

  @action
  async modalChargePrint() {
    const { actionStore: api } = this.props;

    await api.printWithElmanManager(
      {
        sub: 'w_popup_e033w',
        stmon: this.state.popupStmon,
      },
    );
  }

  @action
  onUpdatedRows(rows: Array<SelfCheckModel>, updatedRows: Array<SelfCheckModel>) {
    this.updatedRows = updatedRows;
    this.setState({ SelfCheckList: rows });
  }

  @action
  async onTabChange(index: number) {
    this.tabIndex = index;
    await this.onRetrieveEvent();
  }

  render() {
    return (
      <SelfCheckTemplate
        scope={this}
      />
    );
  }
}
