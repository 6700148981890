import { computed } from 'mobx';

/**
 * 현장담당자별 탭 모델
 * @window w_tb_da034w_01
 * @category 불출현황
 */
export class ActModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly deldate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly delnum: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly delseq: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly pcode: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly pname: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly psize: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly punit: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly pserial: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly qty: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly balqty: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly uamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly samt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly tamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly mamt: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly bigo: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly mijflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly balnum: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly balseq: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly qcdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly qcnum: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly qcseq: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly projno: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly projnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly cltnm: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly actperid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly actpernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly actdivicd: string;

  /**
   *
   *
   * **data type** varchar(40)
   */
  readonly actdivinm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly equpcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;


  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly remark: string;


  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly myn: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.deldate = data.deldate || '';
    this.remark = data.remark || '';
    this.delnum = data.delnum || '';
    this.delseq = data.delseq || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.pserial = data.pserial || '';
    this.qty = data.qty || '';
    this.balqty = data.balqty || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.bigo = data.bigo || '';
    this.mijflag = data.mijflag || '';
    this.baldate = data.baldate || '';
    this.balnum = data.balnum || '';
    this.balseq = data.balseq || '';
    this.qcdate = data.qcdate || '';
    this.qcnum = data.qcnum || '';
    this.qcseq = data.qcseq || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.pernm = data.pernm || '';
    this.actperid = data.actperid || '';
    this.actpernm = data.actpernm || '';
    this.actdivicd = data.actdivicd || '';
    this.actdivinm = data.actdivinm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.myn = data.myn || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
