import { IoIosMail } from 'react-icons/all';
import { GLHF } from '../../../../../constants';
import { Badge, FlexLayout, FormatTextBox } from '../../../../../components';
import { Result } from '../Result';
import ResultModel from '../models/ResultModel';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';


export const Appgubuns = [
  { value: '%', remark: '전체', color: 'inherit' },
  { value: '101', remark: '결재', color: 'var(--color-blue)' },
  { value: '121', remark: '참조', color: 'inherit' },
  { value: '001', remark: '대기', color: 'var(--color-blue)' },
  { value: '131', remark: '반려', color: 'var(--color-red)' },
  { value: '111', remark: '결재중', color: 'inherit' },
  { value: '301', remark: '전결', color: 'var(--color-blue)' },
  { value: '401', remark: '대결', color: 'var(--color-blue)' },
];


/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e471w_02
 * @category 공사결과보고서
 */
export const ResultGridHeader: GLHF<ResultModel, Result> = (scope) => ([
  {
    id: 'perid',
    text: '주공사자',
    group: true,
    isHidden: true,
  },
  {
    id: 'appgubun',
    text: '결재',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{
          color: Appgubuns.filter((y) => y.value === x.appgubun)[0]?.color,
        }}
      >
        {Appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
        {x?.appremark && <Badge>
          <IoIosMail color="var(--color-red)" size={25} />
        </Badge>}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvflag',
    text: '수금',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={x.rcvflag === '0' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
      >
        {x.rcvflag === '0' ? '미수금' : '수금'}
      </FlexLayout>
    ),
  },
  {
    id: 'projno',
    text: '코드',
    width: 100,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'projnm',
    text: '프로젝트명',
    width: 300,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.projnm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {scope.state.total} 건
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope.state.gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvdate',
    text: '수금일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.rcvdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'ordamt',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          수주(계약금액)
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          수금금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="end"
          style={{
            color: 'var(--color-green)',
            paddingRight: 8,
          }}
        >
          {Format.number(x.ordamt, true)}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="end"
          style={{
            paddingRight: 8,
          }}
        >
          {Format.number(x.rcvamt, true)}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="end"
          style={{
            color: 'var(--color-green)',
            paddingRight: 8,
          }}
        >
          {Format.number(Sum.byGroup(data, 'ordamt', x, ['perid']), true)}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="end"
          style={{
            paddingRight: 8,
          }}
        >
          {Format.number(Sum.byGroup(data, 'rcvamt', x, ['perid']), true)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="end"
          style={{
            color: 'var(--color-green)',
            paddingRight: 8,
          }}
        >
          {Format.number(scope.state.ordamt_tot || '', true)}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="end"
          style={{
            paddingRight: 8,
          }}
        >
          {Format.number(scope.state.rcvamt_tot || '', true)}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt',
    text: '매입금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={{
          color: 'var(--color-red)',
          paddingRight: 8,
        }}
      >
        {Format.number(x.mijamt, true)}
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout
        align="center"
        justify="end"
        style={{
          color: 'var(--color-red)',
          paddingRight: 8,
        }}
      >
        {Format.number(Sum.byGroup(data, 'mijamt', x, ['perid']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
        style={{
          color: 'var(--color-red)',
          paddingRight: 8,
        }}
      >
        {Format.number(scope.state.mijamt_tot || '', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt',
    text: '이익금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.iamt, true)}
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(data, 'iamt', x, ['perid']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.iamt_tot || '', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'comperyul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          주공사자
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          적용율
        </FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          {x.pernm}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          {x.comperyul && x.comperyul !== '0' ? `${x.comperyul}%` : ''}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  // {
  //   id: 'useamt',
  //   text: (
  //     <FlexLayout isVertical={true}>
  //       <FlexLayout
  //         align="end"
  //         justify="center"
  //       >
  //         주공사자
  //       </FlexLayout>
  //
  //       <FlexLayout
  //         align="start"
  //         justify="center"
  //       >
  //         적용금액
  //       </FlexLayout>
  //     </FlexLayout>
  //   ),
  //   width: 100,
  //   render: (x) => (
  //     <FlexLayout
  //       align="center"
  //       justify="end"
  //       style={{
  //         color: 'var(--color-blue)',
  //         paddingRight: 8,
  //       }}
  //     >
  //       {Format.number(x.useamt, true)}
  //     </FlexLayout>
  //   ),
  //   sum: (x, data) => (
  //     <FlexLayout
  //       align="center"
  //       justify="end"
  //       style={{
  //         color: 'var(--color-blue)',
  //         paddingRight: 8,
  //       }}
  //     >
  //       {Format.number(Sum.byGroup(data, 'useamt', x, ['perid']), true)}
  //     </FlexLayout>
  //   ),
  //   trail: () => (
  //     <FlexLayout
  //       align="center"
  //       justify="end"
  //       style={{
  //         color: 'var(--color-blue)',
  //         paddingRight: 8,
  //       }}
  //     >
  //       {Format.number(scope.state.useamt_tot || '', true)}
  //     </FlexLayout>
  //   ),
  // },
  {
    id: 'comperyul2',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          부공사자2
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          적용율
        </FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          {x.pernm2}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          {x.comperyul2 && x.comperyul2 !== '0' ? `${x.comperyul2}%` : ''}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  // {
  //   id: 'useamt2',
  //   text: (
  //     <FlexLayout isVertical={true}>
  //       <FlexLayout
  //         align="end"
  //         justify="center"
  //       >
  //         부공사자3
  //       </FlexLayout>
  //
  //       <FlexLayout
  //         align="start"
  //         justify="center"
  //       >
  //         적용금액
  //       </FlexLayout>
  //     </FlexLayout>
  //   ),
  //   width: 100,
  //   render: (x) => (
  //     <FlexLayout
  //       align="center"
  //       justify="end"
  //       style={{
  //         color: 'var(--color-blue)',
  //         paddingRight: 8,
  //       }}
  //     >
  //       {Format.number(x.useamt2, true)}
  //     </FlexLayout>
  //   ),
  //   sum: (x, data) => (
  //     <FlexLayout
  //       align="center"
  //       justify="end"
  //       style={{
  //         color: 'var(--color-blue)',
  //         paddingRight: 8,
  //       }}
  //     >
  //       {Format.number(Sum.byGroup(data, 'useamt2', x, ['perid']), true)}
  //     </FlexLayout>
  //   ),
  //   trail: () => (
  //     <FlexLayout
  //       align="center"
  //       justify="end"
  //       style={{
  //         color: 'var(--color-blue)',
  //         paddingRight: 8,
  //       }}
  //     >
  //       {Format.number(scope.state.useamt2_tot || '', true)}
  //     </FlexLayout>
  //   ),
  // },
  {
    id: 'comperyul3',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          부공사자3
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          적용율
        </FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          {x.pernm3}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          {x.comperyul3 && x.comperyul3 !== '0' ? `${x.comperyul3}%` : ''}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  // {
  //   id: 'useamt3',
  //   text: (
  //     <FlexLayout isVertical={true}>
  //       <FlexLayout
  //         align="end"
  //         justify="center"
  //       >
  //         부공사자3
  //       </FlexLayout>
  //
  //       <FlexLayout
  //         align="start"
  //         justify="center"
  //       >
  //         적용금액
  //       </FlexLayout>
  //     </FlexLayout>
  //   ),
  //   width: 100,
  //   render: (x) => (
  //     <FlexLayout
  //       align="center"
  //       justify="end"
  //       style={{
  //         color: 'var(--color-blue)',
  //         paddingRight: 8,
  //       }}
  //     >
  //       {Format.number(x.useamt3, true)}
  //     </FlexLayout>
  //   ),
  //   sum: (x, data) => (
  //     <FlexLayout
  //       align="center"
  //       justify="end"
  //       style={{
  //         color: 'var(--color-blue)',
  //         paddingRight: 8,
  //       }}
  //     >
  //       {Format.number(Sum.byGroup(data, 'useamt3', x, ['perid']), true)}
  //     </FlexLayout>
  //   ),
  //   trail: () => (
  //     <FlexLayout
  //       align="center"
  //       justify="end"
  //       style={{
  //         color: 'var(--color-blue)',
  //         paddingRight: 8,
  //       }}
  //     >
  //       {Format.number(scope.state.useamt3_tot || '', true)}
  //     </FlexLayout>
  //   ),
  // },
  {
    id: 'appnum',
    text: '문서번호',
    width: 120,
    render: (x) => (
      <FormatTextBox
        format="######-###-####"
        textAlign="center"
        value={x.appnum}
        readonly={true}
        transparent={true}
      />
    ),
  },
  {
    id: 'appdate',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          결재일자
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
        >
          최종승인일자
        </FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Date8.withDash(x.appdate)}
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Date8.withDash(x.appdate_max)}
        </FlexLayout>
      </FlexLayout>
    ),
  },

  {
    id: 'misdate',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          매출일자
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
        >
          수금일자
        </FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Date8.withDash(x.misdate)}
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Date8.withDash(x.rcvdate)}
        </FlexLayout>
      </FlexLayout>
    ),
  },
]);
