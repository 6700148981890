import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Format } from '../../../../../utils/string';
import { FreeModel } from '../models';
import { MonthWork } from '../MonthWork';
import { Date8 } from '../../../../../utils/time';
import { Sum } from '../../../../../utils/array';

const contgs = [
  { value: '01', remark: '신규' },
  { value: '02', remark: '연장' },
  { value: '03', remark: '재계약' },
  { value: '04', remark: '해지' },
  { value: '05', remark: '회수' },
  { value: '06', remark: '타사' },
];

const contgubuns = [
  { value: '01', remark: 'FM계약' },
  { value: '02', remark: '다년계약' },
  { value: '03', remark: '일반계약' },
  { value: '04', remark: '상주' },
  { value: '05', remark: '외주' },
];

const mgubuns = [
  { value: '0', remark: '무상' },
  { value: '1', remark: '유상' },
];

// 무상관리현장
const Free: GridLayoutHeader<FreeModel, MonthWork>[] = [
  {
    id: 'divinm',
    text: '부서별그룹',
    isHidden: true,
    group: true,
    value: (x) => x.divinm,
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 5,
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#8106aa' }}
      >
        {x.divinm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 5,
    sum: (x, data) => {
      const sumPernm = Sum.groupLength(data, 'pernm', x, ['divinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumPernm)} 건
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.total}건
      </FlexLayout>
    ),
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 5,
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 17,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '대수',
    width: 5,
    sum: (x, data) => {
      const sumQty = Sum.byGroup(data, 'qty', x, ['divinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumQty)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.qty_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'senddate',
    text: '설치->보수일',
    width: 5,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.senddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'stdate',
    text: '시작일',
    width: 5,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.stdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'enddate',
    text: '종료일',
    width: 5,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.enddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'amt',
    text: '보수료',
    width: 5,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.amt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'contg',
    text: '계약분류',
    width: 5,
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
      >
        {contgs.filter((y) => y.value === x.contg)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'contgubun',
    text: '계약구분',
    width: 5,
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
      >
        {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'mgubun',
    text: '유무상',
    width: 5,
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
      >
        {mgubuns.filter((y) => y.value === x.mgubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'actpernm',
    text: '현장담당자',
    width: 5,
  },
  {
    id: 'hp',
    text: '담당자전화번호',
    width: 5,
  },
  {
    id: 'address',
    text: '주소',
    width: 18,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.address}
      </FlexLayout>
    ),
  },
];

export default Free;
