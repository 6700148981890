import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox,
  FormatTextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { InspectionType } from './InspectionType';


/**
 * 화면
 * @window w_tb_e030_new
 * @category 점검기종등록
 */
export const InspectionTypeTemplate: React.FC<TemplateProps<InspectionType>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        { id: 'evcd', text: '종류코드', width: 60 },
        {
          id: 'evnm',
          text: '종류명',
          width: 100,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.evnm}
              onChange={(value) => rowUpdate({ evnm: value })}
            />
          ),
        },
        {
          id: 'evdate',
          text: '시행일자',
          width: 60,
          render: (x, rowUpdate) => (
            <FormatTextBox
              format="####-##-##"
              textAlign="center"
              value={x.evdate}
              onChange={(value) => rowUpdate({ evdate: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'CHK_NUM',
          text: '정보센터형식코드',
          width: 80,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="left"
              value={x.CHK_NUM}
              onChange={(value) => rowUpdate({ CHK_NUM: value })}
            />
          ),
        },
        {
          id: 'SEL_CHK_ITEM_CD',
          text: '정보센터',
          width: 100,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="left"
              value={x.SEL_CHK_ITEM_CD}
              onChange={(value) => rowUpdate({ SEL_CHK_ITEM_CD: value })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 100,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="left"
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      data={scope.state.data}
    />
  </FlexLayout>
);
