import * as React from 'react';
import {
  FlexLayout, TableLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Report } from '../Report';
import { ReportDetailTableHeader } from '../headers/ReportDetailTableHeader';

/**
 * 화면 > 탭 A
 * @window w_tb_b035
 * @category 샘플페이지
 */
export const ReportTabTemplate: React.FC<TemplateProps<Report>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <TableLayout
        ref={scope.table}
        header={ReportDetailTableHeader(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.dataDetail}
        onChange={(rows, updates) => scope.onChangedDetailTable(rows, updates)}
        onRowFocusEvent={(item, index) => scope.onFocusedDetailTableRow(item, index)}
      />
    </FlexLayout>
  );
};
