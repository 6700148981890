/**
 * 모델
 * @window w_tb_e035_gosi
 * @category 전자점검연동내역
 */
import { computed } from 'mobx';

export class DailyChartModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 출력
   *
   * **data type** varchar(10)
   */
  chk: string;

  /**
   * 호기코드
   *
   * **data type** varchar(10)
   */
  equpcd: string;

  /**
   * 전송결과
   *
   * **data type** varchar(13)
   */
  result_code: string;

  /**
   * 회사
   *
   * **data type** varchar(13)
   */
  wkactcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 호기명
   *
   * **data type** varchar(2)
   */
  equpnm: string;

  /**
   * 승강기번호
   *
   * **data type** varchar(10)
   */
  elno: string;

  /**
   * 점검자
   *
   * **data type** float
   */
  kcpernm: string;

  /**
   * 계획일자
   *
   * **data type** varchar(20)
   */
  plandate: string;

  /**
   * 점검일자
   *
   * **data type** varchar(20)
   */
  rptdate: string;

  /**
   * 전송일자
   *
   * **data type** char(1)
   */
  senddate: string;

  /**
   * 전송시간
   *
   * **data type** varchar(50)
   */
  sendtime: string;

  /**
   * 차이일
   *
   * **data type** varchar(6)
   */
  gigan: string;

  /**
   * 기종
   *
   * **data type** varchar(6)
   */
  evnm: string;

  /**
   * 전송실패내용
   *
   * **data type** varchar(?)
   */
  error_message: string;

  /**
   * 하단 총계 데이터
   *
   * **data type** varchar(?)
   */
  equp_cnttot: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday01: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday02: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday03: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday04: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday05: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday06: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday07: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday08: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday09: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday10: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday11: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday12: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday13: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday14: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday15: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday16: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday17: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday18: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday19: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday20: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday21: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday22: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday23: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday24: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday25: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday26: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday27: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday28: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday29: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday30: string;

  /**
   * 요일이름
   *
   * **data type** varchar(?)
   */
  weekday31: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm01: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm02: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm03: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm04: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm05: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm06: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm07: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm08: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm09: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm10: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm11: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm12: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm13: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm14: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm15: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm16: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm17: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm18: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm19: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm20: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm21: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm22: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm23: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm24: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm25: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm26: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm27: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm28: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm29: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm30: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  pernm31: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime01: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime02: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime03: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime04: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime05: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime06: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime07: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime08: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime09: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime10: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime11: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime12: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime13: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime14: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime15: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime16: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime17: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime18: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime19: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime20: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime21: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime22: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime23: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime24: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime25: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime26: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime27: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime28: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime29: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime30: string;

  /**
   * 사람이름
   *
   * **data type** varchar(?)
   */
  sttime31: string;

















  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.chk = data.chk || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.result_code = data.result_code || '';
    this.wkactcd = data.wkactcd || '';
    this.actnm = data.actnm || '';
    this.equpnm = data.equpnm || '';
    this.elno = data.elno || '';
    this.kcpernm = data.kcpernm || '';
    this.plandate = data.plandate || '';
    this.rptdate = data.rptdate || '';
    this.senddate = data.senddate || '';
    this.sendtime = data.sendtime || '';
    this.gigan = data.gigan || '';
    this.evnm = data.evnm || '';
    this.error_message = data.error_message || '';
    this.equp_cnttot = data.equp_cnttot || '';
    this.weekday01 = data.weekday01 || '';
    this.weekday02 = data.weekday02 || '';
    this.weekday03 = data.weekday03 || '';
    this.weekday04 = data.weekday04 || '';
    this.weekday05 = data.weekday05 || '';
    this.weekday06 = data.weekday06 || '';
    this.weekday07 = data.weekday07 || '';
    this.weekday08 = data.weekday08 || '';
    this.weekday09 = data.weekday09 || '';
    this.weekday10 = data.weekday10 || '';
    this.weekday11 = data.weekday11 || '';
    this.weekday12 = data.weekday12 || '';
    this.weekday13 = data.weekday13 || '';
    this.weekday14 = data.weekday14 || '';
    this.weekday15 = data.weekday15 || '';
    this.weekday16 = data.weekday16 || '';
    this.weekday17 = data.weekday17 || '';
    this.weekday18 = data.weekday18 || '';
    this.weekday19 = data.weekday19 || '';
    this.weekday20 = data.weekday20 || '';
    this.weekday21 = data.weekday21 || '';
    this.weekday22 = data.weekday22 || '';
    this.weekday23 = data.weekday23 || '';
    this.weekday24 = data.weekday24 || '';
    this.weekday25 = data.weekday25 || '';
    this.weekday26 = data.weekday26 || '';
    this.weekday27 = data.weekday27 || '';
    this.weekday28 = data.weekday28 || '';
    this.weekday29 = data.weekday29 || '';
    this.weekday30 = data.weekday30 || '';
    this.weekday31 = data.weekday31 || '';
    this.pernm01 = data.pernm01 || '';
    this.pernm02 = data.pernm02 || '';
    this.pernm03 = data.pernm03 || '';
    this.pernm04 = data.pernm04 || '';
    this.pernm05 = data.pernm05 || '';
    this.pernm06 = data.pernm06 || '';
    this.pernm07 = data.pernm07 || '';
    this.pernm08 = data.pernm08 || '';
    this.pernm09 = data.pernm09 || '';
    this.pernm10 = data.pernm10 || '';
    this.pernm11 = data.pernm11 || '';
    this.pernm12 = data.pernm12 || '';
    this.pernm13 = data.pernm13 || '';
    this.pernm14 = data.pernm14 || '';
    this.pernm15 = data.pernm15 || '';
    this.pernm16 = data.pernm16 || '';
    this.pernm17 = data.pernm17 || '';
    this.pernm18 = data.pernm18 || '';
    this.pernm19 = data.pernm19 || '';
    this.pernm20 = data.pernm20 || '';
    this.pernm21 = data.pernm21 || '';
    this.pernm22 = data.pernm22 || '';
    this.pernm23 = data.pernm23 || '';
    this.pernm24 = data.pernm24 || '';
    this.pernm25 = data.pernm25 || '';
    this.pernm26 = data.pernm26 || '';
    this.pernm27 = data.pernm27 || '';
    this.pernm28 = data.pernm28 || '';
    this.pernm29 = data.pernm29 || '';
    this.pernm30 = data.pernm30 || '';
    this.pernm31 = data.pernm31 || '';
    this.sttime01 = data.sttime01 || '';
    this.sttime02 = data.sttime02 || '';
    this.sttime03 = data.sttime03 || '';
    this.sttime04 = data.sttime04 || '';
    this.sttime05 = data.sttime05 || '';
    this.sttime06 = data.sttime06 || '';
    this.sttime07 = data.sttime07 || '';
    this.sttime08 = data.sttime08 || '';
    this.sttime09 = data.sttime09 || '';
    this.sttime10 = data.sttime10 || '';
    this.sttime11 = data.sttime11 || '';
    this.sttime12 = data.sttime12 || '';
    this.sttime13 = data.sttime13 || '';
    this.sttime14 = data.sttime14 || '';
    this.sttime15 = data.sttime15 || '';
    this.sttime16 = data.sttime16 || '';
    this.sttime17 = data.sttime17 || '';
    this.sttime18 = data.sttime18 || '';
    this.sttime19 = data.sttime19 || '';
    this.sttime20 = data.sttime20 || '';
    this.sttime21 = data.sttime21 || '';
    this.sttime22 = data.sttime22 || '';
    this.sttime23 = data.sttime23 || '';
    this.sttime24 = data.sttime24 || '';
    this.sttime25 = data.sttime25 || '';
    this.sttime26 = data.sttime26 || '';
    this.sttime27 = data.sttime27 || '';
    this.sttime28 = data.sttime28 || '';
    this.sttime29 = data.sttime29 || '';
    this.sttime30 = data.sttime30 || '';
    this.sttime31 = data.sttime31 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
