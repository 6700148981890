import * as React from 'react';
import mStyle from './SubHeader.module.scss';
import { FlexLayout } from '../FlexLayout';
import { Global } from '../../../constants';

export class SubHeader extends React.Component {
  render() {
    const {
      children,
    } = this.props;

    return (
      <FlexLayout
        className={mStyle.container}
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        {children}
      </FlexLayout>
    );
  }
}
