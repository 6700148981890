import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Collect } from '../Collect';
import { CollectTabCollectGridHeader } from '../headers/CollectTabCollectGridHeader';

/**
 * 화면 > 탭 > 수금내역
 * @window w_tb_e601w_sulchi_02
 * @category 설치수금현황
 */
export const CollectTabCollectTemplate: React.FC<TemplateProps<Collect>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      header={CollectTabCollectGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataCollect}
    />
  </FlexLayout>
);
