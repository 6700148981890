import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { TextBox } from '../../components/forms/TextBox';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { SearchLayout } from '../../components/layout/SearchLayout';
import { GridLayout } from '../../components/layout/GridLayout';
import { LabelBox } from '../../components/forms/LabelBox';
import style from '../AuthorizedScreen.module.scss';
import { Button } from '../../components/forms/Button';
import { ConfirmWarning } from '../../utils/confirm';

interface ModalBoilerPlateProps {
  modalStore: ModalStore;
}

@observer
export class ModalBoilerplate extends React.Component<ModalBoilerPlateProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={800}
        height={600}
        zIndex={1}
        isVisible={modalStore.isVisibleBoilerplate}
        onClose={() => modalStore.closeBoilerplate()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <LayoutTitle>자주쓰는 문구</LayoutTitle>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={modalStore.boilerplateSearchQuery}
                onChange={(value) => {
                  modalStore.boilerplateSearchQuery = value;
                }}
                onEnter={() => modalStore.searchBoilerplate()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          </SearchLayout>

          <FlexLayout>
            <FlexLayout>
              <GridLayout
                ref={modalStore.boilerplateGrid}
                header={[
                  {
                    id: 'seq',
                    text: '순번',
                    width: 30,
                  },
                  {
                    id: 'title',
                    text: '문구 제목',
                    width: 70,
                    render: (x) => (
                      <FlexLayout justify="start" align="center">
                        {x.title}
                      </FlexLayout>
                    ),
                  },
                ]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                data={modalStore.boilerplateData}
                onRowClick={(item) => {
                  modalStore.boilerplateSelected = item;
                }}
                onRowDoubleClick={(item) => {
                  modalStore.closeBoilerplate(item);
                }}
              />
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox title="순번">
                  <TextBox value={modalStore.boilerplateSelected?.seq || ''} />
                </LabelBox>
                <LabelBox title="문구 제목">
                  <TextBox value={modalStore.boilerplateSelected?.title || ''} />
                </LabelBox>
              </FlexLayout>
              <LabelBox title="문구 내용">
                <TextBox
                  value={modalStore.boilerplateSelected?.remark || ''}
                  isMultiline={true}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnDanger}
              isFull={true}
              onClick={() => modalStore.closeBoilerplate()}
            >
              취소
            </Button>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => {
                if (!modalStore.boilerplateSelected) {
                  ConfirmWarning.show('경고', '행을 선택해주세요!');
                  return;
                }

                modalStore.closeBoilerplate(modalStore.boilerplateSelected);
              }}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
