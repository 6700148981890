import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdPlace } from 'react-icons/md';
import {
  FlexLayout,
  SearchLayout,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  TextBox,
  TableLayout,
  FormatTextBox,
  CheckBox,
  Button,
  ModalView,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Day } from './Day';
import { Date6Calculator, Date8 } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import style from './Day.module.scss';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_pb201
 * @category 일근태등록
 */

export const DayTemplate: React.FC<TemplateProps<Day>> = ({
  scope,
  update,
}) => {
  const setData2 = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={250}>
            <span>월</span>
            <DateTextBox
              value={scope.state.mon}
              textAlign="center"
              format="####년 ##월"
              onChange={(value) => setData2({ mon: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  // eslint-disable-next-line max-len
                  setData2({ mon: new Date6Calculator(scope.state.mon).add(-1).toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  // eslint-disable-next-line max-len
                  setData2({ mon: new Date6Calculator(scope.state.mon).add().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span>직영구분</span>
            <ComboBox
              value={scope.state.mpclafi}
              style={{
                padding: '0 0 0 6px',
                fontSize: 12,
              }}
              data={[
                { value: '%', remark: '전체' },
                { value: '0', remark: '직영' },
                { value: '1', remark: '상주' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => setData2({ mpclafi: option.value })}
            />
          </FlexLayout>
          <FlexLayout
            justify="left"
            size={250}
          >
            <span style={{ color: 'var(--color-red)' }}>부서</span>
              <TextBox
                value={scope.state.divicd}
                textAlign="center"
                onChange={(value) => setData2({ divicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData2({
                      divicd: item.cd,
                      divinm: item.cdnm,
                      searchQuery: item.cd,
                    });
                    scope.onRetrieveEvent();
                  },
                )}
              />
              <TextBox
                value={scope.state.divinm}
                textAlign="center"
                onChange={(value) => setData2({ divinm: value })}
                readonly={true}
              />
          </FlexLayout>
          <FlexLayout
            justify="left"
            size={250}
          >
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => setData2({ perid: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData2({
                    perid: item.cd,
                    pernm: item.cdnm,
                    searchQuery: item.cd,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.pernm}
              textAlign="center"
              onChange={(value) => setData2({ pernm: value })}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1} style={{ width: 1000 }}>
        <table className={style.blueTable}>
          <tr className={style.caption}>
            <td>
              정상
            </td>
            <td style={{ color: 'var(--color-brown)' }}>
              지각
            </td>
            <td style={{ color: 'var(--color-yellow)' }}>
              연차
            </td>
            <td style={{ color: 'var(--color-pink)' }}>
              조퇴
            </td>
            <td style={{ color: 'var(--color-red)' }}>
              결근
            </td>
            <td style={{ color: 'var(--color-blue)' }}>
              출장
            </td>
            <td style={{ color: 'var(--color-gray)' }}>
              훈련
            </td>
            <td style={{ color: 'var(--color-green)' }}>
              교육
            </td>
          </tr>
          <tr>
            <td className={style.head}>
              {/* 정상 데이터 */}
              {scope.state.dayData?.atdcd01}명
            </td>
            <td className={style.head}>
              {/* 지각 데이터 */}
              {scope.state.dayData?.atdcd02}명
            </td>
            <td className={style.head}>
              {/* 연차 데이터 */}
              {scope.state.dayData?.atdcd03}명
            </td>
            <td className={style.head}>
              {/* 조퇴 데이터 */}
              {scope.state.dayData?.atdcd04}명
            </td>
            <td className={style.head}>
              {/* 결근 데이터 */}
              {scope.state.dayData?.atdcd05}명
            </td>
            <td className={style.head}>
              {/* 출장 데이터 */}
              {scope.state.dayData?.atdcd06}명
            </td>
            <td className={style.head}>
              {/* 훈련 데이터 */}
              {scope.state.dayData?.atdcd07}명
            </td>
            <td className={style.head}>
              {/* 교육 데이터 */}
              {scope.state.dayData?.atdcd08}명
            </td>
          </tr>
        </table>
      </FlexLayout>
      <FlexLayout>
        <FlexLayout weight={1.8}>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'pchk',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>출력</FlexLayout>
                    <CheckBox
                      style={{ paddingRight: 4 }}
                      value={scope.state.printChkAll === '1'}
                      onChange={(value) => scope.printCheckAll(value)}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
                width: 30,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ marginRight: 4 }}
                  >
                    <CheckBox
                      value={x.chk === '1'}
                      onChange={async (value) => rowUpdate({ chk: value ? '1' : '0' })}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'workday',
                text: '일자',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.workday)}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekday',
                text: '요일',
                width: 40,
                render: (x) => {
                  let color = '';

                  switch (x.weekday) {
                    case '토':
                      color = 'var(--color-blue)';
                      break;

                    case '일':
                      color = 'var(--color-red)';
                      break;

                    default:
                      break;
                  }

                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color }}
                    >
                      {x.weekday}
                    </FlexLayout>
                  );
                },
              },
            ]}
            data={scope.state.dayList || []}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows) => scope.onUpdatedRows(rows)}
            onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>

        <FlexLayout weight={9}>
          <FlexLayout isVertical={true}>
            <TableLayout
              ref={scope.tableDetail}
              header={[
                {
                  id: 'divinm',
                  text: '부서명',
                  width: 30,
                },
                {
                  id: 'seq',
                  text: '순번',
                  width: 10,
                },
                {
                  id: 'perid',
                  text: '사원번호',
                  width: 20,
                },
                {
                  id: 'pernm',
                  text: '사원명',
                  width: 20,
                },
                {
                  id: 'restnum',
                  text: '남은연차',
                  width: 20,
                  render: (x, _, ref) => {
                    const restnum = parseFloat(x.restnum) ?? 0.0;
                    let color = 'var(--color-secondary)';

                    if (restnum > 0) {
                      color = 'var(--color-blue)';
                    } else if (restnum < 0) {
                      color = 'var(--color-red)';
                    }

                    return (
                      <TextBox
                        textAlign="center"
                        ref={ref}
                        value={restnum}
                        color={color}
                        readonly={true}
                      />
                    );
                  },
                },
                {
                  id: 'workrestnum',
                  text: '남은대휴',
                  width: 20,
                  render: (x, _, ref) => {
                    const workrestnum = parseFloat(x.workrestnum) ?? 0.0;
                    let color = 'var(--color-secondary)';

                    if (workrestnum > 0) {
                      color = 'var(--color-blue)';
                    } else if (workrestnum < 0) {
                      color = 'var(--color-red)';
                    }

                    return (
                      <TextBox
                        textAlign="center"
                        ref={ref}
                        value={workrestnum}
                        color={color}
                        readonly={true}
                      />
                    );
                  },
                },
                {
                  id: 'atdcd',
                  text: '근태',
                  width: 20,
                  render: (x, rowUpdate) => {
                    let color = '';

                    switch (x.atdcd) {
                      case '00':
                        color = 'var(--color-red)';
                        break;

                      case '01':
                        color = '';
                        break;

                      case '02':
                        color = '#5B1A86';
                        break;

                      case '03':
                        color = '#7F7A2C';
                        break;

                      case '04':
                        color = '#ED809B';
                        break;

                      case '05':
                        color = 'var(--color-red)';
                        break;

                      case '06':
                        color = 'var(--color-blue)';
                        break;

                      case '07':
                        color = '#881B20';
                        break;

                      case '12':
                        color = '#FF7F27';
                        break;

                      default:
                        color = '#FAC96C';
                        break;
                    }

                    return (
                      <ComboBox
                        value={x.atdcd}
                        style={{ color }}
                        data={scope.state.atdcds?.map((y) => new ComboBoxModel(y.atdcd, y.atdnm || '전체'))}
                        onSelect={(option) => rowUpdate({ atdcd: option.value })}
                      />
                    );
                  },
                },
                {
                  id: 'sttime',
                  text: '출근시간',
                  width: 20,
                  render: (x, rowUpdate, ref) => (
                    <FormatTextBox
                      textAlign="center"
                      color={Number(x.sttime) > Number(x.st_worktime) ? '#9051BA' : '' }
                      style={{ fontSize: 12 }}
                      format="##:##"
                      ref={ref}
                      value={x.sttime}
                      onChange={(value) => rowUpdate({
                        sttime: value,
                      })}
                    />
                  ),
                },
                {
                  id: 'edtime',
                  text: '퇴근시간',
                  width: 20,
                  render: (x, rowUpdate, ref) => (
                    <FormatTextBox
                      textAlign="center"
                      style={{ fontSize: 12 }}
                      format="##:##"
                      ref={ref}
                      value={x.edtime}
                      onChange={(value) => rowUpdate({
                        edtime: value,
                      })}
                    />
                  ),
                },
                {
                  id: 'workflag',
                  text: '공사',
                  width: 20,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        value={x.workflag === '1'}
                        onChange={(value) => rowUpdate({ workflag: value ? '1' : '0' })}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'memotxt',
                  text: '사유',
                  width: 50,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      textAlign="left"
                      ref={ref}
                      value={(x.memotxt).replace(/\\n|￦n/g, '')}
                      onChange={(value) => rowUpdate({
                        memotxt: value,
                      })}
                    />
                  ),
                },
                {
                  id: 'distance',
                  text: (
                    <FlexLayout isVertical={true}>
                      <FlexLayout>출근시 사무실</FlexLayout>
                      <FlexLayout>거리차이</FlexLayout>
                    </FlexLayout>
                  ),
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      justify="end"
                      align="center"
                    >
                      {Format.number(x.distance)} km
                    </FlexLayout>
                  ),
                },
                {
                  id: 'x',
                  text: '위치보기(출근)',
                  width: 20,
                  render: (x) => (
                    <FlexLayout justify="center">
                      <Button
                        isIcon={true}
                        onClick={() => scope.buttonClicked(x)}
                      >
                        <MdPlace size={ 30 }/>
                      </Button>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'y',
                  text: '위치보기(퇴근)',
                  width: 20,
                  render: (x) => (
                    <FlexLayout justify="center">
                      <Button
                        isIcon={true}
                        onClick={() => scope.buttonClicked2(x)}
                      >
                        <MdPlace size={ 30 }/>
                      </Button>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'z',
                  text: '위치보기(이동경로)',
                  width: 24,
                  render: (x) => (
                    <FlexLayout justify="center">
                      <Button
                        isIcon={true}
                        onClick={() => scope.buttonClicked3(x)}
                      >
                        <MdPlace size={ 30 }/>
                      </Button>
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.dayDetailList}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity2}
              onRowFocusEvent={(item) => scope.onDetailRowFocusEvent(item)}
              onChange={(rows2, updatedRows2) => scope.onUpdatedRows2(rows2, updatedRows2)}
            />

            <ModalView
              width={300}
              height={100}
              isVisible={scope.state.newDetailModal}
              onClose={() => scope.newModal(false, true)}
            >
              <FlexLayout style={{ padding: 8 }}>
                <span>날짜</span>
                <DateTextBox
                  value={scope.state.workday2}
                  textAlign="center"
                  format="####-##-##"
                  onChange={(value) => scope.setState({ workday2: value })}
                  onEnter={() => scope.onRetrieveEvent()}
                  head={(<button
                    onClick={() => {
                      const date = new Date(Date8.withDash(scope.state.workday2));
                      date.setDate(date.getDate() - 1);
                      scope.setState({ mon: Date8.make(date) });
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>)}
                  trail={(<button
                    onClick={() => {
                      const date = new Date(Date8.withDash(scope.state.workday2));
                      date.setDate(date.getDate() + 1);
                      scope.setState({ workday2: Date8.make(date) });
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>)}
                  isDisabledTrackingStateChange={true}
                />
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ marginBottom: 200 }}
                >
                  <Button
                    onClick={() => scope.newModal(false, false)}
                    style={{ height: '30px' }}
                  >
                    확 인
                  </Button>
                </FlexLayout>
              </FlexLayout>
            </ModalView>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
