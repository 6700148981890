import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e601w_sulchi_10
 * @category 설치수주/수금현황
 */
export class OrderCollectionModel {
  /**
   * 거래처코드
   *
   */
  readonly actcd: string;


  /**
   * 거래처명
   *
   */
  readonly actnm: string;

  /**
   * 수주금액
   *
   */
  readonly compamt: string;

  /**
   * 수주날짜
   *
   */
  readonly compdate: string;

  /**
   * 월
   *
   */
  readonly compmon: string;

  /**
   * 계약일자
   *
   */
  readonly contdate: string;



  /**
   * 회사코드
   *
   */
  readonly custcd: string;

  /**
   * 납기일
   *
   */
  readonly enddate: string;

  /**
   * 구분
   *
   */
  readonly gubun: string;

  /**
   * 매출일자
   *
   */
  readonly misdate: string;

  /**
   * 수금여부
   *
   */
  readonly misflag: string;

  /**
   * 매출번호
   *
   */
  readonly misnum: string;

  /**
   * 미수금
   *
   */
  readonly notamt: string;

  /**
   * 담당자코드
   *
   */
  readonly pperid: string;

  /**
   * 담당자명
   *
   */
  readonly ppernm: string;

  /**
   * 수량
   *
   */
  readonly qty: string;

  /**
   * 수금액
   *
   */
  readonly rcvamt: string;


  /**
   * 사업장코드
   *
   */
  readonly spjangcd: string;

  /**
   * 수금예정일
   *
   */
  readonly rcvexdatetime: string;

  /**
   * 수금예정구분
   *
   */
  readonly rcvexflag: string;


  /**
   * 신규 new
   *
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.compamt = data.compamt || '';
    this.compdate = data.compdate || '';
    this.compmon = data.compmon || '';
    this.contdate = data.contdate || '';
    this.custcd = data.custcd || '';
    this.enddate = data.enddate || '';
    this.gubun = data.gubun || '';
    this.misdate = data.misdate || '';
    this.misflag = data.misflag || '';
    this.misnum = data.misnum || '';
    this.notamt = data.notamt || '';
    this.pperid = data.pperid || '';
    this.ppernm = data.ppernm || '';
    this.qty = data.qty || '';
    this.rcvamt = data.rcvamt || '';
    this.spjangcd = data.spjangcd || '';
    this.rcvexdatetime = data.rcvexdatetime || '';
    this.rcvexflag = data.rcvexflag || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
