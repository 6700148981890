import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import {
  EnrollModel,
  EnrollDetailModel,
} from './models';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { EnrollTemplate } from './Enroll.template';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

export enum EnrollItemChangeTypes {
  PAPERCD,
}

export enum EnrollItemChangeTypeNames {
  papercd,
}

interface EnrollState {
  // 검색 조건
  perid: string;
  pernm: string;
  papercd: string;
  papercds?: Array<any>;

  // 데이터 객체
  data: EnrollModel;
  focusedEnroll?: EnrollModel;
  focusedDetailEnroll?: EnrollDetailModel;
  enrollList: Array<EnrollModel>;
  enrollDetailList: Array<EnrollDetailModel>;

  focusIndex: number;
}

/**
 * 컨트롤러
 * @window w_tb_e063
 * @category 업무결재라인등록
 */

export class Enroll extends PageComponent<PageProps, EnrollState>
  implements PageToolEvents {
  updatedRows?: Array<EnrollDetailModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      perid: '',
      pernm: '',
      papercd: '%',
      papercds: [],

      data: new EnrollModel(),
      enrollList: [],
      enrollDetailList: [],

      focusIndex: 0,
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    // 문서코드
    const data = await api.dropdown('wf_dd_ca510_620_02');

    if (!data) return;
    this.setState({
      perid: user.perid,
      pernm: user.pernm,
      papercds: data.items,
    });

    await this.onRetrieveEvent();
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const json = JSON.parse(JSON.parse(message));

    ConfirmWarning.show('확인', json?.response.messagebox);
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        perid: this.state.perid,
        papercd: this.state.papercd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          enrollList: [...this.state.enrollList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          enrollList: [],
          enrollDetailList: [],
          data: new EnrollModel(),
        });

        await this.infinity?.retrieveAll();

        if (this.state.enrollList && this.state.enrollList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      enrollList: [],
      enrollDetailList: [],
      data: new EnrollModel(),
    }, async () => {
      const index = await this.infinity?.retrieveTo(['papercd', 'perid'],
        [this.state.focusedEnroll?.papercd, this.state.focusedEnroll?.perid], type, true) || 0;
      if (this.state.enrollList && this.state.enrollList.length > index) {
        await this.grid.current?.setFocus(index);
        // rowfocus 2번타서 주석처리함
        // this.onRowFocusEvent(this.state.enrollList[this.state.focusIndex], this.state.focusIndex);
      }
    });
    await this.table.current?.update(true);
  }

  @action
  onRowFocusEvent(item: EnrollModel, index: number) {
    if (item?.isNew) {
      this.setState({
        data: new EnrollModel(item, true),
        focusedDetailEnroll: undefined,
        enrollDetailList: [],
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.updatedRows = [];

    this.setState(
      {
        focusedEnroll: item,
        focusIndex: index,
      },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged', this.state.focusedEnroll);
        data && this.setState({
          data: new EnrollModel(data),
        });
      },
    );

    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      { ...item },
      (params) => api.fxExec('dw_1_RowFocuschanged', params),
      (items) => {
        this.setState({
          enrollDetailList: items.map((x: any, index: number) => new EnrollDetailModel({
            ...x, seq: index + 1,
          })),
        });
      },
      async () => {
        await this.SS({
          enrollDetailList: [],
        });

        await this.infinity2?.retrieve();
        if (this.state.enrollDetailList && this.state.enrollDetailList?.length > 0) {
          this.table.current?.update(true);
          this.table.current?.setFocus(0, 1);
        }
      },
    );

    this.setState({
      enrollDetailList: [],
    }, async () => {
      await this.infinity2?.retrieve();
      this.table.current?.update(true);
      this.table.current?.setFocus(0, 1);
    });
  }


@action
  onRowFocusEvent2(item:EnrollDetailModel) {
    this.setState({
      focusedDetailEnroll: item,
    });
  }

  // 전체 복사
  @action
async onAllCopy() {
  const text = '전체복사를 하시면 기존에 있던 문서내용은 사라지고 현재선택한 내용으로 덮어씌웁니다. 그래도 진행하시겠습니까?';
  if (!await Confirm.show('복사하시겠습니까?', text, ConfirmType.QUESTION)) {
    return;
  }

  const { actionStore: api } = this.props;
  await api.exec(Category.EMAIL, 'wb_create', {
    perid: this.state.data.perid,
    papercd: this.state.data.papercd,
  });
}

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const data = await api.save({
      perid: this.state.data.perid,
      pernm: this.state.data.pernm,
      papercd: this.state.data.papercd,
      remark: this.state.data.remark,
      items: this.state.enrollDetailList,
    }, this.state.data?.new === '1');

    if (data) {
      this.updatedRows = [];
      this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new({
      perid: this.state.perid,
      pernm: this.state.pernm,
    });
    if (data) {
      const newModel = new EnrollModel(data, true);

      this.setState({
        data: newModel,
        enrollList: [
          ...this.state.enrollList,
          newModel,
        ],
        enrollDetailList: [],
        focusedEnroll: newModel,
        focusIndex: 0,
      }, async () => {
        await this.table.current?.update(true);
        await this.grid.current?.setFocus(this.state.enrollList.length - 1);
      });
    }
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;

    // 결재자 4명까지만 가능
    let gubunCount = 0;

    this.state.enrollDetailList.forEach((x) => {
      if (x.gubun === '101') {
        gubunCount += 1;
      }
    });

    if (gubunCount > 3) {
      ConfirmWarning.show('경고', '결재자는 4명까지만 추가 하실 수 있습니다.');
      return;
    }

    // 결재자 4명 미만 이면 신규추가
    const data = await api.fxExec(
      'dw_3_new',
      {
        perid: this.state.data.perid,
        papercd: this.state.data.papercd,
      },
    );

    const seq = this.state.enrollDetailList.length + 1;

    data && this.setState({
      enrollDetailList: [...this.state.enrollDetailList, new EnrollDetailModel({ ...data, seq: seq.toString() }, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.enrollDetailList.length - 1);
    });
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    if (this.state.enrollDetailList.length > 0) {
      ConfirmWarning.show('경고', '결재자가 있습니다. 삭제할 수 없습니다!');
      return;
    }

    const text = `사원번호: ${this.state.focusedEnroll?.perid}, 사원이름: ${this.state.focusedEnroll?.pernm}`;
    await api.delete(text, this.state.data) && await this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    const text = `코드: ${this.state.focusedDetailEnroll?.kcperid}, 결재자명: ${this.state.focusedDetailEnroll?.kcpernm}`;
    await api.fxDelete(
      'dw_3_delete',
      text,
      {
        perid: this.state.data.perid,
        papercd: this.state.data.papercd,
        no: this.state.focusedDetailEnroll?.no,
      },
    ) && this.onRowFocusEvent(this.state.data!, this.state.focusIndex);
  }

  @action
  async itemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;
    let data = { new: undefined };

    if (type === EnrollItemChangeTypeNames.papercd) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: EnrollItemChangeTypeNames[type],
          data: item,
          new: this.state.data.new,
        },
      );
    }

    data && this.setState({
      data: new EnrollModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
        papercd: item,
      }, data.new === '1'),
    });
  }


  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */
  @action
  onUpdatedRows(rows: Array<EnrollDetailModel>, updatedRows: Array<EnrollDetailModel>) {
    this.updatedRows = updatedRows;
    this.setState({ enrollDetailList: rows });
  }

  render() {
    return (
      <EnrollTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new EnrollModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
