import * as React from 'react';
import './Normalize.module.css';
import './Forms.module.scss';
import '../constants/global.scss';
import { ReactElement, RefObject } from 'react';
import { JoinClassName } from '../utils/string';

interface RootProps {
  refs: RefObject<HTMLDivElement>;
  children: ReactElement;
  theme: string;
  contrast: boolean;
}

export const Root: React.FC<RootProps> = ({
  children,
  theme,
  contrast,
  refs,
}) => (
  <div
    ref={refs}
    className={JoinClassName.make([theme, contrast ? 'contrast' : false])}
    style={{
      transformOrigin: 'top left',
    }}
  >
    {children}
  </div>
);
