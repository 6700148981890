import { Global, TLHF } from '../../../../../constants';
import {
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  TextBox,
} from '../../../../../components';
import { Journal } from '../Journal';
import JournalDetailModel from '../models/JournalDetailModel';

/**
 * 화면 > 테이블 레이아웃 A
 * @window w_tb_e045_03
 * @category 사전점검등록(점검일지)
 */
export const JournalDetailTableHeader: TLHF<JournalDetailModel, Journal> = () => ([
  {
    id: 'kccd',
    text: '순번',
    width: 60,
  },
  {
    id: 'kcnm',
    text: '부적합내용',
    width: 200,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.kcnm}
      </FlexLayout>
    ),
  },
  {
    id: 'aa_error',
    text: '판정기준',
    width: 200,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.aa_error}
      </FlexLayout>
    ),
  },
  {
    id: 'state',
    text: '상태',
    width: 70,
    render: (x, rowUpdate) => {
      const color = x.state === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED;
      return (
        <ComboBox
          value={x.state}
          style={color}
          data={[
            { value: '1', remark: '정상' },
            { value: '2', remark: '부적합' },
          ].map((y) => new ComboBoxModel(y.value, y.remark))}
          onSelect={(item) => rowUpdate({ state: item.value })}
        />
      );
    },
  },
  {
    id: 'remark',
    text: '확인결과',
    width: 300,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
