import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Contract } from '../Contract';
import ContractDefaultModel from '../models/ContractDefaultModel';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e101_share
 * @category 외주계약등록
 */
export const ContractGridHeader: GLHF<ContractDefaultModel, Contract> = () => ([
  {
    id: 'actcd',
    text: '코드',
    width: 20,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.actcd}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '설치현장명',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '계약자',
    width: 20,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
]);
