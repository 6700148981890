import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_gift_history
 * @category 명절선물등록
 */
export class HolidayGiftModel {
  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 선택
   *
   * **data type** varchar(8)
   */
  chk: string;

  /**
   * 전송
   *
   * **data type** varchar(8)
   */
  readonly sendflag: string;

  /**
   * 사원코드
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 받는주소
   *
   * **data type** varchar(8)
   */
  readonly rzipcd: string;

  /**
   * 주소
   *
   * **data type** varchar(8)
   */
  readonly radres: string;

  /**
   * 상세주소
   *
   * **data type** varchar(8)
   */
  readonly radres2: string;

  /**
   * 선물코드
   *
   * **data type** varchar(8)
   */
  readonly giftcd: string;

  /**
   * 선물
   *
   * **data type** varchar(8)
   */
  readonly giftnm: string;

  /**
   * 연락처
   *
   * **data type** varchar(8)
   */
  readonly handphone: string;

  /**
   * 이메일
   *
   * **data type** varchar(8)
   */
  readonly email: string;

  /**
   * 기본주소
   *
   * **data type** varchar(8)
   */
  readonly zipcd: string;

  /**
   * 주소
   *
   * **data type** varchar(8)
   */
  readonly adres: string;

  /**
   * 상세주소
   *
   * **data type** varchar(8)
   */
  readonly adres2: string;

  /**
   * 연도
   *
   * **data type** varchar(8)
   */
  readonly year: string;

  /**
   * 구분(설/추석)
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 기본 경도
   *
   * **data type** varchar(8)
   */
  readonly lng: string;

  /**
   * 기본 위도
   *
   * **data type** varchar(8)
   */
  readonly lat: string;

  /**
   * 찐 경도
   *
   * **data type** varchar(8)
   */
  readonly rlng: string;

  /**
   * 찐 위도
   *
   * **data type** varchar(8)
   */
  readonly rlat: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * SMS 전송여부
   *
   * **data type** varchar(8)
   */
  readonly sendflag_sms: string;

  /**
   * 마감구분
   *
   * **data type** varchar(8)
   */
  readonly endflag: string;

  new : string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.spjangcd = data.spjangcd || '';
    this.seq = data.seq || '';
    this.chk = data.chk || '0';
    this.sendflag = data.sendflag || '0';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.rzipcd = data.rzipcd || '';
    this.radres = data.radres || '';
    this.radres2 = data.radres2 || '';
    this.giftcd = data.giftcd || '';
    this.giftnm = data.giftnm || '';
    this.handphone = data.handphone || '';
    this.email = data.email || '';
    this.zipcd = data.zipcd || '';
    this.adres = data.adres || '';
    this.adres2 = data.adres2 || '';
    this.year = data.year || '';
    this.gubun = data.gubun || '';
    this.lng = data.lng || '';
    this.lat = data.lat || '';
    this.rlng = data.rlng || '';
    this.rlat = data.rlat || '';
    this.remark = data.remark || '';
    this.sendflag_sms = data.sendflag_sms || '';
    this.endflag = data.endflag || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
