import { FaQuoteLeft, FaQuoteRight } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { BreakUnit } from './BreakUnit';
import { TabModel } from '../../../../models';
// @ts-ignore
import { BreakUnitTabAllTemplate } from './tabs/BreakUnitTabAll.template';
// @ts-ignore
import { BreakUnitTabMonthTemplate } from './tabs/BreakUnitTabMonth.template';

/**
 * 화면 > 탭
 * @window w_tb_e401w_01
 * @category 호기별고장통계
 */
export const BreakUnitTab: Array<TabLayoutDataItem<BreakUnit>> = [
  {
    tab: new TabModel('All', FaQuoteLeft, '전체'),
    template: (scope) => <BreakUnitTabAllTemplate scope={scope} />,
  },
  {
    tab: new TabModel('Month', FaQuoteRight, '월별'),
    template: (scope) => <BreakUnitTabMonthTemplate scope={scope} />,
  },
];
