
/**
 * 모델
 * @window w_tb_ca602w_02
 * @category 재고이동내역
 */
export class BarcodeDetailModel {
  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  actcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  actnm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  gubun: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  idate: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  inperid: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  inum: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  iseq: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  mamt: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  outflag: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  perid: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  pernm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  qty: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  remark: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  samt: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  sort: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  tamt: string;


  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  uamt: string;

  constructor(data: any = {}) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.gubun = data.gubun || '';
    this.idate = data.idate || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.inum = data.inum || '';
    this.iseq = data.iseq || '';
    this.mamt = data.mamt || '';
    this.outflag = data.outflag || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.qty = data.qty || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.sort = data.sort || '';
    this.tamt = data.tamt || '';
    this.uamt = data.uamt || '';
  }
}
