import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_pb300_02
 * @category 급여현황
 */
export class PayCurrentStateModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 사업장
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 선택
   *
   * **data type** varchar(1)
   */
  chk: string;

  /**
   * 출력채크
   *
   * **data type** varchar(1)
   */
  prtflag: string;

  /**
   * 메일채크
   *
   * **data type** varchar(1)
   */
  emailflag: string;

  /**
   * 월
   *
   * **data type** varchar(8)
   */
  mon: string;

  /**
   * 순번
   *
   * **data type** varchar(2)
   */
  seq: string;

  /**
   * 입력자
   *
   * **data type** varchar(6)
   */
  perid: string;

  /**
   * 부서
   *
   * **data type** varchar(4)
   */
  divicd: string;

  /**
   * 갑근세
   *
   * **data type** varchar(10)
   */
  payamt_tax: string;

  /**
   * 고용보험
   *
   * **data type** number
   */
  insu_unem: string;

  /**
   * 건강보험
   *
   * **data type** number
   */
  insu_health : string;

  /**
   * 장기요양보험
   *
   * **data type** number
   */
  insu_longterm : string;

  /**
   * 국민연금
   *
   * **data type** number
   */
  insu_national : string;

  /**
   * 월기본급여
   *
   * **data type** number
   */
  pay_month : string;

  /**
   * 기본급(주휴포함)
   *
   * **data type** number
   */
  pay_basic: string;

  /**
   * 식대
   *
   * **data type** number
   */
  pay_food: string;

  /**
   * 직무수당
   *
   * **data type** number
   */
  pay_SerAllow: string;

  /**
   * 고정시간외근로수당
   *
   * **data type** float
   */
  pay_older: string;

  /**
   * 고정야간근로수당
   *
   * **data type** float
   */
  pay_night: string;

  /**
   * 고정휴일근로수당
   *
   * **data type** float
   */
  pay_holiday: string;

  /**
   * 연차수당
   *
   * **data type** number
   */
  pay_yearly: string;

  /**
   * 결근지각조퇴 총 차감금액
   *
   * **data type** float
   */
  minuspay_late_tot: string;

  /**
   * 인센티브1(직책수당)
   *
   * **data type** float
   */
  pay_leader: string;

  /**
   * 인센티브2(월실적)
   *
   * **data type** float
   */
  pay_incentive: string;

  /**
   * 연장시간계산
   *
   * **data type** float
   */
  over_older_time_cal: string;

  /**
   * 연장시간(1.5배)
   *
   * **data type** float
   */
  over_older_pay: string;

  /**
   * 야간근무시간계산
   *
   * **data type** float
   */
  over_duty_time_cal: string;

  /**
   * 야근정산금액
   *
   * **data type** float
   */
  over_duty_pay: string;

  /**
   * 휴일일수계산
   *
   * **data type** float
   */
  over_holi_time_cal: string;

  /**
   * 휴일정산금액
   *
   * **data type** float
   */
  over_holi_pay: string;

  /**
   * 연차수당정산금액
   *
   * **data type** float
   */
  rest_pay: string;

  /**
   * 소득세환급금
   *
   * **data type** float
   */
  tax_income: string;

  /**
   * 건강보험료환급금
   *
   * **data type** float
   */
  tax_health: string;

  /**
   * 연말정산보험료
   *
   * **data type** float
   */
  tax_national: string;

  /**
   * 가불금
   *
   * **data type** float
   */
  tax_suspense: string;

  /**
   * 기타공제
   *
   * **data type** float
   */
  tax_other: string;

  /**
   * 비고
   *
   * **data type** float
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** float
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** text
   */
  indate: string;

  /**
   * 월간연봉급여총액
   *
   * **data type** varchar(10)
   */
  tot_samt: string;

  /**
   * 공제금액
   *
   * **data type** varchar(8)
   */
  tot_tamt: string;

  /**
   * 실수령금액
   *
   * **data type** float
   */
  tot_mamt: string;

  /**
   * 상조회비
   *
   * **data type** float
   */
  insu_maf: string;

  /**
   * 주민세
   *
   * **data type** float
   */
  payamt_tax2: string;

  /**
   * 성명
   *
   * **data type** varchar(1)
   */
  pernm: string;

  /**
   * 재직구분
   *
   * **data type** varchar(1)
   */
  rtclafi: string;

  /**
   * 입사일
   *
   * **data type** varchar(30)
   */
  entdate: string;

  /**
   * 퇴사일
   *
   * **data type** varchar(3)
   */
  rtdate: string;

  /**
   * 직책
   *
   * **data type** varchar(40)
   */
  rspnm: string;

  /**
   * 퇴사일
   *
   * **data type** varchar(27)
   */
  dayterm2: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  no: string;

  /**
   * 지급구분
   *
   * **data type** varchar(3)
   */
  mijflag: string;

  /**
   * 매월차감일수
   *
   * **data type** varchar(3)
   */
  month_minus_tot: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_01: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_02: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_03: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_04: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_05: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_06: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_07: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_08: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_01: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_02: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_03: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_04: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_05: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_06: string;

  /**
   * -
   *
   * **data type** number
   */
  birthday: string;

  /**
   * -
   *
   * **data type** number
   */
  divinm: string;

  /**
   * -
   *
   * **data type** number
   */
  workday: string;

  /**
   * -
   *
   * **data type** number
   */
  worktime: string;

  /**
   * -
   *
   * **data type** number
   */
  time_holiday: string;

  /**
   * -
   *
   * **data type** number
   */
  time_older: string;

  /**
   * -
   *
   * **data type** number
   */
  time_night: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.time_older = data.time_older || '';
    this.time_night = data.time_night || '';
    this.time_holiday = data.time_holiday || '';
    this.custcd = data.custcd || '';
    this.divinm = data.divinm || '';
    this.workday = data.workday || '';
    this.worktime = data.worktime || '';
    this.birthday = data.birthday || '';
    this.spjangcd = data.spjangcd || '';
    this.pay_tax0_01 = data.pay_tax0_01 || '';
    this.pay_tax0_02 = data.pay_tax0_02 || '';
    this.pay_tax0_03 = data.pay_tax0_03 || '';
    this.pay_tax0_04 = data.pay_tax0_04 || '';
    this.pay_tax0_05 = data.pay_tax0_05 || '';
    this.pay_tax0_06 = data.pay_tax0_06 || '';
    this.pay_tax0_07 = data.pay_tax0_07 || '';
    this.pay_tax0_08 = data.pay_tax0_08 || '';
    this.pay_tax1_01 = data.pay_tax1_01 || '';
    this.pay_tax1_02 = data.pay_tax1_02 || '';
    this.pay_tax1_03 = data.pay_tax1_03 || '';
    this.pay_tax1_04 = data.pay_tax1_04 || '';
    this.pay_tax1_05 = data.pay_tax1_05 || '';
    this.pay_tax1_06 = data.pay_tax1_06 || '';
    this.no = data.no || '';
    this.month_minus_tot = data.month_minus_tot || '';
    this.mon = data.mon || '';
    this.mijflag = data.mijflag || '';
    this.seq = data.seq || '';
    this.perid = data.perid || '';
    this.divicd = data.divicd || '';
    this.payamt_tax = data.payamt_tax || '';
    this.pay_month = data.pay_month || '';
    this.pay_basic = data.pay_basic || '';
    this.pay_food = data.pay_food || '';
    this.pay_SerAllow = data.pay_SerAllow || '';
    this.pay_older = data.pay_older || '';
    this.pay_night = data.pay_night || '';
    this.pay_holiday = data.pay_holiday || '';
    this.minuspay_late_tot = data.minuspay_late_tot || '';
    this.pay_leader = data.pay_leader || '';
    this.pay_incentive = data.pay_incentive || '';
    this.over_older_time_cal = data.over_older_time_cal || '';
    this.over_older_pay = data.over_older_pay || '';
    this.over_duty_time_cal = data.over_duty_time_cal || '';
    this.over_duty_pay = data.over_duty_pay || '';
    this.over_holi_time_cal = data.over_holi_time_cal || '';
    this.over_holi_pay = data.over_holi_pay || '';
    this.rest_pay = data.rest_pay || '';
    this.tax_income = data.tax_income || '';
    this.tax_health = data.tax_health || '';
    this.tax_national = data.tax_national || '';
    this.tax_suspense = data.tax_suspense || '';
    this.tax_other = data.tax_other || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.tot_samt = data.tot_samt || '';
    this.tot_tamt = data.tot_tamt || '';
    this.tot_mamt = data.tot_mamt || '';
    this.insu_maf = data.insu_maf || '';
    this.payamt_tax2 = data.payamt_tax2 || '';
    this.pernm = data.pernm || '';
    this.rtclafi = data.rtclafi || '';
    this.entdate = data.entdate || '';
    this.rtdate = data.rtdate || '';
    this.rspnm = data.rspnm || '';
    this.chk = data.chk || '';
    this.prtflag = data.prtflag || '';
    this.emailflag = data.emailflag || '';
    this.spjangnm = data.spjangnm || '';
    this.mon = data.mon || '';
    this.insu_unem = data.insu_unem || '';
    this.insu_health = data.insu_health || '';
    this.insu_longterm = data.insu_longterm || '';
    this.insu_national = data.insu_national || '';
    this.dayterm2 = data.dayterm2 || '';
    this.pay_yearly = data.pay_yearly || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
