import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  ModalView,
  SearchLayout,
  SubHeader,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Result } from './Result';
import { SearchBinding } from '../../../../models/common';
import { Date8 } from '../../../../utils/time';
import { Sum } from '../../../../utils/array';

/**
 * 화면
 * @window w_tb_e075w
 * @category 검사결과현황
 */

export const ResultTemplate: React.FC<TemplateProps<Result>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        style={{ width: 1180 }}
      >
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={240}>
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            value={scope.state.divicd}
            textAlign="center"
            onChange={(value) => scope.setState({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                scope.setState({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.5}
            value={scope.state.divinm}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={240}>
          <span style={{ color: 'var(--color-red)' }}>사용자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.5}
            value={scope.state.pernm}
            onChange={(value) => scope.setState({ pernm: value })}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={240}>
          <span>진행상태</span>
          <ComboBox
            value={scope.state.state}
            textAlign="center"
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '계획' },
              { value: '2', remark: '완료' },
              { value: '3', remark: '만료' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => scope.setState({ state: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>
      </SearchLayout>
      <SearchLayout
        isSub={true}
        style={{ width: 1180 }}
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={620}>
          <span style={{ marginRight: 20 }}>현장명</span>
          <TextBox
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={240}>
          <span style={{ marginLeft: 18 }}>결과</span>
          <ComboBox
            value={scope.state.result}
            textAlign="center"
            style={{ marginLeft: 12 }}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '합격' },
              { value: '2', remark: '불합격' },
              { value: '3', remark: '조건부' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => scope.setState({ result: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>
        <FlexLayout size={240}>
          <span>계약구분</span>
          <ComboBox
            value={scope.state.gubun}
            textAlign="center"
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '계약중' },
              { value: '2', remark: '해지' },
              { value: '3', remark: '미계약' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => scope.setState({ gubun: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>
      </SearchLayout>

      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'pernmgroup',
            text: '담당자별그룹',
            isHidden: true,
            group: true,
            value: (x) => x.pernm,
          },
          {
            id: 'divinm',
            text: '부서명',
            width: 16,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                총계
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify='center'
                align='center'
              >
                {x.divinm}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '담당자',
            width: 16,
            render: (x) => (
              <FlexLayout
                justify='center'
                align='center'
              >
                {x.pernm}
              </FlexLayout>
            ),
            sum: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pernm}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm2',
            text: '보조담당',
            width: 16,
            render: (x) => (
              <FlexLayout
                justify='center'
                align='center'
              >
                {x.pernm2}
              </FlexLayout>
            ),
          },
          {
            id: 'state',
            text: '진행',
            width: 12,
            render: (x) => (
              <FlexLayout
                justify='center'
                align='center'
                style={{
                  // eslint-disable-next-line no-nested-ternary
                  color: x.state === '1' ? 'var(--color-green)'
                    // eslint-disable-next-line no-nested-ternary
                    : x.state === '2' ? 'var(--color-blue)'
                      : x.state === '0' ? 'var(--color-red)' : '',
                }}
              >
                {[{ value: '1', remark: '계획' },
                  { value: '2', remark: '완료' },
                  { value: '0', remark: '만료' }].filter((y) => y.value === x.state)[0]?.remark}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumTot = Sum.groupLength(data, 'state', x, ['pernm']);
              return (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {sumTot} 건
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout>
                {scope.state.cnt_tot} 건
              </FlexLayout>
            ),
          },
          {
            id: 'kcdate',
            text: '검사일자',
            width: 20,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.kcdate)}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumTot = Sum.groupLength(data.filter((x) => x.state === '2'), 'pernm', x, ['pernm']);
              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  처리 {sumTot} 건
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout style={{ color: 'var(--color-blue)' }}>
                처리 {scope.state.compcnt_tot} 건
              </FlexLayout>
            ),
          },
          {
            id: 'result',
            text: '결과',
            width: 20,
            render: (x) => (
              <FlexLayout
                justify='center'
                align='center'
                style={x.result === '001' ? { color: 'var(--color-blue)' }
                  : x.result === '002' ? { color: 'var(--color-blue)' }
                    : x.result === '006' ? { color: 'var(--color-blue)' }
                      : x.result === '004' ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
              >
                {
                  [
                    { value: '001', remark: '합격' },
                    { value: '002', remark: '조건부' },
                    { value: '003', remark: '0' },
                    { value: '004', remark: '불합격' },
                    { value: '005', remark: '2개월조건부조건부불합격' },
                    { value: '006', remark: '조건부합격(1년)' },
                    { value: '007', remark: '보완후합격' },
                  ].filter((y) => y.value === x.result)[0]?.remark
                }
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumResult1 = Sum.groupLength(data.filter((x) => x.result === '001'), 'pernm', x, ['pernm']);
              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  합격 {sumResult1} 건
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout>
                <FlexLayout size={10}>
                  (
                </FlexLayout>
                <FlexLayout style={{ color: 'var(--color-blue)' }}>
                  합격 {scope.state.result01_tot} 건
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify='left'
                align='center'
              >
                {x.actnm}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumResult2 = Sum.groupLength(data.filter((x) => x.result !== '001' && x.result !== '004'), 'pernm', x, ['pernm']);
              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-green)' }}
                >
                  조건부 {sumResult2} 건
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout style={{ color: 'var(--color-green)' }}>
                조건부 {scope.state.result03_tot} 건
              </FlexLayout>
            ),
          },
          {
            id: 'equpnm',
            text: '호기명',
            width: 20,
            render: (x) => (
              <FlexLayout
                justify='center'
                align='center'
              >
                {x.equpnm}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumResult3 = Sum.groupLength(data.filter((x) => x.result === '004'), 'pernm', x, ['pernm']);
              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  불합격 {sumResult3} 건
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout>
                <FlexLayout style={{ color: 'var(--color-red)' }}>
                  불합격 {scope.state.result02_tot} 건
                </FlexLayout>
                <FlexLayout size={10}>
                  )
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'gubun',
            text: '구분',
            width: 16,
            render: (x) => (
              <FlexLayout
                justify='center'
                align='center'
              >
                {
                  [
                    { value: '000', remark: ' ' },
                    { value: '001', remark: '정기' },
                    { value: '002', remark: '정밀' },
                    { value: '003', remark: '자체' },
                    { value: '004', remark: '설치' },
                    { value: '005', remark: '완성' },
                    { value: '006', remark: '수시' },
                    { value: '007', remark: '검사' },
                    { value: '008', remark: '0' },
                  ].filter((y) => y.value === x.gubun)[0]?.remark
                }
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumMisPlan = Sum.groupLength(data.filter((x) => x.state !== '1'), 'pernm', x, ['pernm']);
              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  미처리 {sumMisPlan} 건
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout style={{ color: 'var(--color-red)' }}>
                미처리 {scope.state.miscnt_tot} 건
              </FlexLayout>
            ),
          },
          {
            id: 'kcdate2',
            text: '검사일자',
            width: 20,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.kcdate)}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumMisPlan = Sum.groupLength(data.filter((x) => x.state === '0'), 'pernm', x, ['pernm']);
              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  ( 만료 {sumMisPlan} 건 )
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout style={{ color: 'var(--color-red)' }}>
                미계획 {scope.state.notcnt_tot} 건
              </FlexLayout>
            ),
          },
          {
            id: 'kcpernm',
            text: '검사자',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify='left'
                align='center'
              >
                {x.kcpernm}
              </FlexLayout>
            ),
          },
          {
            id: 'kcspnm',
            text: '검사기관',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify='center'
                align='center'
              >
                {
                  [
                    { value: '3', remark: '승강기안전공단' },
                  ].filter((y) => y.value === x.kcspnm)[0]?.remark
                }
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.resultList}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
        onRowDoubleClick={() => scope.onModalOpenEvenet()}
      />

      <ModalView
        width={850}
        isVisible={scope.state.isModal}
        onClose={() => scope.setState({ isModal: false })}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SubHeader>
            <div>
              <span>상세현황</span>
            </div>

            <FlexLayout></FlexLayout>
          </SubHeader>
          <FlexLayout size={10}>
            <FlexLayout>
              *현장명 : {scope.state.data?.actnm}
            </FlexLayout>

            <FlexLayout>
              *호기명 : {scope.state.data?.equpnm}
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={10}>
            <FlexLayout>
              *검사기관 : {scope.state.data?.kcpernm}
            </FlexLayout>

            <FlexLayout>
              *검사결과 : {scope.state.data?.result}
            </FlexLayout>
          </FlexLayout>

          <GridLayout
            header={[
              {
                id: 'article',
                text: '검사항목',
                width: 10,
              },
              {
                id: 'title',
                text: '검사항목명',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="start"
                    align="center"
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'descript',
                text: '불합내역',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="start"
                    align="center"
                  >
                    {x.descript}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '검사자 불합의견',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="start"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={100}
            data={scope.state.popupData}
          />
          <SubHeader>
            <FlexLayout
              justify="end"
            >
              <Button
                onClick={() => {
                  scope.setState({ isModal: false });
                }}
              >
                확인
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
);
