import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  SearchLayout,
  TextBox,
  ModalView,
  Button,
  SubHeader,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { TextTransmit } from './TextTransmit';
import { Date8 } from '../../../../utils/time';


/**
 * 화면
 * @window w_tb_sms_report
 * @category 문자송신내역
 */
export const TextTransmitTemplate: React.FC<TemplateProps<TextTransmit>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>

    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
    >
      <FlexLayout>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.stdate));
              date.setDate(date.getDate() - 1);
              scope.setState({ stdate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.stdate));
              date.setDate(date.getDate() + 1);
              scope.setState({ stdate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={230}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.enddate));
              date.setDate(date.getDate() - 1);
              scope.setState({ enddate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.enddate));
              date.setDate(date.getDate() + 1);
              scope.setState({ enddate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout weight={3}>
        <span>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="제목, 전송내역, 받는이연락처로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={400}>
        <span style={{ color: 'var(--color-red)' }}>* 전송내역을 더블클릭시 상세하게 표시됩니다.</span>
      </FlexLayout>
    </SearchLayout>

    <GridLayout
      header={[
        {
          id: 'succ_count',
          text: '전송여부',
          width: 4,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
              style={{
                color: x.succ_count === '100' ? 'var(--color-black)'
                  : x.succ_count === '200' ? 'var(--color-black)'
                    : x.succ_count === '300' ? 'var(--color-blue)' : 'var(--color-red)',
              }}>
              {
                x.succ_count === '100' ? '대기중'
                  : x.succ_count === '200' ? '전송중'
                    : x.succ_count === '300' ? '성공' : '실패'
              }
            </FlexLayout>
          ),
        },
        {
          id: 'gubun',
          text: '전송구분',
          width: 5,
          render: (x) => (
            <FlexLayout justify="center" align="center">
              {
                x.gubun === 'SMS' ? '단문문자'
                  : x.gubun === 'MMS' ? '장문문자'
                    : x.gubun === 'VMS' ? '음성문자' : ''
              }
            </FlexLayout>
          ),
        },
        {
          id: 'senddate',
          text: '전송일자',
          width: 7,
          render: (x) => (
            <FormatTextBox
              textAlign="center"
              format="####-##-##"
              value={x.senddate}
            />
          ),
        },
        {
          id: 'sendtime',
          text: '전송시간',
          width: 7,
          render: (x) => (
            <FormatTextBox
              textAlign="center"
              format="##:##:##"
              value={x.sendtime}
            />
          ),
        },
        {
          id: 'callback',
          text: '보낸이연락처',
          width: 7,
        },
        {
          id: 'recenum',
          text: '받는이연락처',
          width: 7,
        },
        {
          id: 'subject',
          text: '제목',
          width: 16,
          render: (x) => (
            <FlexLayout justify="left" align="center">
              {x.subject}
            </FlexLayout>
          ),
        },
        {
          id: 'msg',
          text: '전송내역',
          width: 30,
          render: (x) => {
            const message = x.msg.replace(/\\n/ig, ' ');
            const isLong = message.length > 42;

            return (
              <FlexLayout justify="left" align="center" onDoubleClick={
                () => {
                  scope.textModal(true);
                  scope.setState({ popupData: x.msg });
                }}>
                {isLong ? `${message.substr(0, 42)} ...` : message}
              </FlexLayout>
            );
          },
        },
      ]}
      data={scope.state.data}
      infinityHandler={scope.infinity}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
    />
    <ModalView
      isVisible={scope.state.textDetailModal}
      onClose={() => scope.textModal(false)}
    >
      <FlexLayout isVertical={true} weight={3} style={{ padding: 8 }}>
        <SubHeader>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
            justify="center"
            align="center"
          >
            문자 내용
          </FlexLayout>
          <div>
            <Button
              onClick={() => scope.textModal(false)}>
              확인
            </Button>
          </div>
        </SubHeader>


        <FlexLayout
          isVertical={true}
          style={{ padding: 10, fontSize: 14, lineHeight: 2 }}
        >
          {scope.state.popupData?.split('\\n').map(((x, i) => <div key={i}>{x}</div>))}
        </FlexLayout>

        <FlexLayout isVertical={true}>
        </FlexLayout>
        <FlexLayout isVertical={true}>
        </FlexLayout>
        <FlexLayout isVertical={true}>
        </FlexLayout>
        <FlexLayout isVertical={true} align='right'>
        </FlexLayout>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);
