import { action } from 'mobx';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  InfinityRetrieve,
  TabModel,
} from '../../../../models';
import StatusDateModel from './models/StatusDateModel';
import StatusDetailModel from './models/StatusDetailModel';
import { StatusTemplate } from './Status.template';
import { PageComponent } from '../../../../utils';
import { Date6, Date8 } from '../../../../utils/time';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';


// 계약구분
export const Gubuns = [
  { key: '0', value: '관급' },
  { key: '1', value: '사급' },
  { key: '2', value: 'LH' },
];

export const States = [
  { key: '0', value: '견적' },
  { key: '1', value: '계약' },
];

export enum StatusTabId {
  Date,
  Detail,
}

export const StatusTabTitles = [
  '일자별',
  '상세현황(공사별)',
];

export const StatusTabModels = [
  StatusDateModel,
  StatusDetailModel,
];

export const StatusTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const StatusTabDataStateNames = [
  'dataDate',
  'dataDetail',
];

interface StatusState {
  dataDate: Array<StatusDateModel>;
  dataDetail: Array<StatusDetailModel>;

  focusedTab?: TabModel;
  focusedData?: StatusDateModel;

  stdate: string;
  enddate: string;
  searchQuery: string;
  perid: string;
  pernm: string;
  jeoban: string;
  people: string;
  stage: string;
  state: string;

  gubuns?: Array<any>;

  total: string;
  total2: string;
  tabDateContflag0_tot: string;
  tabDateContflag1_tot: string;
  tabDateContflag2_tot: string;
  tabDateSamt_tot: string;
  tabDateTamt_tot: string;
  tabDateMamt_tot: string;

  tabDetailqty_tot: string;
  tabDetailuamt_tot: string;
  tabDetailsamt_tot: string;
  tabDetailtamt_tot: string;
  tabDetailmamt_tot: string;

  // 견적,계약 total
  state0_tot: string;
  state1_tot: string;
}


/**
 * 컨트롤러
 * @window w_tb_da451w_01
 * @category 설치견적현황
 */
export class Status extends PageComponent<PageProps, StatusState>
  implements PageToolEvents {
  infinityDate?: InfinityRetrieve;

  infinityDetail?: InfinityRetrieve;

  tabIndex: number = 0;

  firstOpen: boolean = false;

  costdate?: String;

  costnum?: String;

  stdate2?: string;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      stdate: `${Date6.make().substr(0, 6)}01`,
      enddate: Date8.make(),
      searchQuery: '',
      perid: '',
      pernm: '',
      jeoban: '%',
      people: '',
      stage: '',
      state: '%',

      dataDate: [],
      dataDetail: [],

      total: '0',
      total2: '0',
      tabDateContflag0_tot: '0',
      tabDateContflag1_tot: '0',
      tabDateContflag2_tot: '0',
      tabDateSamt_tot: '0',
      tabDateTamt_tot: '0',
      tabDateMamt_tot: '0',

      tabDetailqty_tot: '0',
      tabDetailuamt_tot: '0',
      tabDetailsamt_tot: '0',
      tabDetailtamt_tot: '0',
      tabDetailmamt_tot: '0',

      state0_tot: '0',
      state_1tot: '0',
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    const data = await api.dropdown('wf_dd_da020');
    if (!data) return;

    this.setState({ gubuns: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    if (this.tabIndex === 0) {
      this.infinityDate = new InfinityRetrieve(
        {
          stdate: this.state.stdate,
          enddate: this.state.enddate,
          as_nm: this.state.searchQuery,
          perid: this.state.perid,
          jeoban: this.state.jeoban,
          nperson: this.state.people,
          number: this.state.stage,
          state: this.state.state,
        },
        (params) => api.fxExec('tab_1_retrieve', params),
        (items) => {
          this.SS({
            dataDate: [
              ...this.state.dataDate,
              ...items.map((item) => new StatusDateModel(item)),
            ],
          });
        },
        async () => {
          await this.SS({
            dataDate: [],
          });
          await this.infinityDate?.retrieveAll();
        },
      );
    } else if (this.tabIndex === 1) {
      // 무한 스크롤바 헬퍼 초기화
      this.infinityDetail = new InfinityRetrieve(
        {
          stdate: this.state.stdate,
          enddate: this.state.enddate,
          as_nm: this.state.searchQuery,
          perid: this.state.perid,
          jeoban: this.state.jeoban,
          nperson: this.state.people,
          number: this.state.stage,
          state: this.state.state,
        },
        (params) => api.fxExec('tab_2_retrieve', params),
        (items) => {
          this.SS({
            dataDetail: [
              ...this.state.dataDetail,
              ...items.map((item) => new StatusDetailModel(item)),
            ],
          });
        },
        async () => {
          await this.SS({
            dataDetail: [],
          });
          await this.infinityDetail?.retrieveAll();
        },
      );
    }

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      dataDate: [],
      dataDetail: [],
    });
    await this.infinityDate?.retrieveAll();
    await this.infinityDetail?.retrieveAll();

    this.SS({
      total: await this.infinityDate?.box?.total || '0',
      tabDateContflag0_tot: await this.infinityDate?.box?.contflag0_tot || '0',
      tabDateContflag1_tot: await this.infinityDate?.box?.contflag1_tot || '0',
      tabDateContflag2_tot: await this.infinityDate?.box?.contflag2_tot || '0',
      tabDateSamt_tot: await this.infinityDate?.box?.samt_tot || '0',
      tabDateTamt_tot: await this.infinityDate?.box?.tamt_tot || '0',
      tabDateMamt_tot: await this.infinityDate?.box?.mamt_tot || '0',
      state0_tot: await this.infinityDate?.box?.state0_tot || '0',
      state1_tot: await this.infinityDate?.box?.state1_tot || '0',

      total2: await this.infinityDetail?.box?.total || '0',
      tabDetailqty_tot: await this.infinityDetail?.box?.qty_tot || '0',
      tabDetailuamt_tot: await this.infinityDetail?.box?.uamt_tot || '0',
      tabDetailsamt_tot: await this.infinityDetail?.box?.samt_tot || '0',
      tabDetailtamt_tot: await this.infinityDetail?.box?.tamt_tot || '0',
      tabDetailmamt_tot: await this.infinityDetail?.box?.mamt_tot || '0',
    });
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.getPrintRowCount() > 0, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.fxPrint(`tab_${this.tabIndex + 1}_print`, {
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
      jeoban: this.state.jeoban,
      nperson: this.state.people,
      number: this.state.stage,
      state: this.state.state,
    });
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.getPrintRowCount() > 0, '오류', '엑셀전환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.fxExcel(`tab_${this.tabIndex + 1}_excel`, {
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
      jeoban: this.state.jeoban,
      nperson: this.state.people,
      number: this.state.stage,
      state: this.state.state,
    });
  }

  getPrintRowCount(): number {
    let rowCount = 0;
    switch (this.tabIndex) {
      case 0:
        rowCount = this.state.dataDate.length;
        break;

      case 1:
        rowCount = this.state.dataDetail.length;
        break;
    }

    return rowCount;
  }


  @action
  onTabChange(index: number) {
    this.tabIndex = index;
    if (this.firstOpen === true) {
      this.onRetrieveEvent();
    } else {
      this.firstOpen = true;
    }
  }

  @action
  onRowClick(row: any) {
    this.costdate = row.costdate;
    this.costnum = row.costnum;
    this.stdate2 = row.stdate2;
  }

  @action
  async onClickOpenResist() {
    const result = await Confirm.show('선택!', '상세히 보기 위해 설치견적서등록으로 넘어가시겠습니까?', ConfirmType.QUESTION);
    if (result) {
      // @ts-ignore
      const search = this.costdate + this.costnum;
      this.props.publicStore.go(
        '/w_tb_da451',
        {
          costdate: this.costdate || '',
          costnum: this.costnum || '',
          searchQuery: search || '',
        },
      );
    }
  }

  render() {
    return <StatusTemplate
      scope={this}
    />;
  }
}
