/**
 * 모델
 * @window w_tb_da020
 * @category 매출구분코드 등록
 */
import { computed } from 'mobx';

export class SortationCodeModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 구분코드
   *
   * **data type** varchar(2)
   */
  artcd: string;

  /**
   * 구분명
   *
   * **data type** varchar(50)
   */
  artnm: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remeark: string;

  /**
   * 사용유무
   *
   * **data type** char(1)
   */
  useyn: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 대변계정
   *
   * **data type** varchar(4)
   */
  acccd: string;

  /**
   *정기유무
   *
   * **data type** varchar(1)
   */
  jgubun: string;

  /**
   *코드
   *
   * **data type** varchar(8)
   */
  bankcd: string;

  /**
   *차변계정
   *
   * **data type** varchar(4)
   */
  acccd2: string;

  /**
   *부문
   *
   * **data type** varchar(10)
   */
  bumun: string;

  /**
   *구분
   *
   * **data type** varchar(3)
   */
  flag: string;

  /**
   *대변계정명
   *
   * **data type** varchar(40)
   */
  accnm: string;

  /**
   *차변계정명
   *
   * **data type** varchar(40)
   */
  accnm2: string;

  /**
   *은행명
   *
   * **data type** varchar(20)
   */
  banknm: string;

  /**
   *계좌번호
   *
   * **data type** varchar(20)
   */
  accnum: string;

  /**
   * 매입구분
   *
   * **data type** varchar()
   */
  wkgubun: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.artcd = data.artcd || '';
    this.artnm = data.artnm || '';
    this.remeark = data.remeark || '';
    this.useyn = data.useyn || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.acccd = data.acccd || '';
    this.jgubun = data.jgubun || '';
    this.bankcd = data.bankcd || '';
    this.acccd2 = data.acccd2 || '';
    this.bumun = data.bumun || '';
    this.flag = data.flag || '';
    this.accnm = data.accnm || '';
    this.accnm2 = data.accnm2 || '';
    this.banknm = data.banknm || '';
    this.accnum = data.accnum || '';
    this.wkgubun = data.wkgubun || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
