import * as React from 'react';
import { observer } from 'mobx-react';
import style from '../AuthorizedScreen.module.scss';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore, WaitQueueStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { Button } from '../../components/forms/Button';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { GridLayout } from '../../components/layout/GridLayout';

interface ModalWaitQueueProps {
  modalStore: ModalStore;
  waitQueueStore: WaitQueueStore;
}

@observer
export class ModalWaitQueue extends React.Component<ModalWaitQueueProps> {
  render() {
    const { modalStore, waitQueueStore } = this.props;

    return (
      <ModalView
        width={800}
        isVisible={modalStore.isVisibleWaitQueue}
        onClose={() => modalStore.closeWaitQueue()}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>대기열</LayoutTitle>

          <FlexLayout isVertical={true}>
            <GridLayout
              ref={modalStore.gridWaitQueue}
              header={[
                {
                  id: 'status',
                  text: '상태',
                  width: 20,
                  render: (x: any) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{
                        color: x.isDone ? 'var(--color-blue)' : 'var(--color-red)',
                      }}
                    >
                      {x.isDone ? '완료' : '처리중'}
                    </FlexLayout>
                  ),
                }, {
                  id: 'filename',
                  text: '제목',
                  width: 40,
                  render: (x: any) => (
                    <FlexLayout justify="start" align="center">
                      {x.title}
                    </FlexLayout>
                  ),
                }, {
                  id: 'time',
                  text: '시간',
                  width: 20,
                  render: (x: any) => (
                    <FlexLayout justify="center" align="center">
                      {x.runningTimes}
                    </FlexLayout>
                  ),
                }, {
                  id: 'execute',
                  text: '실행',
                  width: 20,
                  render: (x: any) => (
                    x.isDone
                      ? <Button isFull={true} onClick={() => {
                        waitQueueStore.doAction(x);
                        modalStore.closeWaitQueue();
                      }}>실행</Button>
                      : <Button isFull={true} onClick={() => waitQueueStore.doRetry(x)}>재시도</Button>
                  ),
                },
              ]}
              data={waitQueueStore.queue || []}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />

            <FlexLayout className={style.buttons} size={38}>
              <Button
                className={style.btnDanger}
                isFull={true}
                onClick={() => waitQueueStore.removeAll()}
              >
                모두 삭제
              </Button>
              <Button
                className={style.btnSuccess}
                isFull={true}
                onClick={() => modalStore.closeWaitQueue()}
              >
                닫기
              </Button>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
